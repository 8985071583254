import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import planData, { IPlan, getCurrentPlan } from '../../planData';

const footerStyle: any = {
  background: '#f2f5f9',
  color: '#565f5f',
  fontSize: '14px',
  lineHeight: '30px',
};

const Footer: FC = () => {
  const [localPlanData, setLocalPlanData] = useState<IPlan>(planData[1]);

  useEffect(() => {
    setLocalPlanData(getCurrentPlan());
  }, []);

  return (
    <footer className="py-8 mt-8" style={footerStyle}>
      <div className="w-full px-4 md:px-8 mx-auto flex flex-col lg:flex-row lg:justify-between">
        {localPlanData.site === 'providerportaladmin.allyalign.com' ||
        localPlanData.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
        localPlanData.site ===
          'provider-portal-staging-admin.tutelarhealth.io' ? null : (
          <div className="flex justify-between w-full flex-col lg:flex-row">
            <div className="logo w-36 sm:w-56 mb-4">
              <Link to="/">
                <img src={localPlanData.logoUrl} alt="" />
              </Link>
            </div>
            <div className="footerNavigation">
              <div className="grid lg:grid-cols-3 gap-8">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.providerDocuments}
                    >
                      Provider Documents
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.exceptionsAndAppeals}
                    >
                      Exceptions and Appeals
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.claimsAndBilling}
                    >
                      Claims and Billing
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.prescribersAndPharmacies}
                    >
                      Prescribers and Pharmacies
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.compliance}
                    >
                      Compliance
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.quality}
                    >
                      Quality
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.privacyPolicy}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.disclaimers}
                    >
                      Disclaimers
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={localPlanData.languageAssistance}
                    >
                      Language Assistance
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;

import Cookies from 'js-cookie';
import { getCurrentPlan } from '../planData';
import instance from './axios';
import { ICcmReferralSubmit, IMarkAsReviewed } from '../interfaces/ccm-referrals';

const CONTEXT = '/referrals';

// CCM Referrals
export const getCcmReferrals = async () => {
  try {
    const { permissions } = getCurrentPlan();
    const email = Cookies.get('aah-email');
    const url = `${CONTEXT}/history/all`;
    const data = {
      email,
      sitePlans: permissions,
    };
    const response = await instance.post(url, data);
    return response.data.referrals;
  } catch (error: any) {
    throw error?.response?.data?.message || error.message;
  }
};

// CCM Referrals Details
export const getCcmReferral = async (requestId: string) => {
  try {
    const profileId = Cookies.get('aah-profileId');
    const { permissions } = getCurrentPlan();
    const email = Cookies.get('aah-email');
    const url = `${CONTEXT}/history/get/${profileId}/${requestId}`;
    const data = {
      email,
      sitePlans: permissions,
    };
    const res = await instance.post(url, data);
    return res.data.referral;
  } catch (error: any) {
    throw error?.response?.data?.message || error.message;
  }
};

// CCM Referrals Create
export const createCcmReferral = async (data: ICcmReferralSubmit) => {
  try {
    const url = `${CONTEXT}/submit`;
    const email = Cookies.get('aah-email');
    data.requestedBy = email;
    const res = await instance.post(url, data);
    return res.data;
  } catch (error: any) {
    throw error?.response?.data?.message || error.message;
  }
};

// CCM Referrals Mark as Reviewed
export const markCcmReferralsAsReviewed = async (payload: IMarkAsReviewed) => {
  const { notes, requestId, status } = payload;
  try {
    const { permissions } = getCurrentPlan();
    const email = Cookies.get('aah-email');
    const url = `${CONTEXT}/reviewed/${requestId}/${status}`;
    const data = {
      email,
      sitePlans: permissions,
      notes,
    };
    const res = await instance.post(url, data);
    return res.data.referral;
  } catch (error: any) {
    throw error?.response?.data?.message || error.message;
  }
};

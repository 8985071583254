import { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { AppBar, Box, Tab, Tabs } from '@material-ui/core';

import DrawerComponent from '../DrawerComponent';
import ErNotificationsTable from '../ErNotificationsTable';
import ErNotificationsTableSent from '../ErNotificationsTableSent';

export interface IErNotifications {
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  memberMiddleName: string;
  facilityNpi: string;
  facilityName: string;
  memberDateOfBirth: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ErNotifications: FC = () => {
  const [value, setValue] = useState(0);

  // Handle Change Tabs
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="customTable w-full px-4 md:px-8 mx-auto mb-16">
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="dashboard tabs">
          <Tab label="Submit Notifications" {...a11yProps(0)} />
          <Tab label="Sent Notifications" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DrawerComponent />
        <ErNotificationsTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ErNotificationsTableSent />
      </TabPanel>
    </div>
  );
};

export default ErNotifications;

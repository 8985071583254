import {
  FC,
  InputHTMLAttributes,
  MouseEvent,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from 'react';
import clsx from 'clsx';
import {
  useDropzone,
  FileRejection,
  DropzoneOptions,
  DropEvent,
} from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import {
  acceptStyle,
  activeStyle,
  rejectStyle,
  baseStyle,
} from './FileInputStyle';
import Cookies from 'js-cookie';

export type FileComposed = {
  file: File;
  name: string;
  action: 'ADD' | 'KEEP' | 'DELETE' | 'ADDED';
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onFileChange: (files: FileComposed[]) => void;
  // onDeleteFile: (event: MouseEvent<HTMLButtonElement>, index: number) => void
  updateBasedFiles: (index: number, fileName: string) => void;
  actionTitle?: string;
  files: {
    getValues: FileComposed[];
    setValues: Dispatch<SetStateAction<FileComposed[]>>;
  };
  icon: JSX.Element;
}

const FileInput: FC<Props> = (props) => {
  const role = Cookies.get('aah-role');
  const { icon, onFileChange, updateBasedFiles, files, actionTitle, ...rest } =
    props;

  async function onChangeFile(_files: File[]) {
    if (_files.length) {
      let acceptFile = false;
      for (const file of _files) {
        const mime = file.type.split('/')[0];
        acceptFile =
          file.type === rest.accept || (rest.accept || '').includes(mime);
      }

      if (acceptFile) {
        const composedFiles = Array.from(_files).map((file) => {
          const _file: FileComposed = {
            file: file,
            name: file.name,
            action: 'ADD',
          };

          return _file;
        });

        files.setValues((files) => [...files, ...composedFiles]);
      }
    }
  }

  function onDeleteFile(
    event: MouseEvent<HTMLButtonElement>,
    index: number,
    fileName: string
  ) {
    updateBasedFiles(index, fileName);

    const tempFiles = files.getValues;
    const diff = tempFiles.filter((item) => item.name !== fileName);
    files.setValues(diff);
    event.preventDefault();
  }

  // Drag and Drop
  const dropZoneOptions: DropzoneOptions = {
    accept: [
      'application/pdf',
      'application/doc',
      'application/docx',
      'image/*',
    ],
    onDrop: useCallback(onDrop, []),
    multiple: true,
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone(dropZoneOptions);

  const style = useMemo<CSSProperties>(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  function onDrop(
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) {
    onChangeFile(acceptedFiles);
  }

  const customFileUpload: any = {
    display: 'inline-block',
    paddingTop: '5px',
    cursor: 'pointer',
    paddingBottom: '7px',
  };

  return (
    <div>
      <h3>Documents to attach (where applicable)</h3>
      <p>
        History and Physical, Discharge Summary, Therapy Progress Notes,
        Medication list, etc.
      </p>
      <h3 className="mt-4">Files type allowed</h3>
      <p>
        All images type: JPEG, PNG, SVG, TIFF, BMP, etc. <br />
        Document type: PDF, DOCX
      </p>
      {role !== 'Viewer' && (
        <>
          <div {...getRootProps({ style, className: clsx('mt-8') })}>
            <input {...getInputProps()} />
            <article className="text-black">
              <h3>Drag a file here</h3>
              <p>Drag a file here</p>
              <button
                type="button"
                style={{
                  backgroundColor: '#E6E9ED',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #D0D4D8',
                  color: '#565F5F',
                  marginTop: '30px',
                }}
                className={`btn ${role === 'Viewer' && 'btn-disabled'}`}
                disabled={role === 'Viewer'}
              >
                Select a file from your device
              </button>
            </article>
          </div>
          <label style={customFileUpload}>
            {files.getValues && files.getValues.length ? (
              <div className="flex flex-wrap">
                {files.getValues
                  .filter((item) => item.action !== 'DELETE')
                  .map((file: FileComposed, index: number) => (
                    <div
                      key={`${file.name}-${index.toString()}`}
                      className={
                        'mr-4 border my-2 py-2 px-4 flex items-center justify-between relative'
                      }
                    >
                      <InsertDriveFileIcon className="mr-2" />
                      <div className="py-2">
                        <p style={{ fontSize: '10px' }} className="break-all">
                          {file.name}
                        </p>
                      </div>

                      {rest.multiple && (
                        <button
                          type="button"
                          onClick={(e) => onDeleteFile(e, index, file.name)}
                          aria-label="Delete an file"
                          style={{ top: '-12px', right: '-10px' }}
                          className="absolute"
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  ))}
              </div>
            ) : (
              <span className="ml-1.5">{''}</span>
            )}
          </label>
        </>
      )}
    </div>
  );
};

export default FileInput;

import instance from './axios';

export const getAllowedNpis = async () => {
  try {
    const url = `/restriction/NotificationForm`;
    const response = await instance.get(url);
    return response.data.restrictions.restrictions;
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
};

import { FC, useEffect, useState, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  useCancelErNotifications,
  useGetSentErNotifications,
} from '../../services/ServiceHook';
import { Select, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { convertArrayToCSV } from 'convert-array-to-csv';
import Cookies from 'js-cookie';
import { downloadBlob } from '../../utils/download-blob';

export interface IErNotifications {
  memberMiddleName: string;
  facilityNpi: string;
  sendDate: string;
  createdAt: string;
  facilityNPI: string;
  facilityName: string;
  hospitalId: string;
  hospitalName: string;
  hospitalPhone: string;
  id: string;
  memberFirstName: string;
  memberId: string;
  memberLastName: string;
  requestId: string;
  sendStatus: string;
  sitePlans: string[];
  updatedAt: string;
}

interface IControls {
  label: string;
  value: string;
}

const ErNotificationsTableSent: FC = () => {
  const [gridRef, setGridRef] = useState<any>(null);
  const [data, setData] = useState<IErNotifications[] | []>([]);
  const [paginatedData, setPaginatedData] = useState<IErNotifications[] | []>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [getSentErNotifications] = useGetSentErNotifications();
  const [cancelErNotifications] = useCancelErNotifications();
  const [page, setPage] = useState(1);
  const [isCanceling, setIsCanceling] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [hasClicked, setHasClicked] = useState(false);
  const gridStyle = { minHeight: 725 };
  const [filterValue, setFilterValue] = useState('');
  const [filterCounter, setFilterCounter] = useState(0);
  const [filterBy, setFilterBy] = useState('');
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [sendStatus, setSendStatus] = useState('');
  const [isPaginated, setIsPaginated] = useState(true);
  const [isCSVDisabled, setIsCSVDisabled] = useState(false);
  const role = Cookies.get('aah-role');

  const sendStatusList = [
    {
      label: 'Sent to Hospital',
      value: 'Sent to Hospital',
    },
    {
      label: 'Returning from Hospital',
      value: 'Returning from Hospital',
    },
    {
      label: 'Cancelled',
      value: 'Cancelled',
    },
  ];

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const filterByControls = [
    {
      label: 'Member Id',
      value: 'memberId',
    },
    {
      label: 'Member First Name',
      value: 'memberFirstName',
    },
    {
      label: 'Member Last Name',
      value: 'memberLastName',
    },
    {
      label: 'Facility Npi',
      value: 'facilityNpi',
    },
    {
      label: 'Facility Name',
      value: 'facilityName',
    },
    {
      label: 'Hospital NPI',
      value: 'hospitalId',
    },
    {
      label: 'Hospital Name',
      value: 'hospitalName',
    },
    {
      label: 'Status',
      value: 'sendStatus',
    },
    {
      label: 'Sent Date/Returning',
      value: 'sendDate',
    },
    {
      label: 'Updated At',
      value: 'updatedAt',
    },
  ];

  // React Data Grid
  const columns = [
    {
      name: 'id',
      header: 'Id',
      minWidth: 150,
    },

    {
      name: 'facilityNPI',
      header: 'Facility NPI',
      minWidth: 150,
    },
    {
      name: 'facilityName',
      header: 'Facility Name',
      defaultFlex: 1,
    },
    {
      name: 'memberId',
      header: 'Member Id',
      minWidth: 150,
    },
    {
      name: 'memberFirstName',
      header: 'First Name',
      defaultFlex: 1,
    },
    {
      name: 'memberLastName',
      header: 'Last Name',
      defaultFlex: 1,
    },
    {
      name: 'hospitalId',
      header: 'Hospital NPI',
      minWidth: 150,
    },
    {
      name: 'hospitalName',
      header: 'Hospital Name',
      defaultFlex: 1,
    },
    {
      name: 'sendDate',
      header: 'Date Sent/Returning',
      minWidth: 180,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (value: any) => {
        return value.data.sendDate
          ? moment(value.data.sendDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'sendStatus',
      header: 'Status',
      defaultFlex: 1,
      render: (value: any) => {
        return (
          <div
            className={clsx({
              'bg-gray-400 text-white py-1 text-center':
                value.data.sendStatus === 'Cancelled',
              'bg-green-800 text-white py-1 text-center':
                value.data.sendStatus === 'Sent To Hospital' ||
                value.data.sendStatus === 'Sent to Hospital' ||
                value.data.sendStatus === 'Send to' ||
                value.data.sendStatus === 'Sent to' ||
                value.data.sendStatus === 'Sent To',
              'bg-blue-900 text-white py-1 text-center':
                value.data.sendStatus === 'Returning From Hospital' ||
                value.data.sendStatus === 'Returning from Hospital' ||
                value.data.sendStatus === 'Returning From',
            })}
          >
            {value.data.sendStatus}
          </div>
        );
      },
    },
    {
      name: 'action',
      header: 'Action',
      render: (value: any) => {
        if (value.data.sendStatus === 'Cancelled' || role === 'Viewer')
          return (
            <button
              disabled
              className={`bg-gray-400 text-white py-1 text-center w-full cursor-not-allowed`}
            >
              Cancel
            </button>
          );
        return (
          <button
            disabled={isCanceling}
            onClick={() => {
              setHasClicked(true);
            }}
            className={`border-2 border-red-300 text-red-400 hover:bg-red-300 hover:text-white py-1 text-center w-full `}
          >
            Cancel
          </button>
        );
      },
    },
  ];

  // Filter for CURRENT TAB
  const defaultFilterValue = [
    {
      name: 'id',
      operator: 'eq',
      type: 'string',
      value: '',
    },
    {
      name: 'facilityNPI',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'facilityName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'memberId',
      operator: 'eq',
      type: 'string',
      value: '',
    },
    {
      name: 'memberFirstName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'memberLastName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'hospitalId',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'hospitalName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'sendDate',
      operator: 'before',
      type: 'date',
      value: '',
    },
    {
      name: 'sendStatus',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const erNotifications = await getSentErNotifications();
    if (erNotifications && erNotifications.length) {
      setData(erNotifications);
      setPage(1);
      setPaginatedData(paginate(erNotifications, 10, 1));
      setFilterCounter(erNotifications.length);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (requestId && hasClicked) {
      handleCancelErNotification();
      setHasClicked(false);
    }
  }, [requestId, hasClicked]);

  useEffect(() => {
    if (filterBy !== 'sendStatus') {
      setSendStatus('');
    }
    if (filterBy !== 'sendDate' && filterBy !== 'updatedAt') {
      setSelectedDate(null);
    }
    if (filterBy && (filterBy === 'sendDate' || filterBy === 'updatedAt')) {
      setFilterValue('');
    }
  }, [filterBy]);

  useEffect(() => {
    let found = [...data];
    if (filterBy === 'sendStatus' && sendStatus) {
      found = data.filter((item: any) => item.sendStatus === sendStatus);
    }

    if (filterBy === 'sendDate' && selectedDate) {
      found = data.filter(
        (item: any) =>
          moment(item.sendDate).format('MM/DD/yyyy').toString() ===
          moment(selectedDate).format('MM/DD/yyyy').toString()
      );
    }
    if (filterBy === 'updatedAt' && selectedDate) {
      found = data.filter(
        (item: any) =>
          moment(item.updatedAt).format('MM/DD/yyyy').toString() ===
          moment(selectedDate).format('MM/DD/yyyy').toString()
      );
    }
    if (
      filterBy !== 'sendDate' &&
      filterBy !== 'updatedAt' &&
      filterValue.length
    ) {
      found = data.filter((item: any) =>
        item[filterBy].toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    updatePagination(found);
  }, [filterValue, filterBy, selectedDate, sendStatus]);

  // Once a row is selected
  const onSelectionChange = useCallback(({ selected }) => {
    setRequestId(selected);
  }, []);

  const onSelectChange = (event: React.ChangeEvent<any>) => {
    setSendStatus(event.target.value);
  };

  const handleCancelErNotification = async () => {
    setIsCanceling(true);
    const response: any = await cancelErNotifications(requestId);
    if (response.status === 200) {
      const x = [...data];
      const found = x.findIndex((item) => item.requestId === requestId);
      x[found] = response.data.notification;
      setData(x);
      setPage(1);
      setPaginatedData(paginate(x, 10, 1));
    }
    setIsCanceling(false);
  };

  const handleCancelErNotificationMobile = async (id: string) => {
    const response: any = await cancelErNotifications(id);
    if (response.status === 200) {
      getData();
    }
    setIsCanceling(false);
  };

  const updatePagination = (tempData: any[]) => {
    setFilterCounter(tempData.length);
    setPage(1);
    setPaginatedData(paginate(tempData, 10, 1));
  };

  // Pagination
  const paginate = (array: any[], pageSize: number, pageNumber: number) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setPaginatedData(paginate(data, 10, value));
  };

  const handleChangeFilterBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBy((event.target as HTMLInputElement).value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  const getRows = (ref: any) => {
    let dataFormatted = ref.map((item: any) => {
      return {
        ...item,
        sendDate: moment(item.sendDate).format('MM/DD/yyyy'),
      };
    });
    return dataFormatted;
  };

  // Export to CSV
  const exportCSV = () => {
    setIsPaginated(false);
    setIsCSVDisabled(true);
    setTimeout(() => {
      const columns = gridRef.current.visibleColumns;
      let header = columns.map((c: any) => c.header);
      header = header.filter((item: any) => item !== 'Action');
      const dataFormatted = getRows(gridRef.current.data);
      const rows = dataFormatted.map((data: any) =>
        columns.map((c: any) => data[c.id])
      );
      const contents = convertArrayToCSV(rows, {
        header,
        separator: ',',
      });
      const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
      downloadBlob(blob, 'Hospital-Notifications.csv');
      setIsPaginated(true);
      setIsCSVDisabled(false);
    }, 1000);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center mt-8">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="showDesktop mt-4">
        <div className="flex justify-end">
          <button
            onClick={exportCSV}
            className={`btn btn-blue my-4 ${
              (isCSVDisabled || role === 'Viewer') && 'btn-disabled'
            }`}
            disabled={isCSVDisabled || role === 'Viewer'}
          >
            Export CSV
          </button>
        </div>
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="requestId"
          onReady={setGridRef}
          dataSource={data}
          style={gridStyle}
          defaultFilterValue={defaultFilterValue}
          enableSelection={true}
          onSelectionChange={onSelectionChange}
          columns={columns}
          pagination={isPaginated}
          defaultLimit={15}
        />
      </div>
      <div className="showMobile mt-4">
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters"
            id="er-notifications-filters"
          >
            <div className="flex flex-col w-full">
              <div className="font-bold">
                <span className="text-gray-500">Filters</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="flex flex-col">
            <FormControl component="fieldset">
              <FormLabel component="legend">Filter by</FormLabel>
              <RadioGroup
                aria-label="filterBy"
                name="filterBy"
                value={filterBy}
                onChange={handleChangeFilterBy}
              >
                {filterByControls.map((item: IControls) => {
                  return (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio color="primary" />}
                      label={item.label}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            {filterBy &&
            filterBy !== 'sendDate' &&
            filterBy !== 'updatedAt' &&
            filterBy !== 'sendStatus' ? (
              <form noValidate autoComplete="off" className="w-full">
                <TextField
                  className="w-full mb-4"
                  id="filled-name"
                  label="text here..."
                  value={filterValue}
                  onChange={handleFilterChange}
                  disabled={!filterBy ? true : false}
                />
              </form>
            ) : null}
            {filterBy &&
            (filterBy === 'sendDate' || filterBy === 'updatedAt') ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <KeyboardDatePicker
                    className="w-full"
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            ) : null}
            {filterBy === 'sendStatus' ? (
              <div className="mt-4">
                <FormControl variant="outlined" className="w-full mt-8">
                  <Select
                    labelId="role"
                    id="role-select"
                    name="role"
                    value={sendStatus}
                    onChange={onSelectChange}
                  >
                    {sendStatusList.map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </AccordionDetails>
        </Accordion>
        {paginatedData.length ? (
          paginatedData.map((item: any, key: number) => {
            return (
              <Accordion className="mb-4" key={key}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="er-notifications-header"
                >
                  <div className="flex flex-col w-full">
                    <div className="font-bold">
                      <span className="text-gray-500">{item.memberId}</span>
                    </div>
                    <span>
                      <b>
                        {item.memberFirstName} {item.memberLastName}
                      </b>
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    <hr className="mb-4" />
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Facility NPI: </span>
                      <span className="font-bold">{item.facilityNPI}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Facility Name: </span>
                      <span className="font-bold">{item.facilityName}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Hospital NPI: </span>
                      <span className="font-bold">{item.hospitalId}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Hospital Name: </span>
                      <span className="font-bold">{item.hospitalName}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Hospital Phone: </span>
                      <span className="font-bold">{item.hospitalPhone}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">
                        Sent Date/Returning:
                      </span>
                      <span className="font-bold">
                        {moment(item.sendDate).format('MM/DD/yyyy')}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Status: </span>
                      <span className="font-bold">{item.sendStatus}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Updated at:</span>
                      <span className="font-bold">
                        {moment(item.updatedAt).format('MM/DD/yyyy')}
                      </span>
                    </div>
                    <div>
                      {item.sendStatus === 'Cancelled' ? (
                        <button
                          disabled
                          className={`w-full bg-gray-400 text-white py-1 px-6 my-2 cursor-not-allowed`}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          disabled={isCanceling}
                          onClick={() => {
                            handleCancelErNotificationMobile(item.requestId);
                          }}
                          className={`w-full border-2 border-red-300 text-red-400 hover:bg-red-300 hover:text-white py-1 px-6 my-2 `}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <div className="mb-4">
            <Accordion>
              <AccordionSummary>There is no data to show</AccordionSummary>
            </Accordion>
          </div>
        )}
        <div className="flex flex-col items-center showMobile w-full">
          <Typography className="pb-2">Page: {page}</Typography>
          <Pagination
            count={Math.round(filterCounter / 10)}
            page={page}
            onChange={handlePagination}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};

export default ErNotificationsTableSent;

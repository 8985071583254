import Cookies from 'js-cookie';
import { getCurrentPlan } from '../planData';
import instance from './axios';

const CONTEXT = '/pqi';

const getPqiReferrals = async () => {
  const { permissions } = getCurrentPlan();
  const email = Cookies.get('aah-email');

  try {
    if (email && permissions) {
      const url = `${CONTEXT}/history/all`;
      const data = {
        email,
        sitePlans: permissions,
      };
      const response = await instance.post(url, data);
      return response.data.referrals;
    }
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
};

const getPqiReferral = async (requestId: string) => {
  const { permissions } = getCurrentPlan();
  const email = Cookies.get('aah-email');
  const profileId = Cookies.get('aah-profileId');
  try {
    const url = `${CONTEXT}/history/get/${profileId}/${requestId}`;
    const data = {
      email,
      sitePlans: permissions,
    };
    const response = await instance.post(url, data);
    return response.data.referral;
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
};

const createPqiReferral = async (referral: any) => {
  const { permissions } = getCurrentPlan();
  try {
    const url = `${CONTEXT}/submit`;
    referral.sitePlans = permissions;
    const response = await instance.post(url, referral);
    return response.data;
  } catch (error: any) {
    throw error.response.data.message || error.message;
  }
};

export { getPqiReferrals, getPqiReferral, createPqiReferral };

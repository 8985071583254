import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMainProvider } from '../../providers/MainProvider';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSendErNotifications } from '../../services/ServiceHook';
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { setDrawer } from '../../store/DrawerSlice';

const initialValues = {
  facilityName: '',
  facilityNPI: '',
  memberDateOfBirth: '',
  memberFirstName: '',
  memberLastName: '',
  memberId: '',
  memberMiddleInitial: '',
  hospitalId: '',
  hospitalName: '',
  hospitalPhone: '',
  sendDate: '',
  sendStatus: '',
};

const validationSchema = Yup.object({
  hospitalId: Yup.string(),
  sendDate: Yup.string().required('Required'),
  hospitalName: Yup.string().required('Required'),
  sendStatus: Yup.string().required('Required'),
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErNotificationSubmissionForm: FC = () => {
  const [sendErNotifications] = useSendErNotifications();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [value, setValue] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const role = Cookies.get('aah-role');
  const profile = JSON.parse(sessionStorage.getItem('profile') || '');
  const { data } = useSelector((state: RootState) => state.drawer);
  const dispatch = useDispatch();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setFieldValue('sendStatus', event.target.value);
  };

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    getFieldProps,
    resetForm,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(
      {
        memberId,
        facilityNPI,
        facilityName,
        hospitalName,
        hospitalId,
        memberFirstName,
        memberLastName,
        hospitalPhone,
        sendDate,
        sendStatus,
      },
      formikHelpers
    ) {
      setIsSaving(true);
      const payload = {
        id: profile.id,
        requestId: uuidv4(),
        memberId,
        memberFirstName,
        memberLastName,
        facilityNPI,
        facilityName,
        hospitalName,
        hospitalId: hospitalId ? hospitalId : '',
        hospitalPhone: hospitalPhone ? hospitalPhone : '',
        sendDate,
        sendStatus,
      };
      const response: any = await sendErNotifications(payload);
      if (response.status === 200) {
        setIsSaving(false);
        setOpenSnackbar(true);
        resetForm();
        setSelectedDate(null);
        setTimeout(() => {
          dispatch(
            setDrawer({
              isOpen: false,
              data: {},
            })
          );
        }, 2500);
      } else {
        setIsSaving(false);
        setOpenErrorSnackbar(true);
        setSelectedDate(null);
      }
    },
  });

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setFieldValue('sendDate', date);
  };

  useEffect(() => {
    if (data) {
      setValues(data);
      setFieldValue('facilityNPI', data.facilityNpi);
    }
  }, [data, setFieldValue, setValues]);

  if (isSaving) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4 flex flex-col">
          <label htmlFor="memberId" className="mb-2">
            Member ID
          </label>
          <FormControl variant="outlined">
            <InputLabel htmlFor="memberId"></InputLabel>
            <OutlinedInput
              id="memberId"
              disabled
              className="bg-opacity"
              {...getFieldProps('memberId')}
            />
          </FormControl>
        </div>

        <div className="flex flex-col">
          <div className="mb-4 flex flex-col">
            <label htmlFor="memberFirstName" className="mb-2">
              First Name
            </label>
            <FormControl variant="outlined">
              <InputLabel htmlFor="memberFirstName"></InputLabel>
              <OutlinedInput
                id="memberFirstName"
                disabled
                className="bg-opacity"
                {...getFieldProps('memberFirstName')}
              />
            </FormControl>
          </div>

          <div className="mb-4 flex flex-col">
            <label htmlFor="memberLastName" className="mb-2">
              Last Name
            </label>
            <FormControl variant="outlined">
              <InputLabel htmlFor="memberLastName"></InputLabel>
              <OutlinedInput
                id="memberLastName"
                disabled
                className="bg-opacity"
                {...getFieldProps('memberLastName')}
              />
            </FormControl>
          </div>
        </div>

        <div className="mb-4 flex flex-col">
          <label htmlFor="hospitalId" className="mb-2">
            Hospital NPI
          </label>
          <FormControl variant="outlined">
            <InputLabel htmlFor="hospitalId"></InputLabel>
            <OutlinedInput
              id="hospitalId"
              className="bg-opacity"
              disabled={role === 'Viewer'}
              {...getFieldProps('hospitalId')}
            />
          </FormControl>
          {touched.hospitalId && errors.hospitalId ? (
            <div className="text-red-500">{errors.hospitalId}</div>
          ) : null}
        </div>

        <div className="mb-4 flex flex-col">
          <label htmlFor="hospitalName" className="mb-2">
            Hospital Name
          </label>
          <FormControl variant="outlined">
            <InputLabel htmlFor="hospitalName"></InputLabel>
            <OutlinedInput
              id="hospitalName"
              className="bg-opacity"
              disabled={role === 'Viewer'}
              {...getFieldProps('hospitalName')}
            />
          </FormControl>
        </div>

        <div className="mb-4 flex flex-col">
          <label htmlFor="hospitalPhone" className="mb-2">
            Hospital Phone Number
          </label>
          <FormControl variant="outlined">
            <InputLabel htmlFor="hospitalPhone"></InputLabel>
            <OutlinedInput
              id="hospitalPhone"
              className="bg-opacity"
              disabled={role === 'Viewer'}
              {...getFieldProps('hospitalPhone')}
            />
          </FormControl>
        </div>

        <div className="mb-4 flex flex-col">
          <label htmlFor="sentStatus" className="mb-2">
            Status
          </label>
          <FormControl component="fieldset" disabled={role === 'Viewer'}>
            <RadioGroup
              aria-label="sendStatus"
              id="sendStatus"
              value={value}
              onChange={handleChange}
              className="flex"
            >
              <FormControlLabel
                value="Sent to Hospital"
                control={<Radio color="primary" />}
                label="Sent to Hospital"
              />
              <FormControlLabel
                value="Returning from Hospital"
                control={<Radio color="primary" />}
                label="Returning from Hospital"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {value ? (
          <div className="mb-4 flex flex-col">
            <label htmlFor="sendDate" className="mb-2">
              {value === 'Sent to Hospital'
                ? 'Date sent to Hospital'
                : 'Date returning from Hospital'}
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="Select date"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                disabled={role === 'Viewer'}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                // {...getFieldProps('sendDate')}
              />
            </MuiPickersUtilsProvider>
            {touched.sendDate && errors.sendDate ? (
              <div className="text-red-500">{errors.sendDate}</div>
            ) : null}
          </div>
        ) : null}

        <div className="flex justify-end mt-8">
          <button
            type="button"
            className="border-2 py-2 px-8 mr-4 hover:bg-gray-200"
            onClick={() => {
              dispatch(
                setDrawer({
                  isOpen: false,
                  data: {},
                })
              );
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isValid || role === 'Viewer'}
            className={
              isValid || role !== 'Viewer'
                ? 'bg-blue-900 text-white py-2 px-8'
                : 'bg-gray-400 text-white py-2 px-8 cursor-not-allowed'
            }
          >
            Submit
          </button>
        </div>
      </form>
      {/* Success */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          ER Notification Submitted Successfully!
        </Alert>
      </Snackbar>

      {/* Error */}
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert onClose={handleCloseErrorSnackbar} severity="error">
          There was an error sending submission, please try again.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ErNotificationSubmissionForm;

import { ICreateAttachment } from "../interfaces/attachments";
import { IAuthorizationDraftParms } from "../interfaces/authorizations-draft";
import { getCurrentPlan } from "../planData";
import instance from "./axios";

const context = '/preauth/draft';
export const getAuthorizationDraft = async ({userId, id}: IAuthorizationDraftParms) => {
  try {
    const { permissions } = getCurrentPlan()
    const url = `${context}/get/${userId}/${id}`
    const response = await instance.post(url, { sitePlans: permissions });
    return response.data.drafts[0];
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
}

// TODO: Change any to a real model
export const postDraft = async (data: any) => {
  try {
    const response = await instance.post(context, data);
    return response.data.drafts;
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
}

export const postDraftAttachments = async (data: ICreateAttachment) => {
  try {
    const url = `/preauth/attachments/draft`;
    const response = await instance.post(url, data);
    return response.data;
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
}
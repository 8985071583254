import { StyleSheet } from '@react-pdf/renderer';
// Create styles

export const styles = StyleSheet.create({
  logo: {
    width: '170px',
    height: '54px',
    marginBottom: '30px',
  },
  page: {
    backgroundColor: '#fff',
    width: '100%',
  },
  section: {
    margin: 10,
    padding: 10,
    fontSize: 12,
  },
  title: {
    fontSize: 12,
    marginBottom: 15,
  },
  bigTitle: {
    fontSize: 35,
    color: 'red',
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
    borderRadius: 5,
    backgroundColor: '#003967',
    color: '#ffffff',
  },
  sectionSubHeader: {
    color: '#003967',
    backgroundColor: '#F2F5F9',
    borderRadius: 5,
    padding: 15,
    marginBottom: 15,
  },
  tableView: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    marginLeft: 15,
    width: 400,
  },
  tableColumn: {
    padding: 10,
    borderLeft: 1,
    borderTop: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    fontSize: 12,
    width: 100,
  },
});

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  approved: {
    background: '#81B920',
    color: '#fff',
    padding: '5px 10px',
    width: '100%',
    fontWeight: 'normal',
    fontSize: '13px',
    textAlign: 'center'
  },
  partiallyApproved: {
    background: 'white',
    border: '1px solid #81b920',
    color: '#81b920',
    padding: '5px 10px',
    fontSize: '13px',
    textAlign: 'center'
  },
  deferred: {
    background: 'white',
    border: '1px solid #1e508c',
    color: '#1e508c',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  deniedAdministrative: {
    background: 'white',
    border: '1px solid #dd4c21',
    color: '#dd4c21',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  deniedMedicalNecessity: {
    background: '#dd4c21',
    color: '#fff',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  outOfNetwork: {
    background: 'white',
    border: '1px solid #f5a81c',
    color: '#f5a81c',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  pended: {
    background: 'white',
    border: '1px solid #f5a81c',
    color: '#f5a81c',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  cancelled: {
    background: '#999999',
    color: 'white',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  waiting: {
    background: '#1e508c',
    color: 'white',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  notification: {
    border: '1px solid #1e508c',
    color: '#1e508c',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
  customchip: {
    background: '#E0E0E0',
    borderRadius: '25px',
    padding: '6px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    width: '65px'
  },
  servicePended: {
    background: '#f5a81c',
    border: '1px solid #f5a81c',
    color: 'white',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '25px',
  },
}));

export default useStyles;

import { StyleSheet } from '@react-pdf/renderer';

// Create styles
// TODO: clean this code.
export const styles = StyleSheet.create({
  logo: {
    width: '170px',
    height: '54px',
    marginBottom: '30px',
  },
  page: {
    backgroundColor: '#fff',
    width: '100%',
    minHeight: '100vh',
  },
  pageHeaderText: {
    fontSize: 10,
    fontStyle: 'Italic',
    marginBottom: 30,
  },
  pageHeaderTitle: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 15,
  },
  textLink: {
    textDecoration: 'underline',
    color: 'blue',
  },
  section: {
    // margin: 10,
    padding: 10,
    fontSize: 12,
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
  },
  bigTitle: {
    fontSize: 35,
    color: 'red',
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: 13,
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: '#9CC2E5',
    color: '#000000',
    border: 1,
  },
  sectionSubHeader: {
    color: '#003967',
    backgroundColor: '#F2F5F9',
    borderRadius: 5,
    padding: 15,
    marginBottom: 15,
  },
  tableView: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tableViewSignature: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: 1,
    borderBottom: 1,
    borderLeft: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tableBorderBottom: {
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
  },
  tableViewTextWidth45: {
    width: '45%', 
    paddingRight: 10,
  },
  tableViewTextWidth10: {
    width: '10%',
  },
  tableViewTextWidth50: {
    width: '50%', 
  },
  tableColumn: {
    padding: 10,
    borderLeft: 1,
    borderTop: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    fontSize: 12,
    width: 100,
  },

  // Section II
  sectionTwo: {
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 10,
    paddingRight: 10,
  }
});

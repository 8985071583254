import { FC } from 'react';

interface IHeaderTitle {
  title: string;
  origin?: string;
}

const HeaderTitle: FC<IHeaderTitle> = ({ title, origin }) => {
  return (
    <div className="customTable w-full px-4 md:px-8 mx-auto">
      <div className="flex flex-col lg:flex-row justify-between pt-8 print:pt-0">
        <h1 className="text-xl lg:text-3xl">{title}</h1>
        <div className="flex">
          {origin === 'details' ? (
            <button type="button" className="btn btn-gray mr-4 flex">
              <img src="/images/print.png" alt="" />
              <span>Print</span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HeaderTitle;

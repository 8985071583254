import { FC } from 'react';
import CustomChip from '../../CustomChip';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { IChipObject, IStatus } from './DashboardFilters';

interface IProps {
  expanded: string | false;
  status: IStatus[];
  mdReviewed: string;
  lastMdReviewedStatus: {
    label: string;
    value: string;
  }[];
  serviceFromDate: {
    startDate: undefined;
    endDate: undefined;
    key: string;
  };
  serviceToDate: {
    startDate: undefined;
    endDate: undefined;
    key: string;
  };
  npiArray: string[];
  radioValue: string;
  handleChange: (
    panel: string
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  resetFilters: () => void;
  handleStatus: (value: IChipObject) => void;
  handleChangeMdReviewed: (event: any) => void;
  handleServiceFromDate: (ranges: any) => void;
  handleClearServiceFromDate: () => void;
  handleServiceToDate: (ranges: any) => void;
  handleClearServiceToDate: () => void;
  findChecked: (value: string) => boolean;
  handleNPIChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRadioButtonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFilterInputText: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFilterByColumns: () => void;
}

const DashboardFiltersAccordion: FC<IProps> = ({
  expanded,
  status,
  mdReviewed,
  lastMdReviewedStatus,
  serviceFromDate,
  serviceToDate,
  npiArray,
  radioValue,
  handleChange,
  resetFilters,
  handleStatus,
  handleChangeMdReviewed,
  handleServiceFromDate,
  handleClearServiceFromDate,
  handleServiceToDate,
  handleClearServiceToDate,
  findChecked,
  handleNPIChange,
  handleRadioButtonChange,
  handleFilterInputText,
  handleFilterByColumns,
}) => {
  return (
    <div className="w-full my-4 showMobile">
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <FilterListIcon className="mr-4" />
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col w-full">
            {/* Filter by Status */}
            <div>
              <button className="btn mb-4" onClick={resetFilters}>
                Clear Filters
              </button>
              <div className="mb-2 input-label">Filter by status:</div>
              <div className="mb-6">
                {status.map((item: IChipObject) => (
                  <div className="inline-block mr-2 mb-2" key={item.id}>
                    <CustomChip item={item} handleStatus={handleStatus} />
                  </div>
                ))}
              </div>

              <div className="mb-6">
                <div className="mb-2 input-label">Filter by MD Review:</div>
                <FormControl fullWidth>
                  <InputLabel>MD Reviewed?</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mdReviewed}
                    label="Age"
                    onChange={handleChangeMdReviewed}
                  >
                    {lastMdReviewedStatus.map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="mb-6 flex flex-col items-start">
                <div className="mb-2 input-label">
                  Filter by Service From Date:
                </div>
                <DateRangePicker
                  ranges={[serviceFromDate]}
                  onChange={handleServiceFromDate}
                />
                <button
                  type="button"
                  className="btn btn-gray"
                  onClick={handleClearServiceFromDate}
                >
                  Cancel
                </button>
              </div>

              <div className="mb-6 flex flex-col items-start">
                <div className="mb-2 input-label">
                  Filter by Service To Date:
                </div>
                <DateRangePicker
                  ranges={[serviceToDate]}
                  onChange={handleServiceToDate}
                />
                <button
                  type="button"
                  className="btn btn-gray"
                  onClick={handleClearServiceToDate}
                >
                  Cancel
                </button>
              </div>
            </div>

            {/* Filter by NPI */}
            <div className="grid grid-cols-1 gap-4">
              <div className="mb-6">
                <FormControl component="fieldset" className="w-full">
                  <FormLabel component="legend">Filter by NPI</FormLabel>
                  <FormGroup>
                    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6 gap-4">
                      {npiArray.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              className="p-0"
                              checked={findChecked(item)}
                              onChange={handleNPIChange}
                              name={item}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </div>
                  </FormGroup>
                </FormControl>
              </div>
            </div>

            <div className="showMobile">
              <div className="pb-2 input-label">Filter by:</div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="filterColumn"
                  name="filterColumn"
                  value={radioValue}
                  onChange={handleRadioButtonChange}
                >
                  <FormControlLabel
                    value="authNumber"
                    control={<Radio color="primary" />}
                    label="ID"
                  />
                  <FormControlLabel
                    value="memberId"
                    control={<Radio color="primary" />}
                    label="Member Id"
                  />
                  <FormControlLabel
                    value="memberFirstName"
                    control={<Radio color="primary" />}
                    label="First Name"
                  />
                  <FormControlLabel
                    value="memberLastName"
                    control={<Radio color="primary" />}
                    label="Last Name"
                  />
                  <FormControlLabel
                    value="diagnosisDescription"
                    control={<Radio color="primary" />}
                    label="Diagnosis Description"
                  />
                  <FormControlLabel
                    value="procedureDescription"
                    control={<Radio color="primary" />}
                    label="Procedure Description"
                  />
                </RadioGroup>
              </FormControl>
              <div className="flex items-center mt-4">
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="w-full mr-2"
                  onChange={handleFilterInputText}
                />
                <button
                  className="text-white p-3 rounded-md ml-2 flex items-center"
                  onClick={handleFilterByColumns}
                  style={{ backgroundColor: '#1e508c', marginTop: '-4px' }}
                >
                  <Icon>search</Icon>
                </button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DashboardFiltersAccordion;

import { FC, useCallback, useEffect, useState } from 'react';
import { IDashboardDraft } from '../../../providers/MainProvider';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { useGetDashboardDraft } from '../../../services/ServiceHook';

window.moment = moment;

const columns = [
  {
    name: 'requestId',
    header: 'Id',
    defaultFlex: 1,
  },
  {
    name: 'createdAt',
    header: 'Created at',
    defaultFlex: 1,
  },
  {
    name: 'updatedAt',
    header: 'Updated at',
    defaultFlex: 1,
  },
  {
    name: 'memberId',
    header: 'Member Id',
    defaultFlex: 1,
  },
  {
    name: 'memberFirstName',
    header: 'First Name',
    defaultFlex: 1,
  },
  {
    name: 'memberLastName',
    header: 'Last Name',
    defaultFlex: 1,
  },
];

const defaultFilterValue = [
  {
    name: 'requestId',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'createdAt',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'updatedAt',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'memberId',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'memberFirstName',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'memberLastName',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
];

const DashboardTableNotificationDesktop: FC = () => {
  const [getDraft] = useGetDashboardDraft();
  const [currentNotification, setCurrentNotification] = useState<
    IDashboardDraft[]
  >([]);

  const getData = useCallback(async () => {
    const draftData = await getDraft();
    // Notification Only
    const onlyNotificationAuthStatus = draftData.filter(
      (item: any) =>
        item.auth.requestId !== '' && item.auth.authStatus === 'Notification'
    );
    if (onlyNotificationAuthStatus && onlyNotificationAuthStatus.length) {
      const notification = onlyNotificationAuthStatus.map((item: any) => ({
        requestId: item.auth.requestId,
        createdAt: item.auth.createdAt
          ? moment(item.auth.createdAt).format('LLL')
          : null,
        updatedAt: item.auth.updatedAt
          ? moment(item.auth.updatedAt).format('LLL')
          : null,
        memberId: item.auth.memberId,
        memberFirstName: item.auth.memberFirstName,
        memberLastName: item.auth.memberLastName,
      }));
      setCurrentNotification(
        notification.filter((item: any) => item.requestId !== '')
      );
    }
  }, [getDraft]);

  useEffect(() => {
    getData();
  }, [getData]);

  const gridStyle = { minHeight: 650 };
  const rowStyle = { cursor: 'pointer' };

  return (
    <div>
      <div className="mt-4 showDesktop">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="id"
          columns={columns}
          dataSource={currentNotification}
          style={gridStyle}
          rowStyle={rowStyle}
          defaultFilterValue={defaultFilterValue}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default DashboardTableNotificationDesktop;

import { FieldInputProps, FormikErrors } from 'formik';
import { FC, useState } from 'react';
import { IStandardForm } from '../../interfaces/standard-form';
import {
  FormControl,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
} from '@material-ui/core';

interface IProps {
  formValues: IStandardForm;
  hasNoPermissions: boolean;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IStandardForm>>;
}
export const RequestDetailsType: FC<IProps> = ({
  formValues,
  hasNoPermissions,
  getFieldProps,
  setFieldValue,
}) => {
  const [typeCheckbox, setTypeCheckbox] = useState([
    {
      name: 'PT',
      label: 'PT',
      checked: false,
    },
    {
      name: 'OT',
      label: 'OT',
      checked: false,
    },
    {
      name: 'ST',
      label: 'ST',
      checked: false,
    },
    {
      name: 'Other',
      label: 'Other',
      checked: false,
    },
  ]);

  const handleCheckboxChange = (e: boolean, index: number) => {
    const updatedTypeCheckbox = [...typeCheckbox];
    updatedTypeCheckbox[index].checked = e;
    setTypeCheckbox(updatedTypeCheckbox);

    const updatedServiceArray = [...formValues.outPatientServicesArray];

    // Clean Service Array
    updatedServiceArray[index].totalQty = e
      ? updatedServiceArray[index].totalQty
      : 0;

    updatedServiceArray[index].timesWeek = e
      ? updatedServiceArray[index].timesWeek
      : 0;

    updatedServiceArray[index].duration = e
      ? updatedServiceArray[index].duration
      : 0;

    setFieldValue('outPatientServicesArray', updatedServiceArray);
  };

  return (
    <div>
      <div className="hidden xl:grid xl:grid-cols-4 xl:gap-6 mt-8 pb-8">
        <label className="label-top mb-2">Type</label>
        <label className="label-top mb-2">Total Qty</label>
        <label className="label-top mb-2">Times / Week</label>
        <label className="label-top mb-2">Duration in weeks</label>
      </div>
      <label className="label-top mb-2 block xl:hidden">Type</label>
      {formValues &&
        formValues.outPatientServicesArray.map((service, index) => {
          const isDisabled = typeCheckbox.some(
            (type) => type.name === service.type && type.checked
          );
          return (
            <div key={index} className="grid xl:grid-cols-4 xl:gap-6 pb-8">
              <div className="flex flex-col">
                <FormControl component="fieldset" disabled={hasNoPermissions}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleCheckboxChange(e.target.checked, index);
                        }}
                        color="primary"
                      />
                    }
                    label={service.type}
                  />
                </FormControl>
              </div>
              <div className="flex flex-col mb-4 xl:mb-0">
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="totalQty"
                    type="number"
                    inputProps={{ min: '0' }}
                    placeholder="Total Qty"
                    {...getFieldProps(
                      `outPatientServicesArray[${index}].totalQty`
                    )}
                    disabled={!isDisabled}
                  />
                </FormControl>
              </div>
              <div className="flex flex-col mb-4 xl:mb-0">
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="timesWeek"
                    type="number"
                    inputProps={{ min: '0' }}
                    placeholder="Times / Week"
                    disabled={!isDisabled}
                    {...getFieldProps(
                      `outPatientServicesArray[${index}].timesWeek`
                    )}
                  />
                </FormControl>
              </div>
              <div className="flex flex-col">
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="duration"
                    type="number"
                    inputProps={{ min: '0' }}
                    placeholder="Duration"
                    {...getFieldProps(
                      `outPatientServicesArray[${index}].duration`
                    )}
                    disabled={!isDisabled}
                  />
                </FormControl>
              </div>
            </div>
          );
        })}

      {/* Notes */}
      <FormControl className="mb-4 w-full" disabled={hasNoPermissions}>
        <label className="label-top mb-2">Notes</label>
        <TextareaAutosize
          disabled={hasNoPermissions}
          className="border rounded-md"
          id="partBServicesNotes"
          aria-label="notes"
          minRows={3}
          {...getFieldProps('partBServicesNotes')}
        />
      </FormControl>
    </div>
  );
};

import { FC, useCallback, useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  Chip,
  Select,
  MenuItem,
} from '@material-ui/core';

import { useParams } from 'react-router';
import {
  useGetUser,
  useGetRoles,
  useGetPlans,
  useUpdateUser,
  useUpdatePassword,
  useActivateUser,
  useUserLogs,
} from '../../../services/ServiceHook';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IUser, IUserUpdate } from '../../../interfaces/user';
import {
  userInitialState,
  userInitialStateUpdate,
} from '../../../initial-values/user';

interface IResponseMessage {
  isGoodResponse: boolean | null;
  message: string;
}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  groupNpis: Yup.array(),
  plansByState: Yup.array(),
  isActive: Yup.boolean().nullable(),
  role: Yup.string(),
  userEmail: Yup.string().email('Invalid email format').required('Required'),
  hasPortalAccess: Yup.boolean().nullable(),
  profile: Yup.mixed(),
  hasBeenReviewed: Yup.boolean().nullable(),
  createdAt: Yup.string(),
  updatedAt: Yup.string(),
});

const inputStyle: any = {
  padding: '10px',
  border: '1px solid #D0D4D8',
  marginBottom: '15px',
};

const labelStyle: any = {
  color: '#023B64',
  fontSize: '14px',
  marginBottom: '5px',
};

const PortalUsersDetailsComponent: FC = () => {
  const role = useSelector((state: RootState) => state.profile.data.role);
  const history = useHistory();
  const [getUser] = useGetUser();
  const [getRoles] = useGetRoles();
  const [getPlans] = useGetPlans();
  const [postUpdateUser] = useUpdateUser();
  const [postUpdatePassword] = useUpdatePassword();
  const [postActivateUser] = useActivateUser();
  const [localUser, setLocalUser] = useState<IUser>(userInitialState);
  const [localRole, setLocalRole] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [localNpis, setLocalNpis] = useState<any>([]);
  const [localIsActive, setLocalIsActive] = useState(false);
  const [isActiveMessage, setIsActiveMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isActivating, setIsActivating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [localRoles, setLocalRoles] = useState([]);
  const [localPlansByState, setLocalPlansByState] = useState([]);
  const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] =
    useState(true);
  const [responseMessage, setResponseMessage] = useState<IResponseMessage>({
    isGoodResponse: null,
    message: '',
  });
  const [passwordResponseMessage, setPasswordResponseMessage] =
    useState<IResponseMessage>({
      isGoodResponse: null,
      message: '',
    });
  const [logs, setLogs] = useState([]);
  const [userLogs] = useUserLogs();
  let params = useParams<{ id: string }>();

  const initialValues = userInitialStateUpdate;
  interface IPlan {
    id: number;
    name: string;
    isSelected: boolean;
  }

  const getData = useCallback(async () => {
    const data = {
      id: params.id,
      userEmail: null,
    };

    // Get Roles
    const roles = await getRoles();
    if (roles && roles.status === 200) {
      setLocalRoles(roles.data);
    }

    // Get Plans
    const plans = await getPlans();
    let plansMapped = [];

    // Format Plans
    if (plans && plans.status === 200) {
      plansMapped = plans.data.map((item: string, index: number) => ({
        id: index,
        name: item,
        isSelected: false,
      }));
      setLocalPlansByState(plansMapped);

      // Get User Data
      const userResponse = await getUser(data);
      if (userResponse && userResponse.status === 200) {
        delete userResponse.data.createdAt;
        delete userResponse.data.updatedAt;

        setLocalUser(userResponse.data);
        setValues(userResponse.data);
        setLocalRole(userResponse.data.role);

        // Is Active
        setLocalIsActive(userResponse.data.isActive);
        setFieldValue('isActive', userResponse.data.isActive);
        if (userResponse.data.isActive) {
          setIsActiveMessage('Deactivate');
        } else {
          setIsActiveMessage('Activate');
        }

        // Has Portal Access
        // setLocalHasPortalAccess(userResponse.data.hasPortalAccess);
        setFieldValue('hasPortalAccess', userResponse.data.hasPortalAccess);

        // Has been reviewed
        // setLocalHasBeenReviewed(userResponse.data.hasBeenReviewed);
        setFieldValue('hasBeenReviewed', userResponse.data.hasBeenReviewed);

        setFieldValue('groupNpis', '');
        // setLocalNpis(userResponse.data.groupNpis)

        // Set selected Plans
        if (userResponse.data.plansByState.length) {
          // setPreviousPlans(userResponse.data.plansByState);
          setFieldValue('previousPlans', userResponse.data.plansByState);
          const tempArray: any = plansMapped;
          for (const plan of userResponse.data.plansByState) {
            const index: any = plansMapped.findIndex(
              (item: IPlan) => item.name === plan
            );
            if (index >= 0) {
              tempArray[index].isSelected = true;
            }
          }
          setLocalPlansByState(tempArray);
        }
        const localLogs = await userLogs(userResponse.data.id);
        if (localLogs?.status === 200) {
          if (localLogs.data.joinedLogs.length) {
            setLogs(
              localLogs.data.joinedLogs.map((item: any) => {
                return {
                  ...item,
                  date: item.date
                    ? moment(item.date).format('MMMM Do YYYY, h:mm:ss a')
                    : null,
                };
              })
            );
          }
        }
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (role) {
      getData();
    }
  }, [getData, role]);

  useEffect(() => {
    const plansSelected = localPlansByState
      .filter((item: IPlan) => item.isSelected)
      .map((found: IPlan) => found.name);
    setFieldValue('plansByState', plansSelected);
  }, [localPlansByState]);

  const onSubmit = async (
    data: IUserUpdate,
    formikHelpers: FormikHelpers<IUserUpdate>
  ) => {};

  const handleActivate = async () => {
    setIsSaving(true);
    setFieldValue('groupNpis', null);
    let data = formValues;
    const npisTrimmed = localNpis.map((item: string) => item.trim());
    data.groupNpis = npisTrimmed;
    data.firstName = formValues.profile.firstName;
    data.lastName = formValues.profile.lastName;
    delete data.password;
    delete data.confirmPassword;
    const response = await postUpdateUser(data);
    setIsSaving(false);
    if (response && response.status === 200) {
      setFieldValue('groupNpis', '');
      setResponseMessage({
        isGoodResponse: true,
        message: 'User updated successfully',
      });
      setIsEditing(false);
      setTimeout(() => {
        setResponseMessage({
          isGoodResponse: null,
          message: '',
        });
        getData();
      }, 1500);
    } else if (response && response.status === 202) {
      setResponseMessage({
        isGoodResponse: false,
        message: response.data.message,
      });
    } else if (response && response.status === 500) {
      setResponseMessage({
        isGoodResponse: false,
        message: response.data.message,
      });
    } else {
      setFieldValue('groupNpis', '');
      setResponseMessage({
        isGoodResponse: false,
        message: 'There was an error sending the form',
      });
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    // setFieldTouched,
    values: formValues,
    getFieldProps,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (
      formValues.password &&
      formValues.password === formValues.confirmPassword
    ) {
      setIsPasswordButtonDisabled(false);
    } else {
      setIsPasswordButtonDisabled(true);
    }
  }, [formValues]);

  function handleAddNpis(value: any) {
    const x = value.split(',');
    const concat = [...localUser.groupNpis, ...x];
    const unique = concat.filter((localValue, index, self) => {
      return self.indexOf(localValue) === index;
    });
    setLocalUser({
      ...localUser,
      groupNpis: unique,
    });
    setLocalNpis(unique);
    setFieldValue('groupNpis', '');
  }

  function onSelectChange(event: React.ChangeEvent<any>) {
    setFieldValue(event.target.name, event.target.value);
    setLocalRole(event.target.value);
  }

  const handlePlanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLocalPlansByState((localPlansByState: any) => {
      return localPlansByState.map((x: any) => {
        if (x.name === event.target.name) {
          return { ...x, isSelected: event.target.checked };
        }
        return x;
      });
    });
  };

  const handleChipDelete = (e: any) => {
    const temp = localUser.groupNpis;
    const x = temp.findIndex((item) => item === e);
    temp.splice(x, 1);
    setLocalUser({
      ...localUser,
      groupNpis: temp,
    });
  };

  const handleIsActiveButton = async (value: string) => {
    let data = {
      id: localUser.id,
      isActive: false,
    };
    if (value === 'Deactivate') {
      data = {
        id: localUser.id,
        isActive: false,
      };
    } else {
      data = {
        id: localUser.id,
        isActive: true,
      };
    }
    setIsActivating(true);
    const response = await postActivateUser(data);
    setIsActivating(false);

    if (response?.status === 200) {
      if (value === 'Deactivate') {
        setLocalIsActive(false);
        // setBlocked(true);
        setFieldValue('isActive', false);
        setFieldValue('blocked', true);
        setIsActiveMessage('Activate');
      } else {
        setLocalIsActive(true);
        // setBlocked(false);
        setFieldValue('isActive', true);
        setFieldValue('blocked', false);
        setIsActiveMessage('Deactivate');
      }
    } else {
      console.log('There was en error');
    }
  };

  const handleUpdatePassword = async () => {
    const data = {
      id: localUser.id,
      password: formValues.password,
    };

    const response = await postUpdatePassword(data);
    if (response?.status === 200) {
      setPasswordResponseMessage({
        isGoodResponse: true,
        message: 'User Password has been updated!',
      });
    } else {
      setPasswordResponseMessage({
        isGoodResponse: false,
        message: 'There was an error updating password, please try again.',
      });
    }
    setTimeout(() => {
      setResponseMessage({
        isGoodResponse: null,
        message: '',
      });
      setIsUpdatingPassword(false);
    }, 4000);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="px-8 mb-8">
      <h2>
        {formValues.profile.firstName} {formValues.profile.lastName}
      </h2>
      <h3 className="">{localUser.userEmail}</h3>
      <h3>Id: {localUser.id}</h3>
      <form className="my-4 flex flex-col" onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-2 gap-8">
          {!isEditing ? (
            <table style={{ color: '#023B64' }}>
              <tr>
                <td>First Name</td>
                <td>{localUser.profile.firstName}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{localUser.profile.lastName}</td>
              </tr>
              <tr>
                <td>Job Role</td>
                <td>{localUser.profile.jobRole}</td>
              </tr>
              <tr>
                <td>Business Address</td>
                <td>{localUser.profile.businessAddress}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{localUser.profile.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{localUser.profile.state}</td>
              </tr>
              <tr>
                <td>Zip</td>
                <td>{localUser.profile.zip}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{localUser.profile.phone}</td>
              </tr>
              <tr>
                <td>
                  <h3 className="mt-4">Provider Information</h3>
                </td>
              </tr>
              <tr>
                <td>Provider Name</td>
                <td>{localUser.profile.providerName}</td>
              </tr>
              <tr>
                <td>Practice Name</td>
                <td>{localUser.profile.practiceName}</td>
              </tr>
              <tr>
                <td>Provider Status</td>
                <td>{localUser.profile.providerStatus}</td>
              </tr>
            </table>
          ) : (
            <div>
              <h3 className="my-4">Profile Information</h3>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>First Name</label>
                <input
                  style={inputStyle}
                  id="firstName"
                  {...getFieldProps('profile.firstName')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Last Name</label>
                <input
                  style={inputStyle}
                  id="lastName"
                  {...getFieldProps('profile.lastName')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Job Role</label>
                <input
                  style={inputStyle}
                  id="jobRole"
                  {...getFieldProps('profile.jobRole')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Business Address</label>
                <input
                  style={inputStyle}
                  id="businessAddress"
                  {...getFieldProps('profile.businessAddress')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>City</label>
                <input
                  style={inputStyle}
                  id="city"
                  {...getFieldProps('profile.city')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>State</label>
                <input
                  style={inputStyle}
                  id="state"
                  {...getFieldProps('profile.state')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Zip</label>
                <input
                  style={inputStyle}
                  id="zip"
                  {...getFieldProps('profile.zip')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Phone</label>
                <input
                  style={inputStyle}
                  id="phone"
                  {...getFieldProps('profile.phone')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Email</label>
                <input
                  style={inputStyle}
                  id="email"
                  {...getFieldProps('userEmail')}
                />
              </div>
              <h3 className="mb-4">Provider Information</h3>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Provider Name</label>
                <input
                  style={inputStyle}
                  id="providerName"
                  {...getFieldProps('profile.providerName')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Practice Name</label>
                <input
                  style={inputStyle}
                  id="practiceName"
                  {...getFieldProps('profile.practiceName')}
                />
              </div>
              <div className="form-control flex flex-col mb-6">
                <label style={labelStyle}>Provider Status</label>
                <input
                  style={inputStyle}
                  id="providerStatus"
                  {...getFieldProps('profile.providerStatus')}
                />
              </div>
            </div>
          )}
          <div>
            <div>
              {isUpdatingPassword ? (
                <div className="change-password">
                  <h3>Change Password</h3>
                  <div className="form-control flex flex-col my-2">
                    <label style={labelStyle}>Password</label>
                    <input
                      type="password"
                      style={inputStyle}
                      id="password"
                      {...getFieldProps('password')}
                    />
                  </div>
                  <div className="form-control flex flex-col mb-2">
                    <label style={labelStyle}>Confirm Password</label>
                    <input
                      type="password"
                      style={inputStyle}
                      id="confirm-password"
                      {...getFieldProps('confirmPassword')}
                    />
                  </div>
                  <div className="flex mb-2">
                    <button
                      type="button"
                      className="btn btn-gray mr-2"
                      onClick={() => {
                        setIsUpdatingPassword(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={isPasswordButtonDisabled}
                      className={clsx(
                        {
                          'btn-disabled': isPasswordButtonDisabled,
                          'btn block btn-green': !isPasswordButtonDisabled,
                        },
                        'btn'
                      )}
                      onClick={handleUpdatePassword}
                    >
                      Update
                    </button>
                  </div>
                  <div
                    className={clsx(
                      {
                        isGoodResponse: passwordResponseMessage.isGoodResponse,
                        isBadResponse:
                          passwordResponseMessage.isGoodResponse === false,
                      },
                      'mb-8'
                    )}
                  >
                    {passwordResponseMessage.message}
                  </div>
                </div>
              ) : !isEditing ? (
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    className={clsx(
                      { hidden: isEditing, 'btn block btn-yellow': !isEditing },
                      'btn mb-8 mr-2'
                    )}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsUpdatingPassword(true);
                      setPasswordResponseMessage({
                        isGoodResponse: null,
                        message: '',
                      });
                    }}
                    className={clsx(
                      {
                        hidden: isUpdatingPassword,
                        'btn block btn-yellow': !isUpdatingPassword,
                      },
                      'btn mb-8 mr-2'
                    )}
                  >
                    Update Password
                  </button>
                  {isActivating ? (
                    <CircularProgress />
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        handleIsActiveButton(isActiveMessage);
                      }}
                      className={clsx(
                        {
                          'btn-green': !localIsActive,
                          'btn block btn-red': localIsActive,
                        },
                        'btn mb-8'
                      )}
                    >
                      {isActiveMessage}
                    </button>
                  )}
                </div>
              ) : (
                ''
              )}
              <FormControl component="fieldset" className="w-full">
                <FormLabel component="legend">Plans by State</FormLabel>
                <FormGroup>
                  <div className="grid grid-cols-1 md:grid-cols-4  gap-4">
                    {localPlansByState.map((item: any, index) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            color="primary"
                            className="p-0"
                            checked={item.isSelected}
                            onChange={handlePlanChange}
                            name={item.name}
                          />
                        }
                        label={item.name}
                      />
                    ))}
                  </div>
                </FormGroup>
              </FormControl>
            </div>
            <div className="w-full mt-8">
              <FormLabel component="legend" className="my-4">
                Role
              </FormLabel>
              <FormControl variant="outlined" className="w-full">
                <Select
                  labelId="role"
                  id="role-select"
                  name="role"
                  value={localRole}
                  onChange={onSelectChange}
                >
                  {localRoles.map((item: string) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col mt-8">
              <FormLabel component="legend" className="my-2">
                Group NPIs
              </FormLabel>
              <p>Enter one or multiple, separating by commas</p>
              <textarea
                style={inputStyle}
                id="npis"
                {...getFieldProps('groupNpis')}
                disabled={isSaving}
              />
              <button
                type="button"
                className="btn btn-green mb-4"
                onClick={() => handleAddNpis(formValues.groupNpis)}
              >
                Add
              </button>
              <div className="mb-4">
                {localUser.groupNpis.map((item: string, index: number) => (
                  <Chip
                    key={index}
                    label={item}
                    onDelete={() => {
                      handleChipDelete(item);
                    }}
                    style={{ marginRight: '10px', marginBottom: '15px' }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {!isSaving ? (
          <div className="flex justify-end">
            <button
              type="reset"
              className="btn inline-block btn-gray mr-4 md:w-auto mb-2 md:mb-0"
              onClick={() => {
                setIsEditing(false);
                if (!isEditing) {
                  history.push(`/admin/portal-users/`);
                }
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn inline-block btn-blue  md:w-auto mb-2 md:mb-0"
              onClick={handleActivate}
            >
              Save
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <CircularProgress />
            <h3>Sending form...</h3>
          </div>
        )}
      </form>
      <div
        className={clsx(
          {
            isGoodResponse: responseMessage.isGoodResponse,
            isBadResponse: responseMessage.isGoodResponse === false,
          },
          'mt-4'
        )}
      >
        {responseMessage.message}
      </div>
      <div className="py-8">
        <h3 className="pb-4">User Logs</h3>
        <div className="notesTable">
          <MaterialTable
            title=""
            columns={[
              {
                title: 'Event',
                field: 'message',
                cellStyle: {
                  width: 500,
                  minWidth: 500,
                },
                headerStyle: {
                  width: 500,
                  minWidth: 500,
                },
              },
              {
                title: 'When',
                field: 'date',
              },
            ]}
            data={logs}
            options={{
              toolbar: false,
              sorting: true,
              pageSize: 10,
              search: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PortalUsersDetailsComponent;

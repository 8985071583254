import { FC } from 'react';

export const RegisterRedirect: FC = () => {
  const currentHost = window.location.host;
  // const [redirectTo, setRedirectTo] = useState('');
  let redirectTo = '';

  if (currentHost.includes('agerightadvantage')) {
    redirectTo = 'https://agerightadvantage.com/providers/2024updates';
  } else if (currentHost.includes('keycareadvantage')) {
    redirectTo = 'https://keycareadvantage.com/providers/2024updates';
  } else if (currentHost.includes('procareadvantage')) {
    redirectTo = 'https://procareadvantageplan.com/providers/2024updates';
  } else if (currentHost.includes('alignseniorcare')) {
    redirectTo = 'https://alignseniorcare.com/providers/2024updates';
  } else if (currentHost.includes('lifeworksadvantage')) {
    redirectTo = 'https://lifeworksadvantage.com/providers/2024updates';
  } else if (currentHost.includes('nhcadvantageplan')) {
    redirectTo = 'https://nhcadvantageplan.com/providers/2024updates';
  } else if (currentHost.includes('perennialadvantage')) {
    redirectTo = 'https://perennialadvantage.com/providers/2024updates';
  } else if (currentHost.includes('pruitthealthpremier')) {
    redirectTo = 'https://pruitthealthpremier.com/providers/2024updates';
  } else {
    redirectTo = 'https://alignseniorcare.com/providers/2024updates';
  }
  return (
    <div className="p-8 text-xl text-center">
      <h1>
        The legacy Provider Portal is no longer accepting new account
        registrations. <br></br> Please visit{' '}
        <a
          className="text-blue-500"
          href={redirectTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          {redirectTo}
        </a>{' '}
        for more information on our new Provider Portal, including how to
        register for an account.
      </h1>
    </div>
  );
};

import { FC } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { InputLabel, OutlinedInput, TextareaAutosize } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IPqiReferralsSubmit } from '../../../interfaces/pqi-referrals';

interface IPqiOcurrenceInformation {
  role: string | undefined;
  formValues: IPqiReferralsSubmit;
  touched: FormikTouched<IPqiReferralsSubmit>;
  errors: FormikErrors<IPqiReferralsSubmit>;
  handleChange: any;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IPqiReferralsSubmit>>;
}

const PqiOcurrenceInformation: FC<IPqiOcurrenceInformation> = ({
  role,
  formValues,
  touched,
  errors,
  setFieldValue,
  getFieldProps,
  handleChange,
}) => {
  const maxDate = new Date();
  return (
    <>
      <h2 className="mt-8">Section III - Occurrence Information</h2>
      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8 mb-4">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Date of Occurrence</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={role === 'Viewer'}
              className="w-full"
              format="MM/dd/yyyy"
              id="dateOfOcurrence"
              inputVariant="outlined"
              value={formValues.dateOfOcurrence}
              maxDate={maxDate}
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('dateOfOcurrence', date);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Time of Occurrence</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              disabled={role === 'Viewer'}
              className="w-full"
              id="timeOfOccurrence"
              inputVariant="outlined"
              value={formValues.timeOfOccurrence}
              onChange={(time) => {
                setFieldValue('timeOfOccurrence', time);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </MuiPickersUtilsProvider>
          {touched.timeOfOccurrence && errors.timeOfOccurrence && (
            <div className="text-red-500">{errors.timeOfOccurrence}</div>
          )}
        </div>
      </div>

      <div className="mb-2 flex flex-col">
        <div>
          <label className="label-top">Was Patient Hospitalized?</label>{' '}
          <span className="text-red-500">*</span>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="wasPatientHospitalized"
            name="wasPatientHospitalized"
            value={formValues.wasPatientHospitalized}
            onChange={handleChange}
          >
            <FormControlLabel
              value={true.toString()}
              control={<Radio color="primary" disabled={role === 'Viewer'} />}
              label="Yes"
            />
            <FormControlLabel
              value={false.toString()}
              control={<Radio color="primary" disabled={role === 'Viewer'} />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>

      {formValues?.wasPatientHospitalized?.toString() === 'true' && (
        <>
          <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 my-4">
            <div className="w-full">
              <div className="mb-2">
                <label className="label-top">
                  Name of Hospital (If applicable)
                </label>{' '}
                <span className="text-red-500">*</span>
              </div>
              <FormControl variant="outlined" className="w-full">
                <InputLabel htmlFor="nameOfHospital"></InputLabel>
                <OutlinedInput
                  disabled={role === 'Viewer'}
                  id="nameOfHospital"
                  {...getFieldProps('nameOfHospital')}
                />
              </FormControl>
              {touched.nameOfHospital && errors.nameOfHospital ? (
                <div className="text-red-500">{errors.nameOfHospital}</div>
              ) : null}
            </div>
            <div className="w-full">
              <div className="mb-2">
                <label className="label-top">
                  Location of Hospital (If applicable)
                </label>{' '}
                <span className="text-red-500">*</span>
              </div>
              <FormControl variant="outlined" className="w-full">
                <InputLabel htmlFor="locationOfHospital"></InputLabel>
                <OutlinedInput
                  disabled={role === 'Viewer'}
                  id="locationOfHospital"
                  {...getFieldProps('locationOfHospital')}
                />
              </FormControl>
              {touched.locationOfHospital && errors.locationOfHospital ? (
                <div className="text-red-500">{errors.locationOfHospital}</div>
              ) : null}
            </div>
            <div className="w-full">
              <div className="mb-2">
                <label className="label-top">
                  Hospital Admission Date (If applicable)
                </label>{' '}
                <span className="text-red-500">*</span>
              </div>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={role === 'Viewer'}
                  className="w-full"
                  format="MM/dd/yyyy"
                  id="hospitalizationDate"
                  inputVariant="outlined"
                  value={formValues.hospitalizationDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    setFieldValue('hospitalizationDate', date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="w-full">
              <div className="mb-2">
                <label className="label-top">
                  Hospital Admission Time (If applicable)
                </label>{' '}
                <span className="text-red-500">*</span>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  disabled={role === 'Viewer'}
                  className="w-full"
                  id="hospitalizationTime"
                  inputVariant="outlined"
                  value={formValues.hospitalizationTime}
                  onChange={(time) => {
                    setFieldValue('hospitalizationTime', time);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </MuiPickersUtilsProvider>
              {touched.hospitalizationTime && errors.hospitalizationTime && (
                <div className="text-red-500">{errors.hospitalizationTime}</div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="mb-2 flex flex-col mt-4">
        <div>
          <label className="label-top">
            Was the incident reported to a state agency? If yes, please provide
            the agency name.
          </label>
          <span className="text-red-500">*</span>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="wasTheIncidentReportedToAStateAgency"
            name="wasTheIncidentReportedToAStateAgency"
            value={formValues.wasTheIncidentReportedToAStateAgency}
            onChange={handleChange}
          >
            <FormControlLabel
              value={true.toString()}
              control={<Radio color="primary" disabled={role === 'Viewer'} />}
              label="Yes"
            />
            <FormControlLabel
              value={false.toString()}
              control={<Radio color="primary" disabled={role === 'Viewer'} />}
              label="No"
            />
          </RadioGroup>
        </FormControl>

        {formValues.wasTheIncidentReportedToAStateAgency?.toString() ===
          'true' && (
          <div className="w-full xl:w-1/2 mt-4">
            <div className="mb-2">
              <label className="label-top">Name of Agency</label>{' '}
              <span className="text-red-500">*</span>
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="agencyName"></InputLabel>
              <OutlinedInput
                disabled={role === 'Viewer'}
                id="agencyName"
                {...getFieldProps('agencyName')}
              />
            </FormControl>
            {touched.agencyName && errors.agencyName ? (
              <div className="text-red-500">{errors.agencyName}</div>
            ) : null}
          </div>
        )}
      </div>

      <div className="my-4 flex flex-col">
        <div>
          <label className="label-top">
            Was a physician called? If yes, please provide their recommendation
            within the description of the occurrence.
          </label>
          <span className="text-red-500">*</span>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="wasAPhysicianCalled"
            name="wasAPhysicianCalled"
            value={formValues.wasAPhysicianCalled}
            onChange={handleChange}
          >
            <FormControlLabel
              value={true.toString()}
              control={<Radio color="primary" disabled={role === 'Viewer'} />}
              label="Yes"
            />
            <FormControlLabel
              value={false.toString()}
              control={<Radio color="primary" disabled={role === 'Viewer'} />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="mt-4">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">
              Physician Recommendation (If applicable)
            </label>{' '}
            {String(formValues.wasAPhysicianCalled) === 'true' ? (
              <span className="text-red-500">*</span>
            ) : null}
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="physicianRecomendation"></InputLabel>
            <TextareaAutosize
              disabled={role === 'Viewer'}
              minRows={5}
              aria-label="Physician recommendation"
              className="border border-gray-300 rounded-md w-full p-2"
              {...getFieldProps('physicianRecomendation')}
            />
          </FormControl>
          {touched.physicianRecomendation && errors.physicianRecomendation && (
            <div className="text-red-500">{errors.physicianRecomendation}</div>
          )}
        </div>
        <div className="w-full mt-4">
          <div className="mb-2">
            <label className="label-top">
              Brief Description of Occurrence: Provide a brief description of
              the incident to include the time, date, exact location, physical
              findings, or diagnosis.
            </label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="briefDescriptionOfTheOccurrence"></InputLabel>
            <TextareaAutosize
              disabled={role === 'Viewer'}
              minRows={5}
              aria-label="Physician recommendation"
              className="border border-gray-300 rounded-md w-full p-2"
              {...getFieldProps('briefDescriptionOfTheOccurrence')}
            />
          </FormControl>
          {touched.briefDescriptionOfTheOccurrence &&
            errors.briefDescriptionOfTheOccurrence && (
              <div className="text-red-500">
                {errors.briefDescriptionOfTheOccurrence}
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default PqiOcurrenceInformation;

/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  ConfirmationComponent,
} from '../../components';
import MainNavigation from '../../components/MainNavigation';

const ConfirmationPage: FC = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  if (isLoading) return <div>''</div>;
  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  }
  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
  }

  return (
    <div>
      <Header />
      <MainNavigation />
      <HeaderTitle title="Confirmation" />
      <ConfirmationComponent />
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default ConfirmationPage;

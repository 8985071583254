import { FC, useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSendEmail } from '../../services/ServiceHook';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { getCurrentPlan } from '../../planData';
import Cookies from 'js-cookie';

interface IFormProps {
  senderName: string;
  senderEmail: string;
  phoneNumber: string;
  planBase: string;
  subject: string;
  formMessage: string;
  email: string;
}

interface IResponseMessage {
  isGoodResponse: boolean | null;
  formMessage: string;
}

const formContainer: any = {
  backgroundColor: '#F2F5F9',
  borderRadius: '4px',
};

const inputStyle: any = {
  padding: '10px',
  border: '1px solid #D0D4D8',
};

const labelStyle: any = {
  color: '#023B64',
  fontSize: '14px',
  marginBottom: '5px',
};

const initialValues = {
  senderName: '',
  senderEmail: '',
  phoneNumber: '',
  planBase: '',
  subject: '',
  formMessage: '',
  email: '',
};

const validationSchema = Yup.object().shape({
  senderName: Yup.string().required('Required'),
  senderEmail: Yup.string().email('Invalid email format').required('Required'),
  phoneNumber: Yup.string(),
  planBase: Yup.string(),
  subject: Yup.string(),
  formMessage: Yup.string().required('Required'),
});

const ContactForm: FC = () => {
  const { user } = useAuth0();
  const [postSendEmail] = useSendEmail();
  const [isSaving, setIsSaving] = useState(false);
  const [responseMessage, setResponseMessage] = useState<IResponseMessage>({
    isGoodResponse: null,
    formMessage: '',
  });
  const role = Cookies.get('aah-role');

  const foundPlanData = getCurrentPlan();

  const onSubmit = async (
    data: IFormProps,
    formikHelpers: FormikHelpers<IFormProps>
  ) => {
    setIsSaving(true);
    data.planBase = foundPlanData.rootSite;
    data.email = foundPlanData.contactEmail;
    const response = await postSendEmail(data);
    setIsSaving(false);
    if (response && response.status === 200) {
      setResponseMessage({
        isGoodResponse: true,
        formMessage: 'Message sent successfully',
      });
      clearForm();
      setTimeout(() => {
        setResponseMessage({
          isGoodResponse: null,
          formMessage: '',
        });
        clearForm();
      }, 3000);
    } else {
      setResponseMessage({
        isGoodResponse: false,
        formMessage: 'There was an error sending the message',
      });
    }
  };

  const clearForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  const {
    handleSubmit,
    setValues,
    values: formValues,
    getFieldProps,
    errors,
    setErrors,
    isValid,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    const hasKeys = !!Object.keys(errors).length;
    if (hasKeys) {
      // console.log('there are errors');
    } else {
      // console.log('there is no error');
    }
  }, [errors]);

  useEffect(() => {
    if (user) {
      setValues({
        ...formValues,
        senderName: user.name,
        senderEmail: user.email,
      });
    }
  }, [user]);

  return (
    <>
      <div className="p-4 xl:p-8" style={formContainer}>
        <h2 className="mb-6">Portal Support</h2>
        <p>
          If you have questions related to the provider portal or need support,
          please complete the form below.
        </p>

        <form className="my-4 flex flex-col" onSubmit={handleSubmit}>
          <div className="form-control flex flex-col mb-6">
            <label style={labelStyle}>
              Name <span className="text-red-500">*</span>
            </label>
            <input
              style={inputStyle}
              id="senderName"
              {...getFieldProps('senderName')}
            />
            {errors.senderName && touched.senderName ? (
              <div className="text-red-500">{errors.senderName}</div>
            ) : null}
          </div>
          <div className="form-control flex flex-col mb-6">
            <label style={labelStyle}>
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              style={inputStyle}
              id="senderEmail"
              {...getFieldProps('senderEmail')}
            />
            {errors.senderEmail && touched.senderEmail ? (
              <div className="text-red-500">{errors.senderEmail}</div>
            ) : null}
          </div>
          <div className="form-control flex flex-col mb-6">
            <label style={labelStyle}>Phone Number</label>
            <input
              style={inputStyle}
              id="phoneNumber"
              {...getFieldProps('phoneNumber')}
            />
          </div>
          <div className="form-control flex flex-col mb-6">
            <label style={labelStyle}>Subject</label>
            <input
              style={inputStyle}
              id="subject"
              {...getFieldProps('subject')}
            />
          </div>
          <div className="form-control flex flex-col mb-6">
            <label style={labelStyle}>
              Message <span className="text-red-500">*</span>
            </label>
            <textarea
              style={inputStyle}
              id="formMessage"
              {...getFieldProps('formMessage')}
            />
            {errors.formMessage && touched.formMessage ? (
              <div className="text-red-500">{errors.formMessage}</div>
            ) : null}
          </div>
          {!isSaving ? (
            <button
              disabled={!isValid || role === 'Viewer'}
              type="submit"
              className={`btn block w-full md:w-auto mb-2 md:mb-0 ${
                isValid && role !== 'Viewer' ? 'btn-red' : 'btn-gray'
              }`}
            >
              Submit
            </button>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <CircularProgress />
              <h3>Sending form...</h3>
            </div>
          )}
          <div
            className={clsx(
              {
                'text-green-500': responseMessage.isGoodResponse,
                'text-red-500': responseMessage.isGoodResponse,
              },
              'mt-4'
            )}
          >
            {responseMessage.formMessage}
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;

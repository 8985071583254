import { FC } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IPqiReferralsSubmit } from '../../../interfaces/pqi-referrals';

interface IPqiGeneralInformationProps {
  role: string | undefined;
  formValues: IPqiReferralsSubmit;
  touched: FormikTouched<IPqiReferralsSubmit>;
  errors: FormikErrors<IPqiReferralsSubmit>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IPqiReferralsSubmit>>;
}

const PqiGeneralInformation: FC<IPqiGeneralInformationProps> = ({
  role,
  formValues,
  touched,
  errors,
  setFieldValue,
  getFieldProps,
}) => {
  const maxDate = new Date();
  return (
    <>
      <h2 className="mt-8">Section I - General Information</h2>
      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8 mb-8">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Date of Referral</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={role === 'Viewer'}
              className="w-full"
              format="MM/dd/yyyy"
              id="dateOfReferral"
              inputVariant="outlined"
              value={formValues.dateOfReferral}
              maxDate={maxDate}
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('dateOfReferral', date);
              }}
            />
          </MuiPickersUtilsProvider>
          {touched.dateOfReferral && errors.dateOfReferral && (
            <div className="text-red-500">{errors.dateOfReferral}</div>
          )}
        </div>
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Time of Referral</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              disabled={role === 'Viewer'}
              className="w-full"
              id="timeOfReferral"
              inputVariant="outlined"
              value={formValues.timeOfReferral}
              onChange={(time) => {
                setFieldValue('timeOfReferral', time);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </MuiPickersUtilsProvider>
          {touched.timeOfReferral && errors.timeOfReferral && (
            <div className="text-red-500">{errors.timeOfReferral}</div>
          )}
        </div>
      </div>

      <div className="grid lg:grid-cols-12 gap-4 xl:gap-6 mb-8">
        <div className="w-full col-span-6">
          <div className="mb-2">
            <label className="label-top">Member Name</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="memberName"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="memberName"
              {...getFieldProps('memberName')}
            />
          </FormControl>
          {touched.memberName && errors.memberName ? (
            <div className="text-red-500">{errors.memberName}</div>
          ) : null}
        </div>
        <div className="w-full col-span-3">
          <div className="mb-2">
            <label className="label-top">Date of Birth</label> {''}
            <span className="text-red-500">*</span>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={role === 'Viewer'}
              className="w-full"
              format="MM/dd/yyyy"
              id="memberDateOfBirth"
              inputVariant="outlined"
              value={formValues.memberDateOfBirth}
              maxDate={maxDate}
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('memberDateOfBirth', date);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="w-full col-span-3">
          <div className="mb-2">
            <label className="label-top">Sex</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel id="demo-simple-select-outlined-label">Sex</InputLabel>
            <Select
              disabled={role === 'Viewer'}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={formValues.sex}
              onChange={(value) => {
                setFieldValue('sex', value.target.value);
              }}
              label="Sex"
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mb-8">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Health Plan</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="healthPlan"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="healthPlan"
              {...getFieldProps('healthPlan')}
            />
          </FormControl>
          {touched.healthPlan && errors.healthPlan && (
            <div className="text-red-500">{errors.healthPlan}</div>
          )}
        </div>
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Member ID</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="memberId"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="memberId"
              {...getFieldProps('memberId')}
            />
          </FormControl>
          {touched.memberId && errors.memberId && (
            <div className="text-red-500">{errors.memberId}</div>
          )}
        </div>
      </div>
      <p className="italic">
        Either Provider Name or Facility Name is Required{' '}
        <span className="text-red-500">*</span>
      </p>
      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Provider Name</label>{' '}
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="providerName"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="providerName"
              {...getFieldProps('providerName')}
            />
          </FormControl>
          {touched.providerName && errors.providerName && (
            <div className="text-red-500">{errors.providerName}</div>
          )}
        </div>

        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Provider Number</label>{' '}
            {String(formValues.providerName) !== '' ? (
              <span className="text-red-500">*</span>
            ) : null}
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="providerNumber"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="providerNumber"
              {...getFieldProps('providerNumber')}
            />
          </FormControl>
          {touched.providerNumber && errors.providerNumber && (
            <div className="text-red-500">{errors.providerNumber}</div>
          )}
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Facility Name</label>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="facilityName"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="facilityName"
              {...getFieldProps('facilityName')}
            />
          </FormControl>
          {touched.facilityName && errors.facilityName && (
            <div className="text-red-500">{errors.facilityName}</div>
          )}
        </div>
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Facility Location</label> {'  '}
            {String(formValues.facilityName) !== '' ? (
              <span className="text-red-500">*</span>
            ) : null}
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="facilityLocation"></InputLabel>
            <OutlinedInput
              id="facilityLocation"
              disabled={role === 'Viewer'}
              {...getFieldProps('facilityLocation')}
            />
          </FormControl>
          {touched.facilityLocation && errors.facilityLocation && (
            <div className="text-red-500">{errors.facilityLocation}</div>
          )}
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">
              Name of Person Submitting Referral
            </label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="nameOfPersonSubmittingReferral"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="nameOfPersonSubmittingReferral"
              {...getFieldProps('nameOfPersonSubmittingReferral')}
            />
          </FormControl>
          {touched.nameOfPersonSubmittingReferral &&
            errors.nameOfPersonSubmittingReferral && (
              <div className="text-red-500">
                {errors.nameOfPersonSubmittingReferral}
              </div>
            )}
        </div>
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Department and Title</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="departmentAndTitle"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="departmentAndTitle"
              {...getFieldProps('departmentAndTitle')}
            />
          </FormControl>
          {touched.departmentAndTitle && errors.departmentAndTitle && (
            <div className="text-red-500">{errors.departmentAndTitle}</div>
          )}
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8">
        <div className="w-full">
          <div className="mb-2">
            <label className="label-top">Contact Information</label>{' '}
            <span className="text-red-500">*</span>
          </div>
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="contactInformation"></InputLabel>
            <OutlinedInput
              disabled={role === 'Viewer'}
              id="contactInformation"
              {...getFieldProps('contactInformation')}
            />
          </FormControl>
          {touched.contactInformation && errors.contactInformation && (
            <div className="text-red-500">{errors.contactInformation}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default PqiGeneralInformation;

import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { CircularProgress, Snackbar } from '@material-ui/core';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { useCcmReferralQuery } from '../../hooks/queries/useCcmReferralsQuery';
import Cookies from 'js-cookie';
import { useMarkCcmReferralsAsReviewedMutation } from '../../hooks/mutations/useCcmReferralsMutations';
import CcmReferralsMarkAsReviewed from './CcmReferralsMarkAsReviewed';

export interface IStatuses {
  id: number;
  label: string;
  value: string;
}

const CcmReferralsDetails: FC = () => {
  const [status, setStatus] = useState('');
  const [statusNote, setStatusNote] = useState('');
  const [enableUpdateStatus, setEnableUpdateStatus] = useState(false);
  const [showStatus, setShowStatus] = useState(true);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const role = Cookies.get('aah-role');
  let params = useParams<{ id: string }>();
  const { data, isLoading, isError, error } = useCcmReferralQuery(params.id);
  const isStatusDisabled = !status || !statusNote;

  // Mark as reviewed
  const {
    mutate: markAsReviewed,
    isLoading: isSavingMarked,
    error: errorMarkAsReviewed,
  } = useMarkCcmReferralsAsReviewedMutation();

  const handleMarkAsReviewed = async () => {
    const payload = {
      requestId: params.id,
      status,
      notes: statusNote,
    };
    markAsReviewed(payload, {
      onSuccess: () => {
        setEnableUpdateStatus(false);
        setShowStatus(true);
      },
      onError: (error: any) => {
        setIsSnackbarErrorOpen(true);
      },
    });
  };

  const onStatusChange = (e: any) => {
    setStatus(e.target.value);
    setStatusNote('');
  };

  // Styles
  const label: any = {
    color: '#003967',
    letterSpacing: '0',
    lineHeight: '15px',
    display: 'block',
    marginBottom: '10px',
  };

  const content: any = {
    color: '#112233',
    lineHeight: '15px',
  };

  if (isLoading) {
    return (
      <div className="hidden xl:flex container mx-auto px-4  flex-col justify-center items-center mt-8 ">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  return (
    <div className="px-4 md:px-8">
      <div className="grid lg:grid-cols-2 xl:gap-6">
        <h2>
          {data.memberFirstName} {data.memberLastName}
        </h2>
        <CcmReferralsMarkAsReviewed
          data={data}
          role={role}
          status={status}
          statusNote={statusNote}
          isSavingMarked={isSavingMarked}
          enableUpdateStatus={enableUpdateStatus}
          isStatusDisabled={isStatusDisabled}
          showStatus={showStatus}
          setShowStatus={setShowStatus}
          setStatus={setStatus}
          setStatusNote={setStatusNote}
          handleMarkAsReviewed={handleMarkAsReviewed}
          setEnableUpdateStatus={setEnableUpdateStatus}
          onStatusChange={onStatusChange}
        />
      </div>

      <h2 className="text-lg">Member Information</h2>
      <div className="grid lg:grid-cols-2 xl:gap-6 mt-4">
        <div className="mb-4 mr-4">
          <span style={label}>Plan/State</span>
          <span style={content}>{data.memberBenefitPlan}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Member Last Name</span>
          <span style={content}>{data.memberLastName}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Member First Name</span>
          <span style={content}>{data.memberFirstName}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Date of Birth</span>
          <span style={content}>{data.memberDateOfBirth}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Member Id</span>
          <span style={content}>{data.memberId}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Facility Name</span>
          <span style={content}>
            {data.facilityName ? data.facilityName : null}
          </span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Facility NPI</span>
          <span style={content}>
            {data.facilityNpi ? data.facilityNpi : null}
          </span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Primary Diagnosis</span>
          <span style={content}>{data.primaryDiagnosis}</span>
        </div>
      </div>

      <h2 className="text-lg mt-4">Referrer Information</h2>
      <div className="grid lg:grid-cols-2 xl:gap-6 mt-4">
        <div className="mb-4 mr-4">
          <span style={label}>Date of referral</span>
          <span style={content}>
            {data.dateOfReferral
              ? moment(data.dateOfReferral).format('MM/DD/yyyy')
              : null}
          </span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Requester Name</span>
          <span style={content}>{data.requesterName}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Requester Phone Number</span>
          <span style={content}>{data.requesterPhoneNumber}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Requester Relationship to Member</span>
          <span style={content}>{data.requesterMemberRelationship}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Requester Email, if Applicable</span>
          <span style={content}>{data.requesterEmail}</span>
        </div>
        <div className="mb-4 mr-4">
          <span style={label}>Request entered by</span>
          <span style={content}>{data.requestedBy}</span>
        </div>
      </div>

      <div className="my-4 mr-4">
        <span style={label}>Reason for referral</span>
        <span style={content}>{data.reasonForReferral}</span>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSnackbarErrorOpen}
        autoHideDuration={8000}
        onClose={() => {
          setIsSnackbarErrorOpen(false);
        }}
        className="m-0 p-0 w-full min-w-0"
      >
        <Alert severity="error">
          There was an error submitting the record, please try again later!
          Error message: {errorMarkAsReviewed}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CcmReferralsDetails;

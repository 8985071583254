import { FC, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSVLink } from 'react-csv';
import PortalUsersComponentDesktop from '../PortalUsersComponentDesktop/PortalUsersComponentDesktop';
import { IPortalUser } from '../../../interfaces/interfaces';
import PortalUsersComponentMobile from '../PortalUsersComponentMobile/PortalUsersComponentMobile';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useUsersQuery } from '../../../hooks/queries/useUsersQuery';
import { Alert } from '@material-ui/lab';

export interface ICustomCheck {
  id: number | null;
  name: string | null;
  isActive: boolean | null;
}

const PortalUsersComponent: FC = () => {
  const role = useSelector((state: RootState) => state.profile.data.role);
  const [data, setData] = useState<IPortalUser[]>([]);
  const { data: users, isLoading, isError, error } = useUsersQuery({});

  useEffect(() => {
    if (users && users.length) {
      const hasBeenReviewed = users.filter(
        (item: IPortalUser) => item.hasBeenReviewed
      );
      if (hasBeenReviewed.length) {
        for (let item of hasBeenReviewed) {
          item['firstName'] = item.profile.firstName;
          item['lastName'] = item.profile.lastName;
        }
        setData(hasBeenReviewed);
      }
    }
  }, [users]);

  const csvDownload = (usersFiltered: IPortalUser[]) => {
    let headerRow: any[] = [
      'Case Id',
      'First Name',
      'Last Name',
      'Email',
      'Plans',
      'Role',
    ];
    let rows: any[][] = [];
    rows = usersFiltered.map((elem) => [
      elem.id,
      elem.profile.firstName,
      elem.profile.lastName,
      elem.userEmail,
      elem.plansByState.join(' '),
      elem.role,
    ]);
    rows.unshift(headerRow);
    return rows;
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  return (
    <section className="customTable w-full px-4 md:px-8 mx-auto mb-16">
      <div className="btn-div">
        {role === 'MD - Case Manager' || role === 'Admin' ? (
          <CSVLink
            data={csvDownload(data)}
            filename={'portalUsers.csv'}
            className="btn btn-blue mb-4"
          >
            Export CSV
          </CSVLink>
        ) : (
          <button type="button" className="mb-4 btn btn-disabled" disabled>
            Export CSV
          </button>
        )}
      </div>

      <PortalUsersComponentDesktop data={data} />
      <PortalUsersComponentMobile data={data} />
    </section>
  );
};

export default PortalUsersComponent;

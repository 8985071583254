import { useAuth0 } from '@auth0/auth0-react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FC, useEffect, useState } from 'react';
import { getCurrentPlan } from '../../planData';
import { usePostDicisionSubmission } from '../../services/ServiceHook';
import DecisionSubmitSidebar from '../DecisionSubmitSidebar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useGetMemberDetails } from '../../services/ServiceHook';
import Modal from '../Modal';

const DecisionSubmit: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [decisionType, setDecisionType] = useState('');
  const [denialRationale, setDenialRationale] = useState('');
  const [medicalReview, setMedicalReview] = useState('');
  const [getMemberDetails] = useGetMemberDetails();
  const [showMedicalReviewNote, setShowMedicalReviewNote] = useState(false);
  const [showDenialRationale, setShowDenialRationale] = useState(false);
  const [additionalNote, setAdditionalNote] = useState('');
  const [showAdditionalNote, setShowAdditionalNote] = useState(false);
  const [showP2P, setShowP2P] = useState(false);
  const [p2pNote, setP2PNote] = useState('');
  const [postDecisionSubmission] = usePostDicisionSubmission();
  const { user } = useAuth0();
  const [showPeerReviewToggle, setShowPeerReviewToggle] = useState(false);
  const [showWhatIsYourAvailability, setShowWhatIsYourAvailability] =
    useState(false);
  const [peerReviewNote, setPeerReviewNote] = useState('');
  const [focusValue, setFocusValue] = useState<boolean | null>(null);
  const foundPlanData = getCurrentPlan();
  const [isPeerReview, setIsPeerReview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [memberDetails, setMemberDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [serverResponse, setServerResponse] = useState('');
  const role = sessionStorage.getItem('role');

  if (
    role !== 'MD - Reviewer' &&
    role !== 'MD - Reviewer Restricted' &&
    role !== 'Admin'
  ) {
    history.push(`/dashboard/${location.pathname.split('/')[2]}`);
  }
  // Get member details
  useEffect(() => {
    (async () => {
      try {
        const memberDetailsResponse = await getMemberDetails();
        setMemberDetails(memberDetailsResponse);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setServerResponse(
          error.response.data.message || error.response.statusText
        );
        setIsSnackbarErrorOpen(true);
      }
    })();
  }, []);

  function Alert(props: AlertProps) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        className="w-1/3 p-0 m-0"
        {...props}
      />
    );
  }

  const decisionSubmissionSelect = [
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Denied-Medical Necessity',
      value: 'deniedMedicalNecessity',
    },
    {
      label: 'Requesting Additional Documents',
      value: 'requestingAdditionalDocuments',
    },
    {
      label: 'Partial Approval',
      value: 'partialApproval',
    },
    {
      label: 'Peer to Peer',
      value: 'p2p',
    },
  ];

  const clearItems = () => {
    setShowPeerReviewToggle(false);
    setFocusValue(null);
    clearNotes();
  };

  const clearNotes = () => {
    setShowWhatIsYourAvailability(false);
    setShowMedicalReviewNote(false);
    setShowDenialRationale(false);
    setShowAdditionalNote(false);
    setShowP2P(false);
    setP2PNote('');
    setPeerReviewNote('');
    setMedicalReview('');
    setDenialRationale('');
    setAdditionalNote('');
  };

  const validateDecisionType = () => {
    if (decisionType === 'deniedMedicalNecessity') {
      setShowDenialRationale(true);
    }
    if (decisionType === 'partialApproval') {
      setShowDenialRationale(true);
      setShowMedicalReviewNote(true);
    }
  };

  const handleDecisionType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDecisionType(event.target.value as string);
    clearItems();
    switch (event.target.value) {
      case 'approved':
        setShowMedicalReviewNote(true);
        setShowAdditionalNote(true);
        break;
      case 'deniedMedicalNecessity':
        setShowPeerReviewToggle(true);
        break;
      case 'requestingAdditionalDocuments':
        setShowMedicalReviewNote(true);
        setShowAdditionalNote(true);
        break;
      case 'partialApproval':
        setShowPeerReviewToggle(true);
        break;
      case 'p2p':
        setShowP2P(true);
    }
  };

  const handleDisableSubmitDecision = (): boolean => {
    switch (decisionType) {
      case 'approved':
        if (medicalReview) {
          return false;
        }
        break;
      case 'deniedMedicalNecessity':
        if (!isPeerReview && peerReviewNote && denialRationale) {
          return false;
        }
        if (isPeerReview && denialRationale) {
          return false;
        }
        break;
      case 'requestingAdditionalDocuments':
        if (medicalReview) {
          return false;
        }
        break;
      case 'partialApproval':
        if (
          !isPeerReview &&
          peerReviewNote &&
          medicalReview &&
          denialRationale
        ) {
          return false;
        }

        if (isPeerReview && medicalReview && denialRationale) {
          return false;
        }
        break;
      case 'p2p':
        if (p2pNote) {
          return false;
        }
        break;
    }
    return true;
  };

  const handleMedicalReviewNote = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMedicalReview(event.target.value);
  };

  const handleChangeRationale = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDenialRationale(event.target.value);
  };

  const handleAdditionalNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalNote(event.target.value);
  };

  const handleP2PlNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setP2PNote(event.target.value);
  };

  const handlePeerReviewNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeerReviewNote(event.target.value);
  };

  const handleButtonGroup = (event: any) => {
    clearNotes();
    if (event) {
      if (focusValue) {
        setFocusValue(null);
        setIsPeerReview(false);
      } else {
        setFocusValue(true);
        validateDecisionType();
        setIsPeerReview(true);
        setShowAdditionalNote(true);
      }
    } else if (!event) {
      setIsPeerReview(false);
      setShowAdditionalNote(true);
      if (focusValue === false) {
        setFocusValue(null);
        setShowWhatIsYourAvailability(false);
        setShowAdditionalNote(false);
      } else {
        setFocusValue(false);
        setShowWhatIsYourAvailability(true);
        validateDecisionType();
      }
    }
  };

  const handleSubmitDecision = async () => {
    setIsSaving(true);
    const id = user.sub.split('|')[1];
    const sitePlans = foundPlanData.permissions;
    const decisions = [
      {
        headerTitle: 'Denial Rationale',
        decisionText: denialRationale,
      },
      {
        headerTitle: 'Peer Review Availability',
        decisionText: peerReviewNote,
      },
      {
        headerTitle: 'Medical Review',
        decisionText: medicalReview,
      },
      {
        headerTitle: 'Peer to Peer Note',
        decisionText: p2pNote,
      },
      {
        headerTitle: 'Additional Note',
        decisionText: additionalNote,
      },
    ];

    let localServiceRequests = [];

    if (memberDetails.serviceRequests && memberDetails.serviceRequests.length) {
      localServiceRequests = memberDetails.serviceRequests.map(
        (item: any) => item.sequence
      );
    }

    if (memberDetails) {
      const payload = {
        id,
        authorizationNumber: location.pathname.split('/')[2],
        requestId: uuidv4(),
        memberId: memberDetails.memberId,
        decisionType,
        isPeerReview: isPeerReview,
        decisions,
        serviceRequests: localServiceRequests,
        sitePlans,
      };
      try {
        const response = await postDecisionSubmission(payload);
        clearItems();
        setIsSaving(false);
        setDecisionType('');
        if (response.status === 200) {
          setIsSnackbarOpen(true);
          setTimeout(
            () => history.push(`/dashboard/${location.pathname.split('/')[2]}`),
            2000
          );
        } else {
          setIsSnackbarErrorOpen(true);
        }
      } catch (error: any) {
        setServerResponse(
          `There was an error, please try again. The server response: ${error.response.data.message}`
        );
        setIsSnackbarErrorOpen(true);
        setIsSaving(false);
      }
      setIsSaving(false);
      setOpenModal(false);
    }
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiInputBase-root': {
          minHeight: '100px',
        },
      },
      customSnack: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    })
  );

  const classes = useStyles();

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full px-4 md:px-8 grid xl:grid-cols-2 xl:gap-4">
      <section className="xl:border-r xl:pr-4">
        <div className="flex flex-col">
          <div className="mb-4">
            <FormControl variant="outlined" className="w-full mb-4">
              <InputLabel id="demo-simple-select-outlined-label">
                Select decision
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={decisionType}
                onChange={handleDecisionType}
                label="Select Decision"
              >
                <MenuItem value="">
                  <em>Select decision</em>
                </MenuItem>
                {decisionSubmissionSelect.map((item: any) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          {showPeerReviewToggle && (
            <div className="mb-4 w-full">
              <div className="mb-4">
                <label className="block mb-2">Is this a peer review?</label>
                {focusValue}
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    onClick={() => {
                      handleButtonGroup(true);
                    }}
                    color="primary"
                    className={focusValue ? 'toggleButtonSelected' : ''}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      handleButtonGroup(false);
                    }}
                    className={
                      focusValue === false && focusValue !== null
                        ? 'toggleButtonSelected'
                        : ''
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}

          {showWhatIsYourAvailability && (
            <div className="mb-4">
              <label className="mb-2 block">
                What is your availability?{' '}
                <span className="text-red-500">*</span>
              </label>
              <form className={classes.root} noValidate autoComplete="off">
                <FormControl className="mb-4 w-full">
                  <TextField
                    id="peerReview"
                    label="Write Peer Review"
                    multiline
                    rows={4}
                    value={peerReviewNote}
                    onChange={handlePeerReviewNote}
                    variant="outlined"
                    className="min-h-max"
                  />
                </FormControl>
              </form>
            </div>
          )}

          {showMedicalReviewNote && (
            <div className="mb-4 w-full">
              <label className="mb-2 block">
                Medical Review <span className="text-red-500">*</span>
              </label>
              <form className={classes.root} noValidate autoComplete="off">
                <FormControl className="mb-4 w-full">
                  <TextField
                    id="medical-review-textarea"
                    label="Write a Medical Review note"
                    multiline
                    rows={4}
                    value={medicalReview}
                    onChange={handleMedicalReviewNote}
                    variant="outlined"
                    className="min-h-max"
                    // disabled={disableTextArea}
                  />
                </FormControl>
              </form>
            </div>
          )}

          {showDenialRationale && (
            <div className="mb-4 w-full">
              <form className={classes.root} noValidate autoComplete="off">
                <label className="mb-2 block">
                  Denial Rationale <span className="text-red-500">*</span>
                </label>
                <FormControl className="mb-4 w-full">
                  <TextField
                    id="denial-rationale-textarea"
                    label="Write denial rationale note"
                    multiline
                    rows={4}
                    value={denialRationale}
                    onChange={handleChangeRationale}
                    variant="outlined"
                    className="min-h-max"
                    // disabled={disableTextArea}
                  />
                </FormControl>
              </form>
            </div>
          )}

          {showP2P && (
            <div className="mb-4 w-full">
              <form className={classes.root} noValidate autoComplete="off">
                <label className="mb-2 block">
                  Peer to Peer Note <span className="text-red-500">*</span>
                </label>
                <FormControl className="mb-4 w-full">
                  <TextField
                    id="p2p-note"
                    label="Write Peer to Peer Note"
                    multiline
                    rows={4}
                    value={p2pNote}
                    onChange={handleP2PlNote}
                    variant="outlined"
                    className="min-h-max"
                    // disabled={disableTextArea}
                  />
                </FormControl>
              </form>
            </div>
          )}

          {showAdditionalNote && (
            <div className="mb-4 w-full">
              <form className={classes.root} noValidate autoComplete="off">
                <label className="mb-2 block">Additional note</label>
                <FormControl className="mb-4 w-full">
                  <TextField
                    id="additional-note"
                    label="Write additional note"
                    multiline
                    rows={4}
                    value={additionalNote}
                    onChange={handleAdditionalNote}
                    variant="outlined"
                    className="min-h-max"
                    // disabled={disableTextArea}
                  />
                </FormControl>
              </form>
            </div>
          )}

          {isSaving ? (
            <div className="flex justify-center my-4">
              <CircularProgress />
            </div>
          ) : (
            <button
              className={`btn flex  mb-4 w-full xl:w-1/2 justify-center ${
                handleDisableSubmitDecision() ? 'btn-gray' : 'btn-blue'
              } `}
              disabled={handleDisableSubmitDecision()}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Submit Decision
            </button>
          )}
        </div>
      </section>
      <section className="">
        <DecisionSubmitSidebar />
      </section>
      <div className={classes.customSnack}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={() => {
            setIsSnackbarOpen(false);
          }}
          className="m-0 p-0 w-full min-w-0"
        >
          <Alert severity="success">
            Decision has been submitted successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarErrorOpen}
          autoHideDuration={8000}
          onClose={() => {
            setIsSnackbarErrorOpen(false);
          }}
          className="m-0 p-0 w-full min-w-0"
        >
          <Alert severity="error">{serverResponse} </Alert>
        </Snackbar>
      </div>
      <Modal
        title="Attestation"
        description="I am a licensed provider and hold the necessary credentials, including board certification in the appropriate specialty to competently review and make an organizational determination on this case."
        open={openModal}
        className="confirmationModal"
        onClose={() => setOpenModal(false)}
      >
        <div className="buttonsContainer flex justify-end">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="btn mr-2"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleSubmitDecision}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DecisionSubmit;

import { FC } from 'react';
import { Link } from "react-router-dom";

const SuccessMessage: FC = () => {
  return (
    <div className="flex">
      {/* <section>
        <h2 className="py-4">Request Type</h2>
        <hr style={{borderColor:'#0E4273', borderTopWidth:'2px'}}/>
        <div className="grid grid-cols-1 mt-4">
          <h3 className="py-2">Initial Request: Expedited</h3>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque illum similique odio officia cum quam alias voluptatum ipsam nihil veritatis! Mollitia, totam facere dolor cumque quisquam ipsum saepe nulla nesciunt.</p>
        </div>
      </section>
      <section className="mt-8">
        <h2 className="py-4">Disclaimer Statement / Attestation</h2>
        <hr style={{borderColor:'#0E4273', borderTopWidth:'2px'}}/>
        <div className="grid md:grid-cols-4 mt-4">
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Requestor's name <br />
              <strong>Leonard Chapman</strong>
            </p>
          </div>
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Phone Number <br />
              <strong>1-931-451-7533</strong>
            </p>
          </div>
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Fax Number <br />
              <strong>1-931-451-7533</strong>
            </p>
          </div>
        </div>
      </section>
      <section className="mt-8">
        <h2 className="py-4">Member Information</h2>
        <hr style={{borderColor:'#0E4273', borderTopWidth:'2px'}}/>
        <div className="grid md:grid-cols-4 mt-4">
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Member Name <br />
              <strong>John Doe</strong>
            </p>
          </div>
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Date of birth<br />
              <strong>02.02.1963</strong>
            </p>
          </div>
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Member ID <br />
              <strong>AD-4U5-AAA</strong>
            </p>
          </div>
          <div className="mb-4">
            <p style={{color:'#0E4273'}}>Member Living Facility <br />
              <strong>Facility</strong>
            </p>
          </div>
        </div>
      </section> */}
      <hr className="my-16" />
      <div className="buttonContainer">
        <Link to="/dashboard" className="btn btn-green">
          Back to Prior Auth Dashboard
        </Link>
      </div>
    </div>
  )
}

export default SuccessMessage;
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { ICcmReferral } from '../../interfaces/ccm-referrals';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Pagination } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IControls } from '.';
import moment from 'moment';

interface IProps {
  paginatedData: ICcmReferral[];
  role: string | undefined;
  filterBy: string;
  filterValue: string;
  filterByControls: IControls[];
  selectedBirthday: Date | null;
  selectedDateOfReferral: Date | null;
  page: number;
  filterCounter: number;
  csvDownload: () => any[][];
  handleChangeFilterBy: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (date: Date | null) => void;
  handleDateOfReferralChange: (date: Date | null) => void;
  clearFilter: () => void;
  handleDetails: (item: ICcmReferral) => void;
  handlePagination: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const CcmReferralsSentMobileView: FC<IProps> = ({
  paginatedData,
  role,
  filterBy,
  filterValue,
  filterByControls,
  selectedBirthday,
  selectedDateOfReferral,
  page,
  filterCounter,
  csvDownload,
  handleChangeFilterBy,
  handleFilterChange,
  handleDateChange,
  handleDateOfReferralChange,
  clearFilter,
  handleDetails,
  handlePagination,
}) => {
  return (
    <div className="flex flex-col xl:hidden mt-4">
      <div className="flex justify-end">
        {role !== 'Viewer' ? (
          <CSVLink
            data={csvDownload()}
            filename={'CCM-Referrals.csv'}
            className="bt mb-4 btn-blue"
          >
            Export CSV
          </CSVLink>
        ) : (
          <button type="button" className="mb-4 btn btn-disabled" disabled>
            Export CSV
          </button>
        )}
      </div>

      <Accordion className="mb-4">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filters"
          id="er-notifications-filters"
        >
          <div className="flex flex-col w-full">
            <div className="font-bold">
              <span className="text-gray-500">Filters</span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          <FormControl component="fieldset">
            <FormLabel component="legend">Filter by</FormLabel>
            <RadioGroup
              aria-label="filterBy"
              name="filterBy"
              value={filterBy}
              onChange={handleChangeFilterBy}
            >
              {filterByControls.map((item: IControls) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio color="primary" />}
                    label={item.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {filterBy &&
          filterBy !== 'birthday' &&
          filterBy !== 'dateOfReferral' ? (
            <form noValidate autoComplete="off" className="w-full">
              <TextField
                className="w-full"
                id="filled-name"
                label="text here..."
                value={filterValue}
                onChange={handleFilterChange}
                disabled={!filterBy ? true : false}
              />
            </form>
          ) : null}
          {filterBy && filterBy === 'birthday' ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  className="w-full"
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date of birth"
                  format="MM/dd/yyyy"
                  value={selectedBirthday}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : null}

          {filterBy && filterBy === 'dateOfReferral' ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  className="w-full"
                  margin="normal"
                  id="date-picker-dialog-referral"
                  label="Date of Referral"
                  format="MM/dd/yyyy"
                  value={selectedDateOfReferral}
                  onChange={handleDateOfReferralChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : null}
          <button className="btn btn-blue mt-4" onClick={clearFilter}>
            Clear Filters
          </button>
        </AccordionDetails>
      </Accordion>
      {paginatedData.length ? (
        paginatedData.map((item: any, key: number) => {
          return (
            <Accordion className="mb-4" key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="er-notifications-header"
              >
                <div className="flex flex-col w-full">
                  <div className="font-bold">
                    <span className="text-gray-500">{item.memberId}</span>
                  </div>
                  <span>
                    {item.memberFirstName} {item.memberLastName}
                  </span>
                  <span>
                    {item.memberDateOfBirth
                      ? moment().diff(item.memberDateOfBirth, 'years') +
                        ' years old'
                      : null}
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-full">
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Date of Birth: </span>
                    <span className="font-bold">
                      {item.memberDateOfBirth
                        ? moment(item.memberDateOfBirth).format('MM/DD/yyyy')
                        : null}
                    </span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Primary Diagnosis: </span>
                    <span className="font-bold">{item.primaryDiagnosis}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Facility Name: </span>
                    <span className="font-bold">{item.facilityName}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Facility NPI: </span>
                    <span className="font-bold">{item.facilityNpi}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Date Of Referral: </span>
                    <span className="font-bold">
                      {item.dateOfReferral
                        ? moment(item.dateOfReferral).format('MM/DD/yyyy')
                        : null}
                    </span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Requester Name: </span>
                    <span className="font-bold">{item.requesterName}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Requester Email: </span>
                    <span className="font-bold">{item.requesterEmail}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">
                      Requester Phone Number:{' '}
                    </span>
                    <span className="font-bold">
                      {item.requesterPhoneNumber}
                    </span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">
                      Requester Member Relationship:
                    </span>
                    <span className="font-bold">
                      {item.requesterMemberRelationship}
                    </span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Reason for Referral:</span>
                    <span className="font-bold">{item.reasonForReferral}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Status:</span>
                    <span className="font-bold">{item.status}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-blue-transparent w-full"
                      onClick={() => {
                        handleDetails(item);
                      }}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <div className="mb-4">
          <Accordion>
            <AccordionSummary>There is no data to show</AccordionSummary>
          </Accordion>
        </div>
      )}
      <div className="flex flex-col items-center showMobile w-full">
        <Typography className="pb-2">Page: {page}</Typography>
        <Pagination
          count={Math.round(filterCounter / 10)}
          page={page}
          onChange={handlePagination}
          shape="rounded"
          showFirstButton
          showLastButton
        />
      </div>
    </div>
  );
};

export default CcmReferralsSentMobileView;

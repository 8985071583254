import { FC, useEffect, ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePasswordValidator from 'react-use-password-validator';
import { initialValues } from '../../initial-values/user';
import { useRegisterUserMutation } from '../../hooks/mutations/useUserMutations';
import { Alert } from '@material-ui/lab';
import { getCurrentPlan } from '../../planData';

const validationSchema = Yup.object().shape({
  providerStatus: Yup.string(),
  taxId: Yup.string(),
  firstName: Yup.string().required('First name  is a required field'),
  lastName: Yup.string().required('Last name  is a required field'),
  jobRole: Yup.string(),
  businessAddress: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string(),
  phone: Yup.string(),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is a required field'),
  password: Yup.string().required(
    'The password entered does not meet the requirements.'
  ),
  providerName: Yup.string().required('Provider is a required field'),
  practiceName: Yup.string(),
  npi: Yup.string(),
  plans: Yup.array(),
});

const inputStyle: any = {
  padding: '10px',
  border: '1px solid #D0D4D8',
};

const RegisterForm: FC = () => {
  const history = useHistory();
  const [isValidPassword, setIsValidPassword] = usePasswordValidator({
    digits: true,
    min: 8,
    spaces: false,
    lowercase: true,
    uppercase: true,
    symbols: true,
  });

  const {
    mutate: createRegisterUser,
    isLoading: isSaving,
    isError,
    isSuccess,
    error: errorMessage,
  } = useRegisterUserMutation();

  const { permissions, site } = getCurrentPlan();

  if (site === 'providers.simpra.com') {
    history.push(`/dashboard`);
  }

  let planState: any = {
    H3291: 'Georgia',
    H6345: 'South Carolina',
    H1372: 'Oregon',
    H4172: 'Missouri/Tennessee/South Carolina',
    H4091: 'Alabama',
    H3467: 'Texas',
    H6959: 'Maryland',
    H6832: 'Michigan',
    H9917: 'Florida',
    H3274: 'California',
    H8797: 'Ohio',
    H3419: 'Colorado',
    Massachusetts: 'Massachusetts',
    H1277: 'Virginia',
  };

  const {
    values: formValues,
    errors,
    touched,
    isValid,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    getFieldProps,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log('payload', values);
      createRegisterUser(values, {
        onSuccess: () => {
          console.log('onSuccess');
          resetForm();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    },
  });

  const isPlanRequired =
    site === 'providers.alignseniorcare.com' ||
    site === 'provider-portal-dev-ui.azurewebsites.net' ||
    site === 'provider-portal-staging-ui.azurewebsites.net' ||
    site === 'localhost:3000';

  const isPlanSelected = formValues.plans.length;

  const isDisabled =
    !isValid || !isValidPassword || (isPlanRequired && !isPlanSelected);

  useEffect(() => {
    if (formValues.password) {
      setIsValidPassword(formValues.password);
    }
  }, [formValues, setIsValidPassword]);

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    setFieldValue(name, value);
    setFieldTouched(name, true);
  }

  if (isSuccess) {
    return (
      <Alert severity="success" className="m-8 p-4">
        <h1>
          The registration was successfully saved! Please allow 24-48 hours for
          the review and account activation process to be completed.
        </h1>
        <button
          type="button"
          className="btn btn-green mt-4"
          onClick={() => {
            resetForm();
            history.go(0);
          }}
        >
          Go back to register form
        </button>
      </Alert>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        <h1>There was en error saving the record, please try again later. </h1>
        <button
          type="button"
          className="btn btn-green mt-4"
          onClick={() => {
            resetForm();
            history.go(0);
          }}
        >
          Go back to register form
        </button>
      </Alert>
    );
  }

  return (
    <div className="w-full px-4 md:px-8 mx-auto mb-8 relative">
      <form className="my-4 flex flex-col" onSubmit={handleSubmit}>
        <div className="form-control flex flex-col mb-6">
          <FormControl component="fieldset">
            <h2 className="mb-6">Please Choose one:</h2>
            <RadioGroup
              aria-label="Part A Services"
              name="providerStatus"
              value={formValues.providerStatus}
              onChange={onInputChange}
            >
              <FormControlLabel
                value="In-Network"
                control={<Radio color="primary" />}
                label="I am an In-Network Provider"
              />
              <FormControlLabel
                value="On-Behalf-Of-Provider"
                control={<Radio color="primary" />}
                label="I am registering as a representative of an In-Network Provider/Practice"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {isPlanRequired ? (
          <div className="mb-4">
            <h2>
              <span className="text-red-500">*</span> Please identify the Align
              Senior Care market the provider/practice serves:
            </h2>
            <FormGroup>
              {permissions.length
                ? permissions.map((item: string) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          name="plans"
                          value={item}
                          checked={formValues.plans.includes(item)}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label={planState[item]}
                    />
                  ))
                : null}
            </FormGroup>
            {errors.plans && <div className="text-red-500">{errors.plans}</div>}
          </div>
        ) : null}

        <div className="form-control flex flex-col mb-6">
          <h2>Tax Identification Numbers</h2>
          <p>
            At the completion of registration we will let you know who can
            assign you access to additional features of the Provider Portal for
            each TIN you entered.
          </p>
          <div className="flex flex-col">
            <FormLabel component="legend" className="my-2">
              Tax Indentification Number:
            </FormLabel>
            <input style={inputStyle} id="taxId" {...getFieldProps('taxId')} />
          </div>
        </div>
        <div className="form-control flex flex-col mb-6">
          <h2>Create Your Account</h2>
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <div className="flex">
                <span className="text-red-500">*</span>
                <FormLabel component="legend" className="my-2">
                  First Name
                </FormLabel>
              </div>
              <input
                style={inputStyle}
                id="firstName"
                {...getFieldProps('firstName')}
              />
              {touched.firstName && errors.firstName ? (
                <div className="text-red-500">{errors.firstName}</div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <span className="text-red-500">*</span>
                <FormLabel component="legend" className="my-2">
                  Last Name
                </FormLabel>
              </div>
              <input
                style={inputStyle}
                id="lastName"
                {...getFieldProps('lastName')}
              />
              {touched.lastName && errors.lastName ? (
                <div className="text-red-500">{errors.lastName}</div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <FormLabel component="legend" className="my-2">
                Job Role
              </FormLabel>
              <input
                style={inputStyle}
                id="jobRole"
                {...getFieldProps('jobRole')}
              />
            </div>
          </div>
        </div>
        <div className="form-control flex flex-col mb-6">
          <FormLabel component="legend" className="my-2">
            Business Address:
          </FormLabel>
          <input
            style={inputStyle}
            id="businessAddress"
            {...getFieldProps('businessAddress')}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="form-control flex flex-col mb-6">
            <FormLabel component="legend" className="my-2">
              City:
            </FormLabel>
            <input style={inputStyle} id="city" {...getFieldProps('city')} />
          </div>
          <div className="form-control flex flex-col mb-6">
            <FormLabel component="legend" className="my-2">
              State:
            </FormLabel>
            <input style={inputStyle} id="state" {...getFieldProps('state')} />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="form-control flex flex-col mb-6">
            <FormLabel component="legend" className="my-2">
              ZIP:
            </FormLabel>
            <input style={inputStyle} id="zip" {...getFieldProps('zip')} />
          </div>
          <div className="form-control flex flex-col mb-6">
            <FormLabel component="legend" className="my-2">
              Phone:
            </FormLabel>
            <input style={inputStyle} id="phone" {...getFieldProps('phone')} />
          </div>
        </div>
        <h2>Provider Information</h2>
        <div className="form-control flex flex-col mb-6">
          <FormLabel component="legend" className="my-2">
            <span className="text-red-500">*</span> Provider Name:
          </FormLabel>
          <input
            style={inputStyle}
            id="providerName"
            {...getFieldProps('providerName')}
          />
          {touched.providerName && errors.providerName ? (
            <div className="text-red-500">{errors.providerName}</div>
          ) : null}
        </div>
        <div className="form-control flex flex-col mb-6">
          <FormLabel component="legend" className="my-2">
            Practice Name:
          </FormLabel>
          <input
            style={inputStyle}
            id="practiceName"
            {...getFieldProps('practiceName')}
          />
        </div>
        <div className="form-control flex flex-col mb-6">
          <FormLabel component="legend" className="my-2">
            NPI #:
          </FormLabel>
          <input style={inputStyle} id="npi" {...getFieldProps('npi')} />
        </div>
        <h2>Account Information</h2>
        <div className="form-control flex flex-col mb-6">
          <div className="flex">
            <span className="text-red-500">*</span>
            <FormLabel component="legend" className="my-2">
              Email:
            </FormLabel>
          </div>
          <input
            type="email"
            style={inputStyle}
            id="email"
            {...getFieldProps('email')}
          />
          {touched.email && errors.email ? (
            <div className="text-red-500">{errors.email}</div>
          ) : null}
        </div>
        <div className="form-control flex flex-col mb-6">
          <div className="flex">
            <span className="text-red-500">*</span>
            <FormLabel component="legend" className="my-2">
              Password:
            </FormLabel>{' '}
          </div>
          <input
            type="password"
            style={inputStyle}
            id="password"
            {...getFieldProps('password')}
          />
          {errors.password || !isValidPassword ? (
            <div className="text-red-500">
              The password entered does not meet the requirements.
            </div>
          ) : null}
          <div>
            <p>Password is required and must includes:</p>
            <ul>
              <li>Special characters (!@#$%^&*)</li>
              <li>Lower case (a-z), uppercase (A-Z) and numbers(0-9)</li>
              <li>Must have 8 characters in length</li>
              <li>Non-empty password is required</li>
            </ul>
          </div>
        </div>
        {!isSaving ? (
          <div className="flex">
            <button
              type="button"
              className="btn btn-gray mr-2"
              onClick={() => {
                history.push('/');
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={clsx(
                {
                  'btn-disabled': isDisabled,
                  'btn btn-red': !isDisabled,
                },
                'btn'
              )}
              disabled={isDisabled}
            >
              Submit
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <CircularProgress />
            <h3>Sending form...</h3>
          </div>
        )}
      </form>
    </div>
  );
};

export default RegisterForm;

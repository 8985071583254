import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';
import MainNavigation from '../../components/MainNavigation';

const NotAuthenticated: FC<any> = (props) => {
  return (
    <div className="custom-container">
      <div>
        <Header />
        <MainNavigation />
        <HeaderTitle title="Login Authentication Error" />

        <div className="md:grid md:grid-cols-2 md:gap-4 px-8">
          <div>
            <p style={{ color: '#003967' }}>
              We are experiencing an issue logging in to your account. To resolve the issue, your account's authentication will need to be reset. Please contact your Portal administrator for assistance.
            </p>
            <Link to="/" className="btn btn-red mt-8 inline-block">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
      <div>
        <Footer />
        <FooterBottom />
      </div>
    </div>
  );
};

export default NotAuthenticated;

import { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { IMemberDetails } from '../../interfaces/interfaces';
interface IProps {
  member: IMemberDetails;
}

const DecisionSubmitSidebarAuthorizationHistory: FC<IProps> = ({ member }) => {
  const role = window.sessionStorage.getItem('role');

  return (
    <div>
      {member.notes.length &&
        member.notes.map((item: any, key: number) => {
          return (
            <div className="mt-4">
              <Accordion key={key}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex flex-col w-full">
                    <div className="font-bold">{item.subject}</div>
                    <div>{item.createdDate}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    <hr className="mb-4" />
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Subject: </span>
                      <span className="font-bold">{item.subject}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Created date: </span>
                      <span className="font-bold">
                        {item.createdDate
                          ? moment(item.createdDate).format('L')
                          : null}
                      </span>
                    </div>
                    {(role === 'MD' ||
                      role === 'MD - Restricted' ||
                      role === 'MD - Case Manager' ||
                      role === 'Viewer' ||
                      role === 'MD - Reviewer' || 
                      role === 'MD - Reviewer Restricted') && (
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Notes: </span>
                        <span className="font-bold">{item.note}</span>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

export default DecisionSubmitSidebarAuthorizationHistory;



export const defaultFilterValue = [
  {
    name: 'id',
    operator: 'eq',
    type: 'string',
    value: '',
  },
  {
    name: 'firstName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'lastName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'userEmail',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'providerName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'practiceName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'npi',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'state',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'plansByState',
    operator: 'Has',
    type: 'plansByState',
    value: '',
  },
  {
    name: 'createdAt',
    operator: 'before',
    type: 'date',
    value: '',
  },
];
import { FC } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';
import { FieldInputProps } from 'formik';
import { partBServices, rehabPotentials } from './constants';

interface IProps {
  hasNoPermissions: boolean;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
}

export const RequestDetailsPartB: FC<IProps> = ({
  hasNoPermissions,
  getFieldProps,
}) => {
  return (
    <div>
      <div className="">
        <FormControl component="fieldset" disabled={hasNoPermissions}>
          <RadioGroup
            aria-label="Part B Services"
            className="flex flex-col md:flex-row"
            {...getFieldProps('partBServices')}
          >
            {partBServices.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>

      <div className="grid xl:grid-cols-3 xl:gap-6 mt-8 pb-8">
        <FormControl component="fieldset" disabled={hasNoPermissions}>
          <FormLabel component="legend">Rehab Potential</FormLabel>
          <RadioGroup
            aria-label="Rehab Potential"
            {...getFieldProps('rehabPotential')}
          >
            {rehabPotentials.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <div className="mt-4 xl:mt-0">
          <FormControl component="fieldset" disabled={hasNoPermissions}>
            <FormLabel component="legend">
              Significant Improvement Made?
            </FormLabel>
            <RadioGroup
              aria-label="significantImprovementMade"
              {...getFieldProps('significantImprovementMade')}
            >
              {['Yes', 'No', 'N/A'].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio color="primary" />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="grid grid-cols-1 pb-8">
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">Description or CPT Code</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="descriptionOrCptCode">
              Description or CPT Code
            </InputLabel>
            <OutlinedInput
              id="descriptionOrCptCode"
              label="descriptionOrCptCode"
              {...getFieldProps('descriptionOrCptCode')}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Dashboard: FC<any> = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const role = useSelector((state: RootState) => state.profile.data.role);
  const plan = useSelector((state: RootState) => state.planData.data);

  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  if (isAuthenticated) {
    return (
      <div id="navigation">
        {role === 'Admin' &&
        (plan.site === 'providerportaladmin.allyalign.com' ||
          plan.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
          plan.site === 'provider-portal-staging-admin.tutelarhealth.io' ||
          plan.site === 'localhost:3000') ? (
          <div className="py-8" style={{ backgroundColor: '#F2F5F9' }}>
            <div className="w-full px-4 lg:px-8 mx-auto">
              <ul className="flex flex-col lg:flex-row">
                <Link
                  to="/admin/portal-users"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Portal Users
                </Link>
                <Link
                  to="/admin/registrations"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Review Registrations
                </Link>
                <Link
                  to="/admin/portal-activity"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Portal Activity
                </Link>
                <Link
                  to="/dashboard"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Prior Auth Dashboard
                </Link>
                <Link
                  to="/ccm-referrals"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  CCM Referrals
                </Link>
                <Link
                  to="/pqi-referrals"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  PQI Referrals
                </Link>
                <Link
                  to="/members"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Member Search
                </Link>
                <Link
                  to="/hospital-notifications"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Hospital Notifications
                </Link>
                <Link
                  to="/contact"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Contact UM
                </Link>
              </ul>
            </div>
          </div>
        ) : (
          <div className="py-8" style={{ backgroundColor: '#F2F5F9' }}>
            <div className="w-full px-4 md:px-8 mx-auto">
              <ul className="flex flex-col md:flex-row">
                <Link
                  to="/dashboard"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Prior Auth Dashboard
                </Link>
                <Link
                  to="/authorization"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  New Prior Authorization Request
                </Link>
                {role === 'MD' ||
                role === 'MD - Restricted' ||
                role === 'MD - Case Manager' ||
                role === 'Viewer' ||
                role === 'MD - Reviewer' ||
                role === 'MD - Reviewer Restricted' ? (
                  <Link
                    to="/members"
                    style={{ color: '#023B64' }}
                    className="mr-8 py-2"
                  >
                    Member Search
                  </Link>
                ) : null}

                {plan.site === 'providers.alignseniorcare.com' ||
                plan.site === 'providers.lifeworksadvantage.com' ||
                //plan.site === 'providers.nhcadvantageplan.com' ||
                plan.site === 'provider-portal-dev-ui.azurewebsites.net' ||
                plan.site === 'provider-portal-staging-ui.azurewebsites.net' ||
                plan.site === 'providers.perennialadvantage.com' ||
                plan.site === 'providerportaladmin.allyalign.com' ||
                plan.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
                plan.site ===
                  'provider-portal-staging-admin.tutelarhealth.io' ||
                plan.site === 'localhost:3000' ? (
                  <>
                    <Link
                      to="/hospital-notifications"
                      style={{ color: '#023B64' }}
                      className="mr-8 py-2"
                    >
                      Hospital Notifications
                    </Link>
                  </>
                ) : null}

                {plan.site === 'providers.alignseniorcare.com' ||
                plan.site === 'providers.lifeworksadvantage.com' ||
                plan.site === 'provider-portal-dev-ui.azurewebsites.net' ||
                plan.site === 'provider-portal-staging-ui.azurewebsites.net' ||
                plan.site === 'providerportaladmin.allyalign.com' ||
                plan.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
                plan.site ===
                  'provider-portal-staging-admin.tutelarhealth.io' ||
                plan.site === 'localhost:3000' ? (
                  <Link
                    to="/ccm-referrals"
                    style={{ color: '#023B64' }}
                    className="mr-8 py-2"
                  >
                    CCM Referrals
                  </Link>
                ) : null}

                <Link
                  to="/pqi-referrals"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  PQI Referrals
                </Link>

                <Link
                  to="/contact"
                  style={{ color: '#023B64' }}
                  className="mr-8 py-2"
                >
                  Contact UM
                </Link>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <div>You need to login in order to see information!</div>;
};

export default Dashboard;

import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  MemberComponent,
} from '../../components';
import MainNavigation from '../../components/MainNavigation';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';

const MembersPage: FC = () => {
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();
  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  };

  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
    return (
      <div className="custom-container">
        <div>
          <Header />
          <MainNavigation />
          <HeaderTitle title="Member Search" />
          <MemberComponent />
        </div>
        <div>
          <Footer />
          <FooterBottom />
        </div>
      </div>
    );
  }
  return <div>You need to login in order to see information!</div>;
};

export default MembersPage;

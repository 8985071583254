import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';

const MaintenancePage: FC<any> = (props) => {
  return (
    <div className="custom-container">
      <div>
        <Header />
        <HeaderTitle title="Provider Portal Maintenance Notification" />
        <div className="w-full px-8">
          <p>
            Please note that the Provider Portal for Prior Authorization
            submissions and reviews will be{' '}
            <b className="text-black bold">UNAVAILABLE.</b>
          </p>
          <div className="my-8 flex justify-center flex-col items-center">
            <h2 className="primary-color">Maintenance Window</h2>
            <h3>The platform will be back today.</h3>
            {/* <p>
              During this window, please submit all Prior Authorization requests
              by email or phone.{' '}
            </p> */}
            <p className="primary-color mt-4">
              <Link to="/contact">
                Click here to view UM contact information.
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
        <FooterBottom />
      </div>
    </div>
  );
};

export default MaintenancePage;

import { configureStore } from '@reduxjs/toolkit'
import { authenticationName, authenticationReducer } from './authentication/auth.slice'
import { authorizationCaseName, authorizationCaseReducer } from './authorizationCase/authorizationCase.slice'
import { memberDetailsName, memberDetailsReducer } from './memberDetails/memberDetails.slice'
import { planDataName, planDataReducer } from './plan/plan.slice'
import { profileName, profileReducer } from './profile/profile.slice'
import drawerReducer from './DrawerSlice'
  
export const store = configureStore({
  reducer: {
    [authenticationName]: authenticationReducer,
    [authorizationCaseName]: authorizationCaseReducer,
    [profileName]: profileReducer,
    [planDataName]: planDataReducer,
    [memberDetailsName]: memberDetailsReducer,
    drawer: drawerReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
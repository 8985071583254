import { FC, useEffect, useState } from 'react';
import { Chip, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { IPortalUser } from '../../../interfaces/interfaces';
import _ from 'lodash';

export interface ICustomCheck {
  id: number | null;
  name: string | null;
  isActive: boolean | null;
}

export interface IProps {
  data: IPortalUser[];
}

const PortalUsersComponentMobile: FC<IProps> = ({ data }) => {
  const [currentData, setCurrentData] = useState<IPortalUser[]>(data);
  const history = useHistory();
  const [roles, setRoles] = useState<ICustomCheck[]>([
    {
      id: null,
      name: null,
      isActive: null,
    },
  ]);
  const [plans, setPlans] = useState<ICustomCheck[]>([
    {
      id: null,
      name: null,
      isActive: null,
    },
  ]);
  const [portalUsersFiltered, setPortalUsersFiltered] =
    useState<IPortalUser[]>(data);

  const handleDetails = (id: number) => {
    history.push(`/admin/portal-users/${id}`);
  };

  useEffect(() => {
    const hasBeenReviewed = data.filter(
      (item: IPortalUser) => item.hasBeenReviewed
    );
    if (hasBeenReviewed) {
      setCurrentData(hasBeenReviewed);
      setPortalUsersFiltered(hasBeenReviewed);

      // ROLES
      const rolesMapped = hasBeenReviewed.map((item: IPortalUser) => item.role);
      const distinct = _.uniq(rolesMapped);
      let noneEmpty = distinct.filter((item: string) => item !== '');
      noneEmpty = ['All', ...noneEmpty];
      const tempRoles: any = noneEmpty.map((item: string, index: number) => ({
        id: index,
        name: item,
        isActive: false,
      }));
      tempRoles[0].isActive = true;
      setRoles(tempRoles);

      // PLANS
      // For Maps Filtering
      const plansMapped = hasBeenReviewed.map((item: any) => item.plansByState);
      const allPlans: any = [];
      plansMapped.forEach((item: any) => {
        allPlans.push(...item);
      });
      const distinctPlans = allPlans.filter(
        (value: any, index: number, self: any) => {
          return self.indexOf(value) === index;
        }
      );
      distinctPlans.unshift('All');
      const tempPlans: any = [];
      distinctPlans.forEach((item: string, index: number) => {
        tempPlans.push({
          id: index,
          name: item,
          isActive: false,
        });
      });
      tempPlans[0].isActive = true;
      setPlans(tempPlans);
    }
  }, []);

  const handleRoles = (value: ICustomCheck) => {
    value.isActive = !value.isActive;
    let rolesActive: any[] = [];
    roles
      .filter((item) => item.isActive)
      .forEach((element) => rolesActive.push(element.name));
    if (value.name === 'All' || rolesActive.length === 0) {
      roles.forEach((item: ICustomCheck) => {
        item.isActive = false;
      });
      roles[0].isActive = true;
      const dataFiltered = filter(currentData);
      setPortalUsersFiltered(dataFiltered);
    } else {
      if (rolesActive[0] === 'All') {
        rolesActive.splice(0, 1);
        roles[0].isActive = false;
      }
      const dataFiltered = filter(currentData);
      setPortalUsersFiltered(dataFiltered);
    }
  };

  const handlePlans = (value: any) => {
    value.isActive = !value.isActive;
    let plansActive = plans.filter((item) => item.isActive);
    let dataFiltered = [];

    if (value.name === 'All' || plansActive.length === 0) {
      plans.forEach((item: ICustomCheck) => {
        item.isActive = false;
      });
      plans[0].isActive = true;
    } else {
      if (plansActive[0].name === 'All') {
        plansActive.splice(0, 1);
        plans[0].isActive = false;
      }
    }
    dataFiltered = filter(currentData);

    setPortalUsersFiltered(dataFiltered);
  };

  const filterByLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataFiltered = currentData.filter((item) =>
      item.profile.lastName
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setPortalUsersFiltered(dataFiltered);
  };

  const filterByCaseId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataFiltered = currentData.filter((item) =>
      item.id.toString().includes(event.target.value.toString())
    );
    setPortalUsersFiltered(dataFiltered);
  };

  const filter = (currentData: IPortalUser[]) => {
    //Passes currentData through both Roles and Plans filters
    let rolesActive: any[] = [];
    roles
      .filter((item) => item.isActive)
      .forEach((element) => rolesActive.push(element.name));
    let dataRoleFiltered: IPortalUser[] = [];
    if (rolesActive[0] === 'All') {
      dataRoleFiltered = currentData;
    } else {
      dataRoleFiltered = currentData.filter((item: IPortalUser) =>
        rolesActive.includes(item.role)
      );
    }
    let plansActive = plans.filter((item) => item.isActive);
    let dataFiltered: IPortalUser[] = [];
    if (plansActive[0].name === 'All') {
      dataFiltered = dataRoleFiltered;
    } else {
      dataFiltered = dataRoleFiltered.filter((item: any) =>
        plansActive.every((val) => item.plansByState.indexOf(val.name!) !== -1)
      );
    }
    return dataFiltered;
  };

  return (
    <section className="w-full px-4 md:px-8 mx-auto mb-16 showMobile">
      <Accordion className="mb-4">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <FilterListIcon className="mr-4" />
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          <>
            <FormControl component="fieldset" className="w-full">
              <FormLabel component="legend" className="mb-2">
                Filter by Role
              </FormLabel>
              <FormGroup>
                <div className="mb-6">
                  {roles.map((item: any) => (
                    <div className="inline-block mr-2 mb-2" key={item.id}>
                      <Chip
                        color={item.isActive ? 'primary' : 'default'}
                        key={item.id}
                        label={item.name}
                        onClick={() => {
                          handleRoles(item);
                        }}
                        style={{ marginRight: '5px', marginBottom: '5px' }}
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>
            </FormControl>
          </>
          <>
            <FormControl component="fieldset" className="w-full">
              <FormLabel component="legend" className="mb-2">
                Filter by Plan
              </FormLabel>
              <FormGroup>
                <div className="mb-6">
                  {plans.map((item: any) => (
                    <div className="inline-block mr-2 mb-2" key={item.id}>
                      <Chip
                        color={item.isActive ? 'primary' : 'default'}
                        key={item.id}
                        label={item.name}
                        onClick={() => {
                          handlePlans(item);
                        }}
                        style={{ marginRight: '5px', marginBottom: '5px' }}
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>
            </FormControl>
          </>
          <div className="mb-4">
            <FormControl className="w-full xl:w-1/2">
              <FormLabel component="legend" className="mb-2">
                Filter by Last Name
              </FormLabel>
              <TextField
                id="filled-basic"
                variant="outlined"
                onChange={filterByLastName}
              />
            </FormControl>
          </div>
          <div className="mb-4">
            <FormControl className="w-full xl:w-1/2">
              <FormLabel component="legend" className="mb-2">
                Filter by Id
              </FormLabel>
              <TextField
                id="filled-basic"
                variant="outlined"
                onChange={filterByCaseId}
              />
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>
      {portalUsersFiltered.length ? (
        <div className="showMobile">
          {portalUsersFiltered.length &&
            portalUsersFiltered.map((item: any, key: number) => {
              return (
                <Accordion className="mb-4 showMobile" key={key}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="service-request-header"
                  >
                    <div className="flex flex-col w-full">
                      <div className="font-bold">
                        <span className="text-gray-500">{item.id}</span>
                      </div>
                      <span>
                        {item.profile.firstName} {item.profile.lastName}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full">
                      <hr className="mb-4" />
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Email: </span>
                        <span className="font-bold">{item.userEmail}</span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Plans: </span>
                        <div>
                          {item.plansByState &&
                            item.plansByState.map(
                              (item: string, index: number) => (
                                <Chip
                                  key={index}
                                  label={item}
                                  style={{
                                    marginRight: '5px',
                                    marginBottom: '5px',
                                  }}
                                />
                              )
                            )}
                        </div>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Role: </span>
                        <span className="font-bold">
                          <div>
                            <Chip
                              label={item.role}
                              style={{
                                marginRight: '5px',
                                marginBottom: '5px',
                              }}
                            />
                          </div>
                        </span>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-blue-transparent w-full"
                          onClick={() => {
                            handleDetails(item.id);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      ) : (
        <h3>There is no data to display!</h3>
      )}
    </section>
  );
};

export default PortalUsersComponentMobile;

import { FC } from 'react';
import ContactForm from '../ContactForm';
import ContactInformation from '../ContactInformation';

const ContactComponent: FC = () => {
  return (
    <div className="w-full px-4 md:px-8 mx-auto mb-8 relative">
      <div className="grid lg:grid-cols-3 gap-8 xl:gap-14">
        <div className="col-span-2">
          <ContactInformation />
        </div>
        <div>
          <ContactForm />
        </div>
      </div>


    </div>
  )
}

export default ContactComponent;
import { FC, useCallback, useEffect, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setDashboardData,
  setDashboardFiltered,
  setDashboardPaginatedData,
  setDashboardPaginatedDataByPage,
} from '../../../store/authorizationCase/authorizationCase.slice';
import Cookies from 'js-cookie';
import { IAuthorization } from '../../../interfaces/authorizations';
import DashboardFiltersAccordion from './DashboardFiltersAccordion';
export interface IChipObject {
  id: number;
  label: string;
  isActive: boolean;
}
interface checkboxStatus {
  name: string;
  selected: boolean;
}

const lastMdReviewedStatus = [
  {
    label: 'SUBMITTED',
    value: 'SUBMITTED',
  },
  {
    label: 'PENDING PEER REVIEW',
    value: 'PENDING PEER REVIEW',
  },
  {
    label: 'DOCUMENTS ADDED',
    value: 'DOCUMENTS ADDED',
  },
  {
    label: 'PENDING DOCUMENTS',
    value: 'PENDING DOCUMENTS',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export interface IStatus {
  id: number;
  label: string;
  isActive: boolean;
}

interface IProps {
  data: IAuthorization[];
}

const DashboardFilters: FC<IProps> = ({ data }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState<IStatus[]>([]);
  const [currentLabel, setCurrentLabel] = useState('');
  const [checkboxStatus, setCheckboxStatus] = useState<checkboxStatus[]>([]);
  const [npiArray, setNPIArray] = useState<string[]>([]);
  const [serviceFromDate, setServiceFromDate] = useState({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });
  const [serviceToDate, setServiceToDate] = useState({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });
  const [radioValue, setRadioValue] = useState('');
  const [inputTextFilterValue, setInputTextFilterValue] = useState('');
  const [mdReviewed, setMdReviewed] = useState('');
  const dashboardData = useSelector(
    (state: RootState) => state.authorizationCase.dashboardData
  );

  const role = Cookies.get('aah-role');

  useEffect(() => {
    if (data && data.length) {
      dispatch(setDashboardData(data));
      dispatch(setDashboardPaginatedData(data));
      dispatch(setDashboardFiltered(data));
      dispatch(
        setDashboardPaginatedDataByPage({ pageSize: 10, pageNumber: 1 })
      );
    }
  }, [data, dispatch]);

  const updateState = useCallback(
    (dataFiltered) => {
      dispatch(setDashboardFiltered(dataFiltered));
      dispatch(
        setDashboardPaginatedDataByPage({ pageSize: 10, pageNumber: 1 })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (data && data.length) {
      if (role === 'MD - Reviewer' || role === 'MD - Reviewer Restricted') {
        const dataFiltered = data.filter(
          (item) => item.authStatus === 'UNDER MEDICAL REVIEW'
        );
        updateState(dataFiltered);
      }
    }
  }, [data, role, updateState]);

  const setStatuses = useCallback(() => {
    if (dashboardData && dashboardData.length) {
      const x = dashboardData.map((item: any) => item.authStatus);
      let distinct = _.uniq(x);
      distinct = ['All', ...distinct];
      const z = distinct.map((label: string, index: number) => ({
        id: index + 1,
        label,
        isActive: label === currentLabel,
      }));
      setStatus(z);
    }
  }, [currentLabel, dashboardData]);

  const setNPIs = useCallback(() => {
    if (dashboardData.length) {
      const npis = dashboardData.map((item: any) => item.npi);
      let distinct = _.uniq(npis);
      distinct = ['All', ...distinct];
      setNPIArray(distinct);
      const tempStatus = distinct.map((item) => ({
        name: item,
        selected: true,
      }));
      setCheckboxStatus(tempStatus);
    }
  }, [dashboardData]);

  useEffect(() => {
    setStatuses();
    setNPIs();
  }, [setStatuses, setNPIs]);

  const handleChangeMdReviewed = (event: any) => {
    const dataFiltered = dashboardData.filter(
      (item: any) => item.lastMDReviewedStatus === event.target.value
    );
    updateState(dataFiltered);
    setMdReviewed(event.target.value);
  };

  const resetFilters = () => {
    setCurrentLabel('');
    setStatuses();
    updateState(dashboardData);
    setExpanded('panel2');
  };

  // Handle Auth Status Change
  const handleStatus = (value: IChipObject) => {
    setCurrentLabel('');
    status.forEach((item: IChipObject) => {
      item.isActive = false;
    });
    value.isActive = true;
    setCurrentLabel(value.label);
    // TODO: Improve this. Check ALL
    if (value.label === 'All') {
      resetFilters();
    } else {
      const dataFiltered = dashboardData.filter(
        (item) => item.authStatus === value.label
      );
      updateState(dataFiltered);
    }
    setStatus([...status]);
  };

  // Handle NPI checkbox change
  const handleNPIChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const a = checkboxStatus.findIndex(
      (item) => item.name === event.target.name
    );
    checkboxStatus[a].selected = event.target.checked;
    if (event.target.name === 'All') {
      checkboxStatus.forEach((item) => {
        item.selected = event.target.checked;
      });
    } else {
      checkboxStatus[0].selected = false;
      if (checkboxStatus.length === 2) {
        checkboxStatus[0].selected = event.target.checked;
      }
    }
    setCheckboxStatus([...checkboxStatus]);
    if (checkboxStatus.length) {
      const x = checkboxStatus
        .filter((item) => item.selected === true)
        .map((a) => a.name);
      const dataFiltered = dashboardData.filter((item) => x.includes(item.npi));
      updateState(dataFiltered);
    }
  };

  const handleFilterInputText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputTextFilterValue(event.target.value);
    if (!event.target.value) {
      resetFilters();
    }
  };

  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleFilterByColumns = () => {
    if (!inputTextFilterValue) {
      resetFilters();
    }
    const dataFiltered = dashboardData.filter((item: any) => {
      return (
        item[radioValue].toLowerCase() === inputTextFilterValue.toLowerCase()
      );
    });
    updateState(dataFiltered);
  };

  const handleServiceFromDate = (ranges: any) => {
    setServiceFromDate({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: 'selection',
    });
    const dataFiltered = dashboardData.filter(
      (item) =>
        item.serviceFromDate >=
          moment(ranges.selection.startDate).format('L') &&
        item.serviceFromDate <= moment(ranges.selection.endDate).format('L')
    );
    updateState(dataFiltered);
  };

  const handleClearServiceFromDate = () => {
    setServiceFromDate({
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    });
    resetFilters();
  };

  const handleClearServiceToDate = () => {
    setServiceToDate({
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    });
    resetFilters();
  };

  const handleServiceToDate = (ranges: any) => {
    setServiceToDate({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: 'selection',
    });
    const dataFiltered = dashboardData.filter(
      (item) =>
        item.serviceToDate >= moment(ranges.selection.startDate).format('L') &&
        item.serviceToDate <= moment(ranges.selection.endDate).format('L')
    );
    updateState(dataFiltered);
  };

  const findChecked = (value: string) => {
    const x = checkboxStatus.find((item) => item.name === value);
    return x ? x.selected : false;
  };

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <DashboardFiltersAccordion
      expanded={expanded}
      status={status}
      mdReviewed={mdReviewed}
      lastMdReviewedStatus={lastMdReviewedStatus}
      serviceFromDate={serviceFromDate}
      serviceToDate={serviceToDate}
      npiArray={npiArray}
      radioValue={radioValue}
      handleChange={handleChange}
      resetFilters={resetFilters}
      handleStatus={handleStatus}
      handleChangeMdReviewed={handleChangeMdReviewed}
      handleServiceFromDate={handleServiceFromDate}
      handleClearServiceFromDate={handleClearServiceFromDate}
      handleServiceToDate={handleServiceToDate}
      handleClearServiceToDate={handleClearServiceToDate}
      findChecked={findChecked}
      handleNPIChange={handleNPIChange}
      handleRadioButtonChange={handleRadioButtonChange}
      handleFilterInputText={handleFilterInputText}
      handleFilterByColumns={handleFilterByColumns}
    />
  );
};

export default DashboardFilters;

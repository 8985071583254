import { Dispatch, FC, SetStateAction } from 'react';
import { ICcmReferral } from '../../interfaces/ccm-referrals';
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { IStatuses } from './CcmReferralsDetails';

const label: any = {
  color: '#003967',
  letterSpacing: '0',
  lineHeight: '15px',
  display: 'block',
  marginBottom: '10px',
};

const statuses: IStatuses[] = [
  {
    id: 1,
    label: 'Approved',
    value: 'approved',
  },
  {
    id: 2,
    label: 'Denied - Not Eligible',
    value: 'denied',
  },
  {
    id: 3,
    label: 'Cancelled',
    value: 'cancelled',
  },
];

interface IProps {
  data: ICcmReferral;
  role: string | undefined;
  status: string;
  statusNote: string;
  isSavingMarked: boolean;
  enableUpdateStatus: boolean;
  isStatusDisabled: boolean;
  showStatus: boolean;
  setStatus: Dispatch<SetStateAction<string>>;
  setStatusNote: Dispatch<SetStateAction<string>>;
  handleMarkAsReviewed: () => Promise<void>;
  setEnableUpdateStatus: Dispatch<SetStateAction<boolean>>;
  onStatusChange: (e: any) => void;
  setShowStatus: Dispatch<SetStateAction<boolean>>;
}

const CcmReferralsMarkAsReviewed: FC<IProps> = ({
  data,
  role,
  status,
  statusNote,
  isSavingMarked,
  enableUpdateStatus,
  isStatusDisabled,
  showStatus,
  setStatus,
  setStatusNote,
  handleMarkAsReviewed,
  setEnableUpdateStatus,
  onStatusChange,
  setShowStatus,
}) => {
  return (
    <div className="flex flex-col mt-8">
      {/* If status === NEW anyone can Cancel his own referrals */}
      {data.status === 'NEW' && role !== 'MD - Case Manager' && (
        <div className="flex flex-col">
          <button
            className={`btn w-full md:w-1/2 mb-4 ${
              status || role === 'Viewer'
                ? 'btn-disabled disabled'
                : ' btn-blue'
            }`}
            onClick={() => {
              setStatus('cancelled');
              setStatusNote('');
            }}
            disabled={status === 'cancelled' || role === 'Viewer'}
          >
            Cancel Referral
          </button>

          {status && (
            <div className="my-4">
              <FormControl className="mb-4 w-full">
                <FormLabel component="legend">
                  Note <span className="text-red-500">*</span>
                </FormLabel>
                <TextareaAutosize
                  aria-label="status note"
                  minRows={3}
                  placeholder="Write a Note"
                  value={statusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                  className="border border-gray-400 rounded-md p-2"
                />
              </FormControl>
            </div>
          )}

          {statusNote && (
            <button
              onClick={handleMarkAsReviewed}
              className={`btn ${
                !statusNote || isSavingMarked ? 'btn-disabled' : ' btn-blue'
              }`}
              disabled={!statusNote || isSavingMarked}
            >
              Submit
            </button>
          )}
        </div>
      )}
      {/* Show Status if it is Not NEW */}
      {data.status !== 'NEW' && showStatus && (
        <div>
          <div className="flex-1 mb-4 mr-4">
            <span style={label}>Status</span>
            <span
              className={clsx(
                {
                  'btn-disabled': data.status === 'CANCELLED',
                  'btn btn-green': data.status === 'REVIEWED',
                },
                'btn inline-block mt-2'
              )}
            >
              {data.status}
            </span>

            <div className="my-4 mr-4 flex flex-col">
              <p className="text-gray-4000 text-sm">
                Last Updated at:{' '}
                <b>
                  {' '}
                  {data.updatedAt
                    ? moment(data.updatedAt).format('MM/DD/yyyy')
                    : null}
                </b>{' '}
              </p>
              <p>
                by <b> {data.lastUpdatedBy}</b>
              </p>
              <p className="text-gray-4000">
                <b className="text-sm">Notes:</b> <br />
                <span>{data.notes ? <>{data.notes}</> : null}</span>
              </p>
            </div>
          </div>
        </div>
      )}
      {data.status === 'NEW' &&
        role === 'MD - Case Manager' &&
        !enableUpdateStatus && (
          <div>
            <div className="flex-1 mb-4 mr-4">
              <span style={label}>Status</span>
              <span className="btn inline-block mt-2">{data.status}</span>

              <div className="my-4 mr-4 flex flex-col">
                <p className="text-gray-4000 text-sm">
                  Last Updated at:{' '}
                  <b>
                    {' '}
                    {data.updatedAt
                      ? moment(data.updatedAt).format('MM/DD/yyyy')
                      : null}
                  </b>{' '}
                </p>
                <p>
                  by <b> {data.lastUpdatedBy}</b>
                </p>
                <p className="text-gray-4000">
                  <b className="text-sm">Notes:</b> <br />
                  <span>{data.notes ? <>{data.notes}</> : null}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      {/* If role === MD - Case Manager can update any status  */}
      <div>
        {role === 'MD - Case Manager' &&
          !enableUpdateStatus &&
          data.status !== 'Approved' &&
          data.status !== 'Denied - Not Eligible' && (
            <button
              className="btn btn-blue"
              onClick={() => {
                setEnableUpdateStatus(true);
                setShowStatus(false);
              }}
            >
              Update Status
            </button>
          )}
      </div>
      {/* If role === MD - Case Manager */}
      {role === 'MD - Case Manager' && enableUpdateStatus && (
        <div className="flex-1">
          <div className="flex flex-col">
            <div className="flex w-full items-end">
              <div className="w-full mb-4">
                <FormLabel component="legend" className="my-4">
                  Select Status
                </FormLabel>
                <FormControl variant="outlined" className="w-full">
                  <Select
                    labelId="status"
                    id="status-select"
                    name="status"
                    value={status}
                    placeholder="Select Status"
                    onChange={onStatusChange}
                  >
                    {statuses.map((item: any) => (
                      <MenuItem value={item.value} key={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {status && (
              <div className="mb-4">
                <FormControl className="mb-4 w-full">
                  <FormLabel component="legend">
                    Status Note <span className="text-red-500">*</span>
                  </FormLabel>
                  <TextareaAutosize
                    aria-label="status note"
                    minRows={3}
                    placeholder="Write a Status Note"
                    value={statusNote}
                    onChange={(e) => setStatusNote(e.target.value)}
                    className="border border-gray-400 rounded-md p-2"
                  />
                </FormControl>
              </div>
            )}
            <button
              onClick={handleMarkAsReviewed}
              className={`btn ${
                isStatusDisabled || isSavingMarked
                  ? 'btn-disabled'
                  : ' btn-blue'
              }`}
              disabled={isStatusDisabled || isSavingMarked}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CcmReferralsMarkAsReviewed;

import { IRegisterForm } from '../interfaces/user';
import instance from './axios';

const CONTEXT = '/user';

export const getUsers = async () => {
  try {
    const url = `${CONTEXT}/get/all`;
    const response = await instance.get(url);
    return response.data.data;
  } catch (error: any) {
    throw error?.response?.data?.message || error.message;
  }
};

export const getProfile = async (email: string) => {
  try {
    const url = `${CONTEXT}/get`;
    const response = await instance.post(url, {
      userEmail: email,
    });
    return response.data.data;
  } catch (error: any) {
    throw error?.response?.data?.message || error.message;
  }
};

export const registerUser = async (data: IRegisterForm) => {
  try {
    console.log('enter to register');
    console.log('data', data);
    const url = `${CONTEXT}/register`;
    //This data needs planData.ts permissions[] IF data.plans[] is empty, ELSE keep current data.plans[]
    data.plans = data.plans.length ? data.plans : data.permissions;
    const response = await instance.post(url, data);
    console.log('response', response);
    return response.data.data;
  } catch (error: any) {    
    throw error?.response?.innerError?.message
  }
}

export const partAServiceOptions = [
  {
    label: 'OBS (Observation)',
    value: 'OBS',
  },
  {
    label: 'IP (Inpatient)',
    value: 'IP',
  },
  {
    label: 'LTAC (Long Term Acute Care)',
    value: 'LTAC',
  },
  {
    label: 'SNF (Skilled Nursing Facility)',
    value: 'SNF',
  },
  {
    label: 'SIP (Skilled In Place)',
    value: 'SIP',
  },
  {
    label: 'N/A',
    value: 'N/A',
  },
];

export const partBServices = [
  {
    label: 'DME',
    value: 'DME',
  },
  {
    label: 'Procedure/Testing',
    value: 'Procedure/Testing',
  },
  {
    label: 'Therapy',
    value: 'Therapy',
  },
  {
    label: 'Home Health',
    value: 'Home Health',
  },
  {
    label: 'N/A',
    value: 'N/A',
  },
];

export const rehabPotentials = [
  {
    label: 'Poor',
    value: 'OBS',
  },
  {
    label: 'Fair',
    value: 'IP',
  },
  {
    label: 'Good',
    value: 'LTAC',
  },
  {
    label: 'N/A',
    value: 'N/A',
  },
];
export const baseStyle: any = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 0',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'black',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: 'white',
  outline: 'none',
  verticalAlign: 'middle',
  // height: '150px',
  WebkitBorderRadius: '3px',
  MozBorderRadius: '3px',
  textAlign: 'center',
  cursor: 'pointer',
  transition:
    'border .24s ease-in-out, box-shadow .24s ease-in-out, outline .24s ease-in-out',
  width: '100%!important',
};

export const activeStyle: any = {
  borderColor: '#2196f3',
};

export const acceptStyle: any = {
  borderColor: 'var(--thirdnary)',
  outline: '1px solid var(--thirdnary)',
  boxShadow: 'inset 0 0 10px var(--thirdnary)',
};

export const rejectStyle: any = {
  borderColor: '#ff1744',
  outline: '1px solid #ff1744',
  boxShadow: 'inset 0 0 10px #ff1744',
};

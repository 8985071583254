import { FC, useState, useEffect } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  attachmentPayloadInitialState,
  formPayloadInitialState,
  IAttachmentPayload,
  useMainProvider,
} from '../../providers/MainProvider';
import { IFormPayload } from '../../interfaces/interfaces';
import planData, { IPlan, getCurrentPlan } from '../../planData';

// Create styles
const styles = StyleSheet.create({
  logo: {
    width: '170px',
    height: '54px',
    marginBottom: '30px',
  },
  page: {
    backgroundColor: '#fff',
    width: '100%',
  },
  section: {
    margin: 10,
    padding: 10,
    fontSize: 12,
  },
  title: {
    fontSize: 12,
    marginBottom: 15,
  },
  bigTitle: {
    fontSize: 35,
    color: 'red',
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
    borderRadius: 5,
    backgroundColor: '#003967',
    color: '#ffffff',
  },
  sectionSubHeader: {
    color: '#003967',
    backgroundColor: '#F2F5F9',
    borderRadius: 5,
    padding: 15,
    marginBottom: 15,
  },
  tableView: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    marginLeft: 15,
    width: 400,
  },
  tableColumn: {
    padding: 10,
    borderLeft: 1,
    borderTop: 1,
    borderStyle: 'solid',
    borderColor: '#d0d4d8',
    fontSize: 12,
    width: 100,
  },
});

interface IProps {
  data: IFormPayload['data'];
  attachments: IAttachmentPayload;
}

const PdfGenerator: FC<IProps> = ({ data, attachments }) => {
  const [isTestingForm, setIsTestingForm] = useState(false);
  const [localData, setLocalData] = useState<IFormPayload['data']>(
    formPayloadInitialState.data
  );
  const [plans, setPlans] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');
  const [requestTypeTitle, setRequestTypeTitle] = useState('');
  const [localLogo, setLocalLogo] = useState('');
  const [localAttachments, setLocalAttachments] = useState<IAttachmentPayload>(
    attachmentPayloadInitialState
  );

  // useEffect(() => {
  //   const foundPlanData = getCurrentPlan();
  //   if (foundPlanData) {
  //     setLocalLogo(foundPlanData.logoUrlPng)
  //     setPlans(foundPlanData.permissions.join(', '))
  //   }
  // }, [])

  useEffect(() => {
    setLocalData(data);
    if (data && data.sitePlans) {
      setPlans(data.sitePlans.join(', '));
    }
  }, [data]);

  useEffect(() => {
    setLocalAttachments(attachments);
  }, [attachments]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const foundPlanData = getCurrentPlan();
    if (
      currentUrl.includes('providerportal-qa.alignseniorcare.com') ||
      foundPlanData.site === 'localhost:3000'
    ) {
      setIsTestingForm(true);
    }
  }, []);

  useEffect(() => {
    switch (localData.authType) {
      case 1:
        setHeaderTitle('Standard Prior Authorization Request Form');
        setRequestTypeTitle('Standard');
        break;
      case 2:
        setHeaderTitle('Expedited Authorization Request Form');
        setRequestTypeTitle('Expedited');
        break;
      case 3:
        setHeaderTitle('Additional Days Request Form');
        setRequestTypeTitle('Additional Days');
        break;
    }
  }, [localData.authType]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            src={`/images/${localLogo}`}
            allowDangerousPaths={true}
            style={styles.logo}
          ></Image>

          <Text style={styles.bigTitle}>
            {isTestingForm ? 'THIS IS A TEST SUBMISSION!' : ''}
          </Text>
          <Text style={styles.title}>{headerTitle}</Text>
          <Text style={styles.title}>Plans: {plans}</Text>
          <Text style={styles.title}>
            Member Name: {localData.memberFirstName}
          </Text>
          <Text style={styles.title}>Id: {localData.requestId}</Text>
          <Text style={styles.title}>Request Type: {requestTypeTitle}</Text>
          <Text style={styles.title}>
            Attachments:{' '}
            {localAttachments &&
            localAttachments.attachments &&
            localAttachments.attachments.length
              ? localAttachments.attachments.length
              : 0}
          </Text>
          {/* <Text>
            Most services if requested by or with a written order from a PCP or
            Plan NP are “auto-authorized” within 8 hours or less. CMS allows 14
            days for standard authorizations.
          </Text> */}
          <Text style={styles.sectionHeader}>
            Disclaimer Statements / Attestation
          </Text>
          <Text style={styles.title}>
            Updated Clinical/ therapy documentation within 72 hours of admission
            (initial)/ last covered date (extension)::{' '}
            {localData.clinicalOrTherapyDocUpdated}
          </Text>
          <Text style={styles.title}>
            Order or Clinical note included for out-of-network requests:{' '}
            {localData.orderOrClinicalNoteIncluded}
          </Text>
          <Text style={styles.title}>
            Requestor’s Name: {localData.requestorsName}
          </Text>
          <Text style={styles.title}>
            Phone Number: {localData.requestorsPhoneNumber}
          </Text>
          <Text style={styles.title}>
            Fax Number: {localData.requestorsFaxNumber}
          </Text>
          <Text style={styles.sectionHeader}>Member Information</Text>
          <Text style={styles.title}>
            Member Name: {localData.memberFirstName}
          </Text>
          <Text style={styles.title}>
            Date of Birth: {localData.memberDateOfBirth}
          </Text>
          <Text style={styles.title}>Member ID: {localData.memberId}</Text>
          <Text style={styles.title}>
            Member Living Facility: {localData.memberLivingFacility}
          </Text>
          <Text style={styles.title}>
            Referring Provider: {localData.referringProvider}
          </Text>
          <Text style={styles.title}>
            Referring Provider Type: {localData.referringProviderType}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Request Details</Text>
          <Text style={styles.title}>
            Admitting/ Servicing Facility/ Provider Name:{' '}
            {localData.facilityOrProviderName}
          </Text>
          <Text style={styles.title}>
            Admission Date/ Date of Service: {localData.admissionOrServiceDate}
          </Text>
          <Text style={styles.title}>
            NPI/ Pin Number: {localData.npiPinNumber}
          </Text>
          <Text style={styles.title}>
            Phone Number: {localData.providerPhoneNumber}
          </Text>
          <Text style={styles.title}>
            Fax Number: {localData.providerFaxNumber}
          </Text>
          <Text style={styles.sectionSubHeader}>Part A Services:</Text>
          <Text style={styles.title}>
            Selected service: {localData.partAServices}
          </Text>
          <Text style={styles.title}>Requested: {localData.daysRequested}</Text>
          <Text style={styles.title}>
            Expected LOS in Day: {localData.expectedLosDays}
          </Text>
          <Text style={styles.sectionSubHeader}>
            Part B/ Outpatient Services
          </Text>
          <Text style={styles.title}>
            Part B / Outpatient Services: {localData.partBServices}
          </Text>
          <Text style={styles.title}>
            Rehab Potential: {localData.rehabPotential}
          </Text>
          <Text style={styles.title}>
            Significant Improvement Made? {localData.significantImprovementMade}
          </Text>
          <Text style={styles.title}>
            Description or CPT Code {localData.descriptionOrCptCode}
          </Text>
        </View>
        <View style={styles.tableView}>
          <Text style={styles.tableColumn}>Type</Text>
          <Text style={styles.tableColumn}>Total Qty</Text>
          <Text style={styles.tableColumn}>Times Week</Text>
          <Text style={styles.tableColumn}>Duration</Text>
        </View>
        {localData.outPatientServicesArray ? (
          localData.outPatientServicesArray.map((item) => (
            <View style={styles.tableView}>
              <Text style={styles.tableColumn}>{item.type}</Text>
              <Text style={styles.tableColumn}>{item.totalQty}</Text>
              <Text style={styles.tableColumn}>{item.timesWeek}</Text>
              <Text style={styles.tableColumn}>{item.duration}</Text>
            </View>
          ))
        ) : (
          <View></View>
        )}
        <View style={styles.section}>
          <Text style={styles.title}>
            Notes: {localData.partBServicesNotes}
          </Text>
        </View>

        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>
              Discharge Planning Out Of Nursing Home / Assisted Or Independent
              Living
            </Text>
            <Text style={styles.title}>
              Where are they going? {localData.transferLocationName}
            </Text>
            <Text style={styles.title}>
              Address: {localData.transferLocationAddress}
            </Text>
            <Text style={styles.title}>
              Admission Date/ Date of Service: {localData.expectedDischargeDate}
            </Text>
            <Text style={styles.title}>
              Phone number: {localData.transferLocationPhone}
            </Text>
            <Text style={styles.title}>
              Home Health/ DME/ Specialist Follow-up Appointment Needs?:{' '}
              {localData.appointmentNeeds}
            </Text>
          </View>
        </Page>
      </Page>
    </Document>
  );
};

export default PdfGenerator;

import Cookies from "js-cookie";
import { getCurrentPlan } from "../planData";
import instance from "./axios";

const CONTEXT = "/dashboard";

export const getAuthorizations = async () => {
  try {
    const { permissions } = getCurrentPlan();
    const email = Cookies.get('aah-email');

    const data = {
      email: email,
      plans: permissions,
    };

    const url = `${CONTEXT}/authorizations`;
    const response = await instance.post(url, data);
    return response.data.data;
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
};

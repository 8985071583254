import { FC } from 'react';
import { Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import { IPortalUser } from '../../../interfaces/interfaces';

export interface ICustomCheck {
  id: number | null;
  name: string | null;
  isActive: boolean | null;
}

export interface IProps {
  data: IPortalUser[];
}

const PortalUsersComponentDesktop: FC<IProps> = ({ data }) => {
  const history = useHistory();
  const columns = [
    {
      name: 'id',
      header: 'Id',
      minWidth: 230,
    },
    {
      id: 'firstName',
      name: 'firstName',
      header: 'First Name',
      render: (value: any) => {
        return value.data.profile.firstName;
      },
      defaultFlex: 1,
    },
    {
      name: 'lastName',
      header: 'Last Name',
      render: (value: any) => {
        return value.data.profile.lastName;
      },
      defaultFlex: 1,
    },
    {
      name: 'userEmail',
      header: 'Email',
      defaultFlex: 1,
    },
    {
      name: 'plansByState',
      header: 'Plans',
      defaultFlex: 1,
      render: (value: any) => {
        return (
          <div>
            {value.data.plansByState &&
              value.data.plansByState.map((item: string, index: number) => (
                <Chip
                  key={index}
                  label={item}
                  style={{
                    marginRight: '5px',
                    marginBottom: '5px',
                  }}
                />
              ))}
          </div>
        );
      },
    },
    {
      name: 'role',
      header: 'Role',
      defaultFlex: 1,
    },
  ];

  const filterCustomTypes: any = {
    ...ReactDataGrid.defaultProps.filterTypes,
    plansByState: {
      name: 'plansByState',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
  };

  const defaultFilterValue = [
    {
      name: 'id',
      operator: 'eq',
      type: 'string',
      value: '',
    },
    {
      name: 'firstName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'lastName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'userEmail',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'plansByState',
      operator: 'Has',
      type: 'plansByState',
      value: '',
    },
    {
      name: 'role',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ];

  return (
    <section className="w-full mb-16">
      <div className="showDesktop">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="id"
          dataSource={data}
          style={{ minHeight: 650 }}
          rowStyle={{ cursor: 'pointer' }}
          filterTypes={filterCustomTypes}
          defaultFilterValue={defaultFilterValue}
          columns={columns}
          pagination={true}
          enableSelection={true}
          onSelectionChange={(data) =>
            history.push(`/admin/portal-users/${data.selected}`)
          }
        />
      </div>
    </section>
  );
};

export default PortalUsersComponentDesktop;

import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';
import { CircularProgress } from '@material-ui/core';
import MainNavigation from '../../components/MainNavigation';
import CcmReferralsSubmit from '../../components/CcmReferralsSubmit';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';

const CcmReferralsSubmitPage: FC<any> = () => {
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();
  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  };
  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
    return (
      <div className="custom-container">
        <div>
          <Header />
          <MainNavigation />
          <HeaderTitle title="CCM Referrals" />
          <div className="px-8 pb-8 text-sm">
            <p>
              Complex Case Management (CCM) is provided to members who have
              serious medical needs and high utilization requiring increased
              frequency of care coordination. All referrals will be reviewed for
              CCM eligibility by the Case Management department.{' '}
            </p>

            <p>
              <u className="py-4 block">Potential Qualifying Conditions:</u>
              <ul>
                <li>
                  <span className="text-lg px-2">&#8226;</span>Multiple
                  uncontrolled comorbidities
                </li>
                <li>
                  <span className="text-lg px-2">&#8226;</span>
                  Newly diagnosed terminal condition
                </li>
                <li>
                  <span className="text-lg px-2">&#8226;</span>Frequent
                  hospitalizations or readmissions
                </li>
                <li>
                  <span className="text-lg px-2">&#8226;</span>Frequent
                  Emergency Department visits
                </li>
                <li>
                  <span className="text-lg px-2">&#8226;</span>High risk
                  medications
                </li>
                <li>
                  <span className="text-lg px-2">&#8226;</span>Socioeconomic
                  deficiencies
                </li>
              </ul>
              <u className="py-4 block">
                Members Not Suited for Complex Case Management
              </u>
              <p>
                Complex Case Management is not suitable for members (or POA) who
                are unable to be reached or refuse to participate.
              </p>
            </p>
          </div>
          <CcmReferralsSubmit />
        </div>
        <div>
          <Footer />
          <FooterBottom />
        </div>
      </div>
    );
  }
  return <div>You need to login in order to see information!</div>;
};

export default CcmReferralsSubmitPage;

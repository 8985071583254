import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IDrawer {
  isOpen: boolean;
  data: any;
}

const initialState: IDrawer = {
  isOpen: false,
  data: {},
}

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawer: (state, action: PayloadAction<IDrawer>) => {
      state.isOpen = action.payload.isOpen;
      state.data = action.payload.data;
    }
  }
})

export const { setDrawer } = drawerSlice.actions;
export default drawerSlice.reducer
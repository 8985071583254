import { FC, useCallback, useEffect, useState } from 'react';
import { IDashboardDraft } from '../../../providers/MainProvider';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  useDeleteDraft,
  useGetDashboardDraft,
} from '../../../services/ServiceHook';
import Modal from '../../Modal';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

window.moment = moment;

const DashboardTableDraftMobile: FC = () => {
  const [currentDraft, setCurrentDraft] = useState<IDashboardDraft[]>([]);
  const [draftToDelete, setDraftToDelete] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [getDraft] = useGetDashboardDraft();
  const [deleteDraft] = useDeleteDraft();
  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  const history = useHistory();

  const getData = useCallback(async () => {
    const draftData = await getDraft();
    const onlyDraftAuthStatus = draftData.filter(
      (item: any) =>
        item.auth.requestId !== '' && item.auth.authStatus === 'Draft'
    );

    if (onlyDraftAuthStatus && onlyDraftAuthStatus.length) {
      const drafts = onlyDraftAuthStatus.map((item: any) => ({
        authNumber: item.auth.id,
        createdAt: item.auth.createdAt
          ? moment(item.auth.createdAt).format('LLL')
          : null,
        updatedAt: item.auth.updatedAt
          ? moment(item.auth.updatedAt).format('LLL')
          : null,
        authType: item.auth.authType,
        memberFirstName: item.auth.memberFirstName,
        memberLastName: item.auth.memberLastName,
        memberId: item.auth.memberId,
        requestId: item.auth.requestId,
        npi: item.auth.npiPinNumber,
        authStatus: item.auth.authStatus,
      }));
      setCurrentDraft(drafts.filter((item: any) => item.requestId !== ''));
    }
  }, [getDraft]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDraftDetails = (requestId: string, authType: string) => {
    switch (authType) {
      case 'Standard':
        history.push(`/authorization/standard/${requestId}`);
        break;
      case 'Expedited':
        history.push(`/authorization/expedited/${requestId}`);
        break;
      case 'Request for Additional Days':
        history.push(`/authorization/additional-days/${requestId}`);
        break;
      case 'Notification':
        history.push(`/authorization/notification-only/${requestId}`);
        break;
    }
  };

  const handleDraftDelete = async () => {
    if (draftToDelete) {
      const deleteResponse = await deleteDraft(draftToDelete);
      if (deleteResponse.status === 200) {
        setCurrentDraft((currentDraft) => {
          return currentDraft.filter(
            (item) => item.requestId !== draftToDelete
          );
        });
      } else {
        setOpenErrorMessage(true);
      }
      setOpenModal(false);
    }
  };

  return (
    <div>
      {!currentDraft.length && (
        <div className="showMobile mt-4">
          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="current-draft"
            >
              No records available.
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
        </div>
      )}
      {currentDraft.map((item: any, key: number) => {
        return (
          <div className="mt-4 showMobile" key={key}>
            <Accordion className="mb-4 mt-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="current-draft"
              >
                <div className="flex flex-col w-full">
                  <div>{item.requestId}</div>
                  <div className="font-bold">
                    {item.memberFirstName} {item.memberLastName}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-full">
                  <hr className="mb-4" />
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Draft Id: </span>
                    <span className="font-bold">{item.requestId}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Created at: </span>
                    <span className="font-bold">{item.createdAt}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Updated at: </span>
                    <span className="font-bold">{item.updatedAt}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">First Name: </span>
                    <span className="font-bold">{item.memberFirstName}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Last Name: </span>
                    <span className="font-bold">{item.memberLastName}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Status: </span>
                    <span className="font-bold">{item.authStatus}</span>
                  </div>
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-blue-transparent w-full"
                      onClick={() => {
                        handleDraftDetails(item.requestId, item.authType || '');
                      }}
                    >
                      Continue
                    </button>
                  </div>
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-delete w-full"
                      onClick={() => {
                        setDraftToDelete(item.requestId);
                        setOpenModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      <Modal
        title="Are you sure you want to Delete?"
        description="All your information in this record will be lost"
        open={openModal}
        className="confirmationModal"
        onClose={() => setOpenModal(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="btn"
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={handleDraftDelete}
          >
            Yes
          </button>
        </div>
      </Modal>

      <Modal
        title="The record could not be deleted"
        description="Please try again"
        open={openErrorMessage}
        className="confirmationModal"
        onClose={() => setOpenErrorMessage(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            className="btn btn-green"
            onClick={() => setOpenErrorMessage(false)}
          >
            Yes
          </button>
        </div>
      </Modal>

      <ErrorMessage />
    </div>
  );
};

export default DashboardTableDraftMobile;

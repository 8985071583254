import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';
import MainNavigation from '../../components/MainNavigation';

const Page404: FC<any> = (props) => {
  return (
    <div className="custom-container">
      <div>
        <Header />
        <MainNavigation />
        <HeaderTitle title="Page Not Found" />

        <div className="md:grid md:grid-cols-2 md:gap-4 px-8">
          <div>
            <p style={{ color: '#003967' }}>
              We can’t seem to find the page you’re looking for. Are you sure
              the website URL is correct? Try going back to the home page or get
              in touch with the site owner.
            </p>
            <Link to="/" className="btn btn-red mt-8 inline-block">
              Back to Home
            </Link>
          </div>
          <div>
            <img src="/images/not-found.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <Footer />
        <FooterBottom />
      </div>
    </div>
  );
};

export default Page404;

import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { ICategory, ISuspectedType } from './interfaces';
import { categoryInitialValues } from './categoryInitialValues';
import { usePqiReferralQuery } from '../../hooks/queries/usePqiReferralsQuery';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { CategoriesMap } from './categories-map';
import LocalPrintshop from '@material-ui/icons/LocalPrintshop';
// import { PDFViewer } from '@react-pdf/renderer';
// import { GeneratePdfComponent } from './PqiReferralsForm/GeneratePdfComponent';

const PqiReferralsDetails: FC = () => {
  let params = useParams<{ id: string }>();
  const { data, isLoading, isError, error } = usePqiReferralQuery(params.id);
  const [category, setCategory] = useState<ICategory[]>(categoryInitialValues);

  useEffect(() => {
    if (data && data.suspectedCategory.length) {
      setCategory(CategoriesMap(data));
    }
  }, [data]);

  // Styles
  const label: any = {
    color: '#003967',
    letterSpacing: '0',
    lineHeight: '15px',
    display: 'block',
    marginBottom: '10px',
  };

  const content: any = {
    color: '#112233',
    lineHeight: '15px',
  };

  if (isLoading) {
    return (
      <div className="flex container mx-auto px-4  flex-col justify-center items-center mt-8 ">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  return (
    <div className="px-4 md:px-8">
      {/* <PDFViewer className="h-full" style={{ height: '1000px' }}>
        <GeneratePdfComponent data={data} />
      </PDFViewer> */}
      <div className="flex justify-end w-full">
        <button
          className="print-icon height-6 w-6 h-6"
          onClick={() => window.print()}
        >
          <LocalPrintshop style={{ color: '#003967', fontSize: '30px' }} />
        </button>
      </div>

      <div className="mb-4">
        <h2>{data.memberName}</h2>
      </div>
      <h2 className="text-lg mb-4">Section I - General Information</h2>
      <div className="grid sm:grid-cols-12 xl:gap-6 mb-8">
        <div className="col-span-6">
          <span style={label}>Date of Referral</span>
          <span style={content}>
            {moment(data.dateOfReferral).format('MM/DD/yyyy')}
          </span>
        </div>
        <div className="col-span-3 mt-4 md:mt-0">
          <span style={label}>Time of Referral</span>
          <span style={content}>
            {moment(data.timeOfReferral).format('LT')}
          </span>
        </div>
        <div className="col-span-3">
          <span style={label}>Status</span>
          <span style={content}>{data.status}</span>
        </div>
      </div>

      <div className="grid sm:grid-cols-12 xl:gap-6 mb-8">
        <div className="col-span-6">
          <span style={label}>Member Name</span>
          <span style={content}>{data.memberName}</span>
        </div>
        <div className="col-span-3 mt-4 md:mt-0">
          <span style={label}>Date of Birth</span>
          <span style={content}>
            {moment(data.memberDateOfBirth).format('L')}
          </span>
        </div>
        <div className="col-span-3">
          <span style={label}>Sex</span>
          <span style={content}>{data.sex}</span>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 xl:gap-6 mb-8">
        <div>
          <span style={label}>Health Plan</span>
          <span style={content}>{data.healthPlan}</span>
        </div>
        <div className="mt-4 md:mt-0">
          <span style={label}>Member ID</span>
          <span style={content}>{data.memberId}</span>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 xl:gap-6 mb-8">
        <div>
          <span style={label}>Provider Name</span>
          <span style={content}>{data.providerName}</span>
        </div>
        <div className="mt-4 md:mt-0">
          <span style={label}>Provider Number</span>
          <span style={content}>{data.providerNumber}</span>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 xl:gap-6 mb-8">
        <div>
          <span style={label}>Facility Name</span>
          <span style={content}>{data.facilityName}</span>
        </div>
        <div>
          <span style={label}>Facility Location</span>
          <span style={content}>{data.facilityLocation} </span>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 xl:gap-6 mb-8">
        <div>
          <span style={label}>Name of Person Submitting Referral</span>
          <span style={content}>{data.nameOfPersonSubmittingReferral}</span>
        </div>
        <div className="mt-4 md:mt-0">
          <span style={label}>Department and Title</span>
          <span style={content}>{data.departmentAndTitle} </span>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 xl:gap-6 mb-8">
        <div>
          <span style={label}>Contact Information</span>
          <span style={content}>{data.contactInformation}</span>
        </div>
      </div>

      <hr />

      <h2 className="text-lg my-8">
        Section II - Potential Quality Issue (Must check at least one)
      </h2>
      <div className="flex mb-4" style={{ color: '#023b64' }}>
        <div className="flex-1 text-lg">Suspected Category</div>
        <div className="flex-1 text-lg">Suspected Type</div>
      </div>
      {category.map((item: ICategory) => (
        <div className="flex mb-4" key={item.category}>
          <div className="flex-1">
            <label className="label-top">{item.category}</label>
          </div>
          <div className="flex-1">
            {item.suspectedType.map((suspectedType: ISuspectedType) => (
              <FormGroup className="flex flex-col" key={suspectedType.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={suspectedType.checked}
                      name={suspectedType.name}
                      color="primary"
                      readOnly
                    />
                  }
                  label={suspectedType.label}
                />
              </FormGroup>
            ))}
          </div>
        </div>
      ))}

      <div className="break-after-page">
        <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
        <hr />
        <h2 className="mt-8">Section III - Occurrence Information</h2>
        <div className="grid sm:grid-cols-3 gap-4 xl:gap-6 mt-8 mb-4">
          <div>
            <span style={label}>Date of Occurrence</span>
            <span style={content}>
              {moment(data.dateOfOcurrence).format('L')}
            </span>
          </div>
          <div>
            <span style={label}>Time of Occurrence</span>
            <span style={content}>
              {moment(data.timeOfOccurrence).format('LT')}
            </span>
          </div>
          <div>
            <span style={label}>Was Patient Hospitalized?</span>
            <span style={content}>
              {String(data.wasPatientHospitalized) === 'true' ? 'Yes' : 'No'}
            </span>
          </div>
        </div>

        <div className="grid sm:grid-cols-3 gap-4 xl:gap-6 mt-8 mb-4">
          <div className="w-full">
            <span style={label}>Name of Hospital</span>
            <p className="italic">{data.nameOfHospital}</p>
          </div>

          <div className="w-full">
            <span style={label}>Location of Hospital</span>
            <p className="italic">{data.locationOfHospital}</p>
          </div>

          <div className="w-full">
            <span style={label}>Hospitalization Date</span>
            {data.hospitalizationDate && (
              <p className="italic">
                {moment(data.hospitalizationDate).format('L')}
              </p>
            )}
          </div>
          <div className="w-full">
            <span style={label}>Hospitalization Time</span>
            {data.hospitalizationTime && (
              <p className="italic">
                {moment(data.hospitalizationTime).format('LT')}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col mt-8 mb-8">
          <div>
            <span style={label}>
              Was the incident reported to a state agency? If yes, please
              provide the agency name.
            </span>
            <span style={content}>
              {String(data.wasTheIncidentReportedToAStateAgency) === 'true'
                ? 'Yes'
                : 'No'}
            </span>
            <span style={label} className="mt-4">
              Name of Agency
            </span>
            <p className="italic">{data.agencyName ? data.agencyName : ''}</p>
          </div>
        </div>

        <div className="flex flex-col">
          <div>
            <span style={label}>
              Was a physician called? If yes, please provide their
              recommendation within the description of the occurrence.
            </span>
            <span style={content}>
              {String(data.wasAPhysicianCalled) === 'true' ? 'Yes' : 'No'}
            </span>
            <span style={label} className="mt-8">
              Physician Recomendation
            </span>
            <p className="italic">
              {data.physicianRecomendation ? data.physicianRecomendation : ''}
            </p>
            <span style={label} className="mt-8">
              Brief description of the occurrence: Provide a brief description
              of the incident to include the time, date, exact location,
              physical findings or diagnosis.
            </span>
            <p className="italic">
              {data.briefDescriptionOfTheOccurrence
                ? data.briefDescriptionOfTheOccurrence
                : ''}
            </p>
          </div>
        </div>
        {/* Signature */}
        <div>
          <h2 className="mt-8">Signature</h2>
          <div className="signatureFont mt-4">{data.signatureName}</div> <br />
          {data.signatureDate} <br />
          <img src={data.signatureSource} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PqiReferralsDetails;

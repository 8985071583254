import { FC, useEffect, useState } from 'react';
import { useGetDashboardDataMember } from '../../../services/ServiceHook';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import DashboardTableMemberMobile from '../DashboardTableMemberMobile/DashboardTableMemberMobile';
import { IMember } from '../../../interfaces/interfaces';
import DashboardTableMemberDesktop from '../DashboardTableMemberDesktop/DashboardTableMemberDesktop';

const DashboardTableMember: FC = () => {
  const member = useSelector(
    (state: RootState) => state.memberDetails.memberSelected
  );
  const [data, setData] = useState<IMember[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getDashboardDataMember] = useGetDashboardDataMember();

  // Get Dashboard Data
  useEffect(() => {
    (async () => {
      if (member.memberId) {
        setIsLoading(true);
        const value = await getDashboardDataMember(member.memberId);
        const dataOrdered = value.sort((a: any, b: any) => {
          return +new Date(b.serviceFromDate) - +new Date(a.serviceFromDate);
        });
        // Unique Diagnoses
        for (let item of dataOrdered) {
          item.diagnoses = [
            ...new Map(
              item.diagnoses.map((item: any) => [item['icdCode'], item])
            ).values(),
          ];
        }
        // Unique Service Requests
        for (let item of dataOrdered) {
          item.serviceRequests = [
            ...new Map(
              item.serviceRequests.map((item: any) => [item['cptCode'], item])
            ).values(),
          ];
        }
        setData(dataOrdered);
        setIsLoading(false);
      }
    })();
  }, [member]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <DashboardTableMemberMobile data={data} />
      <DashboardTableMemberDesktop data={data} />
    </>
  );
};

export default DashboardTableMember;

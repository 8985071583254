import { Page, Text, Image, View, Document } from '@react-pdf/renderer';
import { styles } from './confirmation-styles';
import { getCurrentPlan } from '../../planData';
export const generatePdf = (
  data: any,
  headerTitle: string,
  numberOfAttachments: number
) => {
  const { logoUrlPng, site, permissions } = getCurrentPlan();
  let isTestingForm = false;
  if (
    site === 'provider-portal-dev-ui.azurewebsites.net' ||
    site === 'provider-portal-staging-ui.azurewebsites.net' ||
    site === 'provider-portal-dev-admin.tutelarhealth.io' ||
    site === 'provider-portal-staging-admin.tutelarhealth.io' ||
    site === 'localhost:3000'
  ) {
    isTestingForm = true;
  }

  const plans = permissions.join(', ');
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {logoUrlPng && (
            <Image
              src={`/images/${logoUrlPng}`}
              allowDangerousPaths={true}
              style={styles.logo}
            ></Image>
          )}

          <Text style={styles.bigTitle}>
            {isTestingForm ? 'THIS IS A TEST SUBMISSION!' : ''}
          </Text>

          <Text style={styles.title}>{headerTitle}</Text>
          <Text style={styles.title}>Plans: {plans}</Text>
          <Text style={styles.title}>Member Name: {data.memberFirstName}</Text>
          <Text style={styles.title}>Id: {data.requestId}</Text>
          <Text style={styles.title}>Request Type: {data.authType}</Text>
          <Text style={styles.title}>Attachments: {numberOfAttachments}</Text>
          {/* <Text>
            Most services if requested by or with a written order from a PCP
            or Plan NP are “autoauto-authorized” within 8 hours or less. CMS
            allows 14 days for standard authorizations.
          </Text> */}
          <Text style={styles.sectionHeader}>
            Disclaimer Statements / Attestation
          </Text>
          <Text style={styles.title}>
            Updated Clinical/ therapy documentation within 72 hours of admission
            (initial)/ last covered date (extension)::{' '}
            {data.clinicalOrTherapyDocUpdated}
          </Text>
          <Text style={styles.title}>
            Order or Clinical note included for out-of-network requests:{' '}
            {data.orderOrClinicalNoteIncluded}
          </Text>
          <Text style={styles.title}>
            Requestor's Name: {data.requestorsName}
          </Text>
          <Text style={styles.title}>
            Phone Number: {data.requestorsPhoneNumber}
          </Text>
          <Text style={styles.title}>
            Fax Number: {data.requestorsFaxNumber}
          </Text>
          <Text style={styles.sectionHeader}>Member Information</Text>
          <Text style={styles.title}>Member Name: {data.memberFirstName}</Text>
          <Text style={styles.title}>
            Date of Birth: {data.memberDateOfBirth}
          </Text>
          <Text style={styles.title}>Member ID: {data.memberId}</Text>
          <Text style={styles.title}>
            Member Living Facility: {data.memberLivingFacility}
          </Text>
          <Text style={styles.title}>
            Referring Provider: {data.referringProvider}
          </Text>
          <Text style={styles.title}>
            Referring Provider Type: {data.referringProviderType}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        {data.authType !== 'Notification' ? (
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>Request Details</Text>
            <Text style={styles.title}>
              Admitting/ Servicing Facility/ Provider Name:{' '}
              {data.facilityOrProviderName}
            </Text>
            <Text style={styles.title}>
              Admission Date/ Date of Service: {data.admissionOrServiceDate}
            </Text>
            <Text style={styles.title}>
              NPI/ Pin Number: {data.npiPinNumber}
            </Text>
            <Text style={styles.title}>
              Phone Number: {data.providerPhoneNumber}
            </Text>
            <Text style={styles.title}>
              Fax Number: {data.providerFaxNumber}
            </Text>
            <Text style={styles.sectionSubHeader}>Part A Services:</Text>
            <Text style={styles.title}>
              Selected service: {data.partAServices}
            </Text>
            <Text style={styles.title}>Requested: {data.daysRequested}</Text>
            <Text style={styles.title}>
              Expected LOS in Day: {data.expectedLosDays}
            </Text>
            <Text style={styles.sectionSubHeader}>
              Part B/ Outpatient Services
            </Text>
          </View>
        ) : (
          <View></View>
        )}
        <View style={styles.section}>
          <Text style={styles.title}>
            Part B / Outpatient Services: {data.partBServices}
          </Text>
          <Text style={styles.title}>
            Rehab Potential: {data.rehabPotential}
          </Text>
          <Text style={styles.title}>
            Significant Improvement Made? {data.significantImprovementMade}
          </Text>
          <Text style={styles.title}>
            Description or CPT Code {data.descriptionOrCptCode}
          </Text>
        </View>
        <View style={styles.tableView}>
          <Text style={styles.tableColumn}>Type</Text>
          <Text style={styles.tableColumn}>Total Qty</Text>
          <Text style={styles.tableColumn}>Times Week</Text>
          <Text style={styles.tableColumn}>Duration</Text>
        </View>
        {data.outPatientServicesArray ? (
          data.outPatientServicesArray.map((item: any, index: number) => (
            <View style={styles.tableView}>
              <Text style={styles.tableColumn}>{item.type}</Text>
              <Text style={styles.tableColumn}>{item.totalQty}</Text>
              <Text style={styles.tableColumn}>{item.timesWeek}</Text>
              <Text style={styles.tableColumn}>{item.duration}</Text>
            </View>
          ))
        ) : (
          <View></View>
        )}
        <View style={styles.section}>
          <Text style={styles.title}>Notes: {data.partBServicesNotes}</Text>
        </View>
        {data.authType !== 'Notification' ? (
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>
              Discharge Planning Out Of Nursing Home / Assisted Or Independent
              Living
            </Text>
            <Text style={styles.title}>
              Where are they going? {data.transferLocationName}
            </Text>
            <Text style={styles.title}>
              Address: {data.transferLocationAddress}
            </Text>
            <Text style={styles.title}>
              Admission Date/ Date of Service: {data.expectedDischargeDate}
            </Text>
            <Text style={styles.title}>
              Phone number: {data.transferLocationPhone}
            </Text>
            <Text style={styles.title}>
              Home Health/ DME/ Specialist Follow-up Appointment Needs?:{' '}
              {data.appointmentNeeds}
            </Text>
          </View>
        ) : (
          <View></View>
        )}

        {data.signatureSource ? (
          <View style={styles.section}>
            <Text style={styles.title}>{data.signatureDate}</Text>
            <Text style={styles.title}>{data.signatureName}</Text>
            <Image
              src={data.signatureSource || ''}
              allowDangerousPaths={true}
            ></Image>
          </View>
        ) : (
          <View></View>
        )}
      </Page>
    </Document>
  );
};

import { FC } from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  default: {
    background: '#E2E8EF',
    borderRadius: '2px',
    display: 'block',
    marginRight: '5px',
    padding: '5px 8px',
    cursor: 'pointer',
    fontSize: '13px'
  },
  actived: {
    background: '#003967',
    color: '#fff',
  },
}));

interface IChipObject {
  id: number,
  label: string,
  isActive: boolean
}

interface ICustomChip {
  item: any
  handleStatus: (item: IChipObject) => void;
}

const CustomChip: FC<ICustomChip> = ({ item, handleStatus }) => {
  const classes = useStyles();
  return (
    <span onClick={() => handleStatus(item)} className={clsx({ [classes.actived]: item.isActive }, [classes.default])}>{item.label}</span>
  )
}

export default CustomChip
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../App';
import {
  createCcmReferral,
  markCcmReferralsAsReviewed,
} from '../../services/ccm-referrals.service';
import { CCM_REFERRALS_QUERY_KEYS } from '../../constants/ccm-referrals';

const useCreateCcmReferralMutation = () =>
  useMutation({
    mutationFn: createCcmReferral,
    onSuccess: () => {
      queryClient.invalidateQueries([
        CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRALS,
        CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRAL,
      ]);
    },
  });

const useMarkCcmReferralsAsReviewedMutation = () =>
  useMutation({
    mutationFn: markCcmReferralsAsReviewed,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRALS,
        CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRAL,
      ]);
      queryClient.setQueryData([CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRAL], data);
    },
  });

export { useCreateCcmReferralMutation, useMarkCcmReferralsAsReviewedMutation };

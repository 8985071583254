import { FC } from 'react';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';
import MainNavigation from '../../components/MainNavigation';
import MemberDetailsSampleComponent from '../../components/MemberDetailsSampleComponent';

const MemberDetails: FC<any> = (props) => {
  return (
    <div>
      <Header />
      <MainNavigation />
      <HeaderTitle title="Case Details" />
      <MemberDetailsSampleComponent />
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default MemberDetails;

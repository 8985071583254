import { FC, useState, ChangeEvent } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Modal } from '../../components';
import ReactSignatureCanvas from 'react-signature-canvas';
import { FormControl, OutlinedInput, InputLabel } from '@material-ui/core';

interface IProps {
  open: boolean;
  onClose: () => void;
  isSignatureNameDisabled?: boolean;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSignatureChange: (value: string) => void;
  signatureName: string | undefined;
  description: string;
}

const SignatureModal: FC<IProps> = ({
  open,
  onClose,
  onInputChange,
  onSignatureChange,
  isSignatureNameDisabled,
  signatureName,
  description,
}) => {
  const [signPad, setSignPad] = useState<ReactSignatureCanvas | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const trim = () => {
    if (signPad) {
      onSignatureChange(signPad.getTrimmedCanvas().toDataURL('image/png'));
    }
    onClose();
  };

  const cancel = () => {
    if (signPad) {
      signPad.clear();
      onSignatureChange('');
    }
    onClose();
  };

  // Signature Container
  const signatureContainer: any = {
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#F2F5F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: "'Dancing Script', cursive",
    fontSize: '25px',
  };

  return (
    <>
      <Modal
        title="Adopt Your Initials"
        description="Confirm your name and signature."
        open={open}
        className="signatureModal"
        onClose={onClose}
      >
        <div className="text-center">
          <div className="grid grid-cols-1">
            <div className="flex flex-col my-4">
              <label className="pb-2 text-left">Full Name</label>
              <FormControl variant="outlined">
                <InputLabel htmlFor="signatureName">Full Name</InputLabel>
                <OutlinedInput
                  id="signatureName"
                  name="signatureName"
                  value={signatureName}
                  onChange={onInputChange}
                  disabled={isSignatureNameDisabled}
                />
              </FormControl>
            </div>
          </div>

          <div className="flex justify-start">
            <div style={{ color: '#023B64' }}>
              <strong>Preview</strong>
            </div>
          </div>

          <div className="grid grid-cols-1">
            <div style={signatureContainer}>{signatureName}</div>
          </div>

          <p className="text-left mt-2">{description}</p>
          <div className="mx-auto flex justify-center">
            <SignatureCanvas
              onBegin={() => {
                setIsDisabled(false);
              }}
              canvasProps={{ className: 'customCanvas' }}
              ref={(ref) => {
                console.log('ref', ref);
                setSignPad(ref);
              }}
            />
          </div>
        </div>

        <div className="buttonsContainer flex justify-between">
          <button type="button" className="btn" onClick={cancel}>
            Cancel
          </button>
          <button
            type="button"
            className={`btn ${isDisabled ? 'btn-disabled' : 'btn-green'}`}
            onClick={trim}
            disabled={isDisabled}
          >
            Adopt and Sign
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SignatureModal;

import { FC } from 'react';
import {
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  RegisterForm,
} from '../../components';
import { RegisterRedirect } from '../../components/RegisterForm/RegisterRedirect';

const Register: FC<any> = (props) => {
  return (
    <div className="custom-container">
      <div>
        <Header />
        <HeaderTitle title="Provider Portal Registration" />
        <RegisterRedirect />
        {/* <RegisterForm /> */}
      </div>
      <div>
        <Footer />
        <FooterBottom />
      </div>
    </div>
  );
};

export default Register;

import { FC } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

interface IProps {
  files: any[];
  removeFile: (index: number) => void;
}
export const FileList: FC<IProps> = ({ files, removeFile }) => {
  return (
    <ul>
      {files.map((file, index) => (
        <li
          key={index}
          className={'mr-4 border my-2 p-4 inline-block relative text-sm '}
        >
          <InsertDriveFileIcon className="mr-2" />
          {file.fileName}{' '}
          <button
            type="button"
            onClick={() => removeFile(index)}
            style={{ top: '-12px', right: '-10px' }}
            className="absolute"
          >
            <CloseIcon />
          </button>
        </li>
      ))}
    </ul>
  );
};

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getCurrentPlan } from './planData';
import {
  Dashboard,
  Authorization,
  MemberDetails,
  Standard,
  ConfirmationPage,
  AdditionalDaysPage,
  SuccessMessagePage,
  Expedited,
  ContactPage,
  Page404,
  MembersPage,
  RegisterPage,
  RegistrationPage,
  RegistrationDetailsPage,
  PortalUsers,
  PortalUsersDetails,
  NotificationOnlyPage,
  PortalActivityPage,
} from './pages';
import DecisionPage from './pages/DecisionPage';
import MemberDetailsSample from './pages/MemberDetailsSample';
import ErNotificationsPage from './pages/ErNotificationsPage';
import MaintenancePage from './pages/Maintenance/MaintenancePage';
import CcmReferralsDetailsPage from './pages/CcmReferrals/CcmReferralsDetails';
import CcmReferralsSubmitPage from './pages/CcmReferrals/CcmReferralsSubmit';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import Splash from './pages/Splash';
import ConfirmationAccount from './pages/ConfirmationAccount/ConfirmationAccount';
import PqiReferralsPage from './pages/PQIReferrals';
import PqiReferralsDetailsPage from './pages/PQIReferrals/PqiReferralsDetailsPage';
import NotAuthenticated from './pages/NotAuthenticated';

export default function AppRouter() {
  const foundPlanData = getCurrentPlan();
  const role = useSelector((state: RootState) => state.profile.data.role);

  if (foundPlanData.site === 'providers.simpra.com') {
    return (
      <Router>
        <Switch>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </Router>
    );
  }

  if (
    role === 'Admin' &&
    (foundPlanData.site === 'providerportaladmin.allyalign.com' ||
      foundPlanData.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
      foundPlanData.site === 'provider-portal-staging-admin.tutelarhealth.io' ||
      foundPlanData.site === 'localhost:3000')
    // process.env.REACT_APP_DEV_ROUTE === 'admin')
  ) {
    return (
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/admin/registrations/:id">
              <RegistrationDetailsPage />
            </Route>
            <Route path="/admin/portal-users/:id">
              <PortalUsersDetails />
            </Route>
            <Route path="/admin/portal-users">
              <PortalUsers />
            </Route>
            <Route path="/admin/registrations" exact>
              <RegistrationPage />
            </Route>
            <Route path="/admin/portal-activity" exact>
              <PortalActivityPage />
            </Route>
            <Route path="/admin" exact>
              <PortalUsers />
            </Route>
            <Route path="/authorization" exact>
              <Authorization />
            </Route>
            <Route path="/authorization/standard/:id">
              <Standard />
            </Route>
            <Route path="/authorization/expedited/:id">
              <Expedited />
            </Route>
            <Route path="/authorization/additional-days/:id">
              <AdditionalDaysPage />
            </Route>
            <Route path="/authorization/additional-days/">
              <AdditionalDaysPage />
            </Route>
            <Route path="/authorization/notification-only/:id">
              <NotificationOnlyPage />
            </Route>
            <Route path="/authorization/confirmation/:id">
              <ConfirmationPage />
            </Route>
            <Route path="/authorization/confirmation">
              <ConfirmationPage />
            </Route>
            <Route path="/authorization/success-message">
              <SuccessMessagePage />
            </Route>
            <Route path="/dashboard/details">
              <MemberDetailsSample />
            </Route>
            <Route path="/dashboard/:id/submit-decision">
              <DecisionPage />
            </Route>
            <Route path="/dashboard/:id">
              <MemberDetails />
            </Route>
            <Route path="/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/members" exact>
              <MembersPage />
            </Route>
            <Route path="/register" exact>
              <RegisterPage />
            </Route>
            <Route path="/hospital-notifications" exact>
              <ErNotificationsPage />
            </Route>
            <Route path="/" exact>
              <PortalUsers />
            </Route>
            <Route path="/maintenance-message" exact>
              <MaintenancePage />
            </Route>
            <Route path="/maintenance-message" exact>
              <MaintenancePage />
            </Route>
            <Route path="/ccm-referrals/:id" exact>
              <CcmReferralsDetailsPage />
            </Route>
            <Route path="/ccm-referrals" exact>
              <CcmReferralsSubmitPage />
            </Route>
            <Route path="/pqi-referrals/:id" exact>
              <PqiReferralsDetailsPage />
            </Route>
            <Route path="/pqi-referrals" exact>
              <PqiReferralsPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/confirmation-account" exact>
              <ConfirmationAccount />
            </Route>
            <Route path="/not-authenticated" exact>
              <NotAuthenticated />
            </Route>
            {/* <Route path="/" exact>
              <MaintenancePage />
            </Route> */}
            <Route component={Page404} />
          </Switch>
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/authorization" exact>
              <Authorization />
            </Route>
            <Route path="/authorization/standard/:id">
              <Standard />
            </Route>
            <Route path="/authorization/expedited/:id">
              <Expedited />
            </Route>
            <Route path="/authorization/additional-days/:id">
              <AdditionalDaysPage />
            </Route>
            <Route path="/authorization/additional-days/">
              <AdditionalDaysPage />
            </Route>
            <Route path="/authorization/notification-only/:id">
              <NotificationOnlyPage />
            </Route>
            <Route path="/authorization/confirmation/:id">
              <ConfirmationPage />
            </Route>
            <Route path="/authorization/confirmation">
              <ConfirmationPage />
            </Route>
            <Route path="/authorization/success-message">
              <SuccessMessagePage />
            </Route>
            <Route path="/dashboard/details">
              <MemberDetailsSample />
            </Route>
            <Route path="/dashboard/:id/submit-decision">
              <DecisionPage />
            </Route>
            <Route path="/dashboard/:id">
              <MemberDetails />
            </Route>
            <Route path="/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/members" exact>
              <MembersPage />
            </Route>
            <Route path="/register" exact>
              <RegisterPage />
            </Route>
            <Route path="/confirmation-account" exact>
              <ConfirmationAccount />
            </Route>
            <Route path="/hospital-notifications" exact>
              <ErNotificationsPage />
            </Route>
            <Route path="/" exact>
              <Dashboard />
            </Route>
            <Route path="/maintenance-message" exact>
              <MaintenancePage />
            </Route>
            <Route path="/ccm-referrals/:id" exact>
              <CcmReferralsDetailsPage />
            </Route>
            <Route path="/ccm-referrals" exact>
              <CcmReferralsSubmitPage />
            </Route>
            <Route path="/pqi-referrals/:id" exact>
              <PqiReferralsDetailsPage />
            </Route>
            <Route path="/pqi-referrals" exact>
              <PqiReferralsPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/not-authenticated" exact>
              <NotAuthenticated />
            </Route>
            {/* <Route path="/" exact>
              <MaintenancePage />
            </Route> */}
            <Route component={Page404} />
          </Switch>
        </div>
      </Router>
    );
  }
}

import { FC, useState } from 'react';
import '@inovua/reactdatagrid-enterprise/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DashboardFilters from '../DashboardFilters';
import moment from 'moment';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DashboardTableCurrentMobile from '../DashboardTableCurrentMobile/DashboardTableCurrentMobile';
import DashboardTableCurrentDesktop from '../DashboardTableCurrentDesktop/DashboardTableCurrentDesktop';
import DashboardTableDraftMobile from '../DashboardTableDraftMobile';
import DashboardTableDraftDesktop from '../DashboardTableDraftDesktop';
import DashboardTableSubmittedMobile from '../DashboardTableSubmittedMobile';
import DashboardTableSubmittedDesktop from '../DashboardTableSubmittedDesktop';
import DashboardTableNotificationDesktop from '../DashboardTableNotificationDesktop/DashboardTableNotificationDesktop';
import DashboardTableNotificationMobile from '../DashboardTableNotificationMobile';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ProfileNotActive from '../../ProfileNotActive/ProfileNotActive';
import { useAuthorizationsQuery } from '../../../hooks/queries/useAuthorizationsQuery';
import { Alert } from '@material-ui/lab';
import { IAuthorization } from '../../../interfaces/authorizations';
import _ from 'lodash';
import Cookies from 'js-cookie';

window.moment = moment;
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const formatData = (items: IAuthorization[]) => {
  return items.map((item: IAuthorization) => ({
    ...item,
    diagnoses: _.uniqBy(item.diagnoses, 'icdCode'),
    serviceRequests: _.uniqBy(item.serviceRequests, 'cptCode'),
  }));
};

const DashboardTable: FC = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const isActiveProfile = useSelector(
    (state: RootState) => state.profile.data.isActive
  );
  const role = Cookies.get('aah-role');
  const { data, isLoading, isError, error } = useAuthorizationsQuery({
    select: formatData,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return (
      <div className="mx+8 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  if (!isActiveProfile && !isLoading) {
    <ProfileNotActive />;
  }

  return (
    <section className="customTable w-full px-4 md:px-8 mx-auto mb-16">
      <div className="flex w-full lg:w-auto lg:justify-end">
        <button
          className={`btn btn-blue flex mb-4 w-full lg:w-auto justify-center `}
          onClick={() => {
            history.push('/authorization');
          }}
        >
          New Prior Auth Request
        </button>
      </div>

      <>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="dashboard tabs"
          >
            <Tab label="Current" {...a11yProps(0)} />
            <Tab label="Draft" {...a11yProps(1)} />
            <Tab label="Submitted" {...a11yProps(2)} />
            {role === 'NP' && (
              <Tab label="Notification Only" {...a11yProps(3)} />
            )}
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <DashboardFilters data={data} />
          <DashboardTableCurrentMobile />
          <DashboardTableCurrentDesktop data={data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DashboardTableDraftMobile />
          <DashboardTableDraftDesktop />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DashboardTableSubmittedMobile />
          <DashboardTableSubmittedDesktop />
        </TabPanel>
        {role === 'NP' && (
          <TabPanel value={value} index={3}>
            <DashboardTableNotificationDesktop />
            <DashboardTableNotificationMobile />
          </TabPanel>
        )}
      </>
    </section>
  );
};

export default DashboardTable;

import { FC, useEffect, useState, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import moment from 'moment';
import { useGetErNotifications } from '../../services/ServiceHook';
import Pagination from '@material-ui/lab/Pagination';
import { useMainProvider } from '../../providers/MainProvider';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import { setDrawer } from '../../store/DrawerSlice';

export interface IErNotifications {
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  memberMiddleName: string;
  facilityNpi: string;
  facilityName: string;
  memberDateOfBirth: string;
}

// React Data Grid
const columns = [
  {
    name: 'memberId',
    header: 'Member Id',
    minWidth: 150,
  },
  {
    name: 'memberFirstName',
    header: 'First Name',
    defaultFlex: 1,
  },
  {
    name: 'memberLastName',
    header: 'Last Name',
    defaultFlex: 1,
  },
  {
    name: 'memberDateOfBirth',
    header: 'Date of Birth',
    minWidth: 180,
    filterEditor: DateFilter,
    filterEditorProps: (value: any, index: number) => {
      return {
        dateFormat: 'MM/DD/YYYY',
        cancelButton: false,
        highlightWeekends: false,
      };
    },
    render: (value: any) => {
      return value.data.memberDateOfBirth
        ? moment(value.data.memberDateOfBirth).format('MM/DD/YYYY')
        : null;
    },
  },
  {
    name: 'facilityNpi',
    header: 'Facility NPI',
    minWidth: 150,
  },
  {
    name: 'facilityName',
    header: 'Facility Name',
    defaultFlex: 1,
  },
];

// Filter for CURRENT TAB
const defaultFilterValue = [
  {
    name: 'memberId',
    operator: 'eq',
    type: 'string',
    value: '',
  },
  {
    name: 'memberFirstName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberLastName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberDateOfBirth',
    operator: 'before',
    type: 'date',
    value: '',
  },
  {
    name: 'facilityNpi',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'facilityName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

interface IControls {
  label: string;
  value: string;
}

const ErNotificationsTable: FC = () => {
  const [data, setData] = useState<IErNotifications[] | []>([]);
  const [paginatedData, setPaginatedData] = useState<IErNotifications[] | []>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [getNotifications] = useGetErNotifications();
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const [filterCounter, setFilterCounter] = useState(0);
  const [filterBy, setFilterBy] = useState('');
  // The first commit of Material-UI
  const [selectedBirthday, setSelectedBirthday] = React.useState<Date | null>(
    null
  );

  const dispatch = useDispatch();

  const handleDateChange = (date: Date | null) => {
    setSelectedBirthday(date);
  };

  const filterByControls = [
    {
      label: 'Member Id',
      value: 'memberId',
    },
    {
      label: 'Member First Name',
      value: 'memberFirstName',
    },
    {
      label: 'Member Last Name',
      value: 'memberLastName',
    },
    {
      label: 'Facility Npi',
      value: 'facilityNpi',
    },
    {
      label: 'Facility Name',
      value: 'facilityName',
    },
    {
      label: 'Date of Birth',
      value: 'birthday',
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const erNotifications = await getNotifications();
      if (erNotifications && erNotifications.length) {
        setData(erNotifications);
        setPaginatedData(paginate(erNotifications, 10, 1));
        setFilterCounter(erNotifications.length);
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (filterBy !== 'birthday') {
      setSelectedBirthday(null);
    }
    if (filterBy && filterBy === 'birthday') {
      setFilterValue('');
    }
  }, [filterBy]);

  useEffect(() => {
    let found = [...data];

    if (filterBy === 'birthday' && selectedBirthday) {
      found = data.filter(
        (item: any) =>
          item.memberDateOfBirth ===
          moment(selectedBirthday).format('MM/DD/yyyy').toString()
      );
    }

    if (filterBy !== 'birthday' && filterValue.length) {
      found = data.filter((item: any) =>
        item[filterBy].toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    updatePagination(found);
  }, [filterValue, filterBy, selectedBirthday]);

  const handleChangeFilterBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBy((event.target as HTMLInputElement).value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  const updatePagination = (tempData: any[]) => {
    setFilterCounter(tempData.length);
    setPage(1);
    setPaginatedData(paginate(tempData, 10, 1));
  };

  // Once a row is selected
  const onSelectionChange = useCallback(
    ({ data, selected }) => {
      dispatch(
        setDrawer({
          isOpen: true,
          data: data,
        })
      );
    },
    [dispatch]
  );

  const gridStyle = { minHeight: 725 };
  const rowStyle = { cursor: 'pointer' };

  const handleDetails = (data: any) => {
    dispatch(
      setDrawer({
        isOpen: true,
        data: data,
      })
    );
  };

  // Pagination
  const paginate = (array: any[], pageSize: number, pageNumber: number) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setPaginatedData(paginate(data, 10, value));
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center mt-8">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="showDesktop mt-4">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="memberId"
          dataSource={data}
          style={gridStyle}
          rowStyle={rowStyle}
          defaultFilterValue={defaultFilterValue}
          columns={columns}
          pagination={true}
          enableSelection={true}
          defaultLimit={15}
          onSelectionChange={onSelectionChange}
        />
      </div>
      <div className="showMobile mt-4">
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters"
            id="er-notifications-filters"
          >
            <div className="flex flex-col w-full">
              <div className="font-bold">
                <span className="text-gray-500">Filters</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="flex flex-col">
            <FormControl component="fieldset">
              <FormLabel component="legend">Filter by</FormLabel>
              <RadioGroup
                aria-label="filterBy"
                name="filterBy"
                value={filterBy}
                onChange={handleChangeFilterBy}
              >
                {filterByControls.map((item: IControls) => {
                  return (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio color="primary" />}
                      label={item.label}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            {filterBy && filterBy !== 'birthday' ? (
              <form noValidate autoComplete="off" className="w-full">
                <TextField
                  className="w-full"
                  id="filled-name"
                  label="text here..."
                  value={filterValue}
                  onChange={handleFilterChange}
                  disabled={!filterBy ? true : false}
                />
              </form>
            ) : null}
            {filterBy && filterBy === 'birthday' ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <KeyboardDatePicker
                    className="w-full"
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of birth"
                    format="MM/dd/yyyy"
                    value={selectedBirthday}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            ) : null}
          </AccordionDetails>
        </Accordion>
        {paginatedData.length ? (
          paginatedData.map((item: any, key: number) => {
            return (
              <div key={key}>
                <Accordion className="mb-4" key={key}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="er-notifications-header"
                  >
                    <div className="flex flex-col w-full">
                      <div className="font-bold">
                        <span className="text-gray-500">{item.memberId}</span>
                      </div>
                      <span>
                        {item.memberFirstName} {item.memberLastName}
                      </span>
                      <span>
                        {moment().diff(item.memberDateOfBirth, 'years')} years
                        old.
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full">
                      <hr className="mb-4" />
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Date of Birth: </span>
                        <span className="font-bold">
                          {item.memberDateOfBirth}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Facility NPI: </span>
                        <span className="font-bold">{item.facilityNpi}</span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Facility Name: </span>
                        <span className="font-bold">{item.facilityName}</span>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-blue-transparent w-full"
                          onClick={() => {
                            handleDetails(item);
                          }}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
        ) : (
          <div className="mb-4">
            <Accordion>
              <AccordionSummary>There is no data to show</AccordionSummary>
            </Accordion>
          </div>
        )}
        <div className="flex flex-col items-center showMobile w-full">
          <Typography className="pb-2">Page: {page}</Typography>
          <Pagination
            count={Math.round(filterCounter / 10)}
            page={page}
            onChange={handlePagination}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};

export default ErNotificationsTable;

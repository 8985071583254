export interface phoneSection {
  section: string;
  phone: string;
  fax: string;
  email: string;
  chat?: string;
  url: string;
  notes: string;
}

export interface contactPlan {
  state: string;
  phones: phoneSection[];
}

export interface IPlan {
  name: string;
  site: string;
  rootSite: string;
  logoUrl: string;
  logoUrlPng: string;
  faviconUrl: string;
  theme: string;
  cmsIdNumber: string;
  stateApiUrl?: string;
  permissions: string[];
  contactEmail: string;
  auth0: {
    domain: string;
    clientId: string;
  };
  contact: contactPlan[];
  providerDocuments: string;
  exceptionsAndAppeals: string;
  claimsAndBilling: string;
  prescribersAndPharmacies: string;
  compliance: string;
  quality: string;
  privacyPolicy: string;
  disclaimers: string;
  languageAssistance: string;
  reCaptchaKey: string;
}
export const planData: IPlan[] = [
  {
    name: 'Select a Plan',
    site: '',
    rootSite: '',
    logoUrl: '',
    logoUrlPng: '',
    faviconUrl: '',
    theme: '',
    cmsIdNumber: '',
    permissions: [],
    contactEmail: '',
    auth0: {
      domain: '',
      clientId: '',
    },
    contact: [
      {
        state: '',
        phones: [
          {
            section: '',
            phone: '',
            fax: '',
            email: '',
            chat: '',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments: '',
    exceptionsAndAppeals: '',
    claimsAndBilling: '',
    prescribersAndPharmacies: '',
    compliance: '',
    quality: '',
    privacyPolicy: '',
    disclaimers: '',
    languageAssistance: '',
    reCaptchaKey: '',
  },
  // TODO: REMOVE THIS TEST
  {
    name: 'Local Host Portal',
    site: 'localhost:3000',
    rootSite: 'localhost:3000',
    logoUrl: 'https://via.placeholder.com/200x100?text=QA+Site',
    logoUrlPng: 'https://via.placeholder.com/200x100?text=QA+Site',
    faviconUrl: '/images/favicon.ico',
    theme: 'allyalign-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: ['H9917', 'H6832', 'H1277', 'Massachusetts', 'H3274', 'H4091'],
    contactEmail: 'noel@logiccadence.com',
    auth0: {
      domain: 'provider-portal-dev.us.auth0.com',
      clientId: 'AH9Jxl7A62eZrjLpyAxXLbHjLIEZMTXk',
    },
    contact: [
      {
        state: '',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336',
            fax: '',
            email: '',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=944cb286-85b6-4e62-af16-d396f56913b1&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711), option 4',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=944cb286-85b6-4e62-af16-d396f56913b1&bu=4597633',
            url: '',
            notes:
              '<b>Paper Claims Mailing Address</b>: </br>Align Senior Care. </br>Attn: Claims Dept. </br>PO Box 908 </br>Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711), option 3',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=944cb286-85b6-4e62-af16-d396f56913b1&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: 'www.navitus.com',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '1-855-855-0336',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711), option 4',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals:
      'https://alignseniorcare.com/resources/exceptions-or-appeals/',
    claimsAndBilling: 'https://alignseniorcare.com/providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/for-providers/for-prescribes-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/language-assistance/',
    reCaptchaKey: '6LfFiDYbAAAAAG14gRy1yHSDeBcHR-g-XDXZdDu3',
  },
  {
    name: 'Local Host Admin Site',
    site: 'localhost:3000',
    rootSite: 'localhost:3000',
    logoUrl: 'https://via.placeholder.com/200x100?text=QA+Site',
    logoUrlPng: 'https://via.placeholder.com/200x100?text=QA+Site',
    faviconUrl: '/images/favicon.ico',
    theme: 'allyalign-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: ['H9917', 'H6832', 'H1277', 'H3274', 'MMS'],
    contactEmail: 'noel@logiccadence.com',
    auth0: {
      domain: 'provider-portal-dev.us.auth0.com',
      clientId: 'AH9Jxl7A62eZrjLpyAxXLbHjLIEZMTXk',
    },
    contact: [
      {
        state: 'Michigan',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711) option 4',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Florida',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-8935 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-8935 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-8935 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-8935 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareFL@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Virginia',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0489 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0489 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0489 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0489 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareVA@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711) option 4',
            fax: '',
            email: 'alignseniorcareca@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals:
      'https://alignseniorcare.com/resources/exceptions-or-appeals/',
    claimsAndBilling: 'https://alignseniorcare.com/providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/for-providers/for-prescribes-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/language-assistance/',
    reCaptchaKey: '6LfFiDYbAAAAAG14gRy1yHSDeBcHR-g-XDXZdDu3',
  },
  // TODO: REMOVE THE ABOVE TEST
  {
    name: 'Align Senior Care',
    site: 'providers.alignseniorcare.com',
    rootSite: 'alignseniorcare.com',
    logoUrl: '/images/AlignSeniorCare.svg',
    logoUrlPng: '/images/AlignSeniorCare.png',
    faviconUrl: '',
    theme: 'alignseniorcare-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: ['H9917', 'H6832', 'H1277', 'Massachusetts', 'H3274'],
    contactEmail: 'UMInquiryRequest@alignseniorcare.com',
    auth0: {
      domain: 'auth.alignseniorcare.com',
      // domain: 'prod-test.alignseniorcare.com',
      clientId: '0w2S9xrbpBEqSsnEq0ivahoXoGuYnBuX',
    },
    contact: [
      {
        state: 'Michigan',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=3afe87d9-1264-4b87-aad9-c0d4968ab439&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=3afe87d9-1264-4b87-aad9-c0d4968ab439&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711) option 4',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Florida',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-8935 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=9b7163f8-d548-47a7-99af-5f417ef8410a&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-8935 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=9b7163f8-d548-47a7-99af-5f417ef8410a&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-8935 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-8935 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareFL@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Virginia / Massachusetts',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0489 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=0b54b082-7b52-4e36-a1e1-e54865053d05&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0489 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=0b54b082-7b52-4e36-a1e1-e54865053d05&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0489 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0489 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareVA@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4ea1341d-4d99-4e08-8ba0-7e9f1086c31c&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4ea1341d-4d99-4e08-8ba0-7e9f1086c31c&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711) option 4',
            fax: '',
            email: 'alignseniorcareca@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://alignseniorcare.com/contact-us/',
    claimsAndBilling: 'https://alignseniorcare.com/providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/providers/for-prescribers-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy-2/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/contact-us/',
    // reCaptchaKey: '6LeypzYbAAAAABrtAyn_h-WDBdfT3fTI1-TwAI4f',
    reCaptchaKey: '6LcgYDYbAAAAAK5C_8MOVixCkO-dZxSzyGRlGwKa',
  },
  {
    name: 'QA Site',
    site: 'provider-portal-dev-ui.azurewebsites.net',
    rootSite: 'provider-portal-dev-ui.azurewebsites.net',
    logoUrl: 'https://via.placeholder.com/200x100?text=QA+Site',
    logoUrlPng: 'https://via.placeholder.com/200x100?text=QA+Site',
    faviconUrl: '',
    theme: 'alignseniorcare-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: ['H4091', 'H9917', 'H6832', 'H1277', 'Massachusetts', 'H3274', 'MMS'],
    contactEmail: 'emeling@logiccadence.com',
    auth0: {
      domain: 'provider-portal-dev.us.auth0.com',
      clientId: 'AH9Jxl7A62eZrjLpyAxXLbHjLIEZMTXk',
    },
    contact: [
      {
        state: 'Michigan',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711) option 4',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Florida',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-8935 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-8935 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-8935 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-8935 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareFL@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Virginia / Massachusetts',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0489 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0489 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0489 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0489 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareVA@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711) option 4',
            fax: '',
            email: 'alignseniorcareca@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://alignseniorcare.com/contact-us/',
    claimsAndBilling: 'https://alignseniorcare.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy-2/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/contact-us/',
    // reCaptchaKey: '6LeypzYbAAAAABrtAyn_h-WDBdfT3fTI1-TwAI4f',
    reCaptchaKey: '6LcgYDYbAAAAAK5C_8MOVixCkO-dZxSzyGRlGwKa',
  },
  {
    name: 'QA Admin Site',
    site: 'provider-portal-dev-admin.tutelarhealth.io',
    rootSite: 'provider-portal-dev-admin.tutelarhealth.io',
    logoUrl: 'https://via.placeholder.com/200x100?text=QA+Site',
    logoUrlPng: 'https://via.placeholder.com/200x100?text=QA+Site',
    faviconUrl: '',
    theme: 'alignseniorcare-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: [
      'H6832',
      'H1277',
      'H9917',
      'H3274',
      'H8797',
      'H3419',
      'PHP',
      'H6345',
      'H1372',
      'H4172',
      'H2185',
      'H4091',
      'H3467',
      'H6959',
      'Massachusetts',
      'MMS'
    ],
    contactEmail: 'emeling@logiccadence.com',
    auth0: {
      domain: 'provider-portal-dev.us.auth0.com',
      clientId: 'AH9Jxl7A62eZrjLpyAxXLbHjLIEZMTXk',
    },
    contact: [
      {
        state: 'Michigan',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711) option 4',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Florida',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-8935 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-8935 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-8935 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-8935 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareFL@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Virginia / Massachusetts',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0489 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0489 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0489 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0489 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareVA@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711) option 4',
            fax: '',
            email: 'alignseniorcareca@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://alignseniorcare.com/contact-us/',
    claimsAndBilling: 'https://alignseniorcare.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy-2/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/contact-us/',
    // reCaptchaKey: '6LeypzYbAAAAABrtAyn_h-WDBdfT3fTI1-TwAI4f',
    reCaptchaKey: '6LcgYDYbAAAAAK5C_8MOVixCkO-dZxSzyGRlGwKa',
  },
  {
    name: 'Staging Admin Site',
    site: 'provider-portal-staging-admin.tutelarhealth.io',
    rootSite: 'provider-portal-staging-admin.tutelarhealth.io',
    logoUrl: 'https://via.placeholder.com/200x100?text=QA+Site',
    logoUrlPng: 'https://via.placeholder.com/200x100?text=QA+Site',
    faviconUrl: '',
    theme: 'alignseniorcare-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: [
      'H6832',
      'H1277',
      'H9917',
      'H3274',
      'H8797',
      'H3419',
      'PHP',
      'H6345',
      'H1372',
      'H4172',
      'H2185',
      'H4091',
      'H3467',
      'H6959',
      'Massachusetts',
      'MMS'
    ],
    contactEmail: 'emeling@logiccadence.com',
    auth0: {
      domain: 'provider-portal-staging.us.auth0.com',
      clientId: 'pIw9e1g85tHm1ok7pVxOkj2a1Yoe3zQK',
    },
    contact: [
      {
        state: 'Michigan',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711) option 4',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Florida',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-8935 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-8935 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-8935 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-8935 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareFL@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Virginia / Massachusetts',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0489 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0489 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0489 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0489 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareVA@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711) option 4',
            fax: '',
            email: 'alignseniorcareca@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711), option 4',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes:
              '<b>Paper Claims Mailing Address</b>: </br>Align Senior Care. </br>Attn: Claims Dept. </br>PO Box 908 </br>Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711), option 3',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support/Provider Service',
            phone: '1-844-305-3879',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711), option 4',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://alignseniorcare.com/contact-us/',
    claimsAndBilling: 'https://alignseniorcare.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy-2/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/contact-us/',
    // reCaptchaKey: '6LeypzYbAAAAABrtAyn_h-WDBdfT3fTI1-TwAI4f',
    reCaptchaKey: '6LcgYDYbAAAAAK5C_8MOVixCkO-dZxSzyGRlGwKa',
  },
  {
    name: 'Staging Site',
    site: 'provider-portal-staging-ui.azurewebsites.net',
    rootSite: 'provider-portal-staging-ui.azurewebsites.net',
    logoUrl: 'https://via.placeholder.com/200x100?text=Staging+Site',
    logoUrlPng: 'https://via.placeholder.com/200x100?text=Staging+Site',
    faviconUrl: '',
    theme: 'alignseniorcare-ipd',
    cmsIdNumber: 'H6832_ASCWebsite & H1277_ASCWebsite & H9917_ASCWebsite',
    permissions: ['H4091', 'H9917', 'H6832', 'H1277', 'Massachusetts', 'H3274', 'MMS'],
    contactEmail: 'emeling@logiccadence.com',
    auth0: {
      domain: 'provider-portal-staging.us.auth0.com',
      clientId: 'pIw9e1g85tHm1ok7pVxOkj2a1Yoe3zQK',
    },
    contact: [
      {
        state: 'Michigan',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0336 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0336 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0336 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0336 (TTY 711) option 4',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Florida',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-8935 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-8935 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-8935 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-8935 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareFL@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Virginia / Massachusetts',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0489 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0489 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0489 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0489 (TTY 711) option 4',
            fax: '',
            email: 'AlignSeniorCareVA@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'California',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-305-3879 (TTY 711)',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-305-3879 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-305-3879 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@alignseniorcare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-305-3879 (TTY 711) option 4',
            fax: '',
            email: 'alignseniorcareca@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://alignseniorcare.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://alignseniorcare.com/contact-us/',
    claimsAndBilling: 'https://alignseniorcare.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://alignseniorcare.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://alignseniorcare.com/compliance/',
    quality: 'https://alignseniorcare.com/quality/',
    privacyPolicy: 'https://alignseniorcare.com/privacy-policy-2/',
    disclaimers: 'https://alignseniorcare.com/disclaimers/',
    languageAssistance: 'https://alignseniorcare.com/contact-us/',
    // reCaptchaKey: '6LeypzYbAAAAABrtAyn_h-WDBdfT3fTI1-TwAI4f',
    reCaptchaKey: '6LcgYDYbAAAAAK5C_8MOVixCkO-dZxSzyGRlGwKa',
  },
  {
    name: 'AgeRight Advantage',
    site: 'providers.agerightadvantage.com',
    rootSite: 'agerightadvantage.com',
    logoUrl: '/images/age-right.svg',
    logoUrlPng: '/images/age-right.png',
    faviconUrl: '',
    theme: 'ageright-ipd',
    cmsIdNumber: 'H1372_Website',
    permissions: ['H1372'],
    contactEmail: 'UMInquiryRequest@agerightadvantage.com',
    auth0: {
      domain: 'auth.agerightadvantage.com',
      clientId: 't86LPH0QFx38HyPyTYxewx6SQcJD9Nrf',
    },
    contact: [
      {
        state: 'Oregon',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-854-6885 (TTY 711)',
            fax: '',
            email: 'customerservice@agerightadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=b692117c-1791-4978-8157-d5d029235d6f&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-854-6885 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@agerightadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=b692117c-1791-4978-8157-d5d029235d6f&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-854-6885 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@agerightadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4a4f9299-0439-4966-b33c-6aa8e7bff66f&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-844-6885 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@marquisadvantage.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://agerightadvantage.com/for-providers/provider-documents/',
    exceptionsAndAppeals: 'https://agerightadvantage.com/contact-us/',
    claimsAndBilling: 'https://agerightadvantage.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://agerightadvantage.com/for-providers/for-prescribers-and-pharmacies',
    compliance: 'https://agerightadvantage.com/compliance/',
    quality: 'https://agerightadvantage.com/quality/',
    privacyPolicy: 'https://agerightadvantage.com/privacy-policy-2/',
    disclaimers: 'https://agerightadvantage.com/disclaimers/',
    languageAssistance: 'https://agerightadvantage.com/contact-us/',
    reCaptchaKey: '',
  },
  {
    name: 'Great Plains Medicare Advantage',
    site: 'providers.greatplainsmedicareadvantage.com',
    rootSite: 'greatplainsmedicareadvantage.com',
    logoUrl: '/images/great-plains.svg',
    logoUrlPng: '/images/great-plains.png',
    faviconUrl: '',
    theme: 'greatplains-ipd',
    cmsIdNumber: 'H7511_Website, H8967_Website, H1787_Website',
    permissions: ['GPND', 'GPNE', 'GPSD'],
    contactEmail: '',
    auth0: {
      domain: 'auth.greatplainsmedicareadvantage.com',
      clientId: '',
    },
    contact: [
      {
        state: 'Nebraska',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-637-4760',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-205-7244 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes:
              'Great Plains Medicare Advantage </br> PO Box 908 </br> Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-637-4760 (TTY 711), option 3',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '1-844-637-4760',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-637-4760 (TTY 711), option 4',
            fax: '',
            email: 'networksupport@greatplainsmedicareadvantage.com.',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'North Dakota',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-637-4760',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-205-7244 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes:
              'Great Plains Medicare Advantage </br> PO Box 908 </br> Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-637-4760 (TTY 711), option 3',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '1-844-637-4760',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-637-4760 (TTY 711), option 4',
            fax: '',
            email: 'networksupport@greatplainsmedicareadvantage.com.',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'South Dakota',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-637-4760',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-205-7244 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes:
              'Great Plains Medicare Advantage </br> PO Box 908 </br> Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-637-4760 (TTY 711), option 3',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '1-844-637-4760',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-637-4760 (TTY 711), option 4',
            fax: '',
            email: 'networksupport@greatplainsmedicareadvantage.com.',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments: '#',
    exceptionsAndAppeals: '#',
    claimsAndBilling:
      'https://greatplainsmedicareadvantage.com/providers-partners/#Quinto',
    prescribersAndPharmacies:
      'https://greatplainsmedicareadvantage.com/providers-partners/#Sexto',
    compliance:
      'https://greatplainsmedicareadvantage.com/providers-partners/compliance/',
    quality:
      'https://greatplainsmedicareadvantage.com/providers-partners/quality/',
    privacyPolicy:
      'https://greatplainsmedicareadvantage.com/website-privacy-statement/',
    disclaimers: '#',
    languageAssistance: '#',
    reCaptchaKey: '',
  },
  {
    name: 'KeyCare Advantage',
    site: 'keycareadvantageportal.allyalign.com',
    rootSite: 'keycareadvantageportal.allyalign.com',
    logoUrl: '/images/keycare.svg',
    logoUrlPng: '/images/keycare.png',
    faviconUrl: '',
    theme: 'keycare-ipd',
    cmsIdNumber: 'H6959_KCAWebsite',
    permissions: ['H6959'],
    contactEmail: 'Uminquiryrequest@keycareadvantage.com',
    auth0: {
      domain: 'keycareadvantage.us.auth0.com',
      clientId: 'PNiu2FtQOOpv8SPs9H0hcbepOhmTpGCL',
    },
    contact: [
      {
        state: 'Maryland',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-206-1205 (TTY 711)',
            fax: '',
            email: 'keycarecustomerservice@allyalign.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=e0159f37-79f7-4d1e-b3b9-9f19227f9df1&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-206-1205 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@keycareadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=e0159f37-79f7-4d1e-b3b9-9f19227f9df1&bu=4597633',
            url: '',
            notes:
              '"KeyCare Advantage </br> Attn: Claims Dept </br> PO Box 6510 </br> Glen Allen, VA 23058-6510"',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-206-1205 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UminquiryRequest@keycareadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=058a35bf-5259-4a8a-98bb-3e54a72f3b92&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-206-1205 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@keycareadvantage.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://keycareadvantage.com/for-providers/provider-documents/',
    exceptionsAndAppeals: 'https://keycareadvantage.com/contact-us/',
    claimsAndBilling: 'https://keycareadvantage.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://keycareadvantage.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://keycareadvantage.com/compliance/',
    quality: 'https://keycareadvantage.com/quality/',
    privacyPolicy: 'https://keycareadvantage.com/privacy-policy-2/',
    disclaimers: 'https://keycareadvantage.com/disclaimers/',
    languageAssistance: 'https://keycareadvantage.com/contact-us/',
    reCaptchaKey: '',
  },
  {
    name: 'LifeWorks Advantage',
    site: 'providers.lifeworksadvantage.com',
    rootSite: 'lifeworksadvantage.com',
    logoUrl: '/images/lifeworks.svg',
    logoUrlPng: '/images/lifeworks.png',
    faviconUrl: '',
    theme: 'lifeworks-ipd',
    cmsIdNumber: 'H2185_Website',
    permissions: ['H2185'],
    contactEmail: 'UMInquiryRequest@lifeworksadvantage.com',
    auth0: {
      domain: 'auth.lifeworksadvantage.com',
      clientId: 'OZ3wePyMb2YhU60W7PaZwv2QZsvVH4Rz',
    },
    contact: [
      {
        state: 'Virginia',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-854-6883 (TTY 711)',
            fax: '',
            email: 'customerservice@lifeworksadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=b3aec8f9-6803-4ce1-8e3b-5097a66bfbee&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-854-6883 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@lifeworksadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=b3aec8f9-6803-4ce1-8e3b-5097a66bfbee&bu=4597633',
            url: '',
            notes:
              'LifeWorks Advantage </br> PO Box 908 </br> Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-854-6883 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@lifeworksadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=48b5bbd6-cdb6-4e30-aec5-2f68b6402186&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-854-6883 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@lifeworksadvantage.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://lifeworksadvantage.com/for-providers/provider-documents/',
    exceptionsAndAppeals: 'https://lifeworksadvantage.com/contact-us/',
    claimsAndBilling: 'https://lifeworksadvantage.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://lifeworksadvantage.com/for-providers/for-prescribers-and-pharmacies',
    compliance: 'https://lifeworksadvantage.com/compliance/',
    quality: 'https://lifeworksadvantage.com/quality/',
    privacyPolicy: 'https://lifeworksadvantage.com/privacy-policy-2/',
    disclaimers: 'https://lifeworksadvantage.com/disclaimers/',
    languageAssistance: 'https://lifeworksadvantage.com/contact-us/',
    reCaptchaKey: '6LeCqjYbAAAAAFryFccln4OSuCeeFq2FKgyAjSki',
  },
  {
    name: 'NHC Advantage Provider Portal',
    site: 'providers.nhcadvantageplan.com',
    rootSite: 'nhcadvantageplan.com',
    logoUrl: '/images/nhc.svg',
    logoUrlPng: '/images/nhc.png',
    faviconUrl: '',
    theme: 'nhc-ipd',
    cmsIdNumber: 'H4172_Website',
    permissions: ['H4172'],
    contactEmail: 'UMInquiryRequest@nhcadvantageplan.com',
    auth0: {
      domain: 'auth.nhcadvantageplan.com',
      clientId: 'BsLCPD6JZwxFYZaDmBcz41px6noSDNav',
    },
    contact: [
      {
        state: 'Missouri / Tennessee / South Carolina',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-854-6886 (TTY 711)',
            fax: '',
            email: 'customerservice@nhcadvantageplan.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=d673e666-83c3-43b3-a1bb-4375b4ef19e2&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-854-6886 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@nhcadvantageplan.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=d673e666-83c3-43b3-a1bb-4375b4ef19e2&bu=4597633',
            url: '',
            notes:
              'NHC Advantage </br>PO Box 908 </br>Addison, </br>TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-854-6886 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@nhcadvantageplan.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=58c36240-3385-4649-ba88-e68beb018de4&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-854-6886 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@nhcadvantage-mo.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments: 'https://nhcadvantageplan.com/provider-documents/',
    exceptionsAndAppeals: 'https://nhcadvantageplan.com/contact-us/',
    claimsAndBilling: 'https://nhcadvantageplan.com/claims/',
    prescribersAndPharmacies:
      'https://nhcadvantageplan.com/for-prescribers-and-pharmacies/',
    compliance: 'https://nhcadvantageplan.com/compliance/',
    quality: 'https://nhcadvantageplan.com/quality/',
    privacyPolicy: 'https://nhcadvantageplan.com/privacy-policy-2/',
    disclaimers: 'https://nhcadvantageplan.com/disclaimers/',
    languageAssistance: 'https://nhcadvantageplan.com/contact-us/',
    reCaptchaKey: '',
  },
  {
    name: 'Perennial Advantage',
    site: 'providers.perennialadvantage.com',
    rootSite: 'perennialadvantage.com',
    logoUrl: '/images/perennial-advantage.svg',
    logoUrlPng: '/images/perennial-advantage.png',
    faviconUrl: '',
    theme: 'perennial-ipd',
    cmsIdNumber: 'H8797_PAWebsite & H3417_PAWebsite',
    permissions: ['H3419', 'H8797'],
    contactEmail: 'UMInquiryRequest@allyalign.com',
    auth0: {
      domain: 'auth.perennialadvantage.com',
      clientId: 'tlGlBvbtMbJZjP5oGEnB8dPvlILbHEx2',
    },
    contact: [
      {
        state: 'Colorado',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-6959 (TTY 711)',
            fax: '',
            email: 'customerservice@perennialadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=6bf88d03-b55c-4f44-b05e-2fad426e7fa8&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-6959 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@perennialadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=6bf88d03-b55c-4f44-b05e-2fad426e7fa8&bu=4597633',
            url: '',
            notes:
              'Perennial Advantage </br>Attn: Claims Dept </br>PO Box 2190 </br>Glen Allen, VA 23058-2190',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-6959 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@allyalign.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=2c8ecb85-9236-45dc-b3ef-196fd5a0d273&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-6959 (TTY 711) option 4',
            fax: '',
            email: 'PerennialCO@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Ohio',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-788-6986 (TTY 711)',
            fax: '',
            email: 'customerservice@perennialadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=69d3dd93-9df6-4e6a-bdd4-679685628cba&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-788-6986 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@perennialadvantage.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=69d3dd93-9df6-4e6a-bdd4-679685628cba&bu=4597633',
            url: '',
            notes:
              'Perennial Advantage </br>Attn: Claims Dept </br>PO Box 2190 </br>Glen Allen, VA 23058-2190',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-788-6986 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@allyalign.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=9c264686-2827-4793-a563-4911bb502142&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-788-6986 ( TTY 711) option 4',
            fax: '',
            email: 'perennialoh@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://perennialadvantage.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://perennialadvantage.com/contact-us/',
    claimsAndBilling: 'https://perennialadvantage.com/providers/claims/',
    prescribersAndPharmacies:
      'https://perennialadvantage.com/providers/for-prescribers-and-pharmacies/',
    compliance: 'https://perennialadvantage.com/compliance/',
    quality: 'https://perennialadvantage.com/quality/',
    privacyPolicy: 'https://perennialadvantage.com/privacy-policy/',
    disclaimers: 'https://perennialadvantage.com/disclaimers/',
    languageAssistance: 'https://perennialadvantage.com/contact-us/',
    reCaptchaKey: '6LfErTYbAAAAAOQxqG7aevL9XlVD93kGxri6BnJy',
  },
  {
    name: 'Pruitt Health Premier',
    site: 'providers.pruitthealthpremier.com',
    rootSite: 'pruitthealthpremier.com',
    logoUrl: '/images/pruitt.svg',
    logoUrlPng: '/images/pruitt.png',
    faviconUrl: '/images/favicon-pruitt.ico',
    theme: 'pruitt-ipd',
    cmsIdNumber: 'H3291_Website & H6345_Website',
    permissions: ['PHP', 'H6345'],
    contactEmail: 'UMInquiryRequest@pruitthealthpremier.com',
    auth0: {
      domain: 'auth.pruitthealthpremier.com',
      clientId: 'SN21BI40AH6M0vPwKYAqz9fOSXrQEEwV',
    },
    contact: [
      {
        state: 'North Carolina',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0759 (TTY 711)',
            fax: '',
            email: 'customerservice@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=50db9f81-4b69-4151-b0ef-bd64d120e3ab&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0759 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=50db9f81-4b69-4151-b0ef-bd64d120e3ab&bu=4597633',
            url: '',
            notes:
              'PruittHealth Premier </br>PO Box 21593 </br>Addison, Eagan, MN 55121',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0759 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=313afed6-6e5b-452e-ab16-200b6eda5bb2&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0759 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@pruitthealthpremier-nc.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'South Carolina',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0759 (TTY 711)',
            fax: '',
            email: 'customerservice@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=50db9f81-4b69-4151-b0ef-bd64d120e3ab&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0759 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=50db9f81-4b69-4151-b0ef-bd64d120e3ab&bu=4597633',
            url: '',
            notes:
              'PruittHealth Premier </br>PO Box 21593 </br>Addison, Eagan, MN 55121',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0759 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=313afed6-6e5b-452e-ab16-200b6eda5bb2&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0759 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@pruitthealthpremier-nc.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
      {
        state: 'Georgia',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-855-855-0668 (TTY 711)',
            fax: '',
            email: 'customerservice@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=73e73c43-f435-4cc1-81b1-cc04a85bfd59&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-855-855-0668 (TTY 711) option 1',
            fax: '',
            email: 'customerservice@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=73e73c43-f435-4cc1-81b1-cc04a85bfd59&bu=4597633',
            url: '',
            notes:
              'PruittHealth Premier </br>PO Box 21593 </br>Addison, Eagan, MN 55121',
          },
          {
            section: 'Utilization Management',
            phone: '1-855-855-0668 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@pruitthealthpremier.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=944cb286-85b6-4e62-af16-d396f56913b1&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-855-855-0668 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@pruitthealthpremier.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://pruitthealthpremier.com/for-providers/provider-documents/',
    exceptionsAndAppeals: 'https://pruitthealthpremier.com/contact-us/',
    claimsAndBilling: 'https://pruitthealthpremier.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://pruitthealthpremier.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://pruitthealthpremier.com/compliance/',
    quality: 'https://pruitthealthpremier.com/quality/',
    privacyPolicy: 'https://pruitthealthpremier.com/privacy-policy-2/',
    disclaimers: 'https://pruitthealthpremier.com/disclaimers/',
    languageAssistance: 'https://pruitthealthpremier.com/contact-us/',
    reCaptchaKey: '6Lf3rjYbAAAAAE2FQ4Yvc8SU3hR9rgJ1mt-CAabh',
  },
  {
    name: 'ProCare Advantage',
    site: 'procareadvantageportal.allyalign.com',
    rootSite: 'procareadvantageportal.allyalign.com',
    logoUrl: '/images/procare.svg',
    logoUrlPng: '/images/procare.png',
    faviconUrl: '#',
    theme: 'procare-ipd',
    cmsIdNumber: 'procareadvantageplan.com',
    permissions: ['H3467'],
    contactEmail: 'uminquiryrequest@procareadvantageplan.com',
    auth0: {
      domain: 'procareadvantage.us.auth0.com',
      clientId: 'CkQQNyrAgkrtiZFc5Dr447jWM8MwRKCL',
    },
    contact: [
      {
        state: 'Texas',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-206-3719',
            fax: '',
            email: 'procarecustomerservice@allyalign.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4595c7f9-c96b-4758-9638-693d718bbfd8&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-206-3719 (TTY 711), option 4',
            fax: '',
            email: 'procarecustomerservice@allyalign.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4595c7f9-c96b-4758-9638-693d718bbfd8&bu=4597633',
            url: '',
            notes:
              'ProCare Advantage </br>PO Box 908 </br>Addison, TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-206-3719 (TTY 711), option 3',
            fax: '',
            email: 'UMInquiryRequest@procare.com',
            chat: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=19dc00ec-5ef7-4804-83c3-f745a9ec7dd7&bu=4597633',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '1-844-206-3719',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-206-3719 (TTY 711), option 4',
            fax: '',
            email: 'networksupport@procareadvantageplan.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments:
      'https://procareadvantageplan.com/for-providers/provider-documents/',
    exceptionsAndAppeals: 'https://procareadvantageplan.com/contact-us/',
    claimsAndBilling: 'https://procareadvantageplan.com/for-providers/claims/',
    prescribersAndPharmacies:
      'https://procareadvantageplan.com/for-providers/for-prescribers-and-pharmacies/',
    compliance: 'https://procareadvantageplan.com/compliance/',
    quality: 'https://procareadvantageplan.com/quality/',
    privacyPolicy: 'https://procareadvantageplan.com/privacy-policy-2/',
    disclaimers: 'https://procareadvantageplan.com/disclaimer/',
    languageAssistance: 'https://procareadvantageplan.com/contact-us/',
    reCaptchaKey: '6Lf7rjYbAAAAAGYOEjlMDxpdzJVsKhTBTEa5VTMx',
  },
  {
    name: 'Simpra Advantage',
    site: 'providers.simpra.com',
    rootSite: 'simpra.com',
    logoUrl: '/images/simpra.svg',
    logoUrlPng: '/images/simpra.png',
    faviconUrl: '',
    theme: 'simpra-ipd',
    cmsIdNumber: 'H4091_Website',
    permissions: ['H4091'],
    contactEmail: 'UMInquiryRequest@simpra.com',
    auth0: {
      domain: 'auth.simpra.com',
      clientId: 'wTn4lSE1gqOMpKUQr8HMgfDBi6SY4wXL',
    },
    contact: [
      {
        state: '',
        phones: [
          {
            section: 'Plan Phone Number',
            phone: '1-844-637-4770 (TTY 711)',
            fax: '',
            email: 'simpracustomerservice@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Claims Helpdesk',
            phone: '1-844-637-4770 (TTY 711) option 1',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@simpra.com',
            url: '',
            notes:
              'Simpra Advantage </br>PO Box 908 </br>Addison, </br>TX 75001-0908',
          },
          {
            section: 'Utilization Management',
            phone: '1-844-637-4770 (TTY 711) option 3',
            fax: '1-833-610-2399',
            email: 'UMInquiryRequest@simpra.com',
            url: '',
            notes: '',
          },
          {
            section: 'Nativus Pharmacy Helpdesk',
            phone: '1-866-270-3877 (TTY 711)',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
          {
            section: 'EZ-Net Support',
            phone: '',
            fax: '',
            email: 'eznetsupport@allyalign.com',
            url: '',
            notes: '',
          },
          {
            section: 'Network Support',
            phone: '1-844-637-4770 (TTY 711) option 4',
            fax: '',
            email: 'networksupport@simpra.com',
            url: '',
            notes: '',
          },
          {
            section: 'EDI Customer Support',
            phone: '1-800-356-0092',
            fax: '',
            email: 'HelpDesk_Dallas@ssigroup.com',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments: 'https://simpra.com/providers/provider-documents/',
    exceptionsAndAppeals: 'https://simpra.com/contact-us/',
    claimsAndBilling: 'https://simpra.com/providers/claims/',
    prescribersAndPharmacies:
      'https://simpra.com/providers/for-prescribers-and-pharmacies/',
    compliance: 'https://simpra.com/compliance/',
    quality: 'https://simpra.com/quality/',
    privacyPolicy: 'https://simpra.com/privacy-policy-2/',
    disclaimers: 'https://simpra.com/disclaimers/',
    languageAssistance: 'https://simpra.com/contact-us/',
    reCaptchaKey: '6LdqrzYbAAAAAKUg0ViBe5aU6Cj55T2v055VcP6A',
  },
  {
    name: 'AAH Provider Portal Admin',
    site: 'providerportaladmin.allyalign.com',
    rootSite: 'providerportaladmin.allyalign.com',
    logoUrl: '/images/AllyAlignHealth.svg',
    logoUrlPng: '/images/AllyAlignHealth.png',
    faviconUrl: '',
    theme: '',
    cmsIdNumber: '',
    contactEmail: 'UMInquiryRequest@allyalign.com',
    permissions: [
      'H6832',
      'H1277',
      'H9917',
      'H3274',
      'H8797',
      'H3419',
      'PHP',
      'H6345',
      'H1372',
      'H4172',
      'H2185',
      'H4091',
      'H3467',
      'H6959',
    ],
    auth0: {
      domain: 'providerportaladmin.us.auth0.com',
      clientId: 'h5mb9fLAaOq3MnxOoTIeCpQvql28a41m',
    },
    contact: [
      {
        state: '',
        phones: [
          {
            section: '',
            phone: '',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments: '#',
    exceptionsAndAppeals: '#',
    claimsAndBilling: '#',
    prescribersAndPharmacies: '#',
    compliance: '#',
    quality: '#',
    privacyPolicy: '#',
    disclaimers: '#',
    languageAssistance: '#',
    reCaptchaKey: '6LefsDYbAAAAAOs7SnFRjeJNLQdTw-sFEPtTL6Oq',
  },
];

export const getCurrentPlan = (): IPlan => {
  const currentUrl = window.location.host;
  // QA
  if (currentUrl.includes('provider-portal-dev-ui.azurewebsites.net')) {
    return planData.find((item) => item.name === 'QA Site') || planData[1];
  }
  // LocalHost
  else if (
    currentUrl.includes('localhost') &&
    process.env.REACT_APP_DEV_ROUTE !== 'admin'
  ) {
    return (
      planData.find((item) => item.name === 'Local Host Portal') || planData[1]
    );
  }

  // QA ADMIN
  else if (currentUrl.includes('provider-portal-dev-admin.tutelarhealth.io')) {
    return (
      planData.find((item) => item.name === 'QA Admin Site') || planData[1]
    );
  }

  // Staging ADMIN
  else if (
    currentUrl.includes('provider-portal-staging-admin.tutelarhealth.io')
  ) {
    return (
      planData.find((item) => item.name === 'Staging Admin Site') || planData[1]
    );
  }

  // LocalHost ADMIN
  else if (
    currentUrl.includes('localhost') &&
    process.env.REACT_APP_DEV_ROUTE === 'admin'
  ) {
    return (
      planData.find((item) => item.name === 'Local Host Admin Site') ||
      planData[1]
    );
  }

  // Stage
  else if (
    currentUrl.includes('provider-portal-staging-ui.azurewebsites.net')
  ) {
    return planData.find((item) => item.name === 'Staging Site') || planData[1];
  } else if (
    currentUrl.includes('providerportaladmin.allyalign.com') &&
    planData.length
  ) {
    const adminPortal = 'providerportaladmin.allyalign.com';
    return planData.find((item) => item.site === adminPortal) || planData[1];
  } else {
    // const siteDot: any = currentUrl.split('.');
    // const finalUrl: any = `${siteDot[1]}.com`;
    return planData.find((item) => item.site === currentUrl) || planData[1];
  }
  // return planData[1];
};

export const convertHCodesToPlan = (hCode: string) => {
  const planState: any = {
    H3291: {
      plan: 'PHP',
      state: 'GA',
      longState: 'Georgia',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=944cb286-85b6-4e62-af16-d396f56913b1&bu=4597633',
    },
    H6345: {
      plan: 'PHPC',
      state: 'SC',
      longState: 'Carolinas',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=313afed6-6e5b-452e-ab16-200b6eda5bb2&bu=4597633',
    },
    H1372: {
      plan: 'ARA',
      state: 'OR',
      longState: 'Oregon',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4a4f9299-0439-4966-b33c-6aa8e7bff66f&bu=4597633',
    },
    H4172: {
      plan: 'NHC',
      state: 'MO/TN/SC',
      longState: 'Missouri/Tennessee/South Carolina',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=58c36240-3385-4649-ba88-e68beb018de4&bu=4597633',
    },
    H2185: {
      plan: 'LWA',
      state: 'VA',
      longState: 'Virginia',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=48b5bbd6-cdb6-4e30-aec5-2f68b6402186&bu=4597633',
    },
    H4091: {
      plan: 'SIMPRA',
      state: 'AL',
      longState: 'Alabama',
      url: '',
    },
    H3467: {
      plan: 'ProCare',
      state: 'TX',
      longState: 'Texas',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=19dc00ec-5ef7-4804-83c3-f745a9ec7dd7&bu=4597633',
    },
    H6959: {
      plan: 'KeyCare',
      state: 'MD',
      longState: 'Maryland',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=058a35bf-5259-4a8a-98bb-3e54a72f3b92&bu=4597633',
    },
    H6832: {
      plan: 'ASCMI',
      state: 'MI',
      longState: 'Michigan',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=408edacd-90d6-4890-b35e-a7a0272798af&bu=4597633',
    },
    H1277: {
      plan: 'ASCVA/ASCMA',
      state: 'VA',
      longState: 'Virginia / Massachusetts',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=901d6586-c404-45f0-a334-b20328343434&bu=4597633',
    },
    H9917: {
      plan: 'ASCFL',
      state: 'FL',
      longState: 'Florida',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=66d9df9a-a31f-4d24-a7b8-706ee354923b&bu=4597633',
    },
    H3274: {
      plan: 'ASCCA',
      state: 'CA',
      longState: 'California',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=5821249c-5a00-4866-98f1-4e2ec498ada6&bu=4597633',
    },
    H8797: {
      plan: 'PEROH',
      state: 'OH',
      longState: 'Ohio',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=9c264686-2827-4793-a563-4911bb502142&bu=4597633',
    },
    H3419: {
      plan: 'PERCO',
      state: 'CO',
      longState: 'Colorado',
      url: 'https://home-c32.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=2c8ecb85-9236-45dc-b3ef-196fd5a0d273&bu=4597633',
    },
  };

  return planState[hCode];
};

export default planData;

import axios from "axios";
import { appConfig } from "../config"
import { APP_KEY } from "../constants/app-key";

export const getToken = async (email: string) => {
  try {
    const url = `${appConfig.rootUrl}/auth`;
    const params = {
      email: email,
      appKey: APP_KEY
    };
    const response = await axios.post(url, params);
    return response.data.token;
  } catch (e: any) {
    throw e?.response?.data?.message || e.message;
  }
}
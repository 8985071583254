
import { IOutPatientServices } from "../interfaces/out-patient-services";
import { IStandardForm } from "../interfaces/standard-form";

export const outPatientServicesArray: IOutPatientServices[] = [
  {
    type: 'PT',
    totalQty: null,
    timesWeek: null,
    duration: null,
  },
  {
    type: 'OT',
    totalQty: null,
    timesWeek: null,
    duration: null,
  },
  {
    type: 'ST',
    totalQty: null,
    timesWeek: null,
    duration: null,
  },
  {
    type: 'Other',
    totalQty: null,
    timesWeek: null,
    duration: null,
  },
]

export const InitialValuesStandardForm: IStandardForm = {
  plan: '',
  authorizationNumber: '',
  requestId: '',
  authType: null,
  formType: '',
  clinicalOrTherapyDocUpdated: '',
  orderOrClinicalNoteIncluded: '',
  requestorsName: '',
  requestorsPhoneNumber: '',
  requestorsFaxNumber: '',
  signatureName: '',
  signatureDate: new Date(),
  effectiveDate: new Date(),
  signatureSource: '',
  memberFirstName: '',
  memberMiddleName: '',
  memberLastName: '',
  memberDateOfBirth: null,
  memberId: '',
  memberLivingFacility: '',
  referringProvider: '',
  referringProviderType: '',
  facilityOrProviderName: '',
  admissionOrServiceDate: null,
  npiPinNumber: '',
  providerPhoneNumber: '',
  providerFaxNumber: '',
  partAServices: '',
  daysRequested: null,
  expectedLosDays: null,
  partBServices: '',
  rehabPotential: '',
  significantImprovementMade: '',
  descriptionOrCptCode: '',
  outPatientServicesArray: outPatientServicesArray,
  partBServicesNotes: '',
  transferLocationName: '',
  transferLocationPhone: '',
  transferLocationAddress: '',
  expectedDischargeDate: null,
  appointmentNeeds: '',
  attachmentNames: [],
  attachmentsCount: 0,
  pdf: '',
  files: [],
  isEligible: null,
};
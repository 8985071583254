import { FC, useEffect, useState, useCallback } from 'react';
import {
  useMainProvider,
  IProfile,
  profileInitialValues,
} from '../../providers/MainProvider';
import { useGetNpiRegistry } from '../../services/ServiceHook';
// import MaterialTable from 'material-table';
import useStyles from '../MemberDetailsComponent/MemberDetailsStyle';
import { AttachmentsSidebar } from '..';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';

const MemberDetailsSampleComponent: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [memberDetails, setMemberDetails] = useState<any>({});
  const [globalState, setGlobalState] = useMainProvider();
  const [getNpiRegistry] = useGetNpiRegistry();
  const [localProfile, setLocalProfile] =
    useState<IProfile>(profileInitialValues);
  const [isLoading, setIsLoading] = useState(true);
  const [primaryCarePhysician, setPrimaryCarePhysician] = useState('');
  const [requestingProvider, setRequestingProvider] = useState('');
  const [facilityProvider, setFacilityProvider] = useState('');
  const [localRole, setLocalRole] = useState('');
  const [notes, setNotes] = useState<any>([]);
  const [isSubmitDecisionDisabled, setIsSubmitDecisionDisabled] =
    useState(true);
  const [notesColumns, setNotesColumns] = useState<any>([
    {
      name: 'subject',
      header: 'Subject',
    },
    {
      name: 'createdDate',
      header: 'Created Date',
    },
    {
      name: 'note',
      header: 'Notes',
    },
  ]);
  const [serviceRequest, setServiceRequest] = useState<any>([]);

  const serviceRequestFilters = [
    {
      name: 'cptCode',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'description',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'caseServiceFromDate',
      operator: 'before',
      type: 'date',
      value: '',
    },
    {
      name: 'caseServiceToDate',
      operator: 'before',
      type: 'date',
      value: '',
    },
    {
      name: 'serviceAuthorizedQuantity',
      operator: 'gte',
      type: 'number',
      value: 0,
    },
    {
      name: 'serviceRequestedQuantity',
      operator: 'gte',
      type: 'number',
      value: 0,
    },
    {
      name: 'emailLastSubmitted',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'serviceAuthStatus',
      operator: 'eq',
      type: 'select',
      value: null,
    },
  ];

  const notesFilters = [
    {
      name: 'subject',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'createdDate',
      operator: 'eq',
      type: 'date',
      value: '',
    },
  ];

  const serviceRequestColumns = [
    {
      name: 'cptCode',
      header: 'CPT Code',
      defaultFlex: 1,
    },
    {
      name: 'description',
      header: 'Description',
      defaultFlex: 1,
    },
    {
      name: 'caseServiceFromDate',
      header: 'Service From',
      defaultFlex: 1,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MM-DD-YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Service From',
        };
      },
      render: (value: any) => {
        return value.data.caseServiceFrom
          ? moment(value.data.caseServiceFrom).format('MM-DD-YYYY')
          : null;
      },
    },
    {
      name: 'caseServiceToDate',
      header: 'Service To',
      defaultFlex: 1,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MM-DD-YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Service To',
        };
      },
      render: (value: any) => {
        return value.data.caseServiceTo
          ? moment(value.data.caseServiceTo).format('MM-DD-YYYY')
          : null;
      },
    },
    {
      name: 'serviceAuthorizedQuantity',
      header: 'Authorized Quantity',
      defaultFlex: 1,
    },
    {
      name: 'serviceRequestedQuantity',
      header: 'Requested Quantity',
      defaultFlex: 1,
    },
    {
      name: 'emailLastSubmitted',
      header: 'MD Reviewer',
      defaultFlex: 1,
    },
    {
      name: 'serviceAuthStatus',
      header: 'Status',
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: [
          {
            id: 'APPROVED',
            label: 'APPROVED',
          },
          {
            id: 'DONE',
            label: 'DONE',
          },
          {
            id: 'NEW',
            label: 'NEW',
          },
          {
            id: 'DENIED-ADMINISTRATIVE',
            label: 'DENIED-ADMINISTRATIVE',
          },
          {
            id: 'DENIED - MEDICAL NECESSITY',
            label: 'DENIED - MEDICAL NECESSITY',
          },
          {
            id: 'PENDING PEER REVIEW',
            label: 'PENDING PEER REVIEW',
          },
        ],
      },
      defaultFlex: 1,
      render: (value: any) => {
        return (
          <div
            className={clsx({
              [classes.serviceApproved]: value.value === 'APPROVED',
              [classes.servicePended]: value.value === 'PENDING PEER REVIEW',
              [classes.done]: value.value === 'DONE',
              [classes.new]:
                value.value === 'NEW' ||
                value.value.serviceAuthStatus === 'SUBMITTED',
              [classes.serviceDenied]:
                value.value === 'DENIED-ADMINISTRATIVE' ||
                value.value === 'DENIED' ||
                value.value === 'DENIED - MEDICAL NECESSITY',
            })}
          >
            {value.value}
          </div>
        );
      },
    },
  ];

  const data = {
    serviceFromDate: '03/16/2022, 00:00:00',
    serviceToDate: '04/16/2022, 00:00:00',
    memberFirstName: 'Tom',
    memberMiddleName: '',
    memberLastName: 'Cruz',
    submitted: '02/17/2022, 17:10:04',
    memberDateOfBirth: '01/01/1911',
    planStartDate: '',
    planEndDate: '',
    coverageDate: ' - ',
    authNumber: '123456789012345678',
    authType: 'STANDARD PROCESSING ',
    authStatus: 'APPROVED',
    authorizatedUnits: 1,
    requestedUnits: 1,
    memberName: 'CAREY,JUDITH',
    memberId: '12345678',
    npi: '1316054810',
    authPCP: '1104910884',
    companyId: 'H6832',
    requestingProvider: '1316054810',
    facilityProvider: '1235223405',
    serviceRequests: [
      {
        sequence: 1,
        cptCode: 'G0299',
        description: 'HHS/HOSPICE OF RN EA 15 MIN',
        serviceAuthStatus: 'APPROVED',
        caseServiceToDate: '04/16/2022, 00:00:00',
        caseServiceFromDate: '03/16/2022, 00:00:00',
        isPended: null,
        serviceRequestedQuantity: 0,
        serviceAuthorizedQuantity: 1,
        emailLastSubmitted: 'email@demo.com',
      },
    ],
    diagnoses: [],
    notes: [
      {
        createdDate: '03/17/2022, 13:59:32',
        lastUpdate: '03/17/2022, 13:59:32',
        note: 'Method of request: EMAIL\nRequestor?s Name/Company: HOMETOWN HOME HEALTH CARE INC\nRequestor?s Phone: 810-245-3300\nRequestor?s Fax: N.A\nDate Request received: 02/16/2022\nTime Request received: 121244\nExpedited or Standard Review: STANDARD\n\nProcedure/Service Requested: G0299\n \nDIANNE B.',
        subject: 'INITIAL REQUEST',
      },
      {
        createdDate: '02/21/2022, 23:01:18',
        lastUpdate: '02/21/2022, 23:01:18',
        note: '\r\ndsdsdfsdf',
        subject: 'INITIAL CLINICIAL INFORMATION',
      },
      {
        createdDate: '02/21/2022, 23:01:18',
        lastUpdate: '02/21/2022, 23:01:18',
        note: '\r\nPatrick said so',
        subject: 'MEDICAL DIRECTOR REVIEW NOTE',
      },
      {
        createdDate: '03/17/2022, 13:59:32',
        lastUpdate: '03/17/2022, 13:59:32',
        note: 'dsdsdfsdf',
        subject: 'INITIAL CLINICIAL INFORMATION',
      },
      {
        createdDate: '03/17/2022, 13:59:32',
        lastUpdate: '03/17/2022, 13:59:32',
        note: 'Patrick said so',
        subject: 'MEDICAL DIRECTOR REVIEW NOTE',
      },
    ],
    documents: [
      {
        popurl:
          'https://onbaseweb01.dev.allyalign.net/appnet/docpop/docpop.aspx?docid=451899&chksum=fca8ccd2696b95a7b3c38bdb681f9fa62e0f5f913d1957cf6d02b05dbb513dcd',
        itemNumber: '451899',
        description: null,
        documentType: 'UM - Request',
        documentName:
          'UM - Request - Auth #: 22021627701183400010 - Member ID:00020769 - Received: 2/17/2022 10:10:04 PM - Completed:  - Type:Phone',
        dateStored: '2022-02-17T23:10:15.493Z',
        authorizationNumber: '22021627701183400010',
        memberId: '00020769',
        memberFirstName: 'JUDITH',
        memberLastName: 'CAREY',
        plan: null,
      },
    ],
  };

  window.moment = moment;

  // Get Token
  useEffect(() => {
    const memberDetailsResponse = data;
    if (memberDetailsResponse && memberDetailsResponse.authNumber) {
      setMemberDetails(memberDetailsResponse);
      if (memberDetailsResponse.authStatus === 'UNDER MEDICAL REVIEW') {
        const statuses = memberDetailsResponse.serviceRequests.map(
          (item: any) => item.serviceAuthStatus
        );
        const found = statuses.filter(
          (item: any) => item === 'NEW' || item === 'PENDING PEER REVIEW'
        );
        if (found.length) {
          setIsSubmitDecisionDisabled(false);
        }
      }
      setServiceRequest(memberDetailsResponse.serviceRequests);
      if (memberDetailsResponse.notes && memberDetailsResponse.notes.length) {
        let tempNotes = memberDetailsResponse.notes.map((item: any) => {
          return {
            ...item,
            createdDate: moment(item.createdDate).format('MM/DD/YYYY'),
          };
        });
        setNotes(tempNotes);
      }

      setGlobalState({
        ...globalState,
        memberDetails: memberDetailsResponse,
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      if (memberDetails) {
        const response = await getNpiRegistry(memberDetails.authPCP);
        if (response && response.status === 200) {
          setPrimaryCarePhysician(response.data.name);
        }
      }
    })();
  }, [memberDetails.authPCP]);

  useEffect(() => {
    (async () => {
      if (memberDetails) {
        const response = await getNpiRegistry(memberDetails.requestingProvider);
        if (response && response.status === 200) {
          setRequestingProvider(response.data.name);
        }
      }
    })();
  }, [memberDetails.requestingProvider]);

  useEffect(() => {
    (async () => {
      if (memberDetails) {
        const response = await getNpiRegistry(memberDetails.facilityProvider);
        if (response && response.status === 200) {
          setFacilityProvider(response.data.name);
        }
      }
    })();
  }, [memberDetails.facilityProvider]);

  useEffect(() => {
    if (
      localRole === 'MD' ||
      localRole === 'MD - Restricted' ||
      localRole === 'MD - Case Manager' ||
      localRole === 'MD - Reviewer' ||
      localRole === 'MD - Reviewer Restricted' ||
      localRole === 'Viewer'
    ) {
      setNotesColumns([
        {
          name: 'subject',
          header: 'Subject',
          defaultFlex: 2,
        },
        {
          name: 'createdDate',
          header: 'Created Date',
          defaultFlex: 1,
          filterEditor: DateFilter,
          filterEditorProps: (value: any, index: number) => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              dateFormat: 'MM/DD/YYYY',
              cancelButton: false,
              highlightWeekends: false,
              placeholder: 'Created Date',
            };
          },
        },
        {
          name: 'note',
          header: 'Notes',
          defaultFlex: 2,
        },
      ]);
    } else {
      setNotesColumns([
        {
          name: 'subject',
          header: 'Subject',
          defaultFlex: 1,
        },
        {
          name: 'createdDate',
          header: 'Created Date',
          defaultFlex: 1,
          filterEditor: DateFilter,
          filterEditorProps: (value: any, index: number) => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              dateFormat: 'MM/DD/YYYY',
              cancelButton: false,
              highlightWeekends: false,
              placeholder: 'Created Date',
            };
          },
        },
      ]);
    }
  }, [localRole]);

  // Styles
  const label: any = {
    opacity: '0.5',
    color: '#112233',
    fontSize: '13px',
    letterSpacing: '0',
    lineHeight: '15px',
    display: 'block',
  };

  const content: any = {
    color: '#112233',
    fontSize: '13px',
    lineHeight: '15px',
  };

  const classes = useStyles();

  const gridStyle = { minHeight: 350 };
  const gridNotesStyle = { minHeight: 500 };
  const rowStyle = { cursor: 'pointer' };

  // Once a row is selected
  const onSelectionChange = useCallback(({ selected }) => {
    // history.push(`/dashboard/${selected}`);
  }, []);

  if (!memberDetails) {
    return <div className="container mx-auto px-4">''</div>;
  }

  const goToAdditionalDays = () => {
    history.push('/authorization/additional-days');
  };

  const goToSubmitDecision = () => {
    history.push(`${location.pathname}/submit-decision`);
  };

  // Row Details notes
  const renderRowDetailsNotes = (props: any) => {
    return (
      <div style={{ padding: 20 }}>
        <div className="mb-2">
          <span className="font-bold">Subject:</span>{' '}
          <span>{props.data.subject}</span>
        </div>
        <div className="mb-2">
          <span className="font-bold">Created:</span>{' '}
          <span>{props.data.createdDate}</span>
        </div>
        <div className="mb-2">
          <span className="font-bold">Last Update:</span>{' '}
          <span>{props.data.lastUpdate}</span>
        </div>
        {(localRole === 'MD' ||
          localRole === 'MD - Restricted' ||
          localRole === 'MD - Case Manager' ||
          localRole === 'MD - Reviewer' ||
          localRole === 'MD - Reviewer Restricted' ||
          localRole === 'Viewer') && (
          <div className="mb-2">
            <span className="font-bold w-full block">Note:</span>{' '}
            <span>{props.data.note}</span>
          </div>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full px-4 md:px-8 grid lg:grid-cols-4 lg:gap-4">
      <section className="lg:col-span-3 lg:border-r lg:pr-4">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-10">
          <h2 className="xl:col-span-3">
            {memberDetails.memberFirstName} {memberDetails.memberLastName}
          </h2>
          {localProfile.role !== 'MD - Reviewer' &&
            localProfile.role !== 'MD - Reviewer Restricted' && (
              <button
                type="button"
                className="btn btn-blue"
                onClick={goToAdditionalDays}
              >
                Request Additional Days
              </button>
            )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          <div>
            <span style={label}>ID</span>
            <span style={content}>{memberDetails.authNumber}</span>
          </div>
          <div>
            <span style={label}>Created</span>
            <span style={content}>{memberDetails.createdDate}</span>
          </div>
          <div>
            <span style={label}>Last Updated</span>
            <span style={content}>{memberDetails.lastUpdate}</span>
          </div>
          <div className="flex lg:justify-end">
            <div>
              <span style={label} className="mb-2">
                Status
              </span>
              <span
                className={clsx(
                  {
                    [classes.approved]: memberDetails.authStatus === 'APPROVED',
                    [classes.partiallyApproved]:
                      memberDetails.authStatus === 'PARTIALLY APPROVED',
                    [classes.deferred]: memberDetails.authStatus === 'DEFERRED',
                    [classes.deniedAdministrative]:
                      memberDetails.authStatus === 'DENIED--ADMINISTRATIVE',
                    [classes.deniedMedicalNecessity]:
                      memberDetails.authStatus ===
                        'DENIED – MEDICAL NECESSITY' ||
                      memberDetails.authStatus === 'DENIED',
                    [classes.outOfNetwork]:
                      memberDetails.authStatus === 'OUT OF NETWORK INPATIENT',
                    [classes.pended]: memberDetails.authStatus === 'PENDED',
                    [classes.cancelled]:
                      memberDetails.authStatus === 'CANCELLED',
                    [classes.waiting]:
                      memberDetails.authStatus ===
                        `WAITING ON ADDT'L DOCUMENTS` ||
                      memberDetails.authStatus === `UNDER MEDICAL REVIEW`,
                  },
                  'custom-chip green block'
                )}
              >
                {memberDetails.authStatus}
              </span>
            </div>
          </div>
        </div>

        <h3 className="mt-8">Member Details</h3>
        <div className="grid md:grid-cols-2 gap-8 mt-4">
          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Member ID</th>
                <td>{memberDetails.memberId}</td>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <td>{memberDetails.memberDateOfBirth}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>Male</td>
              </tr>
              <tr>
                <th>Eligibility Status</th>
                <td>
                  {memberDetails.isEligible ? 'Is eligible' : 'Is not eligible'}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Primary Care Physician</th>
                <td>Bruce Banner (1234567891)</td>
              </tr>
              <tr>
                <th>Plan</th>
                <td>{memberDetails.companyId}</td>
              </tr>
              <tr>
                <th>Plan / Coverage Date</th>
                <td>{memberDetails.coverageDate}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="mt-8">Authorization Details</h3>
        <div className="grid md:grid-cols-2 gap-8 mt-4">
          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Authorization Type</th>
                <td>{memberDetails.authType}</td>
              </tr>
              <tr>
                <th>Diagnosis</th>
                <td>R1092</td>
              </tr>
              {/* <tr>
                <th>Procedure</th>
                <td>{memberDetails.procedureDescription}</td>
              </tr> */}
              <tr>
                <th>Service from</th>
                <td>{memberDetails.serviceFromDate}</td>
              </tr>
              <tr>
                <th>Service to</th>
                <td>{memberDetails.serviceToDate}</td>
              </tr>
            </tbody>
          </table>

          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Requesting Provider</th>
                <td>America Chavez (1234567891)</td>
              </tr>
              <tr>
                <th>Facility Provider</th>
                <td>
                  {facilityProvider} ({memberDetails.facilityProvider})
                </td>
              </tr>
              <tr>
                <th>Requested Units</th>
                <td>{memberDetails.requestedUnits}</td>
              </tr>
              <tr>
                <th>Authorized Units</th>
                <td>{memberDetails.authorizatedUnits}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-10">
            <h3 className="pb-4 xl:col-span-3">Service Request</h3>
            <button
              id="submitDecisionButton"
              className={clsx(
                {
                  'btn-disabled': isSubmitDecisionDisabled,
                  'btn block btn-blue': !isSubmitDecisionDisabled,
                },
                'btn flex  mb-4 w-full lg:w-auto justify-center'
              )}
              onClick={goToSubmitDecision}
              disabled={isSubmitDecisionDisabled}
            >
              Submit Decision
            </button>
          </div>

          {!serviceRequest.length && <div>There is no data to show</div>}
          {serviceRequest.length &&
            serviceRequest.map((item: any, key: number) => {
              return (
                <Accordion className="mb-4 showMobile" key={key}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="service-request-header"
                  >
                    <div className="flex justify-between w-full">
                      <div className="font-bold">
                        <span className="text-gray-500">{item.cptCode}</span>{' '}
                        {item.description}
                      </div>
                      <div className="flex">
                        <div
                          className={clsx({
                            [classes.approved]:
                              item.serviceAuthStatus === 'APPROVED',
                            [classes.done]: item.serviceAuthStatus === 'DONE',
                            [classes.new]: item.serviceAuthStatus === 'NEW',
                            [classes.new]:
                              item.serviceAuthStatus === 'SUBMITTED',
                            [classes.deniedMedicalNecessity]:
                              item.serviceAuthStatus ===
                                'DENIED-ADMINISTRATIVE' ||
                              item.serviceAuthStatus === 'DENIED' ||
                              item.serviceAuthStatus ===
                                'DENIED - MEDICAL NECESSITY',
                          })}
                        >
                          {item.serviceAuthStatus}
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full">
                      <hr className="mb-4" />
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Description: </span>
                        <span className="font-bold">{item.description}</span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">CPT Code: </span>
                        <span className="font-bold">{item.cptCode}</span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Service from: </span>
                        <span className="font-bold">
                          {item.serviceFromDate
                            ? moment(item.serviceFromDate).format('L')
                            : null}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Service to: </span>
                        <span className="font-bold">
                          {item.serviceToDate
                            ? moment(item.serviceToDate).format('L')
                            : null}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">
                          Authorized Quantity:{' '}
                        </span>
                        <span className="font-bold">
                          {item.serviceAuthorizedQuantity}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">
                          Requested Quantity:{' '}
                        </span>
                        <span className="font-bold">
                          {item.serviceRequestedQuantity}
                        </span>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}

          <div className="showDesktop" id="serviceRequestReactDataGrid">
            <ReactDataGrid
              licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
              idProperty="id"
              columns={serviceRequestColumns}
              dataSource={serviceRequest}
              style={gridStyle}
              rowStyle={rowStyle}
              defaultFilterValue={serviceRequestFilters}
              pagination={true}
              // enableSelection={true}
              onSelectionChange={onSelectionChange}
              enableClipboard={true}
            />
          </div>
        </div>

        <div className="py-10">
          <h3 className="pb-4">Authorization History</h3>

          {!notes.length && (
            <Accordion className="mb-4 showMobile">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="flex flex-col w-full">
                  <div>There is no note.</div>
                </div>
              </AccordionSummary>
            </Accordion>
          )}
          {notes.map((item: any, key: number) => {
            return (
              <Accordion className="mb-4 showMobile" key={key}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex flex-col w-full">
                    <div className="font-bold">{item.subject}</div>
                    <div>{item.createdDate ? item.createdDate : null}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    <hr className="mb-4" />
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Subject: </span>
                      <span className="font-bold">{item.subject}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Created date: </span>
                      <span className="font-bold">
                        {item.createdDate
                          ? moment(item.createdDate).format('L')
                          : null}
                      </span>
                    </div>
                    {(localRole === 'MD' ||
                      localRole === 'MD - Restricted' ||
                      localRole === 'MD - Case Manager' ||
                      localRole === 'MD - Reviewer' ||
                      localRole === 'MD - Reviewer Restricted' ||
                      localRole === 'Viewer') && (
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Notes: </span>
                        <span className="font-bold">{item.note}</span>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
          <div className="notesTable showDesktop" id="notesReactDataGrid">
            <ReactDataGrid
              licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
              idProperty="subject"
              columns={notesColumns}
              dataSource={notes}
              style={gridNotesStyle}
              defaultFilterValue={notesFilters}
              pagination={true}
              enableSelection={true}
              onSelectionChange={onSelectionChange}
              enableClipboard={true}
              rowExpandHeight={300}
              renderRowDetails={renderRowDetailsNotes}
            />
          </div>
        </div>
      </section>
      <AttachmentsSidebar member={memberDetails} />
    </div>
  );
};

export default MemberDetailsSampleComponent;

import { FC } from 'react';
import { useMainProvider } from '../../providers/MainProvider';
import { useLogout } from '../../services/ServiceHook';
import Modal from '../Modal';

const ErrorMessage: FC = () => {
  const localLogout = useLogout();
  const [globalState] = useMainProvider();
  return (
    <Modal
      title="We are currently experiencing an issue with this system"
      description="Please contact support and let us know or check back later."
      open={globalState.errorNetwork}
      className="confirmationModal error"
      onClose={() => localLogout()}
    >
      <div className="buttonsContainer flex justify-between">
        <button type="button" onClick={() => localLogout()} className="btn">
          End session
        </button>
      </div>
    </Modal>
  );
};

export default ErrorMessage;

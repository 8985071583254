export const MemberSearchResultInitialValues = {
  memberDateOfBirth: null,
  planStartDate: null,
  planEndDate: null,
  memberFirstName: '',
  memberMiddleName: '',
  memberLastName: '',
  memberId: '',
  companyId: '',
  isEligible: null,
  facilityName: '',
  facilityNpi: '',
  memberBenefitPlan: null,
};

import { FC, useCallback, useEffect, useState } from 'react';
import '@inovua/reactdatagrid-enterprise/index.css';
import moment from 'moment';
import 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IProps } from '../../interfaces/query';
import { Alert } from '@material-ui/lab';
import Cookies from 'js-cookie';
import CcmReferralsSentMobileView from './CcmReferralsSentMobileView';
import { ICcmReferral } from '../../interfaces/ccm-referrals';

export interface IControls {
  label: string;
  value: string;
}

const CcmReferralsSentMobile: FC<IProps> = ({ data }) => {
  const history = useHistory();
  const [paginatedData, setPaginatedData] = useState<ICcmReferral[]>([]);
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const [filterCounter, setFilterCounter] = useState(0);
  const [filterBy, setFilterBy] = useState('');
  const [selectedBirthday, setSelectedBirthday] = React.useState<Date | null>(
    null
  );
  const [selectedDateOfReferral, setSelectedDateOfReferral] =
    React.useState<Date | null>(null);

  const [filteredData, setFilteredData] = useState(data);
  const role = Cookies.get('aah-role');

  const updatePagination = useCallback(
    (tempData: ICcmReferral[]) => {
      setFilterCounter(tempData.length);
      setPage(1);
      setPaginatedData(paginate(tempData, 10, 1));
    },
    [setPaginatedData]
  );

  useEffect(() => {
    if (data && data.length) {
      updatePagination(data);
    }
  }, [data, updatePagination]);

  const handleDateChange = (date: Date | null) => {
    setSelectedBirthday(date);
    const found = data.filter(
      (item) =>
        item.memberDateOfBirth === moment(date).format('MM/DD/yyyy').toString()
    );
    setFilteredData(found && found.length ? found : data);
    updatePagination(found && found.length ? found : data);
  };

  const handleDateOfReferralChange = (date: Date | null) => {
    setSelectedDateOfReferral(date);
    const found = data.filter(
      (item) =>
        item.dateOfReferral === moment(date).format('MM/DD/yyyy').toString()
    );
    setFilteredData(found && found.length ? found : data);
    updatePagination(found && found.length ? found : data);
  };

  const filterByControls = [
    {
      label: 'Member Id',
      value: 'memberId',
    },
    {
      label: 'Member First Name',
      value: 'memberFirstName',
    },
    {
      label: 'Member Last Name',
      value: 'memberLastName',
    },
    {
      label: 'Date of Birth',
      value: 'birthday',
    },
    {
      label: 'Date Of Referral',
      value: 'dateOfReferral',
    },
    {
      label: 'Requester Name',
      value: 'requesterName',
    },
    {
      label: 'Facility Name',
      value: 'facilityName',
    },
    {
      label: 'Outreach Call',
      value: 'outreachCall',
    },
    {
      label: 'Status',
      value: 'status',
    },
  ];

  useEffect(() => {
    // Clear birthday
    if (filterBy !== 'birthday') {
      setSelectedBirthday(null);
    }

    // Clear date of referral
    if (filterBy !== 'dateOfReferral') {
      setSelectedDateOfReferral(null);
    }

    if (
      filterBy &&
      (filterBy === 'birthday' || filterBy === 'dateOfReferral')
    ) {
      setFilterValue('');
    }
  }, [filterBy]);

  const handleChangeFilterBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBy((event.target as HTMLInputElement).value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    const input = event.target.value;
    let found = [...data];
    if (input.length) {
      found = data.filter((item: any) =>
        item[filterBy].toLowerCase().includes(input.toLowerCase())
      );
    } else {
      found = data;
    }
    setFilteredData(found);
    updatePagination(found);
  };

  const handleDetails = (item: ICcmReferral) => {
    history.push(`/ccm-referrals/${item.requestId}`);
  };

  // Pagination
  const paginate = (array: any[], pageSize: number, pageNumber: number) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setPaginatedData(paginate(data, 10, value));
  };

  const clearFilter = () => {
    setFilteredData([]);
    setFilterBy('');
    setSelectedBirthday(null);
    setSelectedDateOfReferral(null);
    setFilterValue('');
    updatePagination([...data]);
  };

  const csvDownload = () => {
    const csvData = filteredData.length ? filteredData : data;
    let headerRow: any[] = [
      'Id',
      'First Name',
      'Last Name',
      'Date of Birth',
      'Primary Diagnosis',
      'Facility Name',
      'Date of Referral',
      'Requester Name',
      'Requester Email',
      'Requester Phone Number',
      'Requester Member Relationship',
      'Reason for Referral',
      'Site Plans',
      'Status',
    ];
    let rows: any[][] = [];
    rows = csvData.map((elem) => [
      elem.id,
      elem.memberFirstName,
      elem.memberLastName,
      elem.memberDateOfBirth,
      elem.primaryDiagnosis,
      elem.facilityName,
      moment(elem.dateOfReferral).format('MM/DD/YYYY'),
      elem.requesterName,
      elem.requesterEmail,
      elem.requesterPhoneNumber,
      elem.requesterMemberRelationship,
      elem.reasonForReferral,
      elem.sitePlans.join(', '),
      elem.status,
    ]);
    rows.unshift(headerRow);
    return rows;
  };

  if (data && data.length) {
    return (
      <CcmReferralsSentMobileView
        paginatedData={paginatedData}
        role={role}
        csvDownload={csvDownload}
        filterBy={filterBy}
        handleChangeFilterBy={handleChangeFilterBy}
        filterByControls={filterByControls}
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
        selectedBirthday={selectedBirthday}
        handleDateChange={handleDateChange}
        selectedDateOfReferral={selectedDateOfReferral}
        handleDateOfReferralChange={handleDateOfReferralChange}
        clearFilter={clearFilter}
        handleDetails={handleDetails}
        page={page}
        filterCounter={filterCounter}
        handlePagination={handlePagination}
      />
    );
  }

  return (
    <Alert severity="warning" className="mt-8">
      No data to show!
    </Alert>
  );
};

export default CcmReferralsSentMobile;

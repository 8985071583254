import { FC } from 'react';
import { IStandardForm } from '../../interfaces/standard-form';
import { FieldInputProps, FormikErrors } from 'formik';
import 'date-fns';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IProps {
  formValues: IStandardForm;
  hasNoPermissions: boolean;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IStandardForm>>;
}

export const DischargePlanningRefactor: FC<IProps> = ({
  formValues,
  hasNoPermissions,
  setFieldValue,
  getFieldProps,
}) => {
  return (
    <section id="dischargePlanningSection" className="pt-6">
      <h2 className="py-8 border-bottom-blue">
        Discharge Planning Out Of Nursing Home / Assisted Or Independent Living
      </h2>
      <div className="grid xl:grid-cols-2 xl:gap-6 mt-8 xl:pb-8">
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">Where are they going?</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="transferLocationName">
              Where are they going?
            </InputLabel>
            <OutlinedInput
              id="transferLocationName"
              label="Name"
              {...getFieldProps('transferLocationName')}
            />
          </FormControl>
        </div>
        <div className="flex flex-col mb-4 xl:mb-0 width-date-100">
          <label className="label-top mb-2">
            Admission Date/ Date of Service
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="expectedDischargeDate"
              value={formValues.expectedDischargeDate}
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('expectedDischargeDate', date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              disabled={hasNoPermissions}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">Address</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="component-outlined">Address</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="transferLocationAddress"
              {...getFieldProps('transferLocationAddress')}
            />
          </FormControl>
        </div>
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">Phone number</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="component-outlined">Phone number</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="transferLocationPhone"
              {...getFieldProps('transferLocationPhone')}
            />
          </FormControl>
        </div>
      </div>
      <div className="grid grid-cols-1 pb-8">
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">
            Home Health / DME / Specialist Follow-up Appointment Needs?:
          </label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="component-outlined">
              Home Health / DME / Specialist Follow-up Appointment Needs?:
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="appointmentNeeds"
              {...getFieldProps('appointmentNeeds')}
            />
          </FormControl>
        </div>
      </div>
    </section>
  );
};

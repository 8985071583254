import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import { appConfig } from '../config';
import { useMainProvider } from '../providers/MainProvider';
import { getCurrentPlan } from '../planData';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import Cookies from 'js-cookie';

// DRAFT
export const useGetDashboardDraft = () => {
  const { user } = useAuth0();
  // Get plans
  let foundPlanData = getCurrentPlan();

  const getDraft = useCallback(async () => {
    try {
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const id = user.sub.split('|')[1];
      const url = `${appConfig.rootUrl}/preauth/draft/get/${id}`;
      const data = {
        sitePlans: foundPlanData.permissions,
      };
      const response = await axios.post(url, data, config);
      console.log('response in draft', response);
      if (response.status === 200) {
        return response.data.drafts;
      } else {
        console.log('there was en error on retrieving draft data', response);
        return [];
      }
    } catch (err) {
      throw err;
    }
  }, [foundPlanData.permissions, user.sub]);
  return [getDraft];
};

// TODO: Remove this
export const useGetDraftDetails = () => {
  const { user, isAuthenticated } = useAuth0();
  console.log('user', user)
  let params = useParams<{ id: string }>();

  // Get plans
  let foundPlanData = getCurrentPlan();

  const getDraftDetail = async () => {
    if (isAuthenticated) {
      try {
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const id = user.sub.split('|')[1];
        const url = `${appConfig.rootUrl}/preauth/draft/get/${id}/${params.id}`;
        const data = {
          sitePlans: foundPlanData.permissions,
        };
        console.log('before response', url, data, config);
        const response = await axios.post(url, data, config);
        console.log('after draft details response', response);
        if (response.status === 200) {
          return response.data.drafts;
        } else {
          console.log('there was en error on retrieving draft data', response);
          return [];
        }
      } catch (err) {
        throw err;
      }
    }
  };
  return [getDraftDetail];
};

export const usePostDraft = () => {
  const { user, isAuthenticated } = useAuth0();
  const foundPlanData = getCurrentPlan();

  const postDraft = async (data: any) => {
    console.log('DATA TO SAVE IN DRAFT', data);
    if (isAuthenticated) {
      try {
        let tempToken = sessionStorage.getItem('token');

        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const id = user.sub.split('|')[1];

        data.id = id;
        data.plans = foundPlanData.permissions;
        data.data.sitePlans = foundPlanData.permissions;

        const url = `${appConfig.rootUrl}/preauth/draft`;
        // TODO: Apply this solution to all components
        const response: any = await axios
          .post(url, data, config)
          .catch((error) => {
            console.log('error', error.response);
            return error.response;
          });
        return response.data;
      } catch (err) {
        console.log('error on service', err);
        throw err;
      }
    }
  };
  return [postDraft];
};

export const usePostDraftAttachments = () => {
  const { user } = useAuth0();
  const foundPlanData = getCurrentPlan();

  const postDraftAttachments = async (data: any) => {
    try {
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const id = user.sub.split('|')[1];
      data.id = id;
      data.sitePlans = foundPlanData.permissions;
      const url = `${appConfig.rootUrl}/preauth/attachments/draft`;
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        return response.data;
      } else {
        console.log('there was en error on saving draft data', response);
        return [];
      }
    } catch (err) {
      throw err;
    }
  };
  return [postDraftAttachments];
};

export const useDeleteDraft = () => {
  const { user, isAuthenticated } = useAuth0();
  const foundPlanData = getCurrentPlan();

  const deleteDraft = async (requestId: string) => {
    if (isAuthenticated) {
      try {
        if (requestId) {
          let tempToken = sessionStorage.getItem('token');

          const config: AxiosRequestConfig = {
            headers: {
              Authorization: `Bearer ${tempToken}`,
            },
          };
          const id = user.sub.split('|')[1];

          const url = `${appConfig.rootUrl}/preauth/draft/delete/${id}/${requestId}`;
          const data = {
            sitePlans: foundPlanData.permissions,
          };
          const response = await axios.post(url, data, config);
          if (response.status === 200) {
            return response.data;
          } else {
            console.log('there was en error on deleting draft data', response);
            return response;
          }
        }
      } catch (err) {
        console.log('err on hook', err);
        throw err;
      }
    }
  };
  return [deleteDraft];
};

export const useGetDashboardDataMember = () => {
  const { user } = useAuth0();
  // Get plans
  const foundPlanData = getCurrentPlan();
  const getDashboardDataMember = async (memberId: string) => {
    try {
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const data = {
        email: user.email,
        memberId,
        plans: foundPlanData.permissions,
      };
      const url = `${appConfig.rootUrl}/dashboard/authorizations/member`;
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.log('there was en error on retrieving data', response);
        return [];
      }
    } catch (err) {
      throw err;
    }
  };
  return [getDashboardDataMember];
};

export const useGetMemberDetails = () => {
  const { user } = useAuth0();
  let params = useParams<{ id: string }>();
  const foundPlanData = getCurrentPlan();
  const getMemberDetails = async () => {
    try {
      const url = `${appConfig.rootUrl}/dashboard/authorizations/details`;
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const memberDetailsPayload = {
        email: user.email,
        authNumber: params.id,
        plans: foundPlanData.permissions,
      };
      const res = await axios.post(url, memberDetailsPayload, config);
      return res.data.data;
    } catch (error: any) {
      console.log('ERROR in Hook', error.response);
      throw error;
    }
  };
  return [getMemberDetails];
};

// Search by Member Information
export const useSearchMember = () => {
  const { user } = useAuth0();

  const getSearchMember = async (data: any) => {
    try {
      const url = `${appConfig.rootUrl}/search`;
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      data.email = user.email;
      const res = await axios.post(url, data, config);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };
  return [getSearchMember];
};

export const useGetNpiRegistry = () => {
  const { isAuthenticated } = useAuth0();

  const getNpiRegistry = async (npi: string) => {
    let tempToken = sessionStorage.getItem('token');
    if (isAuthenticated && tempToken && npi) {
      try {
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const url = `${appConfig.rootUrl}/npi`;
        const response = await axios.post(url, { npi }, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };
  return [getNpiRegistry];
};

// Authorizationn
export const useAuthorizationForm = () => {
  const { user } = useAuth0();

  const postAuthorizationForm = async (data: any) => {
    try {
      const url = `${appConfig.rootUrl}/preauth/submit`;
      const id = user.sub.split('|')[1];
      data.id = id;
      let tempToken = sessionStorage.getItem('token');

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const res = await axios.post(url, data, config).catch((error) => {
        if (error.response) {
          console.log('error.response');
          // Request made and server responded
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('error.request', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('error.message', error.message);
        }
        return error.response;
      });
      return res.data;
    } catch (error: any) {
      console.log('error on service', error);
      // return error.response;
      throw error;
    }
  };
  return [postAuthorizationForm];
};

// Attachements
export const usePostAttachements = () => {
  const { user } = useAuth0();

  const postAttachments = async (data: any) => {
    try {
      const url = `${appConfig.rootUrl}/preauth/attachments`;
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const id = user.sub.split('|')[1];
      data.id = id;
      const res = await axios.post(url, data, config);
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  return [postAttachments];
};

// Send Email
export const useSendEmail = () => {
  const { isAuthenticated } = useAuth0();
  
  const postSendEmail = async (data: any) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/email/send`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.post(url, data, config);
        return res;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return [postSendEmail];
};

export const useGetPortalActivity = () => {
  const { isAuthenticated } = useAuth0();

  const getPortalActivity = async () => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/user/logs/portal`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.get(url, config);
        return res.data;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return [getPortalActivity];
};

// Admin Registrations Detail
// Grabs a specific user's information.
export const useGetUser = () => {
  interface iData {
    id: string | null;
    userEmail: string | null;
  }

  const getUser = async (data: iData) => {
    try {
      const url = `${appConfig.rootUrl}/user/get`;
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const res = await axios.post(url, data, config);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };
  return [getUser];
};

// Admin Registrations Detail
// Grabs Roles
export const useGetRoles = () => {
  const getRoles = async () => {
    try {
      const url = `${appConfig.rootUrl}/information/roles`;
      let tempToken = sessionStorage.getItem('token');
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const res = await axios.get(url, config);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };
  return [getRoles];
};

// Admin Registrations Detail
// Grabs Plans
export const useGetPlans = () => {
  const { isAuthenticated } = useAuth0();

  const getPlans = async () => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/information/plans`;
        let tempToken = sessionStorage.getItem('token');

        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.get(url, config);
        return res.data;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return [getPlans];
};

export const useUpdateUser = () => {
  const { isAuthenticated } = useAuth0();
  const postUpdateUser = async (data: any) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/user/update`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.post(url, data, config);
        return res;
      } catch (error) {
        console.log('error on update user', error);
      }
    }
  };
  return [postUpdateUser];
};

export const useUpdatePassword = () => {
  const { isAuthenticated } = useAuth0();

  const postUpdatePassword = async (data: any) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/user/password`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.post(url, data, config);
        return res;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return [postUpdatePassword];
};

export const useActivateUser = () => {
  const { isAuthenticated } = useAuth0();

  const postActivateUser = async (data: any) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/user/activation`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.post(url, data, config);
        return res;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return [postActivateUser];
};

export const useDeleteUser = () => {
  const { isAuthenticated } = useAuth0();

  const deleteUser = async (id: string) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/user/delete/${id}`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.delete(url, config);
        return res;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return [deleteUser];
};

// export const useRegisterUser = () => {
//   const postRegisterUser = async (data: any) => {
//     try {
//       const url = `${appConfig.rootUrl}/user/register`;
//       const res = await axios.post(url, data).catch((error) => {
//         console.log('error to send', error.response);
//         return error.response;
//       });
//       return res;
//     } catch (error) {
//       console.log('error in hook', error);
//     }
//   };
//   return [postRegisterUser];
// };

export const useUserLogs = () => {
  const { isAuthenticated } = useAuth0();

  const userLogs = async (id: string) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/user/logs`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };

        const res = await axios.post(url, { id }, config);
        return res;
      } catch (error) {
        console.log('error on update user', error);
      }
    }
  };
  return [userLogs];
};

// Submit Decision
export const usePostDicisionSubmission = () => {
  const { isAuthenticated } = useAuth0();

  const postDecisionSubmission = async (data: any) => {
    if (isAuthenticated) {
      try {
        const url = `${appConfig.rootUrl}/preauth/decision`;
        let tempToken = sessionStorage.getItem('token');
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        };
        const res = await axios.post(url, data, config);
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  };
  return [postDecisionSubmission];
};

export const useGetErNotifications = () => {
  const { user } = useAuth0();
  // Get plans
  let foundPlanData = getCurrentPlan();

  const getNotifications = async () => {
    try {
      let tempToken = sessionStorage.getItem('token');

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const data = {
        email: user.email,
        sitePlans: foundPlanData.permissions,
      };
      const url = `${appConfig.rootUrl}/ernotifications`;
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.log('there was en error on retrieving data', response);
        return [];
      }
    } catch (err) {
      return [];
    }
  };
  return [getNotifications];
};

// Sent Hospital Notifications
export const useGetSentErNotifications = () => {
  const { user } = useAuth0();
  const [globalState, setGlobalState] = useMainProvider();
  // Get plans
  let foundPlanData = getCurrentPlan();

  const getSentErNotifications = async () => {
    try {
      let tempToken = sessionStorage.getItem('token');

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const data = {
        email: user.email,
        sitePlans: foundPlanData.permissions,
      };
      const url = `${appConfig.rootUrl}/ernotifications/history/all`;
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        setGlobalState({
          ...globalState,
          errorNetwork: false,
        });
        return response.data.notifications;
      } else {
        console.log('there was en error on retrieving data', response);
        return [];
      }
    } catch (err) {
      setGlobalState({
        ...globalState,
        errorNetwork: true,
      });
      return [];
    }
  };
  return [getSentErNotifications];
};

export const useSendErNotifications = () => {
  const [globalState, setGlobalState] = useMainProvider();

  // Get plans
  let foundPlanData = getCurrentPlan();

  const sendErNotifications = async (data: any) => {
    try {
      let tempToken = sessionStorage.getItem('token');

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      data = {
        ...data,
        sitePlans: foundPlanData.permissions,
      };
      const url = `${appConfig.rootUrl}/ernotifications/submit`;
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        setGlobalState({
          ...globalState,
          errorNetwork: false,
        });
        return response;
      } else {
        console.log('there was en error on retrieving data', response);
        return [];
      }
    } catch (err) {
      setGlobalState({
        ...globalState,
        errorNetwork: true,
      });
      return [];
    }
  };
  return [sendErNotifications];
};

// Cancel Hospital Notifications
export const useCancelErNotifications = () => {
  const { user } = useAuth0();
  const [globalState, setGlobalState] = useMainProvider();
  

  // Get plans
  let foundPlanData = getCurrentPlan();

  const cancelErNotifications = async (requestId: string) => {
    try {
      let tempToken = sessionStorage.getItem('token');

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      };
      const data = {
        email: user.email,
        sitePlans: foundPlanData.permissions,
      };
      const url = `${appConfig.rootUrl}/ernotifications/cancel/${requestId}`;
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        setGlobalState({
          ...globalState,
          errorNetwork: false,
        });
        return response;
      }
    } catch (err) {
      setGlobalState({
        ...globalState,
        errorNetwork: true,
      });
      return {};
    }
  };
  return [cancelErNotifications];
};

export const useLogout = () => {
  const { logout } = useAuth0();
  const foundPlanData = getCurrentPlan();

  const localLogout = () => {
    sessionStorage.clear();
    Cookies.remove('aah-token');
    Cookies.remove('aah-email');
    Cookies.remove('aah-role');
    Cookies.remove('aah-profileId');
    if (foundPlanData.site === 'localhost:3000') {
      logout({ returnTo: `http://localhost:3000/` });
    } else if (foundPlanData.site === 'providerportaladmin.allyalign.com') {
      logout({ returnTo: `https://providerportaladmin.allyalign.com` });
    } else if (
      foundPlanData.site === 'provider-portal-dev-admin.tutelarhealth.io'
    ) {
      logout({
        returnTo: `https://provider-portal-dev-admin.tutelarhealth.io`,
      });
    } else if (
      foundPlanData.site === 'provider-portal-staging-admin.tutelarhealth.io'
    ) {
      logout({
        returnTo: `https://provider-portal-staging-admin.tutelarhealth.io`,
      });
    } else if (
      foundPlanData.site === 'provider-portal-dev-ui.azurewebsites.net'
    ) {
      logout({ returnTo: `https://provider-portal-dev-ui.azurewebsites.net` });
    } else if (
      foundPlanData.site === 'provider-portal-staging-ui.azurewebsites.net'
    ) {
      logout({
        returnTo: `https://provider-portal-staging-ui.azurewebsites.net/`,
      });
    } else if (foundPlanData.site === 'keycareadvantageportal.allyalign.com') {
      logout({
        returnTo: `https://keycareadvantage.com/providers`,
      });
    } else if (foundPlanData.site === 'procareadvantageportal.allyalign.com') {
      logout({
        returnTo: `https://procareadvantageplan.com/providers`,
      });
    } else {
      const siteDot: any = foundPlanData.site.split('.');
      const finalUrl: any = `${siteDot[1]}.com`;
      logout({ returnTo: `https://${finalUrl}/providers` });
    }
  };
  return localLogout;
};

export const useLogoutNotAuthenticated = () => {
  const { logout } = useAuth0();
  const foundPlanData = getCurrentPlan();

  const localLogout = () => {
    sessionStorage.clear();
    Cookies.remove('aah-token');
    Cookies.remove('aah-email');
    Cookies.remove('aah-role');
    Cookies.remove('aah-profileId');
    if (foundPlanData.site === 'localhost:3000') {
      logout({ returnTo: `http://localhost:3000/not-authenticated` });
    } else {
      logout({ returnTo: `https://${foundPlanData.site}/not-authenticated` });
    }
  };
  return localLogout;
};


import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IAuthData {
  name: string;
  email: string;
}

export interface AuthenticationState {
  token: string;
  authData: IAuthData;
}

const initialState: AuthenticationState = {
  token: '',
  authData: {
    name: '',
    email: '',
  },
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setAuthData: (state, action: PayloadAction<IAuthData>) => {
      state.authData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setToken, setAuthData } = authenticationSlice.actions
export const authenticationName = authenticationSlice.name;
export const authenticationReducer = authenticationSlice.reducer;
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IPlan } from '../../planData'

export interface IPlanCaseState {
  data: IPlan
}

const initialState: IPlanCaseState = {
  data: {
    name: '',
    site: '',
    rootSite: '',
    logoUrl: '',
    logoUrlPng: '',
    faviconUrl: '',
    theme: '',
    cmsIdNumber: '',
    permissions: [],
    contactEmail: '',
    auth0: {
      domain: '',
      clientId: '',
    },
    contact: [
      {
        state: '',
        phones: [
          {
            section: '',
            phone: '',
            fax: '',
            email: '',
            url: '',
            notes: '',
          },
        ],
      },
    ],
    providerDocuments: '',
    exceptionsAndAppeals: '',
    claimsAndBilling: '',
    prescribersAndPharmacies: '',
    compliance: '',
    quality: '',
    privacyPolicy: '',
    disclaimers: '',
    languageAssistance: '',
    reCaptchaKey: '',
  }
};

export const planDataSlice = createSlice({
  name: 'planData',
  initialState,
  reducers: {
    setPlan: (state, action: PayloadAction<IPlan>) => {
      state.data = action.payload;
    }
  }
})

export const { setPlan } = planDataSlice.actions;
export const planDataName = planDataSlice.name;
export const planDataReducer = planDataSlice.reducer;
import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';
import MainNavigation from '../../components/MainNavigation';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';
import ErNotifications from '../../components/ErNotifications';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';

const ErNotificationsPage: FC = () => {
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();
  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  };

  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
    return (
      <div className="custom-container">
        <div>
          <Header />
          <MainNavigation />
          <HeaderTitle title="Hospital Notifications" />
          <ErNotifications />
        </div>
        <div>
          <Footer />
          <FooterBottom />
        </div>
      </div>
    );
  }
  return <div>You need to login in order to see information!</div>;
};

export default ErNotificationsPage;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IAuthorization } from '../../interfaces/authorizations';

export interface IDashboardData {
  authNumber: string;
  createdAt: string;
  updatedAt: string;
  authType?: any;
  authSubType: string;
  authStatus: string;
  memberFirstName: string;
  memberLastName: string;
  memberId: string;
  submitted: string;
  lastUpdate: string;
  serviceFromDate: string;
  serviceToDate: string;
  diagnosisDescription: string;
  procedureDescription: string;
  npi: string;
}

export interface IAuthorizationCaseState {
  dashboardData: IAuthorization[];
  dashboardFiltered: IAuthorization[];
  paginatedData: IAuthorization[];
}

const initialState: IAuthorizationCaseState = {
  dashboardData: [],
  dashboardFiltered: [],
  paginatedData: [],
};

export const authorizationCaseSlice = createSlice({
  name: 'authorizationCase',
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    setDashboardFiltered: (state, action) => {
      state.dashboardFiltered = action.payload;
    },
    setResetDasboardFiltered: (state) => {
      state.dashboardFiltered = state.dashboardData;
    },
    setDashboardPaginatedData: (state, action) => {
      state.paginatedData = action.payload;
    },
    setDashboardPaginatedDataByPage: (
      state,
      action: PayloadAction<{ pageSize: number; pageNumber: number }>
    ) => {
      state.paginatedData = state.dashboardFiltered.slice(
        (action.payload.pageNumber - 1) * action.payload.pageSize,
        action.payload.pageNumber * action.payload.pageSize
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDashboardData,
  setDashboardFiltered,
  setResetDasboardFiltered,
  setDashboardPaginatedData,
  setDashboardPaginatedDataByPage,
} = authorizationCaseSlice.actions;
export const authorizationCaseName = authorizationCaseSlice.name;
export const authorizationCaseReducer = authorizationCaseSlice.reducer;

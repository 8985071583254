import { FC } from 'react';
import { IStandardForm } from '../../interfaces/standard-form';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';

import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { partAServiceOptions } from './constants';

interface IProps {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  touched: FormikTouched<IStandardForm>;
  errors: FormikErrors<IStandardForm>;
  hasNoPermissions: boolean;
}

export const RequestDetailsPartA: FC<IProps> = ({
  getFieldProps,
  touched,
  errors,
  hasNoPermissions,
}) => {
  return (
    <section className="part-a border-bottom-gray">
      <h2 className="py-4">Part A Services</h2>

      <FormControl component="fieldset" disabled={hasNoPermissions}>
        <FormLabel component="legend">Select One</FormLabel>
        <RadioGroup
          aria-label="Part A Services"
          {...getFieldProps('partAServices')}
        >
          {partAServiceOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio color="primary" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <div className="grid xl:grid-cols-2 xl:gap-6 mt-8 pb-8">
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">Days Requested</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="daysRequested">Days Requested</InputLabel>
            <OutlinedInput
              type="number"
              inputProps={{ min: '0' }}
              id="daysRequested"
              label="Days Requested"
              {...getFieldProps('daysRequested')}
            />
          </FormControl>
          {touched.daysRequested && errors.daysRequested && (
            <div className="text-red-500">{errors.daysRequested}</div>
          )}
        </div>
        <div className="flex flex-col mb-4 xl:mb-0">
          <label className="label-top mb-2">Expected LOS in Days</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="expectedLosDays">
              Expected LOS in Days
            </InputLabel>
            <OutlinedInput
              type="number"
              inputProps={{ min: '0' }}
              id="expectedLosDays"
              label="Expected LOS in Days"
              {...getFieldProps('expectedLosDays')}
            />
          </FormControl>
          {touched.expectedLosDays && errors.expectedLosDays && (
            <div className="text-red-500">{errors.expectedLosDays}</div>
          )}
        </div>
      </div>
    </section>
  );
};

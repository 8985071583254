import { FC, useCallback } from 'react';
import Modal from '../Modal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import moment from 'moment';
import { IMemberSearchResult } from '../../interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { setMemberSelected } from '../../store/memberDetails/memberDetails.slice';

const columns = [
  {
    name: 'memberId',
    header: 'Member Id',
    defaultFlex: 1,
  },
  {
    name: 'memberFirstName',
    header: 'First Name',
    defaultFlex: 1,
  },
  {
    name: 'memberLastName',
    header: 'Last Name',
    defaultFlex: 1,
  },
  {
    name: 'memberDateOfBirth',
    header: 'Date of Birth',
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'MM/DD/YYYY',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Date of Birth',
      };
    },
    render: (value: any) => {
      return value.data.memberDateOfBirth
        ? moment(value.data.memberDateOfBirth).format('MM/DD/YYYY')
        : null;
    },
  },
  {
    name: 'isEligible',
    header: 'Is Eligible',
    render: (value: any) => {
      return value.data.isEligible ? 'True' : 'False';
    },
  },
  {
    name: 'planStartDate',
    header: 'Start Date',
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'MM/DD/YYYY',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Start Date',
      };
    },
    render: (value: any) => {
      return value.data.planStartDate
        ? moment(value.data.planStartDate).format('MM/DD/YYYY')
        : null;
    },
  },
  {
    name: 'planEndDate',
    header: 'End Date',
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'MM/DD/YYYY',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'End Date',
      };
    },
    render: (value: any) => {
      return value.data.planEndDate
        ? moment(value.data.planEndDate).format('MM/DD/YYYY')
        : null;
    },
  },
];

const columnsFilter = [
  {
    name: 'memberId',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberFirstName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberLastName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberDateOfBirth',
    operator: 'eq',
    type: 'date',
    value: '',
  },
  {
    name: 'isEligible',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'planStartDate',
    operator: 'eq',
    type: 'date',
    value: '',
  },
  {
    name: 'planEndDate',
    operator: 'eq',
    type: 'date',
    value: '',
  },
];

interface IModalMemberResult {
  openModalSelectMember: boolean;
  setOpenModalSelectMember: (value: boolean) => void;
  memberSearchResult: any;
  setIsEligible: (value: number) => void;
}

const ModalMemberResult: FC<IModalMemberResult> = ({
  openModalSelectMember,
  setOpenModalSelectMember,
  setIsEligible,
  memberSearchResult,
}) => {
  const dispatch = useDispatch();
  const handleAssociate = (member: IMemberSearchResult) => {
    updateMember(member);
  };

  // Once a row is selected
  const onSelectionChange = useCallback((data: any) => {
    updateMember(data.data);
  }, []);

  const updateMember = (member: IMemberSearchResult) => {
    if (member.isEligible) {
      setIsEligible(1);
    }
    if (!member.isEligible) {
      setIsEligible(0);
    }
    dispatch(setMemberSelected(member));
    setOpenModalSelectMember(false);
  };

  const gridStyle = { minHeight: 400 };
  const rowStyle = { cursor: 'pointer' };

  return (
    <Modal
      title="Member result"
      description="Select the member you want to associate"
      open={openModalSelectMember}
      className="confirmationModal"
      onClose={() => {
        setOpenModalSelectMember(false);
      }}
      modalStyle={{ width: '90%' }}
    >
      <div className="showMobile">
        {memberSearchResult.map((row: IMemberSearchResult) => (
          <Accordion className="mb-4 mt-4" key={row.memberId}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="current-submitted"
            >
              <div className="flex flex-col w-full">
                <div>{row.memberId}</div>
                <div className="font-bold">
                  {row.memberFirstName} {row.memberLastName}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full">
                <hr className="mb-4" />
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">Date of Birth: </span>
                  <span className="font-bold">
                    {row.memberDateOfBirth
                      ? moment(row.memberDateOfBirth).format('MM/DD/yyyy')
                      : null}
                  </span>
                </div>
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">Is Eligible? </span>
                  <span className="font-bold">
                    {row.isEligible ? 'True' : 'False'}
                  </span>
                </div>
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">Start Date </span>
                  <span className="font-bold">
                    {row.planStartDate
                      ? moment(row.planStartDate).format('MM/DD/yyyy')
                      : null}
                  </span>
                </div>
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">End Date </span>
                  <span className="font-bold">
                    {row.planEndDate
                      ? moment(row.planEndDate).format('MM/DD/yyyy')
                      : null}
                  </span>
                </div>
                <button
                  type="button"
                  className="btn btn-green w-full mt-4"
                  onClick={() => {
                    handleAssociate(row);
                  }}
                >
                  Select
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div className="showDesktop">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="memberId"
          dataSource={memberSearchResult}
          style={gridStyle}
          rowStyle={rowStyle}
          defaultFilterValue={columnsFilter}
          columns={columns}
          pagination={true}
          enableSelection={true}
          enableClipboard={true}
          onSelectionChange={onSelectionChange}
        />
      </div>

      <div className="buttonsContainer flex justify-between mt-8">
        <button
          type="button"
          onClick={() => {
            setOpenModalSelectMember(false);
          }}
          className="btn"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ModalMemberResult;

import { FC, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGetPortalActivity } from '../../../services/ServiceHook';
import { useAuth0 } from '@auth0/auth0-react';
import { Chip, Typography, TextField } from '@material-ui/core';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import NumberFilter from '@inovua/reactdatagrid-enterprise/NumberFilter';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { ICustomCheck } from '../PortalUsersComponent/PortalUsersComponent';

interface IPortalActivity {
  date: string;
  message: string;
  sitePlans: string[];
  user: string;
}

const PortalActivity: FC = () => {
  const [getPortalActivity] = useGetPortalActivity();
  const { isAuthenticated } = useAuth0();
  const [currentData, setCurrentData] = useState<IPortalActivity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [counter, setCounter] = useState(0);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [plans, setPlans] = useState<ICustomCheck[]>([
    {
      id: null,
      name: null,
      isActive: null,
    },
  ]);

  // React Data Grid
  const columns = [
    {
      name: 'id',
      header: 'Id',
      type: 'number',
      filterEditor: NumberFilter,
      minWidth: 50,
    },
    {
      name: 'message',
      header: 'Message',
      defaultFlex: 1,
    },
    {
      name: 'date',
      header: 'Date',
      minWidth: 200,
      filterEditor: DateFilter,
      filterEditorProps: (props: any) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MM-DD-YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (props: any) => {
        return props.value
          ? moment(props.value).format('MM/DD/YYYY hh:mm:ss')
          : null;
      },
    },
    {
      name: 'user',
      header: 'User',
      minWidth: 450,
    },
    {
      name: 'sitePlans',
      header: 'Plans',
      defaultFlex: 1,
      render: (value: any) => {
        return (
          <div>
            {value.data.sitePlans &&
              value.data.sitePlans.map((item: string, index: number) => (
                <Chip
                  key={index}
                  label={item}
                  style={{
                    marginRight: '5px',
                    marginBottom: '5px',
                  }}
                />
              ))}
          </div>
        );
      },
    },
  ];

  const filterCustomTypes: any = {
    ...ReactDataGrid.defaultProps.filterTypes,
    sitePlans: {
      name: 'sitePlans',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
  };

  // Filter for CURRENT TAB
  const defaultFilterValue = [
    {
      name: 'id',
      operator: 'eq',
      type: 'number',
      value: null,
    },
    {
      name: 'message',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'date',
      operator: 'afterOrOn',
      type: 'date',
      value: '',
    },
    {
      name: 'user',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'sitePlans',
      operator: 'Has',
      type: 'sitePlans',
      value: '',
    },
  ];

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        setIsLoading(true);
        const portalActivity = await getPortalActivity();
        if (portalActivity && portalActivity.status === 200) {
          const dataOrdered = portalActivity.data.sort((a: any, b: any) => {
            return b.date - a.date;
          });

          // For Maps Filtering
          const plansMapped = dataOrdered.map((item: any) => item.sitePlans);
          const allPlans: any = [];
          plansMapped.forEach((item: any) => {
            allPlans.push(...item);
          });
          const distinctPlans = allPlans.filter(
            (value: any, index: number, self: any) => {
              return self.indexOf(value) === index;
            }
          );
          distinctPlans.unshift('All');
          const tempPlans: any = [];
          distinctPlans.forEach((item: string, index: number) => {
            tempPlans.push({
              id: index,
              name: item,
              isActive: false,
            });
          });
          tempPlans[0].isActive = true;
          setPlans(tempPlans);
          setCurrentData(dataOrdered);
          setFilteredData(paginate(dataOrdered, 10, 1));
          setCounter(Math.round(dataOrdered.length / 10));
        }
        setIsLoading(false);
      }
    })();
  }, []);

  const gridStyle = { minHeight: 650 };
  const rowStyle = { cursor: 'pointer' };

  // Pagination
  const paginate = (array: any, pageSize: number, pageNumber: number) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    // TODO: IMPROVE THIS
    setFilteredData(paginate(currentData, 10, value));
  };

  const handlePlans = (value: any) => {
    value.isActive = !value.isActive;
    let plansActive = plans.filter((item) => item.isActive);
    if (!plansActive.length || value.name === 'All') {
      setDefaultValues();
    } else {
      if (plansActive[0].name === 'All') {
        plansActive.splice(0, 1);
        plans[0].isActive = false;
      }
      const dataFiltered = currentData.filter((item: any) =>
        plansActive.every((val) => item.sitePlans.indexOf(val.name!) !== -1)
      );
      setCounter(Math.round(dataFiltered.length / 10));
      const fd = paginate(dataFiltered, 10, 1);
      setFilteredData(fd);
    }
  };

  const setDefaultValues = () => {
    plans.forEach((item: ICustomCheck) => {
      item.isActive = false;
    });
    plans[0].isActive = true;
    const fd = paginate(currentData, 10, 1);
    setCounter(Math.round(currentData.length / 10));
    setFilteredData(fd);
  };

  const filterByUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataFiltered = currentData.filter((item) =>
      item.user.includes(event.target.value.toString())
    );
    setFilteredData(paginate(dataFiltered, 10, 1));
  };

  const filterByMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataFiltered = currentData.filter((item) =>
      item.message
        .toLowerCase()
        .includes(event.target.value.toString().toLowerCase())
    );
    setFilteredData(paginate(dataFiltered, 10, 1));
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="w-full px-4 md:px-8 mx-auto mb-16 hidden lg:block">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="id"
          columns={columns}
          dataSource={currentData}
          style={gridStyle}
          rowStyle={rowStyle}
          filterTypes={filterCustomTypes}
          defaultFilterValue={defaultFilterValue}
          pagination={true}
          enableSelection={true}
        />
      </div>
      <div className="w-full px-4 block lg:hidden">
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <FilterListIcon className="mr-4" />
              Filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="flex flex-col">
            <>
              <FormControl component="fieldset" className="w-full">
                <FormLabel component="legend" className="mb-2">
                  Filter by Plan
                </FormLabel>
                <FormGroup>
                  <div className="mb-6">
                    {plans.map((item: any) => (
                      <div className="inline-block mr-2 mb-2" key={item.id}>
                        <Chip
                          color={item.isActive ? 'primary' : 'default'}
                          key={item.id}
                          label={item.name}
                          onClick={() => {
                            handlePlans(item);
                          }}
                          style={{ marginRight: '5px', marginBottom: '5px' }}
                        />
                      </div>
                    ))}
                  </div>
                </FormGroup>
              </FormControl>
            </>
            <div className="mb-4">
              <FormControl className="w-full xl:w-1/2">
                <FormLabel component="legend" className="mb-2">
                  Filter by User
                </FormLabel>
                <TextField
                  id="filled-basic"
                  variant="outlined"
                  onChange={filterByUser}
                />
              </FormControl>
            </div>
            <div className="mb-4">
              <FormControl className="w-full xl:w-1/2">
                <FormLabel component="legend" className="mb-2">
                  Filter by Message
                </FormLabel>
                <TextField
                  id="filled-basic"
                  variant="outlined"
                  onChange={filterByMessage}
                />
              </FormControl>
            </div>
          </AccordionDetails>
        </Accordion>

        {filteredData.length &&
          filteredData.map((item: any, key: number) => {
            return (
              <Accordion className="mb-4" key={key}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="service-request-header"
                >
                  <div className="flex flex-col w-full">
                    <div className="font-bold">
                      <span className="text-gray-500">{item.id}</span>
                    </div>
                    <span>
                      {moment(item.date).format('MM/DD/YYYY hh:mm:ss')}
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    <hr className="mb-4" />
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Message: </span>
                      <span className="font-bold">{item.message}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">User: </span>
                      <span className="font-bold">{item.user}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Plans: </span>
                      <div>
                        {item.sitePlans &&
                          item.sitePlans.map((item: string, index: number) => (
                            <Chip
                              key={index}
                              label={item}
                              style={{
                                marginRight: '5px',
                                marginBottom: '5px',
                              }}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        <div className="flex flex-col items-center showMobile w-full">
          <Typography className="pb-2">Page: {page}</Typography>
          <Pagination
            count={counter}
            page={page}
            onChange={handlePagination}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};

export default PortalActivity;

import { FC } from 'react';
import { FieldInputProps } from 'formik';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';
import 'date-fns';

interface IProps {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  hasNoPermissions: boolean;
}

export const MemberInformation: FC<IProps> = ({
  getFieldProps,
  hasNoPermissions,
}) => {
  return (
    <section id="memberInformationSection">
      <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8">
        <div className="flex flex-col mb-4">
          <label className="label-top mb-2">Member Living Facility</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="memberLivingFacility">
              Member Living Facility
            </InputLabel>
            <OutlinedInput
              id="memberLivingFacility"
              label="Member Living Facility"
              {...getFieldProps('memberLivingFacility')}
            />
          </FormControl>
        </div>

        <div className="flex flex-col mb-4">
          <label className="label-top mb-2">Referring Provider</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="referringProvider">
              Referring Provider
            </InputLabel>
            <OutlinedInput
              id="referringProvider"
              label="Name"
              {...getFieldProps('referringProvider')}
            />
          </FormControl>
        </div>
      </div>

      <div className="mt-4">
        <FormControl component="fieldset" disabled={hasNoPermissions}>
          <FormLabel component="legend">Referring Provider Type</FormLabel>
          <RadioGroup
            aria-label="Referring Provider Type"
            {...getFieldProps('referringProviderType')}
          >
            <FormControlLabel
              value="NP/PA"
              control={<Radio color="primary" />}
              label="NP/PA"
            />
            <FormControlLabel
              value="PCP"
              control={<Radio color="primary" />}
              label="PCP"
            />
            <FormControlLabel
              value="Other"
              control={<Radio color="primary" />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </section>
  );
};

import { FC } from 'react';
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import ErNotificationSubmissionForm from '../ErNotificationSubmissionForm';
import Icon from '@material-ui/core/Icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { setDrawer } from '../../store/DrawerSlice';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const DrawerComponent: FC = () => {
  const { isOpen, data } = useSelector((state: RootState) => state.drawer);
  const dispatch = useDispatch();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      dispatch(setDrawer({ isOpen: !isOpen, data: data }));
    };

  const list = (anchor: Anchor) => (
    <div className="p-4" style={{ minWidth: '360px' }} role="presentation">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-lg lg:text-2xl mt-4">
          Hospital Notification Submission
        </h1>
        <button
          onClick={toggleDrawer(anchor, false)}
          className="absolute right-1 top-1"
        >
          <Icon>close</Icon>
        </button>
      </div>
      <ErNotificationSubmissionForm />
    </div>
  );

  return (
    <div className="flex justify-end w-full">
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>
    </div>
  );
};

export default DrawerComponent;

import { ChangeEvent, FC, useState } from 'react';
import { IStandardForm } from '../../interfaces/standard-form';
import { FormikErrors } from 'formik';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import SignatureModal from '../SignatureModal/SignatureModal';

interface IProps {
  formValues: any;
  hasNoPermissions: boolean;
  description: string;
  isSignatureNameDisabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IStandardForm>>;
}

export const Signature: FC<IProps> = ({
  formValues,
  setFieldValue,
  hasNoPermissions,
  isSignatureNameDisabled,
  description,
}) => {
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [timestamp, setTimestamp] = useState('');
  const maxDate = new Date();

  const onSignatureChange = (value: string) => {
    setFieldValue('signatureSource', value);
  };

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    setFieldValue(name, value);
  }

  return (
    <section>
      <div className="py-4">
        <h2>Signature</h2>
        <h3 className="text-red-400 mb-4">(Signature is Required)</h3>
      </div>

      <div className="grid xl:grid-cols-1 xl:gap-6">
        <div className="flex items-center">
          <div className="mb-4 xl:mb-0 xl:mr-4">
            <label className="mb-2 block">Effective Date</label>
            <div className="flex flex-col lg:flex-row items-end">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="effectiveDate"
                  label=""
                  value={formValues.effectiveDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    setFieldValue('effectiveDate', date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disabled={hasNoPermissions}
                  maxDate={maxDate}
                />
              </MuiPickersUtilsProvider>

              {!formValues.signatureName || !formValues.signatureSource ? (
                <div className="mt-4 lg:mt-0 lg:ml-4">
                  <button
                    className={`btn btn-blue flex ${
                      hasNoPermissions && 'btn-disabled'
                    }`}
                    type="button"
                    disabled={hasNoPermissions}
                    onClick={() => {
                      setShowSignatureModal(true);
                      const timestamp = moment().format(
                        'MMMM Do YYYY, h:mm:ss a'
                      );
                      setFieldValue('signatureDate', timestamp);
                      setTimestamp(timestamp);
                    }}
                  >
                    <img
                      src="/images/signature.svg"
                      alt="Edit icon"
                      className="pr-2"
                    />
                    Sign Now
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {formValues.signatureName || formValues.signatureSource ? (
        <div>
          <div className="signatureFont mt-4">{formValues.signatureName}</div>{' '}
          <br />
          {timestamp} <br />
          <img src={formValues.signatureSource} alt="" />
        </div>
      ) : null}

      <SignatureModal
        open={showSignatureModal}
        onClose={() => {
          setShowSignatureModal(false);
        }}
        onSignatureChange={onSignatureChange}
        onInputChange={onInputChange}
        signatureName={
          formValues.signatureName || formValues.nameOfPersonSubmittingReferral
        }
        description={description}
        isSignatureNameDisabled={isSignatureNameDisabled}
      />
    </section>
  );
};

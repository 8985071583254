import { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { INote } from '../../interfaces/interfaces';

const MemberDetailsComponentAuthorizationHistoryMobile: FC = () => {
  const memberDetails = useSelector(
    (state: RootState) => state.memberDetails.memberDetailsData
  );
  const role = useSelector((state: RootState) => state.profile.data.role);

  return (
    <div>
      {!memberDetails.notes.length && (
        <Accordion className="mb-4 showMobile">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="flex flex-col w-full">
              <div>There is no note.</div>
            </div>
          </AccordionSummary>
        </Accordion>
      )}
      {memberDetails.notes.map((item: INote, key: number) => {
        return (
          <Accordion className="mb-4 showMobile" key={key}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="flex flex-col w-full">
                <div className="font-bold">{item.subject}</div>
                <div>
                  {item.createdDate
                    ? moment(item.createdDate).format('L')
                    : null}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full">
                <hr className="mb-4" />
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">Subject: </span>
                  <span className="font-bold">{item.subject}</span>
                </div>
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">Created date: </span>
                  <span className="font-bold">
                    {item.createdDate
                      ? moment(item.createdDate).format('L')
                      : null}
                  </span>
                </div>
                <div className="mb-2 flex flex-col">
                  <span className="text-gray-500">Last Update: </span>
                  <span className="font-bold">
                    {item.lastUpdate
                      ? moment(item.lastUpdate).format('L')
                      : null}
                  </span>
                </div>
                {(role === 'MD' ||
                  role === 'Admin' ||
                  role === 'MD - Case Manager' ||
                  role === 'MD - Restricted' ||
                  role === 'MD - Reviewer' ||
                  role === 'MD - Reviewer Restricted' ||
                  role === 'Viewer') && (
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Notes: </span>
                    <span className="font-bold">{item.note}</span>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default MemberDetailsComponentAuthorizationHistoryMobile;

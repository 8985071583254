import { isEmpty } from 'lodash';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  memberId: Yup.string().required('Member Id is required'),
  memberName: Yup.string().required('Member name is required'),
  memberDateOfBirth: Yup.string(),
  dateOfReferral: Yup.string().required('Date of referral is required'),
  timeOfReferral: Yup.string()
    .nullable()
    .required('Time of referral is required'),
  sex: Yup.string().required('Sex is Required'),
  healthPlan: Yup.string().required('Health plan is required'),
  providerName: Yup.string(),
  providerNumber: Yup.string().when('providerName', {
    is: (providerName: string) => !isEmpty(providerName),
    then: Yup.string().required('Provider number is required'),
  }),
  facilityName: Yup.string(),
  facilityLocation: Yup.string().when('facilityName', {
    is: (facilityName: string) => !isEmpty(facilityName),
    then: Yup.string().required('Facility location is required'),
  }),
  nameOfPersonSubmittingReferral: Yup.string().required('Name is required'),
  departmentAndTitle: Yup.string().required('Department and title is required'),
  contactInformation: Yup.string().required('Contact information is required'),
  dateOfOcurrence: Yup.string().required('Date of occurrence is required'),
  timeOfOccurrence: Yup.string()
    .nullable()
    .required('Time of occurrence is required'),
  wasPatientHospitalized: Yup.boolean(),
  nameOfHospital: Yup.string().when('wasPatientHospitalized', {
    is: true,
    then: Yup.string().required('Name of hospital is required'),
  }),
  locationOfHospital: Yup.string().when('wasPatientHospitalized', {
    is: true,
    then: Yup.string().required('Location of hospital is required'),
  }),
  hospitalizationDate: Yup.string()
    .nullable()
    .when('wasPatientHospitalized', {
      is: true,
      then: Yup.string().required('Hospitalization date is required'),
    }),
  hospitalizationTime: Yup.string()
    .nullable()
    .when('wasPatientHospitalized', {
      is: true,
      then: Yup.string().required('Hospitalization time is required'),
    }),
  wasTheIncidentReportedToAStateAgency: Yup.boolean(),
  agencyName: Yup.string().when('wasTheIncidentReportedToAStateAgency', {
    is: true,
    then: Yup.string().required('Agency name is required'),
  }),
  wasAPhysicianCalled: Yup.boolean().nullable(),
  physicianRecomendation: Yup.string().when('wasAPhysicianCalled', {
    is: true,
    then: Yup.string().required('Physician recommendation is required'),
  }),
  briefDescriptionOfTheOccurrence: Yup.string().required(
    'Brief description of the occurrence is required'
  ),
  signatureName: Yup.string().required('Signature name is required'),
  signatureSource: Yup.string().required('Signature source is required'),
  signatureDate: Yup.string().required('Signature date is required'),
});

import { FC, useCallback } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import useStyles from '../DashboardTable/dashboardStyle';
import 'date-fns';
import { IMember } from '../../../interfaces/interfaces';

window.moment = moment;

interface IProps {
  data: IMember[];
}

const DashboardTableMemberDesktop: FC<IProps> = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();
  const statusList = [
    {
      id: 'APPROVED',
      label: 'APPROVED',
    },
    {
      id: 'PARTIALLY_APPROVED',
      label: 'PARTIALLY APPROVED',
    },
    {
      id: 'DEFERRED',
      label: 'DEFERRED',
    },
    {
      id: 'DENIED--ADMINISTRATIVE',
      label: 'DENIED--ADMINISTRATIVE',
    },
    {
      id: 'DENIED - MEDICAL NECESSITY',
      label: 'DENIED - MEDICAL NECESSITY',
    },
    {
      id: 'OUT OF NETWORK INPATIENT',
      label: 'OUT OF NETWORK INPATIENT',
    },
    {
      id: 'PENDED',
      label: 'PENDED',
    },
    {
      id: 'CANCELLED',
      label: 'CANCELLED',
    },
    {
      id: `WAITING ON ADDT'L DOCUMENTS`,
      label: `WAITING ON ADDT'L DOCUMENTS`,
    },
    {
      id: `UNDER MEDICAL REVIEW`,
      label: `UNDER MEDICAL REVIEW`,
    },
    {
      id: `NOTIFICATION ONLY`,
      label: `NOTIFICATION ONLY`,
    },
  ];

  const filterCustomTypes: any = {
    ...ReactDataGrid.defaultProps.filterTypes,
    diagnoses: {
      name: 'diagnoses',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.icdCode.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
    procedures: {
      name: 'procedures',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.cptCode.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
    isReviewed: {
      name: 'isReviewed',
      operators: [
        {
          name: 'isReviewed',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '' || filterValue === false) {
              return true;
            }
            if (filterValue === null) {
              return !data.isReviewed;
            }
            if (filterValue) {
              return data.isReviewed;
            }
          },
        },
      ],
    },
  };

  const columns = [
    {
      name: 'authNumber',
      header: 'Case Id',
      minWidth: 180,
    },
    {
      name: 'memberId',
      header: 'Member Id',
    },
    {
      name: 'memberFirstName',
      header: 'First Name',
    },
    {
      name: 'memberLastName',
      header: 'Last Name',
    },
    {
      name: 'serviceFromDate',
      header: 'Service From',
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (value: any) => {
        return value.data.serviceFromDate
          ? moment(value.data.serviceFromDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'serviceToDate',
      header: 'Service To',
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (value: any) => {
        return value.data.serviceToDate
          ? moment(value.data.serviceToDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'diagnoses',
      header: 'Diagnosis',
      defaultFlex: 1,
      render: (value: any) => {
        return value.value.map((item: any) => (
          <Chip
            key={item.icdCode}
            className="mr-2"
            title={item.diagnosisDescription}
            label={item.icdCode}
          />
        ));
      },
    },
    {
      name: 'serviceRequests',
      header: 'Procedures',
      defaultFlex: 1,
      render: (value: any) => {
        return value.value.map((item: any) => (
          <Chip
            key={item.cptCode}
            className="mr-2"
            title={item.description}
            label={item.cptCode}
          />
        ));
      },
    },
    {
      name: 'authStatus',
      header: 'Auth Status',
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: statusList,
      },
      render: (value: any) => {
        return (
          <div className="flex">
            <div
              style={{ display: 'flex' }}
              className={clsx({
                [classes.approved]: value.data.authStatus === 'APPROVED',
                [classes.partiallyApproved]:
                  value.data.authStatus === 'PARTIALLY APPROVED',
                [classes.deferred]: value.data.authStatus === 'DEFERRED',
                [classes.deniedAdministrative]:
                  value.data.authStatus === 'DENIED--ADMINISTRATIVE',
                [classes.deniedMedicalNecessity]:
                  value.data.authStatus === 'DENIED - MEDICAL NECESSITY',
                [classes.outOfNetwork]:
                  value.data.authStatus === 'OUT OF NETWORK INPATIENT',
                [classes.pended]: value.data.authStatus === 'PENDED',
                [classes.cancelled]: value.data.authStatus === 'CANCELLED',
                [classes.waiting]:
                  value.data.authStatus === `WAITING ON ADDT'L DOCUMENTS` ||
                  value.data.authStatus === `UNDER MEDICAL REVIEW`,
                [classes.notification]:
                  value.data.authStatus === `NOTIFICATION ONLY`,
              })}
            >
              {value.data.authStatus}
            </div>
          </div>
        );
      },
    },
  ];

  const columnsFilter = [
    {
      name: 'authNumber',
      operator: 'eq',
      type: 'string',
      value: '',
    },
    {
      name: 'memberId',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'memberFirstName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'memberLastName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'serviceFromDate',
      operator: 'eq',
      type: 'date',
      value: '',
    },
    {
      name: 'serviceToDate',
      operator: 'eq',
      type: 'date',
      value: '',
    },
    {
      name: 'diagnoses',
      operator: 'Has',
      type: 'diagnoses',
      value: '',
    },
    {
      name: 'serviceRequests',
      operator: 'Has',
      type: 'procedures',
      value: '',
    },
    {
      name: 'authStatus',
      operator: 'eq',
      type: 'select',
      value: null,
    },
  ];

  // Once a row is selected
  const onSelectionChange = useCallback(
    ({ selected }) => {
      history.push(`/dashboard/${selected}`);
    },
    [history]
  );

  const gridStyle = { minHeight: 400 };
  const rowStyle = { cursor: 'pointer' };

  return (
    <section className="customTable w-full mb-16">
      <div className="showDesktop">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="authNumber"
          dataSource={data}
          style={gridStyle}
          rowStyle={rowStyle}
          filterTypes={filterCustomTypes}
          defaultFilterValue={columnsFilter}
          columns={columns}
          pagination={true}
          enableSelection={true}
          enableClipboard={true}
          onSelectionChange={onSelectionChange}
        />
      </div>
    </section>
  );
};

export default DashboardTableMemberDesktop;

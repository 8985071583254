import { FC } from 'react';

const Register: FC<any> = () => {
  return (
    <div className="flex justify-center">
      <div>
        <img
          src="https://simpra.wpengine.com/wp-content/uploads/2021/04/Simpra.png"
          alt="Simpra Logo"
        />
        <h1 className="mb-4">Simpra Advantage Provider Portals have moved! </h1>
        <p>
          Please delete any stored bookmarks to this page <br /> <br />
          Visit the new Provider Portal here: <br /> <br />
          <a
            href="https://ehealth-smp.healthsuiteadvantage.com"
            style={{ color: '#06b6d4' }}
          >
            https://ehealth-smp.healthsuiteadvantage.com
          </a>{' '}
          <br />
          <br />
          Contact Member and Provider Services at{' '}
          <a href="callto:1-844-637-4770" style={{ color: '#06b6d4' }}>
            1-844-637-4770
          </a>{' '}
          <a href="callto:1-833-312-0044" style={{ color: '#06b6d4' }}>
            (TTY 1-833-312-0044)
          </a>{' '}
          with questions.
        </p>
      </div>
    </div>
  );
};

export default Register;

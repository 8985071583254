import { FC, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MemberSearch from '../MemberSearch';
import MemberSearchResultLabels from '../MemberSearchResultLabels';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CcmReferralsSubmitForm from '../CcmReferralsSubmitForm';
import CcmReferralsSent from '../CcmReferralsSent';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const CcmReferralsSubmit: FC = () => {
  const member = useSelector(
    (state: RootState) => state.memberDetails.memberSelected
  );
  const [value, setValue] = useState(0);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const updateValue = (val: number) => {
    setValue(val);
  };

  return (
    <section className="customTable w-full px-4 md:px-8 mx-auto mb-16">
      <>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="dashboard tabs"
          >
            <Tab label="Submit Referrals" {...a11yProps(0)} />
            <Tab label="Sent Referrals" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <h2 className="mt-6 mb-4">Member Information</h2>
          <MemberSearch />
          <MemberSearchResultLabels member={member} />
          <CcmReferralsSubmitForm updateValue={updateValue} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CcmReferralsSent />
        </TabPanel>
      </>
    </section>
  );
};

export default CcmReferralsSubmit;

import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Footer, FooterBottom, Header, HeaderTitle } from '../../components';
import { CircularProgress } from '@material-ui/core';
import MainNavigation from '../../components/MainNavigation';
import PqiReferralsContainer from '../../components/PQIReferrals';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';

const PqiReferralsPage: FC<any> = () => {
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();

  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  };
  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
    return (
      <div className="custom-container">
        <div>
          <Header />
          <MainNavigation />
          <HeaderTitle title="PQI Referrals" />
          <div className="px-8 pb-8 text-sm">
            <p>
              This Potential Quality Issue (PQI) Referral may be subject to
              state law provisions pertaining to confidentiality protections,
              attorney-work product, and other types of privilege. All
              documents, reports, and information relating to the review of a
              PQI are kept confidential in accordance with peer review
              protection.{' '}
            </p>
          </div>
          <PqiReferralsContainer />
        </div>
        <div>
          <Footer />
          <FooterBottom />
        </div>
      </div>
    );
  }
  return <div>You need to login in order to see information!</div>;
};

export default PqiReferralsPage;

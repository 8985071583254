import Cookies from 'js-cookie';
import { FC, useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { IStandardForm } from '../../interfaces/standard-form';
import { FormikErrors } from 'formik';
import { FileList } from './FileList';

interface IProps {
  formValues: IStandardForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IStandardForm>>;
}

export const FileInputRefactor: FC<IProps> = ({
  formValues,
  setFieldValue,
}) => {
  const role = Cookies.get('aah-role');

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles.map((file: File) => {
        const { name, size, type } = file;
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            const newFile = reader.result as string;
            const base64 = newFile.split(',')[1];
            resolve({
              fileName: name,
              // name,
              size,
              type,
              base64,
              // dataURL: reader.result, // base64
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(files).then((fileList) => {
        setFieldValue('files', formValues.files?.concat(fileList));
      });
    },
    [formValues.files, setFieldValue]
  );

  const dropZoneOptions: DropzoneOptions = {
    accept: [
      'application/pdf',
      'application/doc',
      'application/docx',
      'image/*',
    ],
    onDrop,
    multiple: true,
  };

  const { getRootProps, getInputProps } = useDropzone(dropZoneOptions);

  const handleRemoveFile = (index: number) => {
    const tempFiles = formValues.files?.filter((_, i) => i !== index);
    setFieldValue('files', tempFiles);
  };

  return (
    <div>
      <h3>Documents to attach (where applicable)</h3>
      <p>
        History and Physical, Discharge Summary, Therapy Progress Notes,
        Medication list, etc.
      </p>
      <h3 className="mt-4">Files type allowed</h3>
      <p>
        All images type: JPEG, PNG, SVG, TIFF, BMP, etc. <br />
        Document type: PDF, DOCX
      </p>
      {role !== 'Viewer' && (
        <>
          <div
            {...getRootProps()}
            className="border-2 cursor-pointer border-dashed border-black rounded flex flex-col items-center justify-center w-full p-12 my-8"
          >
            <input {...getInputProps()} />

            <h3 className="text-2xl">Drag a file here</h3>
            <button
              type="button"
              className={`btn mt-8  ${role === 'Viewer' && 'btn-disabled'}`}
              disabled={role === 'Viewer'}
            >
              Select a file from your device
            </button>
          </div>
          {formValues.files && formValues.files.length ? (
            <FileList files={formValues.files} removeFile={handleRemoveFile} />
          ) : null}
        </>
      )}
    </div>
  );
};

import { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Chip, TextField } from '@material-ui/core';
import { ICustomCheck } from '../PortalUsersComponent/PortalUsersComponent';
import { IUser } from '../../../interfaces/user';

interface IProps {
  plans: ICustomCheck[];
  portalUsersFiltered: IUser[];
  handlePlans: (value: any) => void;
  filterByLastName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filterByCaseId: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDetails: (authNumber: number) => void;
}

export const RegistrationsMobile: FC<IProps> = ({
  plans,
  portalUsersFiltered,
  handlePlans,
  filterByLastName,
  filterByCaseId,
  handleDetails,
}) => {
  return (
    <>
      <Accordion className="mb-4">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <FilterListIcon className="mr-4" />
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          <>
            <FormControl component="fieldset" className="w-full">
              <FormLabel component="legend" className="mb-2">
                Filter by Plan
              </FormLabel>
              <FormGroup>
                <div className="mb-6">
                  {plans.map((item: any) => (
                    <div className="inline-block mr-2 mb-2" key={item.id}>
                      <Chip
                        color={item.isActive ? 'primary' : 'default'}
                        key={item.id}
                        label={item.name}
                        onClick={() => {
                          handlePlans(item);
                        }}
                        style={{ marginRight: '5px', marginBottom: '5px' }}
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>
            </FormControl>
          </>
          <div className="mb-4">
            <FormControl className="w-full xl:w-1/2">
              <FormLabel component="legend" className="mb-2">
                Filter by Last Name
              </FormLabel>
              <TextField
                id="filled-basic"
                variant="outlined"
                onChange={filterByLastName}
              />
            </FormControl>
          </div>
          <div className="mb-4">
            <FormControl className="w-full xl:w-1/2">
              <FormLabel component="legend" className="mb-2">
                Filter by Id
              </FormLabel>
              <TextField
                id="filled-basic"
                variant="outlined"
                onChange={filterByCaseId}
              />
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>

      {portalUsersFiltered.length ? (
        portalUsersFiltered.map((item: any, key: number) => {
          return (
            <Accordion className="mb-4 showMobile" key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="service-request-header"
              >
                <div className="flex flex-col w-full">
                  <div className="font-bold">
                    <span className="text-gray-500">{item.id}</span>
                  </div>
                  <span>
                    {item.profile.firstName} {item.profile.lastName}
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-full">
                  <hr className="mb-4" />
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Email: </span>
                    <span className="font-bold">{item.userEmail}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Plans: </span>
                    <div>
                      {item.plansByState &&
                        item.plansByState.map((item: string, index: number) => (
                          <Chip
                            key={index}
                            label={item}
                            style={{
                              marginRight: '5px',
                              marginBottom: '5px',
                            }}
                          />
                        ))}
                    </div>
                  </div>
                  {item.role && (
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Role: </span>
                      <span className="font-bold">
                        <div>
                          <Chip
                            label={item.role}
                            style={{
                              marginRight: '5px',
                              marginBottom: '5px',
                            }}
                          />
                        </div>
                      </span>
                    </div>
                  )}

                  <div>
                    <button
                      type="button"
                      className="btn btn-blue-transparent w-full"
                      onClick={() => {
                        handleDetails(item.id);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <h3>No Data to show!</h3>
      )}
    </>
  );
};

import { IPqiReferralsSubmit } from "../../interfaces/pqi-referrals";
import { categoryInitialValues } from "./categoryInitialValues";


export const CategoriesMap = (data: IPqiReferralsSubmit) => {
  return categoryInitialValues.map((item) => {
    const suspectedType = item.suspectedType.map((suspectedType) => {
      const index = data.suspectedCategory.findIndex(
        (suspectedCategory: string) => {
          return suspectedCategory === suspectedType.label;
        }
      );
      if (index > -1) {
        return { ...suspectedType, checked: true };
      }
      return suspectedType;
    });
    return { ...item, suspectedType };
  });
}
import {
  IMemberDetails,
  IMemberSearchResult,
  IPortalUser,
  IProvider,
  IStandardForm,
} from '../interfaces/interfaces';

// TODO: remove this
const InitialValues: IStandardForm = {
  plan: '',
  authorizationNumber: '',
  requestId: '',
  authType: null,
  formType: '',
  clinicalOrTherapyDocUpdated: '',
  orderOrClinicalNoteIncluded: '',
  requestorsName: '',
  requestorsPhoneNumber: '',
  requestorsFaxNumber: '',
  signatureName: '',
  signatureDate: null,
  effectiveDate: null,
  signatureSource: '',
  memberFirstName: '',
  memberMiddleName: '',
  memberLastName: '',
  memberDateOfBirth: null,
  memberId: '',
  memberLivingFacility: '',
  memberIsEligible: null,
  referringProvider: '',
  referringProviderType: '',
  facilityOrProviderName: '',
  admissionOrServiceDate: null,
  npiPinNumber: '',
  providerPhoneNumber: '',
  providerFaxNumber: '',
  partAServices: '',
  daysRequested: null,
  expectedLosDays: null,
  partBServices: '',
  rehabPotential: '',
  significantImprovementMade: '',
  descriptionOrCptCode: '',
  outPatientServicesArray: [],
  partBServicesNotes: '',
  transferLocationName: '',
  transferLocationPhone: '',
  transferLocationAddress: '',
  expectedDischargeDate: null,
  appointmentNeeds: '',
  attachmentNames: [''],
  attachmentsCount: 0,
  pdf: '',
  files: [],
  isEligible: null,
};

export const memberSearchResultInitialValues: IMemberSearchResult = {
  memberDateOfBirth: null,
  planStartDate: null,
  planEndDate: null,
  memberFirstName: '',
  memberMiddleName: '',
  memberLastName: '',
  memberId: '',
  companyId: '',
  isEligible: null,
  facilityName: '',
  facilityNpi: '',
  memberBenefitPlan: '',
};

export const providersDefaultValue: IProvider = {
  npi: null,
  firstName: '',
  lastName: '',
  middleInitial: '',
  fullName: '',
  companyID: '',
  class: null,
  contract: null,
  contractEffectiveDate: null,
  termedDate: null,
};

export const memberDetailsInitialValue: IMemberDetails = {
  serviceFromDate: null,
  serviceToDate: null,
  memberFirstName: '',
  memberMiddleName: '',
  memberLastName: '',
  submitted: null,
  memberDateOfBirth: '',
  planStartDate: null,
  planEndDate: '',
  coverageDate: '',
  lastUpdate: null,
  createdDate: null,
  authNumber: '',
  authType: '',
  authStatus: '',
  authorizatedUnits: null,
  requestedUnits: null,
  memberName: '',
  memberId: '',
  npi: null,
  authPCP: '',
  companyId: '',
  memberGender: '',
  requestingProvider: '',
  facilityProvider: '',
  isEligible: null,
  certType: '',
  serviceRequests: [],
  diagnoses: [],
  providers: {
    authPCP: providersDefaultValue,
    facilityProvider: providersDefaultValue,
    requestingProvider: providersDefaultValue,
  },
  notes: [],
  documents: [],
};

export const portalUserInitialValues: IPortalUser = {
  createdAt: '',
  groupNpis: [],
  hasBeenReviewed: null,
  hasPortalAccess: null,
  id: '',
  isActive: null,
  plansByState: [],
  profile: {
    email: '',
    firstName: '',
    jobRole: '',
    lastName: '',
    npis: [],
    plans: [],
  },
  role: '',
  updatedAt: '',
  userEmail: '',
};



export default InitialValues;

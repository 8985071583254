import React, {
  createContext,
  useState,
  FC,
  useEffect,
  useCallback,
} from 'react';
import { IFormPayload } from '../interfaces/interfaces';
import planData, { getCurrentPlan, IPlan } from '../planData';
import { string } from 'yup/lib/locale';
import {
  IDocument,
  IMemberSelectedFromModal,
} from '../components/MemberDetailsComponent/model';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/authentication/auth.slice';
import { setProfile } from '../store/profile/profile.slice';
import { setPlan } from '../store/plan/plan.slice';
import { useLogout } from '../services/ServiceHook';
import { Modal } from '../components';
import Cookies from 'js-cookie';
import { useCreateTokenMutation } from '../hooks/mutations/useTokenMutation';
import { useGetProfileMutation } from '../hooks/mutations/useProfileMutation';
import { CircularProgress } from '@material-ui/core';
import { IAuth0Profile } from '../interfaces/profile';
import { Auth0ProfileInitialValue } from '../initial-values/auth0-profile';
import ProfileNotActive from '../components/ProfileNotActive/ProfileNotActive';
import { IUser } from '../interfaces/user';
import { userInitialState } from '../initial-values/user';

// TODO: Remove everything of Global State and replace it with Redux Toolkit

export const MainContext = createContext([{}, () => {}]);

interface props {
  children: React.ReactNode;
}

// Add here any new value that want to share globally
export interface IAuthData {
  name: string;
  email: string;
}

export interface IDashboardData {
  authNumber: string;
  createdAt: string;
  updatedAt: string;
  authType?: any;
  authSubType: string;
  authStatus: string;
  memberFirstName: string;
  memberLastName: string;
  memberId: string;
  submitted: string;
  lastUpdate: string;
  serviceFromDate: string;
  serviceToDate: string;
  diagnosisDescription: string;
  procedureDescription: string;
  npi: string;
}

export interface IDashboardDraft extends IDashboardData {
  requestId: string;
}

export interface IProfile {
  createdAt: null | string;
  groupNpis: string[];
  hasPortalAccess: null | boolean;
  id: number | null;
  isActive: null | boolean;
  isAdmin: null | boolean;
  plansByState: string[];
  role: string;
  updatedAt: null | string;
  userEmail: string;
}

export const profileInitialValues: IProfile = {
  createdAt: null,
  groupNpis: [],
  hasPortalAccess: null,
  id: null,
  isActive: null,
  isAdmin: null,
  plansByState: [],
  role: '',
  updatedAt: null,
  userEmail: '',
};
export interface INpiAndName {
  npi: string;
  name: string;
}
export interface IMemberInformation {
  caseId: string;
  authType?: string;
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  authStatus: string;
}

export interface IAttachment {
  fileName: string;
  base64: string;
}
export interface IAttachmentPayload {
  authorizationNumber: string;
  memberId: string;
  requestId: string;
  attachments: IAttachment[];
}
interface IInitialState {
  token: string;
  authData: IAuthData;
  planData: IPlan;
  profile: IProfile;
  npisAndNames: INpiAndName[];
  dashboardData: IDashboardData[];
  dashboardFiltered: IDashboardData[];
  formPayload: IFormPayload;
  attachmentPayload: IAttachmentPayload;
  memberSearchResult: string;
  memberDetails: IMemberDetails;
  users: IUser[];
  memberSelectedFromModal: IMemberSelectedFromModal;
  // memberInformation: IMemberInformation
}

const memberSelectedFromModalInitialValues: IMemberSelectedFromModal = {
  companyId: '',
  isEligible: null,
  memberDateOfBirth: null,
  memberFirstName: '',
  memberId: '',
  memberLastName: '',
  memberMiddleName: '',
  planEndDate: null,
  planStartDate: null,
};

export const formPayloadInitialState: IFormPayload = {
  authType: 0,
  authorizationNumber: '',
  requestId: '',
  plans: [],
  data: {
    authorizationNumber: '',
    plan: '',
    requestId: '',
    authType: '',
    formType: '',
    clinicalOrTherapyDocUpdated: '',
    orderOrClinicalNoteIncluded: '',
    requestorsName: '',
    requestorsPhoneNumber: '',
    requestorsFaxNumber: '',
    signatureName: '',
    signatureDate: null,
    effectiveDate: null,
    signatureSource: '',
    memberFirstName: '',
    memberMiddleName: '',
    memberLastName: '',
    memberDateOfBirth: null,
    memberId: '',
    memberIsEligible: null,
    memberLivingFacility: '',
    referringProvider: '',
    referringProviderType: '',
    facilityOrProviderName: '',
    admissionOrServiceDate: null,
    npiPinNumber: '',
    providerPhoneNumber: '',
    providerFaxNumber: '',
    partAServices: '',
    daysRequested: null,
    expectedLosDays: null,
    partBServices: '',
    rehabPotential: '',
    significantImprovementMade: '',
    descriptionOrCptCode: '',
    outPatientServicesArray: [],
    partBServicesNotes: '',
    transferLocationName: '',
    transferLocationPhone: '',
    transferLocationAddress: '',
    expectedDischargeDate: null,
    appointmentNeeds: '',
    files: [],
    attachmentsCount: 0,
    sitePlans: [],
    pdf: '',
    isEligible: null,
  },
};

export const attachmentPayloadInitialState = {
  authorizationNumber: '',
  memberId: '',
  requestId: '',
  attachments: [],
};

export const IDiagnosis = {
  diagnosisDescription: string,
  icdCode: string,
};

// TODO: Remove this
export interface IMemberDetails {
  authNumber: string;
  authPCP: string;
  authStatus: string;
  authSubType: string;
  authType?: string;
  certType?: string;
  companyId: string;
  coverageDate: string;
  cptCode: string;
  createdDate: string;
  diagnoses: string[];
  diagnosisDescription: string;
  documents: IDocument[];
  facilityProvider: string;
  icdCode: string;
  isEligible: boolean | null;
  isPended: boolean | null;
  lastUpdate: string;
  memberDateOfBirth: string;
  memberFirstName: string;
  memberGender: string;
  memberId: string;
  memberLastName: string;
  memberMiddleName: string;
  notes: [];
  npi: string;
  procedureDescription: string;
  requestingProvider: string;
  submitted: string;
  serviceFromDate: string | null;
  serviceToDate: string | null;
  serviceType: string;
  requestedUnits: string;
  authorizatedUnits: string;
  providers: any;
}

export const memberDetailsInitialState: IMemberDetails = {
  authNumber: '',
  authPCP: '',
  authStatus: '',
  authSubType: '',
  authType: '',
  companyId: '',
  coverageDate: '',
  cptCode: '',
  createdDate: '',
  diagnoses: [],
  diagnosisDescription: '',
  documents: [],
  facilityProvider: '',
  icdCode: '',
  isEligible: null,
  isPended: null,
  lastUpdate: '',
  memberDateOfBirth: '',
  memberFirstName: '',
  memberGender: '',
  memberId: '',
  memberLastName: '',
  memberMiddleName: '',
  notes: [],
  npi: '',
  procedureDescription: '',
  requestingProvider: '',
  submitted: '',
  serviceFromDate: '',
  serviceToDate: '',
  serviceType: '',
  requestedUnits: '',
  authorizatedUnits: '',
  providers: {},
};

export interface IDraft {
  admissionOrServiceDate: string;
  appointmentNeeds: string;
  authStatus: string;
  authType?: any;
  authorizationNumber: string;
  attachmentNames: [];
  clinicalOrTherapyDocUpdated: string;
  createdAt: string;
  daysRequested: number | null;
  descriptionOrCptCode: string;
  effectiveDate: string | null;
  expectedDischargeDate: string | null;
  expectedLosDays: number | null;
  facilityOrProviderName: string;
  id: string;
  memberDateOfBirth: string | null;
  memberFirstName: string;
  memberId: string;
  memberLastName: string;
  memberLivingFacility: string;
  memberMiddleName: string;
  npiPinNumber: string;
  orderOrClinicalNoteIncluded: string;
  outPatientServicesArray: [];
  partAServices: string;
  partBServices: string;
  partBServicesNotes: string;
  pdf: string;
  plan: string;
  providerFaxNumber: string;
  providerPhoneNumber: string;
  referringProvider: string;
  referringProviderType: string;
  rehabPotential: string;
  requestId: string;
  requestorsFaxNumber: string;
  requestorsName: string;
  requestorsPhoneNumber: string;
  signatureDate: string | null;
  signatureSource: string;
  significantImprovementMade: string;
  transferLocationAddress: string;
  transferLocationName: string;
  transferLocationPhone: string;
  updatedAt: string | null;
}

export const IDraftInitialValues: IDraft = {
  admissionOrServiceDate: '',
  appointmentNeeds: '',
  authStatus: '',
  authType: '',
  authorizationNumber: '',
  attachmentNames: [],
  clinicalOrTherapyDocUpdated: '',
  createdAt: '',
  daysRequested: null,
  descriptionOrCptCode: '',
  effectiveDate: null,
  expectedDischargeDate: null,
  expectedLosDays: null,
  facilityOrProviderName: '',
  id: '',
  memberDateOfBirth: null,
  memberFirstName: '',
  memberId: '',
  memberLastName: '',
  memberLivingFacility: '',
  memberMiddleName: '',
  npiPinNumber: '',
  orderOrClinicalNoteIncluded: '',
  outPatientServicesArray: [],
  partAServices: '',
  partBServices: '',
  partBServicesNotes: '',
  pdf: '',
  plan: '',
  providerFaxNumber: '',
  providerPhoneNumber: '',
  referringProvider: '',
  referringProviderType: '',
  rehabPotential: '',
  requestId: '',
  requestorsFaxNumber: '',
  requestorsName: '',
  requestorsPhoneNumber: '',
  signatureDate: null,
  signatureSource: '',
  significantImprovementMade: '',
  transferLocationAddress: '',
  transferLocationName: '',
  transferLocationPhone: '',
  updatedAt: null,
};

const MainProvider: FC<props> = ({ children }) => {
  const [openNetworkErrorModal, setOpenNetworkErrorModal] = useState(false);
  const localLogout = useLogout();
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [globalState, setGlobalState] = useState<IInitialState>({
    token: '',
    authData: {
      name: '',
      email: '',
    },
    planData: planData[0],
    profile: profileInitialValues,
    npisAndNames: [
      {
        npi: '',
        name: '',
      },
    ],
    dashboardData: [],
    dashboardFiltered: [],
    formPayload: formPayloadInitialState,
    attachmentPayload: attachmentPayloadInitialState,
    memberSearchResult: '',
    memberSelectedFromModal: memberSelectedFromModalInitialValues,
    memberDetails: memberDetailsInitialState,
    users: [userInitialState],
  });

  const [tokenErrorMessage, setTokenErrorMessage] = useState('');
  const { mutate: getToken } = useCreateTokenMutation();
  const { mutate: getProfile } = useGetProfileMutation();
  const [localProfile, setLocalProfile] = useState<IAuth0Profile>(
    Auth0ProfileInitialValue
  );

  const currentPlan = getCurrentPlan();
  dispatch(setPlan(currentPlan));

  const callGetProfile = useCallback(
    (email: string) => {
      getProfile(email, {
        onSuccess: (profile) => {
          Cookies.set('aah-role', profile.role);
          Cookies.set('aah-profileId', profile.id);
          Cookies.set('aah-isProfileActive', profile.isActive);

          dispatch(setProfile(profile));
          setLocalProfile(profile);

          // TODO: Remove Session Storage and use Cookies
          sessionStorage.setItem('profile', JSON.stringify(profile));
          sessionStorage.setItem('role', profile.role);
          sessionStorage.setItem('isActive', profile.isActive);
        },
        onError: (error: any) => {
          setTokenErrorMessage(
            `There was an error geting your profile.
            The server message is: ${error}`
          );
          setOpenNetworkErrorModal(true);
        },
      });
    },
    [dispatch, getProfile]
  );

  useEffect(() => {
    if (user && user.email) {
      getToken(user.email, {
        onSuccess: (data) => {
          sessionStorage.setItem('token', data);
          Cookies.set('aah-token', data);
          Cookies.set('aah-email', user.email);
          //TODO: Remove this
          dispatch(setToken(data));
          // TODO: Get Profile
          callGetProfile(user.email);
        },
        onError: (error: any) => {
          setTokenErrorMessage(
            `Please contact support and let us know or check back later.
            The server message is: ${error}`
          );
          setOpenNetworkErrorModal(true);
        },
      });
    }
  }, [callGetProfile, dispatch, getToken, user]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4  flex-col justify-center items-center mt-8 ">
        <CircularProgress />
      </div>
    );
  }

  if (!isLoading && localProfile.role && !localProfile.isActive) {
    <ProfileNotActive></ProfileNotActive>;
  }

  return (
    <MainContext.Provider value={[globalState, setGlobalState]}>
      <>
        {children}
        <Modal
          title="We are currently experiencing an issue with this system"
          description={tokenErrorMessage}
          open={openNetworkErrorModal}
          className="confirmationModal error"
          onClose={() => localLogout()}
        >
          <div className="buttonsContainer flex justify-between">
            <button type="button" onClick={() => localLogout()} className="btn">
              End session
            </button>
          </div>
        </Modal>
      </>
    </MainContext.Provider>
  );
};

export default MainProvider;

export const useMainProvider = () => {
  const context = React.useContext<any>(MainContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

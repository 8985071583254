import { FC, ChangeEvent, useState } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { IMemberSearchResult } from '../../interfaces/interfaces';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSearchMember } from '../../services/ServiceHook';
import { FormikHelpers, useFormik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import ModalMemberResult from '../ModalMemberResult/ModalMemberResult';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';

const IsEligibleTrue: FC = () => {
  return (
    <div className="pt-8" style={{ color: '#81B920' }}>
      <CheckIcon></CheckIcon> Member verification successful
    </div>
  );
};

const IsEligibleFalse: FC = () => {
  return (
    <div className="pt-8" style={{ color: '#C74343' }}>
      <WarningIcon></WarningIcon>This member is not currently eligible for
      benefits. Please{' '}
      <Link to="/contact" style={{ fontWeight: 'bold' }}>
        Contact us
      </Link>{' '}
      if you have any questions.
    </div>
  );
};

const IsEligibleNull: FC = () => {
  return (
    <div className="pt-8" style={{ color: '#F5A81C' }}>
      <WarningIcon></WarningIcon>No member records found. Please update the
      member information below and try again or call 844-205-7244 opt 3.
    </div>
  );
};

const MemberSearch: FC = () => {
  const location = useLocation();
  const [openModalSelectMember, setOpenModalSelectMember] = useState(false);
  const [isVerifyingMember, setIsVerifyingMember] = useState(false);
  const [getSearchMember] = useSearchMember();
  const [isEligible, setIsEligible] = useState<number>(-2);
  const [memberSearchResult, setMemberSearchResult] = useState<
    IMemberSearchResult[]
  >([]);
  const plan = useSelector((state: RootState) => state.planData.data);
  interface IMemberSearch {
    memberLastName: string | null;
    memberDateOfBirth: Date | string | null;
    memberId: string;
  }

  const initialValues: IMemberSearch = {
    memberLastName: '',
    memberDateOfBirth: null,
    memberId: '',
  };

  const validationSchema = Yup.object().shape({
    memberLastName: Yup.string(),
    memberDateOfBirth: Yup.string().nullable(),
    memberId: Yup.string(),
  });

  const {
    setFieldValue,
    setFieldTouched,
    values: formValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const enableButton =
    (formValues.memberDateOfBirth && formValues.memberLastName) ||
    formValues.memberId;

  const verifyMember = async () => {
    setIsVerifyingMember(true);
    // Get plans
    const memberPayload = {
      plans: plan.permissions,
      search: {
        lastName: formValues.memberLastName,
        memberId: formValues.memberId,
        memberDateOfBirth: formValues.memberDateOfBirth,
      },
    };

    if (
      memberPayload.plans &&
      ((memberPayload.search.lastName &&
        memberPayload.search.memberDateOfBirth) ||
        memberPayload.search.memberId)
    ) {
      setIsEligible(-2);
      const response = await getSearchMember(memberPayload);
      setIsVerifyingMember(false);
      if (!response || !response.length) {
        setIsEligible(-1);
      }

      if (response && response.length) {
        setMemberSearchResult(response);
        setOpenModalSelectMember(true);
      }
    } else {
      console.log('Seems that plans is missing, please go to Dashboard first');
    }
  };

  const checkEeligibility = (value: number) => {
    if (value === -1) {
      return <IsEligibleNull />;
    }
    if (value === 0) {
      return <IsEligibleFalse />;
    }
    if (value === 1) {
      return <IsEligibleTrue />;
    }
  };

  function onSubmit(
    values: IMemberSearch,
    helpers: FormikHelpers<IMemberSearch>
  ) {}

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    setFieldValue(name, value);
    setFieldTouched(name, true);
  }

  return (
    <div className="w-full mb-16">
      <div
        style={{ backgroundColor: '#F8F8F8', borderTop: '2px solid #003967' }}
        className="p-4"
      >
        {location.pathname !== '/pqi-referrals' ? (
          <p>
            Please enter the Member's last name and date of birth or a Member ID
            to search for and verify the member for which you are requesting
            prior authorization.
          </p>
        ) : (
          <p>
            Please enter the Member’s ID number to verify the member for which
            you want to submit a PQI referral.
          </p>
        )}
        <div className="isEligible mb-4">{checkEeligibility(isEligible)}</div>
        {location.pathname !== '/pqi-referrals' && (
          <>
            <div className="grid md:grid-cols-11 gap-2">
              <div className="flex flex-col mb-4 xl:mb-0 col-span-5">
                <div className="mb-2">
                  <label className="label-top">Member Last Name</label>
                </div>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="memberLastName"></InputLabel>
                  <OutlinedInput
                    id="memberLastName"
                    name="memberLastName"
                    onChange={onInputChange}
                  />
                </FormControl>
              </div>
              <span
                className="lg:p-4 lg:flex lg:justify-center lg:items-center font-bold"
                style={{ color: '#023b64' }}
              >
                And
              </span>
              <div className="mb-4 xl:mb-0 width-date-100 col-span-5">
                <label className="label-top mb-2 block">Date of Birth</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="w-full"
                    format="MM/dd/yyyy"
                    id="memberDateOfBirth"
                    inputVariant="outlined"
                    value={formValues.memberDateOfBirth}
                    name="memberDateOfBirth"
                    onChange={(date: any, value: any) => {
                      setFieldValue('memberDateOfBirth', value);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <span style={{ color: '#023b64' }} className="my-4 block font-bold">
              Or
            </span>
          </>
        )}

        <div className="grid md:grid-cols-11 gap-2">
          <div className="flex flex-col mb-4 xl:mb-0 col-span-5">
            <label className="label-top mb-2">Member ID</label>
            <FormControl variant="outlined">
              <OutlinedInput
                id="memberId"
                name="memberId"
                onChange={onInputChange}
              />
            </FormControl>
          </div>
          <span className="p-4 text-transparent"></span>
          {isVerifyingMember ? (
            <div className="flex justify-center items-end col-span-5">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex flex-col justify-center xl:mb-0 flex-1 col-span-5">
              <button
                type="button"
                disabled={!enableButton}
                className={clsx(
                  {
                    'btn-disabled': !enableButton,
                    'btn-red': enableButton,
                  },
                  'btn',
                  'mt-6'
                )}
                onClick={verifyMember}
              >
                SEARCH
              </button>
            </div>
          )}
        </div>
      </div>

      <ModalMemberResult
        openModalSelectMember={openModalSelectMember}
        setOpenModalSelectMember={setOpenModalSelectMember}
        memberSearchResult={memberSearchResult}
        setIsEligible={setIsEligible}
      />
    </div>
  );
};

export default MemberSearch;

import * as Yup from 'yup';


// TODO: make clinicalOrTherapyDocUpdated and orderOrClinicalNoteIncluded required for all
// forms except for notificationOnly
const validationSchema = Yup.object().shape({
  plan: Yup.string(),
  id: Yup.string(),
  authType: Yup.string(),
  clinicalOrTherapyDocUpdated: Yup.string().required('Required'),
  orderOrClinicalNoteIncluded: Yup.string().required('Required'),
  requestorsName: Yup.string().required('Required'),
  requestorsPhoneNumber: Yup.string().required('Required'),
  requestorsFaxNumber: Yup.string(),
  signatureName: Yup.string(),
  signatureSource: Yup.string(),
  memberFirstName: Yup.string(),
  memberMiddleName: Yup.string().nullable(),
  memberLastName: Yup.string(),
  memberDateOfBirth: Yup.string().nullable(),
  memberId: Yup.string(),
  memberLivingFacility: Yup.string(),
  referringProvider: Yup.string(),
  referringProviderType: Yup.string(),
  facilityOrProviderName: Yup.string(),
  admissionOrServiceDate: Yup.string().nullable(),
  npiPinNumber: Yup.string(),
  providerPhoneNumber: Yup.string(),
  providerFaxNumber: Yup.string(),
  daysRequested: Yup.number()
    .min(0, 'Days requested cannot be a negative number')
    .nullable(),
  expectedLosDays: Yup.number()
    .min(0, 'Expected LOS Days cannot be a negative number')
    .nullable(),
  partBServices: Yup.string(),
  rehabPotential: Yup.string(),
  significantImprovementMade: Yup.string(),
  descriptionOrCptCode: Yup.string(),
  outPatientServicesArray: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      totalQty: Yup.number().nullable(),
      timesWeek: Yup.number().nullable(),
      duration: Yup.number().nullable(),
    })
  ),
  partBServicesNotes: Yup.string(),
  transferLocationName: Yup.string(),
  transferLocationPhone: Yup.string(),
  transferLocationAddress: Yup.string(),
  expectedDischargeDate: Yup.string().nullable(),
  appointmentNeeds: Yup.string(),
  files: Yup.mixed(),
});

export default validationSchema;


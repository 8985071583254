import { FC } from 'react';
import {
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  Registration,
} from '../../components';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainNavigation from '../../components/MainNavigation';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';

const RegistrationPage: FC = () => {
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();

  if (isLoading)
    return (
      <div>
        <div className="container mx-auto px-4 flex flex-col justify-center items-center">
          <CircularProgress />
        </div>
      </div>
    );
  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  };
  sessionStorage.setItem('_email', user.email);
  return (
    <div className="custom-container">
      <div>
        <Header />
        <MainNavigation />
        <HeaderTitle title="New Registrations" />
        <Registration />
      </div>
      <div>
        <Footer />
        <FooterBottom />
      </div>
    </div>
  );
};

export default RegistrationPage;

import { FC } from 'react';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useStyles from '../MemberDetailsComponent/MemberDetailsStyle';
import clsx from 'clsx';

const serviceRequestFilters = [
  {
    name: 'cptCode',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'description',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'caseServiceFromDate',
    operator: 'eq',
    type: 'date',
    value: '',
  },
  {
    name: 'caseServiceToDate',
    operator: 'eq',
    type: 'date',
    value: '',
  },
  {
    name: 'serviceAuthorizedQuantity',
    operator: 'gte',
    type: 'number',
    value: 0,
  },
  {
    name: 'serviceRequestedQuantity',
    operator: 'gte',
    type: 'number',
    value: 0,
  },
  {
    name: 'emailLastSubmitted',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'serviceAuthStatus',
    operator: 'eq',
    type: 'select',
    value: null,
  },
];

const MemberDetailsComponentServiceRequestsDesktop: FC = () => {
  const memberDetails = useSelector(
    (state: RootState) => state.memberDetails.memberDetailsData
  );
  const role = useSelector((state: RootState) => state.profile.data.role);
  let serviceRequestColumns = [
    {
      name: 'cptCode',
      header: 'CPT Code',
      defaultFlex: 1,
    },
    {
      name: 'description',
      header: 'Description',
      defaultFlex: 1,
    },
    {
      name: 'caseServiceFromDate',
      header: 'Service From',
      defaultFlex: 1,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Service From',
        };
      },
      render: (value: any) => {
        return value.data.caseServiceFromDate
          ? moment(value.data.caseServiceFromDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'caseServiceToDate',
      header: 'Service To',
      defaultFlex: 1,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Service To',
        };
      },
      render: (value: any) => {
        return value.data.caseServiceToDate
          ? moment(value.data.caseServiceToDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'serviceAuthorizedQuantity',
      header: 'Authorized Quantity',
      defaultFlex: 1,
    },
    {
      name: 'serviceRequestedQuantity',
      header: 'Requested Quantity',
      defaultFlex: 1,
    },
    {
      name: 'emailLastSubmitted',
      header: 'MD Reviewer',
      defaultFlex: 1,
    },
    {
      name: 'serviceAuthStatus',
      header: 'Status',
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: [
          {
            id: 'APPROVED',
            label: 'APPROVED',
          },
          {
            id: 'DONE',
            label: 'DONE',
          },
          {
            id: 'SUBMITTED',
            label: 'SUBMITTED',
          },
          {
            id: 'NEW',
            label: 'NEW',
          },
          {
            id: 'DENIED-ADMINISTRATIVE',
            label: 'DENIED-ADMINISTRATIVE',
          },
          {
            id: 'DENIED - MEDICAL NECESSITY',
            label: 'DENIED - MEDICAL NECESSITY',
          },
          {
            id: 'PENDING PEER REVIEW',
            label: 'PENDING PEER REVIEW',
          },
        ],
      },
      defaultFlex: 1,
      render: (value: any) => {
        return (
          <div
            className={clsx({
              [classes.serviceApproved]: value.value === 'APPROVED',
              [classes.servicePended]: value.value === 'PENDING PEER REVIEW',
              [classes.done]: value.value === 'DONE',
              [classes.new]: value.value === 'NEW',
              [classes.serviceSubmitted]: value.value === 'SUBMITTED',
              [classes.serviceDocumentsAdded]:
                value.value === 'DOCUMENTS ADDED',
              [classes.servicePendingDocuments]:
                value.value === 'PENDING DOCUMENTS',
              [classes.serviceDenied]:
                value.value === 'DENIED-ADMINISTRATIVE' ||
                value.value === 'DENIED' ||
                value.value === 'DENIED - MEDICAL NECESSITY',
            })}
          >
            {value.value}
          </div>
        );
      },
    },
  ];

  // TODO: implment this approach in case dashboard
  if (
    role !== 'MD - Reviewer' &&
    role !== 'MD - Reviewer Restricted' &&
    role !== 'Admin'
  ) {
    serviceRequestColumns = serviceRequestColumns.filter(
      (item) => item.name !== 'emailLastSubmitted'
    );
  }
  const classes = useStyles();
  const gridStyle = { minHeight: 350 };

  return (
    <div className="showDesktop" id="serviceRequestReactDataGrid">
      <ReactDataGrid
        licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
        idProperty="id"
        columns={serviceRequestColumns}
        dataSource={memberDetails.serviceRequests}
        style={gridStyle}
        defaultFilterValue={serviceRequestFilters}
        pagination={true}
        enableClipboard={true}
      />
    </div>
  );
};

export default MemberDetailsComponentServiceRequestsDesktop;

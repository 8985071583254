import { IRegisterForm, IUser, IUserUpdate } from "../interfaces/user";
import { getCurrentPlan } from '../planData';

const { permissions, site } = getCurrentPlan();

export const userInitialState: IUser = {
  id: 0,
  groupNpis: [],
  plansByState: [],
  isActive: null,
  role: '',
  userEmail: '',
  hasPortalAccess: null,
  hasBeenReviewed: null,
  profile: {
    taxId: '',
    firstName: '',
    lastName: '',
    jobRole: '',
    businessAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    providerName: '',
    practiceName: '',
    npis: [],
    providerStatus: '',
    plans: [],
  },
};

export const userInitialStateUpdate: IUserUpdate = {
  id: 0,
  firstName: '',
  lastName: '',
  groupNpis: [],
  plansByState: [],
  isActive: null,
  role: '',
  userEmail: '',
  hasPortalAccess: null,
  hasBeenReviewed: null,
  password: '',
  blocked: null,
  previousPlans: [],
  profile: {
    taxId: '',
    firstName: '',
    lastName: '',
    jobRole: '',
    businessAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    providerName: '',
    practiceName: '',
    npis: [],
    providerStatus: '',
    plans: [],
  },
};

export const initialValues: IRegisterForm = {
  taxId: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  jobRole: '',
  businessAddress: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  email: '',
  providerName: '',
  practiceName: '',
  npi: '',
  providerStatus: '',
  plans: [],
  permissions: permissions,
};
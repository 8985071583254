import { FC, useState, useEffect } from 'react';
import { formPayloadInitialState } from '../../providers/MainProvider';
import {
  useAuthorizationForm,
  usePostAttachements,
  useGetDraftDetails,
} from '../../services/ServiceHook';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pdf } from '@react-pdf/renderer';
import Modal from '../../components/Modal';
import { useParams } from 'react-router';
import { generatePdf } from './GeneratePdf';
import { getCurrentPlan } from '../../planData';

const titleStyle: any = {
  color: '#555F5F',
};

const contentStyle: any = {
  color: '#023B64',
};

const titleBlue: any = {
  color: 'white',
  backgroundColor: '#00376A',
  borderRadius: '5px',
  padding: '15px',
};

const titleGray: any = {
  color: '#00326F',
  backgroundColor: '#F2F5F9',
  borderRadius: '5px',
  padding: '15px',
};

const booleanStyle: any = {
  backgroundColor: '#F2F5F9',
  color: '#003869',
  padding: '10px',
  borderRadius: '5px',
  margin: '5px',
  display: 'inline-block',
};

const ConfirmationComponent: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postAuthorizationForm] = useAuthorizationForm();
  const [postAttachments] = usePostAttachements();
  const [localPdf, setLocalPdf] = useState('');
  const [localData, setLocalData] = useState(formPayloadInitialState.data);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [context, setContext] = useState('');
  const history = useHistory();

  // MODAL
  const [openModal, setOpenModal] = useState(false);
  let params = useParams<{ id: string }>();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [getDraftDetail] = useGetDraftDetails();
  const [headerTitle, setHeaderTitle] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [originalAttachments, setOriginalAttachments] = useState<any>({});
  const { site } = getCurrentPlan();
  let isTestingForm = false;

  if (
    site === 'provider-portal-dev-ui.azurewebsites.net' ||
    site === 'provider-portal-staging-ui.azurewebsites.net' ||
    site === 'provider-portal-dev-admin.tutelarhealth.io' ||
    site === 'provider-portal-staging-admin.tutelarhealth.io' ||
    site === 'localhost:3000'
  ) {
    isTestingForm = true;
  }

  useEffect(() => {
    (async () => {
      if (params.id) {
        const draftDetailsResponse = await getDraftDetail();
        if (draftDetailsResponse.length) {
          const confirmationData = draftDetailsResponse[0];
          setLocalData(confirmationData.auth);
          setOriginalAttachments(confirmationData.attachments);
          setLocalAttachments(confirmationData.attachments.attachments);

          let tempContext = '';
          switch (confirmationData.auth && confirmationData.auth.plan) {
            case 'Standard':
              tempContext = '/authorization/standard';
              setHeaderTitle('Standard Prior Authorization Request Form');
              break;
            case 'Expedited':
              tempContext = '/authorization/expedited';
              setHeaderTitle('Expedited Authorization Request Form');
              break;
            case 'Additional Days':
              tempContext = '/authorization/additional-days';
              setHeaderTitle('Additional Days Request Form');
              break;
            case 'NotificationOnly':
              tempContext = '/authorization/notification-only';
              setHeaderTitle('Notification Only Form');
              break;
          }
          setContext(tempContext);
          // Generate PDF
          if (confirmationData.auth.memberId) {
            createPdf(
              confirmationData.auth,
              confirmationData.attachments.attachments.length
            );
          }
          setIsLoadingData(false);
        }
      }
      setIsLoadingData(false);
    })();
  }, []);

  const createPdf = (data: any, numberOfAtachments: number = 0) => {
    const blob = pdf(
      generatePdf(data, headerTitle, numberOfAtachments)
    ).toBlob();
    blob.then((value) => {
      var reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onloadend = function () {
        var newFile = reader.result as string;
        const stripedFile = newFile.split(',')[1];
        setLocalPdf(stripedFile);
      };
    });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    let authType = 0;
    switch (localData && localData.authType) {
      case 'Standard':
        authType = 1;
        break;
      case 'Expedited':
        authType = 2;
        break;
      case 'Request for Additional Days':
        authType = 3;
        break;
      case 'Notification':
        authType = 5;
        break;
    }
    const currentPayload = {
      authType: authType,
      authorizationNumber: '',
      requestId: localData.requestId,
      plans: localData.sitePlans,
      data: localData,
    };
    let x = currentPayload;
    x.data.pdf = localPdf;
    delete x.data.authType;

    try {
      const authFormResponse = await postAuthorizationForm(x);
      if (authFormResponse.status === 200) {
        // TOOD FIX this when there is only one endpoint to confirm
        const attachmentPayload = {
          requestId: originalAttachments.requestId,
          sitePlans: originalAttachments.sitePlans,
          forExistingAuth: originalAttachments.forExistingAuth,
          memberId: originalAttachments.memberId,
          authorizationNumber: originalAttachments.authorizationNumber,
          attachments: originalAttachments.attachments,
        };
        if (attachmentPayload.attachments?.length) {
          const attachmentResponse = await postAttachments(attachmentPayload);
          if (attachmentResponse?.status === 200) {
            history.push('/authorization/success-message');
          }
        } else {
          history.push('/authorization/success-message');
        }
      } else {
        setOpenErrorModal(true);
        setErrorMessage(`The server reponse is: ${authFormResponse.message}`);
      }
    } catch (error: any) {
      setOpenErrorModal(true);
      setErrorMessage(`The server reponse is: ${error.response.data.message}`);
    }

    setIsSubmitting(false);
  };

  const handleCancel = () => {
    history.push('/dashboard');
  };

  if (isLoadingData) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <main className="container px-4 mx-auto py-4 mb-20">
      {isTestingForm && (
        <h2 className="text-red-500 mb-4">THIS IS A TEST SUBMISSION!</h2>
      )}
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>Plans: </span>
            {localData.sitePlans &&
              localData.sitePlans.map((item: string) => (
                <span style={contentStyle} key={item}>
                  {item},{' '}
                </span>
              ))}
          </div>
          <div>
            <span style={titleStyle}>Member:</span>{' '}
            <span style={contentStyle}>
              {localData.memberFirstName} {localData.memberLastName}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>ID:</span>{' '}
            <span style={contentStyle}>{localData.requestId}</span>
          </div>
          <div>
            <span style={titleStyle}>Request Type:</span>{' '}
            <span style={contentStyle}>{localData.authType}</span>
          </div>
          <div className="mt-2">
            <span style={titleStyle}>Attachments:</span>{' '}
            <span style={contentStyle}>{localAttachments.length}</span>
          </div>
        </div>
      </div>
      {/* <p className="mb-8 text-center">
          Most services if requested by or with a written order from a PCP or
          Plan NP are “autoauto-authorized” within 8 hours or less. CMS allows
          14 days for standard authorizations.{' '}
        </p> */}

      <h3 style={titleBlue} className="mb-4">
        Disclaimer Statements / Attestation
      </h3>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>
              Updated Clinical/ therapy documentation within 72 hours of
              admission (initial)/ last covered date (extension):
            </span>
            <span style={booleanStyle}>
              {localData.clinicalOrTherapyDocUpdated}
            </span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>
              Order or Clinical note included for out-of-network requests:
            </span>{' '}
            <span style={booleanStyle}>
              {localData.orderOrClinicalNoteIncluded}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>Requestor’s Name:</span>{' '}
            <span style={contentStyle}>{localData.requestorsName}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Phone Number:</span>{' '}
            <span style={contentStyle}>{localData.requestorsPhoneNumber}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Fax Number:</span>{' '}
            <span style={contentStyle}>{localData.requestorsFaxNumber}</span>
          </div>
        </div>
      </div>

      <h3 style={titleBlue} className="mb-4">
        Member Information
      </h3>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>Member Name:</span>{' '}
            <span style={contentStyle}>
              {localData.memberFirstName} {localData.memberLastName}
            </span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Date of Birth:</span>{' '}
            <span style={contentStyle}>{localData.memberDateOfBirth}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Member ID:</span>{' '}
            <span style={contentStyle}>{localData.memberId}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>Member Living Facility:</span>{' '}
            <span style={contentStyle}>{localData.memberLivingFacility}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Referring Provider:</span>{' '}
            <span style={contentStyle}>{localData.referringProvider}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Referring Provider Type:</span>{' '}
            <span style={contentStyle}>{localData.referringProviderType}</span>
          </div>
        </div>
      </div>
      {localData.authType !== 'Notification' && (
        <div>
          <h3 style={titleBlue} className="mb-4">
            Request Details
          </h3>
          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>
                  Admitting/ Servicing Facility/ Provider Name:
                </span>{' '}
                <span style={contentStyle}>
                  {localData.facilityOrProviderName}
                </span>
              </div>
              <div className="mb-2">
                <span style={titleStyle}>Admission Date/ Date of Service:</span>{' '}
                <span style={contentStyle}>
                  {localData.admissionOrServiceDate}
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>NPI/ Pin Number:</span>{' '}
                <span style={contentStyle}>{localData.npiPinNumber}</span>
              </div>
              <div className="mb-2">
                <span style={titleStyle}>Phone Number:</span>{' '}
                <span style={contentStyle}>
                  {localData.providerPhoneNumber}
                </span>
              </div>
              <div className="mb-2">
                <span style={titleStyle}>Fax Number:</span>{' '}
                <span style={contentStyle}>{localData.providerFaxNumber}</span>
              </div>
            </div>
          </div>

          <h4 style={titleGray} className="mb-4">
            Part A Services:
          </h4>
          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>Selected service:</span>{' '}
                <span style={contentStyle}>{localData.partAServices}</span>
              </div>
              <div className="mb-2">
                <span style={titleStyle}>Days Requested:</span>{' '}
                <span style={contentStyle}>{localData.daysRequested}</span>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>Expected LOS in Day:</span>{' '}
                <span style={contentStyle}>{localData.expectedLosDays}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <h4 style={titleGray} className="mb-4">
        Part B/ Outpatient Services
      </h4>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>Part B / Outpatient Services:</span>{' '}
            <span style={contentStyle}>{localData.partBServices}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Rehab Potential:</span>{' '}
            <span style={contentStyle}>{localData.rehabPotential}</span>
          </div>
          <div className="mb-2">
            <span style={titleStyle}>Description or CPT Code:</span>{' '}
            <span style={contentStyle}>{localData.descriptionOrCptCode}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-2">
            <span style={titleStyle}>Significant Improvement Made?</span>{' '}
            <span style={booleanStyle}>
              {localData.significantImprovementMade}
            </span>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="Member Details table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="center">Total Qty</TableCell>
              <TableCell align="center">Times Week</TableCell>
              <TableCell align="center">Duration </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localData.outPatientServicesArray.map((row: any) => (
              <TableRow key={row.memberId}>
                <TableCell>{row.type}</TableCell>
                <TableCell align="right">{row.totalQty}</TableCell>
                <TableCell align="right">{row.timesWeek}</TableCell>
                <TableCell align="right">{row.duration}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="my-4">
        <span style={titleStyle}>Notes:</span>{' '}
        <span style={contentStyle}>{localData.partBServicesNotes}</span>
      </div>
      {localData.authType !== 'Notification' && (
        <div>
          <h3 style={titleBlue} className="my-4">
            Discharge Planning Out Of Nursing Home / Assisted Or Independent
            Living
          </h3>
          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>Where are they going?</span>{' '}
                <span style={contentStyle}>
                  {localData.transferLocationName}
                </span>
              </div>
              <div className="mb-2">
                <span style={titleStyle}>Address:</span>{' '}
                <span style={contentStyle}>
                  {localData.transferLocationAddress}
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>Admission Date/ Date of Service:</span>{' '}
                <span style={contentStyle}>
                  {localData.expectedDischargeDate}
                </span>
              </div>
              <div className="mb-2">
                <span style={titleStyle}>Phone number:</span>{' '}
                <span style={contentStyle}>
                  {localData.transferLocationPhone}
                </span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mb-8">
            <div className="flex flex-col">
              <div className="mb-2">
                <span style={titleStyle}>
                  Home Health/ DME/ Specialist Follow-up Appointment Needs?
                </span>{' '}
                <span style={contentStyle}>{localData.appointmentNeeds}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {(localData.signatureName || localData.signatureSource) && (
        <div>
          <div className="signatureFont mt-4">{localData.signatureName}</div>{' '}
          <br />
          {localData.signatureDate} <br />
          <img src={localData.signatureSource || ''} alt="" />
        </div>
      )}

      {!isSubmitting ? (
        <div className="buttons-container flex flex-col md:flex-row justify-end mt-8">
          <button
            type="button"
            className="block button btn w-full md:w-auto mb-2 md:mb-0 mr-4"
            onClick={() => setOpenModal(true)}
          >
            Cancel
          </button>
          {localData.authType !== 'Notification' ? (
            <button
              type="button"
              className="button btn btn-blue mr-4 flex w-full md:w-auto mb-2 md:mb-0 justify-center"
              onClick={() => {
                history.push(`${context}/${localData.requestId}`);
              }}
            >
              <img
                src="/images/edit-white.svg"
                alt="Edit icon"
                className="pr-2"
              />{' '}
              Continue Editing
            </button>
          ) : (
            ''
          )}

          <button
            type="button"
            onClick={() => {
              handleSave();
            }}
            // disabled={!enableSubmit}
            className="btn block btn-red w-full md:w-auto mb-2 md:mb-0"
          >
            Confirm & Submit
          </button>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <CircularProgress />
          <h3>Sending form...</h3>
        </div>
      )}
      <Modal
        title="Are you sure you want to cancel?"
        description="All your information in this form will be lost"
        open={openModal}
        className="confirmationModal"
        onClose={() => setOpenModal(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="btn"
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={handleCancel}
          >
            Yes
          </button>
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        title="There was an error on form submission"
        description={errorMessage}
        open={openErrorModal}
        className="confirmationModal error"
        onClose={() => setOpenErrorModal(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            onClick={() => setOpenErrorModal(false)}
            className="btn"
          >
            O.k
          </button>
        </div>
      </Modal>
    </main>
  );
};

export default ConfirmationComponent;

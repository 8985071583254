import { AppBar, CircularProgress, Tab, Tabs } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AttachmentsSidebar from '../AttachmentsSidebar';
import DecisionSubmitSidebarCaseDetails from '../DecisionSubmitSidebarCaseDetails';
import DecisionSubmitSidebarAuthorizationHistory from '../DecisionSubmitSidebarAuthorizationHistory';
import { useGetMemberDetails } from '../../services/ServiceHook';
import { IMemberDetails } from '../../interfaces/interfaces';
import { memberDetailsInitialValue } from '../../initial-values/initial-values';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DecisionSubmitSidebar: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [getMemberDetails] = useGetMemberDetails();
  const [member, setMember] = useState<IMemberDetails>(
    memberDetailsInitialValue
  );

  useEffect(() => {
    (async () => {
      const memberDetailsResponse = await getMemberDetails();
      if (memberDetailsResponse) {
        setMember(memberDetailsResponse);
      }
      setIsLoading(false);
    })();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return (
      <div className="mt-8 mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="decision">
            <Tab label="Documents" {...a11yProps(0)} />
            <Tab label="Case Details" {...a11yProps(1)} />
            <Tab label="Authorization History" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AttachmentsSidebar member={member} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DecisionSubmitSidebarCaseDetails member={member} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DecisionSubmitSidebarAuthorizationHistory member={member} />
        </TabPanel>
      </div>
    </div>
  );
};

export default DecisionSubmitSidebar;

import { FC } from 'react';
import { IStandardForm } from '../../interfaces/standard-form';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import 'date-fns';

interface IProps {
  formValues: IStandardForm;
  touched: FormikTouched<IStandardForm>;
  errors: FormikErrors<IStandardForm>;
  hasNoPermissions: boolean;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
}

export const DisclaimerStatement: FC<IProps> = ({
  formValues,
  touched,
  errors,
  hasNoPermissions,
  getFieldProps,
}) => {
  return (
    <section id="disclaimerSection">
      <div className="pt-8">
        <h2>
          {formValues.plan !== 'NotificationOnly'
            ? 'Disclaimer Statements / Attestation'
            : 'Notification Information'}{' '}
        </h2>
      </div>
      {formValues.plan !== 'NotificationOnly' && (
        <>
          <FormControl
            component="fieldset"
            className="w-full"
            disabled={hasNoPermissions}
          >
            <div className="flex flex-col py-4 justify-between">
              <p className="pr-16">
                Updated Clinical/ therapy documentation within 72 hours of
                admission (initial)/ last covered date (extension).{' '}
                <span className="text-red-500">*</span>
              </p>
              <RadioGroup
                aria-label="gender"
                {...getFieldProps('clinicalOrTherapyDocUpdated')}
                className="flex flex-row flex-nowrap"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </div>
          </FormControl>
          {touched.clinicalOrTherapyDocUpdated &&
          errors.clinicalOrTherapyDocUpdated ? (
            <div className="text-red-500">
              {errors.clinicalOrTherapyDocUpdated}
            </div>
          ) : null}

          <FormControl
            component="fieldset"
            className="w-full"
            disabled={hasNoPermissions}
          >
            <div className="flex flex-col py-4 justify-between">
              <p className="pr-16">
                Order or Clinical note included for out-of-network requests.{' '}
                <span className="text-red-500">*</span>
              </p>
              <RadioGroup
                aria-label="gender"
                {...getFieldProps('orderOrClinicalNoteIncluded')}
                className="flex flex-row flex-nowrap"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </div>
          </FormControl>
          {touched.orderOrClinicalNoteIncluded &&
            errors.orderOrClinicalNoteIncluded && (
              <div className="text-red-500">
                {errors.orderOrClinicalNoteIncluded}
              </div>
            )}
        </>
      )}

      <div className="grid xl:grid-cols-3 xl:gap-6">
        <div className="flex flex-col my-4">
          <label className="pb-2">
            Requestor's Name <span className="text-red-500">*</span>
          </label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="requestorsName">Requestor's Name</InputLabel>
            <OutlinedInput
              id="requestorsName"
              label="Requestors Name"
              {...getFieldProps('requestorsName')}
            />
          </FormControl>
          {touched.requestorsName && errors.requestorsName && (
            <div className="text-red-500 font-bold mt-2">
              {errors.requestorsName}
            </div>
          )}
        </div>

        <div className="flex flex-col my-4">
          <label className="pb-2">
            Phone Number <span className="text-red-500">*</span>
          </label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="requestorsPhoneNumber">
              Phone Number
            </InputLabel>
            <OutlinedInput
              id="requestorsPhoneNumber"
              label="Phone Number"
              {...getFieldProps('requestorsPhoneNumber')}
            />
          </FormControl>
          {touched.requestorsPhoneNumber && errors.requestorsPhoneNumber && (
            <article className="text-red-500 font-bold mt-2">
              {errors.requestorsPhoneNumber}
            </article>
          )}
        </div>

        <div className="flex flex-col my-4">
          <label className="pb-2">Fax Number</label>
          <FormControl variant="outlined" disabled={hasNoPermissions}>
            <InputLabel htmlFor="requestorsFaxNumber">Fax Number</InputLabel>
            <OutlinedInput
              id="requestorsFaxNumber"
              label="Fax Number"
              {...getFieldProps('requestorsFaxNumber')}
            />
          </FormControl>
        </div>
      </div>
    </section>
  );
};

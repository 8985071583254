import { FC } from 'react';
import {
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  SuccessMessage,
} from '../../components';
import MainNavigation from '../../components/MainNavigation';

const SuccessMessagePage: FC = () => {
  return (
    <div className="authorization">
      <Header />
      <MainNavigation />
      <HeaderTitle title="Your authorization request has been successfully submitted" />
      <main className="container px-4 mx-auto flex flex-col lg:flex-row py-16">
        <SuccessMessage />
      </main>
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default SuccessMessagePage;

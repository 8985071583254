import { FC, useState } from 'react';
import clsx from 'clsx';

const Sidebar: FC = () => {
  const [currentLink, setCurrentLink] = useState('');
  const sidebarItems = [
    {
      id: '1',
      label: 'Request Type',
      link: '#',
      isActive: false,
    },
    {
      id: '2',
      label: 'Disclaimers',
      link: '#disclaimerSection',
      isActive: false,
    },
    {
      id: '3',
      label: 'Member Info',
      link: '#memberInformationSection',
      isActive: false,
    },
    {
      id: '4',
      label: 'Request Details',
      link: '#requestDetailsSection',
      isActive: false,
    },
    {
      id: '5',
      label: 'Discharge Planning',
      link: '#dischargePlanningSection',
      isActive: false,
    },
    {
      id: '6',
      label: 'Attach Clinical Documents',
      link: '#attachClinicalDocumentsSection',
      isActive: false,
    }
  ]

  const handleActive = (value: any) => {
    value.isActive = true;
    setCurrentLink(value.id)
  }
  return (
    <div className="sidebar">
      <ul className="sidbarFixedNavigation">
        {
          sidebarItems.map(item => (
            <li key={item.id}>
              <a className={clsx("px-8 py-4 block", { 'active': item.id === currentLink })} href={item.link} onClick={() => {
                handleActive(item)
              }}>
                {item.label}
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default Sidebar;
import { FC, useEffect, useState } from 'react';
import {
  CircularProgress,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setMemberSelected } from '../../store/memberDetails/memberDetails.slice';
import { memberSearchResultInitialValues } from '../../initial-values/initial-values';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useCreateCcmReferralMutation } from '../../hooks/mutations/useCcmReferralsMutations';
import Cookies from 'js-cookie';

window.moment = moment;

const initialValues: any = {
  id: '',
  requestId: '',
  memberId: '',
  memberFirstName: '',
  memberLastName: '',
  memberDateOfBirth: '',
  primaryDiagnosis: '',
  facilityName: '',
  dateOfReferral: new Date(),
  requesterName: '',
  requesterPhoneNumber: '',
  requesterMemberRelationship: '',
  requesterEmail: '',
  // outreachCall: '',
  reasonForReferral: '',
  sitePlans: [],
  memberBenefitPlan: '',
  facilityNpi: '',
};

const validationSchema = Yup.object().shape({
  memberId: Yup.string().required('Member Id is Required'),
  memberFirstName: Yup.string(),
  memberLastName: Yup.string(),
  memberDateOfBirth: Yup.string(),
  primaryDiagnosis: Yup.string(),
  facilityName: Yup.string().nullable(),
  dateOfReferral: Yup.string().nullable(),
  requesterName: Yup.string().required('Requester Name is Required'),
  requesterPhoneNumber: Yup.string().required(
    'Requester Phone Number is Required'
  ),
  requesterMemberRelationship: Yup.string().required(
    'Requester Relationship to Member is Required'
  ),
  requesterEmail: Yup.string().email('Requester Email must be a valid email'),
  reasonForReferral: Yup.string().required('Reason for Referral is Required'),
  sitePlans: Yup.array(),
});

interface IData {
  updateValue: (value: number) => void;
}

const CcmReferralsSubmitForm: FC<IData> = ({ updateValue }) => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const memberSelected = useSelector(
    (state: RootState) => state.memberDetails.memberSelected
  );
  const plans = useSelector(
    (state: RootState) => state.planData.data.permissions
  );
  const profile = useSelector((state: RootState) => state.profile.data);
  // Create Referral
  const {
    isLoading: isLoadingCreateReferral,
    mutate: createCcmReferral,
    error,
  } = useCreateCcmReferralMutation();

  const role = Cookies.get('aah-role');

  const handleRegister = async (payload: any) => {
    createCcmReferral(payload, {
      onSuccess: () => {
        setIsSnackbarOpen(true);
        setTimeout(() => {
          dispatch(setMemberSelected(memberSearchResultInitialValues));
          resetForm();
          updateValue(1);
        }, 3000);
      },
      onError: (error) => {
        setIsSnackbarErrorOpen(true);
      },
    });
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiInputBase-root': {
          minHeight: '100px',
        },
      },
      customSnack: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    })
  );

  function Alert(props: AlertProps) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        className="w-1/3 p-0 m-0"
        {...props}
      />
    );
  }

  const classes = useStyles();

  const {
    handleSubmit,
    setFieldValue,
    values: formValues,
    getFieldProps,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let payload = { ...values };
      payload.id = profile.id;
      payload.requestId = uuidv4();
      payload.sitePlans = plans;
      payload.memberId = memberSelected.memberId;
      payload.memberFirstName = memberSelected.memberFirstName;
      payload.memberLastName = memberSelected.memberLastName;
      payload.facilityName = memberSelected.facilityName || '';
      payload.facilityNpi = memberSelected.facilityNpi || '';
      payload.memberDateOfBirth = memberSelected.memberDateOfBirth
        ? moment(memberSelected.memberDateOfBirth).format('MM/DD/YYYY')
        : null;
      payload.memberBenefitPlan = memberSelected.memberBenefitPlan || '';
      handleRegister(payload);
    },
  });

  useEffect(() => {
    setFieldValue('memberId', memberSelected.memberId);
  }, [memberSelected.memberId, setFieldValue]);

  return (
    <div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8">
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">Primary Diagnosis</label>{' '}
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="primaryDiagnosis"></InputLabel>
              <OutlinedInput
                id="primaryDiagnosis"
                disabled={role === 'Viewer'}
                {...getFieldProps('primaryDiagnosis')}
              />
            </FormControl>
          </div>
        </div>
        <h2 className="mt-8">Referrer Information</h2>
        <div className="grid lg:grid-cols-2 gap-4 xl:gap-6 mt-8">
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">Date of Referral</label>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled
                className="w-full"
                format="MM/dd/yyyy"
                id="dateOfReferral"
                inputVariant="outlined"
                value={formValues.dateOfReferral}
                name="dateOfReferral"
                onChange={(date: any, value: any) => {
                  setFieldValue('dateOfReferral', value);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">Requester Name</label>{' '}
              <span className="text-red-500">*</span>
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="requesterName"></InputLabel>
              <OutlinedInput
                disabled={role === 'Viewer'}
                id="requesterName"
                {...getFieldProps('requesterName')}
              />
            </FormControl>
            {touched.requesterName && errors.requesterName ? (
              <div className="text-red-500">{errors.requesterName}</div>
            ) : null}
          </div>
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">Requester Phone Number</label>{' '}
              <span className="text-red-500">*</span>
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="requesterPhoneNumber"></InputLabel>
              <OutlinedInput
                disabled={role === 'Viewer'}
                id="requesterPhoneNumber"
                {...getFieldProps('requesterPhoneNumber')}
              />
            </FormControl>
            {touched.requesterPhoneNumber && errors.requesterPhoneNumber ? (
              <div className="text-red-500">{errors.requesterPhoneNumber}</div>
            ) : null}
          </div>
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">
                Requester Relationship to Member
              </label>{' '}
              <span className="text-red-500">*</span>
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="requesterMemberRelationship"></InputLabel>
              <OutlinedInput
                disabled={role === 'Viewer'}
                id="requesterMemberRelationship"
                {...getFieldProps('requesterMemberRelationship')}
              />
            </FormControl>
            {touched.requesterMemberRelationship &&
            errors.requesterMemberRelationship ? (
              <div className="text-red-500">
                {errors.requesterMemberRelationship}
              </div>
            ) : null}
          </div>
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">
                Requester Email, if Applicable
              </label>
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="requesterEmail"></InputLabel>
              <OutlinedInput
                disabled={role === 'Viewer'}
                id="requesterEmail"
                {...getFieldProps('requesterEmail')}
              />
            </FormControl>
            {touched.requesterEmail && errors.requesterEmail ? (
              <div className="text-red-500">{errors.requesterEmail}</div>
            ) : null}
          </div>
          <div className="w-full">
            <div className="mb-2">
              <label className="label-top">Request entered by</label>
            </div>
            <FormControl variant="outlined" className="w-full">
              <InputLabel htmlFor="requestedBy"></InputLabel>
              <OutlinedInput disabled id="requestedBy" value={user.email} />
            </FormControl>
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="flex">
            <div>
              <label className="label-top">Reason for Referral</label>{' '}
            </div>{' '}
            <span className="text-red-500 mb-2"> *</span>
          </div>

          <TextareaAutosize
            disabled={role === 'Viewer'}
            className="w-full border-2 p-4 rounded-md min-h-2"
            maxRows={10}
            aria-label="Reason for Referral"
            id="reasonForReferral"
            {...getFieldProps('reasonForReferral')}
          />
          {touched.reasonForReferral && errors.reasonForReferral ? (
            <div className="text-red-500">{errors.reasonForReferral}</div>
          ) : null}
        </div>

        {isLoadingCreateReferral ? (
          <div className="px-4 flex flex-col items-center">
            <CircularProgress />
          </div>
        ) : (
          <button
            type="submit"
            className={clsx(
              {
                'btn-disabled': !_.isEmpty(errors) || role === 'Viewer',
                'btn btn-blue': _.isEmpty(errors),
              },
              'btn mt-8'
            )}
            disabled={!_.isEmpty(errors) || role === 'Viewer'}
          >
            SEND REFERRAL
          </button>
        )}
      </form>
      <div className={classes.customSnack}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={() => {
            setIsSnackbarOpen(false);
            dispatch(setMemberSelected(memberSearchResultInitialValues));
          }}
          className="m-0 p-0 w-full min-w-0"
        >
          <Alert severity="success">
            Record has been submitted successfully!
          </Alert>
        </Snackbar>
      </div>
      {/* ERROR */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSnackbarErrorOpen}
        autoHideDuration={5000}
        onClose={() => {
          setIsSnackbarErrorOpen(false);
        }}
        className="m-0 p-0 w-full min-w-0"
      >
        <Alert severity="error">
          There was an error submitting the record, please try again later!
          Error message: {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CcmReferralsSubmitForm;

import { ICategory } from "./interfaces";

export const categoryInitialValues: ICategory[] = [
  {
    category: 'Diagnosis Error',
    suspectedType: [
      {
        label: 'Misdiagnosis',
        name: 'misdiagnosis',
        checked: false,
      },
      {
        label: 'Missed Diagnosis',
        name: 'missedDiagnosis',
        checked: false,
      },
    ],
  },
  {
    category: 'Medication Error',
    suspectedType: [
      {
        label: 'Prescribing wrong or contraindicated medication ',
        name: 'prescribingWrongOrContraindicatedMedication',
        checked: false,
      },
      {
        label:
          'Administration of wrong medication, wrong dosage, or by wrong route',
        name: 'administrationOfWrongMedicationWrongDosageOrByWrongRoute',
        checked: false,
      },
      {
        label: 'Failure to administer medication',
        name: 'failureToAdministerMedication',
        checked: false,
      },
      {
        label: 'Adverse event related to high-risk medication',
        name: 'adverseEventRelatedToHighRiskMedication',
        checked: false,
      },
    ],
  },
  {
    category: 'Evaluation and Treatment Error or Inadequacy',
    suspectedType: [
      {
        label: 'Inadequate examination or evaluation',
        name: 'inadequateExaminationOrEvaluation',
        checked: false,
      },
      {
        label: 'Inadequate or incorrect treatment',
        name: 'inadequateOrIncorrectTreatment',
        checked: false,
      },
    ],
  },
  {
    category: 'Injury or Harm',
    suspectedType: [
      {
        label: 'Fall injury',
        name: 'fallInjury',
        checked: false,
      },
      {
        label: 'Injury caused by another resident',
        name: 'injuryCausedByAnotherResident',
        checked: false,
      },
      {
        label: 'Injury caused by equipment',
        name: 'injuryCausedByEquipment',
        checked: false,
      },
      {
        label: 'Pressure ulcer-new or worsening',
        name: 'pressureUlcerNewOrWorsening',
        checked: false,
      },
    ],
  },
  {
    category: 'Poor Coordination of Care',
    suspectedType: [
      {
        label: 'Potentially preventable hospital admission',
        name: 'potentiallyPreventableHospitalAdmission',
        checked: false,
      },
      {
        label: 'Unplanned hospital readmission ',
        name: 'unplannedHospitalReadmission',
        checked: false,
      },
      {
        label: 'Premature transition in level of care',
        name: 'prematureTransitionInLevelOfCare',
        checked: false,
      },
      {
        label:
          'Delayed or lack of follow-up from a previously identified medical issue',
        name: 'delayedOrLackOfFollowUpFromAPreviouslyIdentifiedMedicalIssue',
        checked: false,
      },
      {
        label:
          'Failure or delay of a practitioner to submit a referral for a specialist or procedure/test',
        name: 'failureOrDelayOfAPractitionerToSubmitAReferralForASpecialistOrProcedureTest',
        checked: false,
      },
    ],
  },
  {
    category: 'Patient Rights Infringement',
    suspectedType: [
      {
        label: 'Lack of informed consent',
        name: 'lackOfInformedConsent',
        checked: false,
      },
    ],
  },
  {
    category: 'Serious Reportable Adverse Event',
    suspectedType: [
      {
        label:
          'Death not associated with the natural course of life or illness**',
        name: 'deathNotAssociatedWithTheNaturalCourseOfLifeOrIllness',
        checked: false,
      },
      {
        label: 'Severe brain or spinal damage**',
        name: 'severeBrainOrSpinalDamage',
        checked: false,
      },
      {
        label: 'A surgical procedure being performed on the wrong patient**',
        name: 'aSurgicalProcedureBeingPerformedOnTheWrongPatient',
        checked: false,
      },
      {
        label:
          'A surgical procedure unrelated to the patient’s diagnosis or medical needs being performed on any patient**',
        name: 'aSurgicalProcedureUnrelatedToThePatientsDiagnosisOrMedicalNeedsBeingPerformedOnAnyPatient',
        checked: false,
      },
      {
        label:
          'Serious physical or psychological injury (i.e. suicide, abuse, neglect, exploitation)',
        name: 'seriousPhysicalOrPsychologicalInjury',
        checked: false,
      },
      {
        label:
          'Loss of function of a limb not related to natural course of an illness or condition',
        name: 'lossOfFunctionOfALimbNotRelatedToNaturalCourseOfAnIllnessOrCondition',
        checked: false,
      },
    ],
  },
];
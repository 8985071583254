import { useQuery } from "@tanstack/react-query";
import { getAuthorizations } from "../../services/authorizations.service";
import { AUTHORIZATIONS_QUERY_KEYS } from "../../constants/authorizations";

const defaultOptions = {
  retry: true,
  refetchOnWindowFocus: false,
}

export const useAuthorizationsQuery = (queryProps: any) =>
  useQuery(
    [AUTHORIZATIONS_QUERY_KEYS.GET_AUTHORIZATIONS],
    () => getAuthorizations(),
    {
      ...defaultOptions,
      ...queryProps,
    }
  );
import { ProfileInitialValues } from "./profile";

export const Auth0ProfileInitialValue = {
  id: null,
  plansByState: [],
  isActive:null,
  userEmail: null,
  hasPortalAccess: null,
  groupNpis: [],
  role: null,
  hasBeenReviewed:null,
  profile: ProfileInitialValues,
  updatedAt: null,
  isEmailVerified: null,
}
import { useQuery } from '@tanstack/react-query';
import {
  getPqiReferral,
  getPqiReferrals,
} from '../../services/pqi-referrals.service';
import { PQI_REFERRALS_QUERY_KEYS } from '../../constants/pqi-referrals';

// staleTime is the length of time before your data becomes stale. 
// The default value in React Query is staleTime: 0 - which means your data is immediately stale!
const defaultOptions = {
  retry: false,
  refetchOnWindowFocus: false,
};

export const usePqiReferralsQuery = (queryProps: any) =>
  useQuery(
    [PQI_REFERRALS_QUERY_KEYS.GET_PQI_REFERRALS],
    () => getPqiReferrals(),
    {
      ...defaultOptions,
      ...queryProps,
    }
  );

export const usePqiReferralQuery = (requestId: string) =>
  useQuery(
    [PQI_REFERRALS_QUERY_KEYS.GET_PQI_REFERRAL],
    () => getPqiReferral(requestId),
    {
      // staleTime: 1000 * 60 * 3, // 3 minutes
      retry: true,
      refetchOnWindowFocus: false,
    }
  );


import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  approved: {
    background: '#81B920',
    color: '#fff',
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  partiallyApproved: {
    background: 'white',
    border: '1px solid #81b920',
    color: '#81b920',
    padding: '5px 10px',
    fontSize: '13px',
  },
  deferred: {
    background: 'white',
    border: '1px solid #1e508c', // Blue
    color: '#1e508c', // Blue
    padding: '5px 10px',
    fontSize: '13px',
  },
  deniedAdministrative: {
    background: 'white',
    border: '1px solid #dd4c21',
    color: '#dd4c21',
    padding: '5px 10px',
    fontSize: '13px',
  },
  deniedMedicalNecessity: {
    background: '#dd4c21',
    color: '#fff',
    padding: '5px 10px',
    fontSize: '13px',
  },
  outOfNetwork: {
    background: 'white',
    border: '1px solid #f5a81c',
    color: '#f5a81c',
    padding: '5px 10px',
    fontSize: '13px',
  },
  pended: {
    background: 'white',
    border: '1px solid #f5a81c',
    color: '#f5a81c',
    padding: '5px 10px',
    fontSize: '13px',
  },
  cancelled: {
    background: '#999999',
    color: 'white',
    padding: '5px 10px',
    fontSize: '13px',
  },
  waiting: {
    background: '#1e508c', // Blue
    color: 'white',
    padding: '5px 10px',
    fontSize: '13px',
    width: 'auto'
  },
  new: {
    background: '#1e508c', // Blue
    color: 'white',
    padding: '5px 10px',
    minWidth: '50px',
    fontSize: '12px',
    borderRadius: '25px',
    textAlign: 'center',
  },
  done: {
    background: '#2d8c1e',
    color: 'white',
    padding: '5px 10px',
    minWidth: '50px',
    fontSize: '12px',
    borderRadius: '25px',
    textAlign: 'center',
  },
  serviceApproved: {
    background: '#81b920',
    color: '#fff',
    padding: '5px 10px',
    minWidth: '50px',
    borderRadius: '25px',
    fontWeight: 'normal',
    fontSize: '12px',
    textAlign: 'center',
  },
  serviceDenied: {
    background: '#dd4c21',
    color: '#fff',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '25px',
  },
  servicePended: {
    background: '#f5a81c',
    border: '1px solid #f5a81c',
    color: 'white',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '25px',
  },
  serviceSubmitted: {
    background: '#99a2ac',
    border: '1px solid #99a2ac',
    color: 'white',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '25px',
  },
  serviceDocumentsAdded: {
    background: '#F39D0F',
    color: 'white',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '25px',
  },
  servicePendingDocuments: {
    border: '1px solid #F39D0F',
    color: '#F39D0F',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '25px',
  },
  authTypeStandardProcessing: {
    background: '#99a2ac',
    color: '#fff',
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  authTypeExpeditedReview: {
    background: '#ef4444',
    color: '#fff',
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  authTypeRetrospectiveReview: {
    border: '2px solid #1e3a8a',
    color: '#1e3a8a',
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  certTypeStandardProcessing: {
    background: '#f97316', //Orange
    color: 'white',
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  certTypeStandardStandard: {
    border: '2px solid #f97316', //Border Orange
    color: '#f97316', // Text Orange
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  certTypeInitial: {
    border: '2px solid #1e508c', //Border Blue
    color: '#1e508c', // Text Blue
    padding: '5px 10px',
    width: 'fit-content',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  notification: {
    border: '1px solid #1e508c',
    color: '#1e508c',
    padding: '5px 10px',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center'
  },
}));

export default useStyles;

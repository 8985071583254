import { FC, useEffect, useReducer } from 'react';
import { FormikErrors } from 'formik';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { categoryInitialValues } from '../categoryInitialValues';
import { ICategory, ISuspectedType } from '../interfaces';
import { IPqiReferralsSubmit } from '../../../interfaces/pqi-referrals';

interface IPqiSuspectedInformationProps {
  role: string | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IPqiReferralsSubmit>>;
}

// function reducer(state: any, action: any)
const reducer = (
  state: ICategory[],
  action: { type: ICategory; name: string }
) => {
  return state.map((category: ICategory) => {
    if (category.category === action.type.category) {
      return {
        ...category,
        suspectedType: category.suspectedType.map((suspectedType: any) => {
          if (suspectedType.name === action.name) {
            return { ...suspectedType, checked: !suspectedType.checked };
          } else {
            return suspectedType;
          }
        }),
      };
    } else {
      return category;
    }
  });
};

const PqiSuspectedInformation: FC<IPqiSuspectedInformationProps> = ({
  role,
  setFieldValue,
}) => {
  const [category, setCategory] = useReducer(reducer, categoryInitialValues);

  const handleChange = (item: ICategory, name: string) => {
    setCategory({ type: item, name: name });
  };

  useEffect(() => {
    const checkedTypes = category.flatMap((item) =>
      item.suspectedType.filter((type) => type.checked === true)
    );
    const checkedLabels = checkedTypes.map((type) => type.label);
    setFieldValue('suspectedCategory', checkedLabels);
  }, [category, setFieldValue]);

  return (
    <>
      <h2 className="mt-8">Section II - Potential Quality Issue</h2>
      <h3 className="text-red-400 mb-4">(Must check at least one)</h3>
      <div className="flex mb-4" style={{ color: '#023b64' }}>
        <div className="flex-1 text-lg">Suspected Category</div>
        <div className="flex-1 text-lg">Suspected Type</div>
      </div>
      {category.map((item: ICategory) => (
        <div className="flex border-t-2 my-2" key={item.category}>
          <div className="flex-1 mt-4">
            <label className="label-top">{item.category}</label>
          </div>
          <div className="flex-1">
            {item.suspectedType.map((suspectedType: ISuspectedType) => (
              <FormGroup className="flex flex-col" key={suspectedType.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={role === 'Viewer'}
                      checked={suspectedType.checked}
                      onChange={() => handleChange(item, suspectedType.name)}
                      name={suspectedType.name}
                      color="primary"
                    />
                  }
                  label={suspectedType.label}
                />
              </FormGroup>
            ))}
          </div>
        </div>
      ))}
      <p className="italic text-center">
        **Florida incidents that require reporting to AHCA within 3 days of
        occurrence if confirmed upon Medical Director Review{' '}
      </p>
    </>
  );
};

export default PqiSuspectedInformation;

import { useMutation } from '@tanstack/react-query';
import { createPqiReferral } from '../../services/pqi-referrals.service';
import { PQI_REFERRALS_QUERY_KEYS } from '../../constants/pqi-referrals';
import { queryClient } from '../../App';

const useCreatePqiReferralMutation = () =>
  useMutation({
    mutationFn: createPqiReferral,
    onSuccess: () => {
      queryClient.invalidateQueries([
        PQI_REFERRALS_QUERY_KEYS.GET_PQI_REFERRALS,
        PQI_REFERRALS_QUERY_KEYS.GET_PQI_REFERRAL,
      ]);
      queryClient.refetchQueries([
        PQI_REFERRALS_QUERY_KEYS.GET_PQI_REFERRALS,
      ])
    },
  });

export { useCreatePqiReferralMutation };

import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { styles } from './generate-pdf-styles';
import { IPqiReferralsSubmit } from '../../../interfaces/pqi-referrals';
import { CategoriesMap } from '../categories-map';
import moment from 'moment';

export const GeneratePdf = (data: IPqiReferralsSubmit) => {
  let nCategory = CategoriesMap(data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.pageHeaderText}>
            This Potential Quality Issue (PQI) Referral Form may be subject to
            state law provisions pertaining to confidentiality protections,
            attorney-work product, and other types of privilege. All documents,
            reports, and information relating to the review of a PQI are kept
            confidential in accordance with peer review protection.
          </Text>

          <View style={styles.pageHeaderTitle}>
            <Text>Potential Quality Issue (PQI) Referral Form</Text>
            <Text>CONFIDENTIAL—DO NOT COPY</Text>
            <Text>
              Email to{' '}
              <Text style={styles.textLink}>pqireferral@allyalign.com</Text>
            </Text>
          </View>

          <Text style={styles.sectionHeader}>
            Section I - General Information
          </Text>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Date of Referral: {moment(data.dateOfReferral).format('L')}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              Time: {moment(data.timeOfReferral).format('LT')}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Member Name: {data.memberName}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              DOB: {moment(data.memberDateOfBirth).format('L')}
            </Text>
            <Text style={styles.tableViewTextWidth10}>Sex: {data.sex}</Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Health Plan: {data.healthPlan}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              Member ID: {data.memberId}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Provider Name: {data.providerName}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              Provider Number: {data.providerNumber}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Facility Name: {data.facilityName}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              Facility Location: {data.facilityLocation}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Name of Person Submitting Referral:{' '}
              {data.nameOfPersonSubmittingReferral}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              Department and Title: {data.departmentAndTitle}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Contact Information: {data.contactInformation}
            </Text>
          </View>
          <Text style={styles.sectionHeader}>
            Section II - Potential Quality Issue
          </Text>

          {nCategory &&
            nCategory.length &&
            nCategory.map((item) => {
              return (
                <View style={styles.sectionTwo}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                        marginTop: '10px',
                        marginBottom: '5px',
                        width: '30%',
                      }}
                    >
                      {item.category}
                    </Text>
                    <View style={{ display: 'flex', flexDirection: 'column' }}>
                      {item.suspectedType.map((suspectedType) => (
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                          }}
                        >
                          <Text style={{ width: '68%', paddingRight: '10px' }}>
                            {' '}
                            {suspectedType.label}:{' '}
                          </Text>
                          <Text style={{ width: '32%' }}>
                            {suspectedType.checked.toString() === 'true'
                              ? 'Yes'
                              : 'No'}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              );
            })}
          <View style={styles.tableView}>
            <Text style={{ padding: '10px' }}>
              *Florida incidents that require reporting to AHCA within 3 days of
              occurrence if confirmed upon Medical Director Review
            </Text>
          </View>
          <Text style={styles.sectionHeader}>
            Section III - Occurrence Information
          </Text>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Date of Occurrence: {moment(data.dateOfOcurrence).format('L')}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              Time of Occurrence: {moment(data.timeOfOccurrence).format('LT')}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Was Patient Hospitalized?:{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {String(data.wasPatientHospitalized) === 'true' ? 'Yes' : 'No'}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>Name of Hospital: </Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.nameOfHospital ? data.nameOfHospital : ''}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Location of Hospital:{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.locationOfHospital ? data.locationOfHospital : ''}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Hospitalization Date:{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.hospitalizationDate
                ? moment(data.hospitalizationDate).format('L')
                : ''}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Hospitalization Time:{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.hospitalizationTime
                ? moment(data.hospitalizationTime).format('LT')
                : ''}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Was the incident reported to a state agency? *If yes, please
              provide the agency name.{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {String(data.wasTheIncidentReportedToAStateAgency) === 'true'
                ? 'Yes'
                : 'No'}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>Name of Agency:</Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.agencyName ? data.agencyName : ''}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Was a physician called? *If yes, please provide their
              recommendation within the description of the occurrence.{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {String(data.wasAPhysicianCalled) === 'true' ? 'Yes' : 'No'}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Physician Recommendation:{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.physicianRecomendation ? data.physicianRecomendation : ''}
            </Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.tableViewTextWidth45}>
              Brief Description of the Occurrence:{' '}
            </Text>
            <Text style={styles.tableViewTextWidth45}>
              {data.briefDescriptionOfTheOccurrence
                ? data.briefDescriptionOfTheOccurrence
                : ''}
            </Text>
          </View>

          <Text style={styles.sectionHeader}>Signature</Text>
          <View style={styles.tableViewSignature}>
            <Text>{data.signatureName ? data.signatureName : ''}</Text>
            <Text>{data.signatureDate ? data.signatureDate : ''}</Text>
            <Image src={data.signatureSource} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

import { FC } from 'react';
import { contactPlan, getCurrentPlan, phoneSection } from '../../planData';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ForumIcon from '@material-ui/icons/Forum';

const ContactInformation: FC = () => {
  const contactTitle: any = {
    height: '28px',
    color: '#D13E6A',
    fontFamily: 'Roboto Slab',
    fontSize: '20px',
    letterSpacing: '0',
    fontWeight: 'normal',
    marginBottom: '15px',
  };

  const containerStyle: any = {
    border: '1px solid #D0D4D8',
    borderRadius: '0 4px 4px 4px',
  };

  const sectionStyle: any = {
    borderBottom: '1px solid #D0D4D8',
  };

  const contactSubTitle: any = {
    color: '#023B64',
  };

  const contactInfo: any = {
    color: '#8F9595',
  };

  const foundPlanData = getCurrentPlan();

  return (
    <>
      <div style={containerStyle}>
        {foundPlanData.contact.map((item: contactPlan) => {
          if (!item.state) {
            return item.phones.map((data: phoneSection, index) => (
              <section className="p-4" style={sectionStyle} key={index}>
                <h2 style={contactTitle}>{data.section}</h2>
                <div className="flex break-words">
                  <div className="flex mr-4 w-full">
                    <div style={contactSubTitle} className="w-1/3">
                      <div className="pb-2">Phone</div>
                      <div className="pb-2">Email</div>
                    </div>
                    {data.section !== 'Utilization Management' ? (
                      <div className="flex-1" style={contactInfo}>
                        <div className="pb-2">{data.phone}</div>
                        <a href={'mailto:' + data.email}>{data.email}</a>
                      </div>
                    ) : (
                      <div className="" style={contactInfo}>
                        <div className="pb-2">{data.phone}</div>
                        <a href={'mailto:' + data.email}>{data.email}</a>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="pb-2" style={contactSubTitle}></div>
                    <div
                      className="pb-2"
                      style={contactInfo}
                      dangerouslySetInnerHTML={{
                        __html: data.notes,
                      }}
                    ></div>
                    <div className="pb-2" style={contactInfo}></div>
                    <div style={contactInfo}></div>
                    <div className="pb-2" style={contactInfo}></div>
                  </div>
                </div>
              </section>
            ));
          } else {
            return (
              <Accordion key={item.state}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{item.state}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {item.phones.map((data: phoneSection, index) => (
                      <section className="p-4" style={sectionStyle} key={index}>
                        <h2 style={contactTitle}>{data.section}</h2>
                        <div className="grid md:grid-cols-2 gap-4 break-words">
                          <div className="">
                            <div className="grid grid-cols-2 gap-2">
                              <div style={contactSubTitle}>
                                <div className="pb-2">Phone</div>
                                <div className="pb-2">Email</div>
                              </div>
                              {data.section !== 'Utilization Management' ? (
                                <div className="flex-1" style={contactInfo}>
                                  <div className="pb-2">{data.phone}</div>
                                  <a href={'mailto:' + data.email}>
                                    {data.email}
                                  </a>
                                </div>
                              ) : (
                                <div className="" style={contactInfo}>
                                  <div className="pb-2">{data.phone}</div>
                                  <a href={'mailto:' + data.email}>
                                    {data.email}
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="pb-2" style={contactSubTitle}></div>
                            <div
                              className="pb-2"
                              style={contactInfo}
                              dangerouslySetInnerHTML={{
                                __html: data.notes,
                              }}
                            ></div>
                            <div className="pb-2" style={contactInfo}></div>
                            <div style={contactInfo}></div>
                            <div className="pb-2" style={contactInfo}></div>
                          </div>
                        </div>
                      </section>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
      </div>
      {foundPlanData.providerDocuments && (
        <div className="mb-8">
          <h1 className="text-xl lg:text-3xl mt-8 mb-4">Provider Resources</h1>
          <div style={contactInfo}>
            Access important resources and provider documents.{' '}
            <a
              href={foundPlanData.providerDocuments}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0b6cb7', fontWeight: 600 }}
            >
              click here
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactInformation;

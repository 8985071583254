import { useMutation } from '@tanstack/react-query';
import {
  getAuthorizationDraft,
  postDraft,
  postDraftAttachments,
} from '../../services/authorizations-drafts.service';
import { queryClient } from '../../App';
import { AUTHORIZATIONS_DRAFT_QUERY_KEYS } from '../../constants/authorizations-draft';

export const useAuthorizationDraftMutations = () =>
  useMutation({
    mutationFn: getAuthorizationDraft,
  });

export const useAuthorizationDraftCreateMutations = () =>
  useMutation({
    mutationFn: postDraft,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        AUTHORIZATIONS_DRAFT_QUERY_KEYS.GET_AUTHORIZATIONS_DRAFT,
        AUTHORIZATIONS_DRAFT_QUERY_KEYS.GET_AUTHORIZATION_DRAFT,
      ]);
      queryClient.setQueriesData(
        [AUTHORIZATIONS_DRAFT_QUERY_KEYS.GET_AUTHORIZATIONS_DRAFT],
        data
      );
    },
  });

  export const useAuthorizationDraftCreateAttachmentsMutations = () =>
    useMutation({
      mutationFn: postDraftAttachments,
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          AUTHORIZATIONS_DRAFT_QUERY_KEYS.GET_AUTHORIZATIONS_DRAFT,
          AUTHORIZATIONS_DRAFT_QUERY_KEYS.GET_AUTHORIZATION_DRAFT,
        ]);
      },
    });

import { FC } from 'react';
import { IStandardForm } from '../../interfaces/standard-form';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import 'date-fns';
import { RequestDetailsPartA } from './RequestDetailsPartA';
import { RequestDetailsGeneralInformation } from './RequestDetailsGeneralInformation';
import { RequestDetailsPartB } from './RequestDetailsPartB';
import { RequestDetailsType } from './RequestDetailsType';

interface IProps {
  formValues: IStandardForm;
  touched: FormikTouched<IStandardForm>;
  errors: FormikErrors<IStandardForm>;
  hasNoPermissions: boolean;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IStandardForm>>;
}

export const RequestDetailsRefactor: FC<IProps> = ({
  formValues,
  touched,
  errors,
  hasNoPermissions,
  setFieldValue,
  getFieldProps,
}) => {
  return (
    <section id="requestDetailsSection" className="pt-6">
      {formValues.plan !== 'NotificationOnly' && (
        <h2 className="py-8 border-bottom-blue">Request Details</h2>
      )}
      <div className="mt-8 border-bottom-gray pb-8">
        {/* TODO: caseId = Authorization Number and is only used for Additional Days */}
        {/* {caseId && (
          <div className="flex flex-col mb-4 xl:mb-0">
            <h3>Id: {caseId}</h3>
          </div>
        )} */}
        {formValues.plan !== 'NotificationOnly' && (
          <RequestDetailsGeneralInformation
            formValues={formValues}
            getFieldProps={getFieldProps}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            hasNoPermissions={hasNoPermissions}
          />
        )}
      </div>

      {formValues.plan !== 'NotificationOnly' && (
        <RequestDetailsPartA
          getFieldProps={getFieldProps}
          touched={touched}
          errors={errors}
          hasNoPermissions={hasNoPermissions}
        />
      )}
      <section className="part-b">
        <h2 className="py-4">
          {formValues.plan !== 'NotificationOnly'
            ? 'Part B / Outpatient Services'
            : 'Part B / Therapy Services'}
        </h2>

        {/* Part B / Outpatient Services */}
        {formValues.plan !== 'NotificationOnly' && (
          <RequestDetailsPartB
            getFieldProps={getFieldProps}
            hasNoPermissions={hasNoPermissions}
          />
        )}

        {/* Part B: 
          Type
          Total Qty
          Times / Week
          Duration in weeks */}
        <RequestDetailsType
          formValues={formValues}
          setFieldValue={setFieldValue}
          getFieldProps={getFieldProps}
          hasNoPermissions={hasNoPermissions}
        />
      </section>
    </section>
  );
};

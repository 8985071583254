import { FC, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MemberSearch, DashboardTableMember } from '../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const MemberComponent: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const role = useSelector((state: RootState) => state.profile.data.role);

  useEffect(() => {
    role ? setIsLoading(false) : setIsLoading(true);
  }, [role]);

  if (isLoading) {
    return (
      <div className=" px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (
    role === 'Admin' ||
    role === 'MD' ||
    role === 'MD - Reviewer' ||
    role === 'MD - Reviewer Restricted' ||
    role === 'MD - Case Manager' ||
    role === 'Viewer' ||
    role === 'MD - Restricted'
  ) {
    return (
      <div className="px-4 md:px-8 mx-auto">
        <MemberSearch />
        <DashboardTableMember />
      </div>
    );
  }

  return (
    <div className="mx-8">
      <h3>Access to this page is currently restricted</h3>
    </div>
  );
};

export default MemberComponent;

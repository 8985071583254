import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IMemberDetails,
  IMemberSearchResult,
} from '../../interfaces/interfaces';
import { memberSearchResultInitialValues } from '../../initial-values/initial-values';

export const providerInitialValue = {
  npi: null,
  firstName: '',
  lastName: '',
  middleInitial: '',
  fullName: '',
  companyID: '',
  class: null,
  contract: null,
  contractEffectiveDate: null,
  termedDate: null,
};

export interface IMemberDetailsState {
  memberDetailsData: IMemberDetails;
  memberDetailsOtherCases: IMemberSearchResult[];
  memberSelected: IMemberSearchResult;
}

const initialState: IMemberDetailsState = {
  memberDetailsData: {
    serviceFromDate: null,
    serviceToDate: null,
    memberFirstName: '',
    memberMiddleName: '',
    memberLastName: '',
    submitted: null,
    memberDateOfBirth: null,
    planStartDate: null,
    planEndDate: null,
    coverageDate: null,
    lastUpdate: null,
    createdDate: null,
    authNumber: '',
    authType: '',
    authStatus: '',
    authorizatedUnits: null,
    requestedUnits: null,
    memberName: '',
    memberId: '',
    npi: null,
    authPCP: '',
    companyId: '',
    memberGender: '',
    requestingProvider: '',
    facilityProvider: '',
    isEligible: null,
    certType: '',
    serviceRequests: [],
    diagnoses: [],
    providers: {
      authPCP: providerInitialValue,
      facilityProvider: providerInitialValue,
      requestingProvider: providerInitialValue,
    },
    notes: [],
    documents: [],
  },
  memberDetailsOtherCases: [],
  memberSelected: memberSearchResultInitialValues
};

export const memberDetailsSlice = createSlice({
  name: 'memberDetails',
  initialState,
  reducers: {
    setMemberDetails: (state, action: PayloadAction<IMemberDetails>) => {
      state.memberDetailsData = action.payload;
    },
    setMemberDetailsOtherCases: (
      state,
      action: PayloadAction<IMemberDetails[]>
    ) => {
      state.memberDetailsOtherCases = action.payload;
    },
    setMemberSelected: (state, action: PayloadAction<IMemberSearchResult>) => {
      state.memberSelected = action.payload;
    },
  },
});

export const {
  setMemberDetails,
  setMemberDetailsOtherCases,
  setMemberSelected,
} = memberDetailsSlice.actions;
export const memberDetailsName = memberDetailsSlice.name;
export const memberDetailsReducer = memberDetailsSlice.reducer;

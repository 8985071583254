import { FC, useCallback, useEffect, useState } from 'react';
import { IDashboardDraft } from '../../../providers/MainProvider';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { useGetDashboardDraft } from '../../../services/ServiceHook';
import { CircularProgress } from '@material-ui/core';

window.moment = moment;

const submittedColumns = [
  {
    name: 'requestId',
    header: 'Submitted Id',
    defaultFlex: 1,
  },
  {
    name: 'createdAt',
    header: 'Created at',
    defaultFlex: 1,
  },
  {
    name: 'updatedAt',
    header: 'Updated at',
    defaultFlex: 1,
  },
  {
    name: 'memberId',
    header: 'Member Id',
    defaultFlex: 1,
  },
  {
    name: 'memberFirstName',
    header: 'First Name',
    defaultFlex: 1,
  },
  {
    name: 'memberLastName',
    header: 'Last Name',
    defaultFlex: 1,
  },
];

const submittedFilters = [
  {
    name: 'requestId',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'createdAt',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'updatedAt',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'memberId',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'memberFirstName',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
  {
    name: 'memberLastName',
    operator: 'startsWith',
    type: 'string',
    value: '',
  },
];

const DashboardTableSubmittedDesktop: FC = () => {
  const [getDraft] = useGetDashboardDraft();
  const [currentSubmitted, setCurrentSubmitted] = useState<IDashboardDraft[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(async () => {
    const draftData = await getDraft();
    const onlyDraftSubmitted = draftData.filter(
      (item: any) =>
        item.auth.requestId !== '' && item.auth.authStatus === 'Submitted'
    );
    if (onlyDraftSubmitted && onlyDraftSubmitted.length) {
      const submitted = onlyDraftSubmitted.map((item: any) => ({
        authNumber: item.auth.id,
        createdAt: item.auth.createdAt
          ? moment(item.auth.createdAt).format('LLL')
          : null,
        updatedAt: item.auth.updatedAt
          ? moment(item.auth.updatedAt).format('LLL')
          : null,
        authType: item.auth.authType,
        memberFirstName: item.auth.memberFirstName,
        memberLastName: item.auth.memberLastName,
        memberId: item.auth.memberId,
        requestId: item.auth.requestId,
        npi: item.auth.npiPinNumber,
        authStatus: item.auth.authStatus,
      }));
      setCurrentSubmitted(
        submitted.filter((item: any) => item.requestId !== '')
      );
    }
    setIsLoading(false);
  }, [getDraft]);

  useEffect(() => {
    getData();
  }, [getData]);

  const gridStyle = { minHeight: 650 };
  const rowStyle = { cursor: 'pointer' };

  if (isLoading) {
    return (
      <div className="mt-4 px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="mt-4 showDesktop">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="requestId"
          columns={submittedColumns}
          dataSource={currentSubmitted}
          style={gridStyle}
          rowStyle={rowStyle}
          defaultFilterValue={submittedFilters}
          pagination={true}
          enableSelection={true}
        />
      </div>
    </div>
  );
};

export default DashboardTableSubmittedDesktop;

import { FC, useCallback, useEffect, useState } from 'react';
import { IDashboardDraft } from '../../../providers/MainProvider';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import Modal from '../../Modal';
import {
  useDeleteDraft,
  useGetDashboardDraft,
} from '../../../services/ServiceHook';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { CircularProgress } from '@material-ui/core';

window.moment = moment;

const DashboardTableDraftDesktop: FC = () => {
  const [draftToDelete, setDraftToDelete] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [currentDraft, setCurrentDraft] = useState<IDashboardDraft[]>([]);
  const [deleteDraft] = useDeleteDraft();
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [getDraft] = useGetDashboardDraft();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const draftData = await getDraft();
      const onlyDraftAuthStatus = draftData.filter(
        (item: any) => item.auth.requestId && item.auth.authStatus === 'Draft'
      );

      if (onlyDraftAuthStatus && onlyDraftAuthStatus.length) {
        const drafts = onlyDraftAuthStatus.map((item: any) => ({
          authNumber: item.auth.id,
          createdAt: item.auth.createdAt
            ? moment(item.auth.createdAt).format('LLL')
            : null,
          updatedAt: item.auth.updatedAt
            ? moment(item.auth.updatedAt).format('LLL')
            : null,
          authType: item.auth.authType,
          memberFirstName: item.auth.memberFirstName,
          memberLastName: item.auth.memberLastName,
          memberId: item.auth.memberId,
          requestId: item.auth.requestId,
          npi: item.auth.npiPinNumber,
          authStatus: item.auth.authStatus,
        }));
        setCurrentDraft(drafts.filter((item: any) => item.requestId));
      }
      setIsLoading(false);
    };
    getData();
  }, [getDraft]);

  const draftColumns = [
    {
      name: 'requestId',
      header: 'Draft Id',
      defaultFlex: 1,
    },
    {
      name: 'createdAt',
      header: 'Created at',
      defaultFlex: 1,
    },
    {
      name: 'updatedAt',
      header: 'Updated at',
      defaultFlex: 1,
    },
    {
      name: 'memberFirstName',
      header: 'First Name',
      defaultFlex: 1,
    },
    {
      name: 'memberLastName',
      header: 'Last Name',
      defaultFlex: 1,
    },
    {
      name: 'authType',
      header: 'Type',
      defaultFlex: 1,
    },
  ];

  const draftFilters = [
    {
      name: 'requestId',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'createdAt',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'updatedAt',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'memberFirstName',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'memberLastName',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'authType',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
  ];

  // TODO: implement this into Draft Details
  const handleDraftDelete = async () => {
    if (draftToDelete) {
      const deleteResponse = await deleteDraft(draftToDelete);
      if (deleteResponse.status === 200) {
        setCurrentDraft((currentDraft) => {
          return currentDraft.filter(
            (item) => item.requestId !== draftToDelete
          );
        });
      } else {
        setOpenErrorMessage(true);
        console.log('there was en error deleting');
      }
      setOpenModal(false);
    }
  };

  const onSelectionChange = useCallback(({ data }) => {
    switch (data.authType) {
      case 'Standard':
        history.push(`/authorization/standard/${data.requestId}`);
        break;
      case 'Expedited':
        history.push(`/authorization/expedited/${data.requestId}`);
        break;
      case 'Request for Additional Days':
        history.push(`/authorization/additional-days/${data.requestId}`);
        break;
      case 'Notification':
        history.push(`/authorization/notification-only/${data.requestId}`);
        break;
    }
  }, []);

  const gridStyle = { minHeight: 650 };
  const rowStyle = { cursor: 'pointer' };

  if (isLoading) {
    return (
      <div className="mt-4 px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="mt-4 showDesktop">
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="requestId"
          columns={draftColumns}
          dataSource={currentDraft}
          style={gridStyle}
          rowStyle={rowStyle}
          defaultFilterValue={draftFilters}
          pagination={true}
          enableSelection={true}
          onSelectionChange={onSelectionChange}
        />
      </div>
      {/* TODO: Implement this into Auth details */}
      <Modal
        title="Are you sure you want to Delete?"
        description="All your information in this record will be lost"
        open={openModal}
        className="confirmationModal"
        onClose={() => setOpenModal(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="btn"
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={handleDraftDelete}
          >
            Yes
          </button>
        </div>
      </Modal>

      <Modal
        title="The record could not be deleted"
        description="Please try again"
        open={openErrorMessage}
        className="confirmationModal"
        onClose={() => setOpenErrorMessage(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            className="btn btn-green"
            onClick={() => setOpenErrorMessage(false)}
          >
            Yes
          </button>
        </div>
      </Modal>

      <ErrorMessage />
    </div>
  );
};

export default DashboardTableDraftDesktop;

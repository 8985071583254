import { Chip } from '@material-ui/core';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import moment from 'moment';

export const columns = [
  {
    name: 'id',
    header: 'Id',
  },
  {
    id: 'firstName',
    name: 'firstName',
    header: 'First Name',
    render: (value: any) => {
      return value.data.profile.firstName;
    },
    defaultFlex: 1,
  },
  {
    name: 'lastName',
    header: 'Last Name',
    render: (value: any) => {
      return value.data.profile.lastName;
    },
    defaultFlex: 1,
  },
  {
    name: 'userEmail',
    header: 'Email',
    defaultFlex: 1,
  },
  {
    name: 'providerName',
    header: 'Provider Name',
  },
  {
    name: 'practiceName',
    header: 'Practice Name',
  },
  {
    name: 'npi',
    header: 'NPI #',
  },
  {
    name: 'state',
    header: 'State',
  },
  {
    name: 'plansByState',
    header: 'Plans',
    defaultFlex: 1,
    render: (value: any) => {
      return (
        <div>
          {value.data.plansByState &&
            value.data.plansByState.map((item: string, index: number) => (
              <Chip
                key={index}
                label={item}
                style={{
                  marginRight: '5px',
                  marginBottom: '5px',
                }}
              />
            ))}
        </div>
      );
    },
  },
  {
    name: 'createdAt',
    header: 'Date',
    minWidth: 150,
    filterEditor: DateFilter,
    filterEditorProps: (value: any, index: number) => {
      return {
        dateFormat: 'MM/DD/YYYY',
        cancelButton: false,
        highlightWeekends: false,
      };
    },
    render: (value: any) => {
      return value.data.createdAt
        ? moment(value.data.createdAt).format('MM/DD/YYYY')
        : null;
    },
  },
];

import axios from 'axios';
import Cookies from 'js-cookie';
import { appConfig } from '../config';

const instance = axios.create({
  baseURL: appConfig.rootUrl,
});

// https://github.com/axios/axios#interceptors

// Add access token to headers if it exists
instance.interceptors.request.use((config) => {
  const configCopy = { ...config };
  const token = Cookies.get('aah-token');
  if (token) configCopy.headers.Authorization = `Bearer ${token}`;
  return configCopy;
});

export default instance;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

interface IProfile {
  id: string;
  plansByState: string[];
  isActive: boolean | null;
  userEmail: string;
  hasPortalAccess: boolean | null;
  groupNpis: string[];
  role: string;
  hasBeenReviewed: boolean | null;
  profile: {
    npis: string[];
    email: string;
    plans: string[];
    firstName: string;
    lastName: string;
  };
  updatedAt: string | Date | null;
  isEmailVerified: boolean | null;
}

interface IProfileState {
  data: IProfile
}

const initialState: IProfileState = {
  data: {
    id: '',
    plansByState: [],
    isActive: null,
    userEmail: '',
    hasPortalAccess: null,
    groupNpis: [],
    role: '',
    hasBeenReviewed: null,
    profile: {
      npis: [],
      email: '',
      plans: [],
      firstName: '',
      lastName: '',
    },
    updatedAt: null,
    isEmailVerified: null,
  }
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IProfile>) => {
      state.data = action.payload;
      sessionStorage.setItem('profileId', state.data.id);
      Cookies.set('aah-profileId', state.data.id);
    }
  }
});

export const { setProfile } = profileSlice.actions;
export const profileName = profileSlice.name;
export const profileReducer = profileSlice.reducer;

import { useQuery } from '@tanstack/react-query';
import { CCM_REFERRALS_QUERY_KEYS } from '../../constants/ccm-referrals';
import { getCcmReferral, getCcmReferrals } from '../../services/ccm-referrals.service';


// staleTime is the length of time before your data becomes stale. 
// The default value in React Query is staleTime: 0 - which means your data is immediately stale!
const defaultOptions = {
  retry: false,
  refetchOnWindowFocus: false,
};

const useCcmReferralsQuery = (queryProps: any) =>
  useQuery(
    [CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRALS],
    () => getCcmReferrals(),
    {
      ...defaultOptions,
      ...queryProps,
    }
  );

const useCcmReferralQuery = (requestId: string) =>
  useQuery(
    [CCM_REFERRALS_QUERY_KEYS.GET_CCM_REFERRAL],
    () => getCcmReferral(requestId),
    {
      retry: true,
      refetchOnWindowFocus: false,
    }
  );

export { useCcmReferralsQuery, useCcmReferralQuery };

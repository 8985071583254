import { FC, useEffect, useState } from 'react';
import { useGetNpiRegistry } from '../../services/ServiceHook';
import useStyles from '../MemberDetailsComponent/MemberDetailsStyle';
import clsx from 'clsx';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IMemberDetails } from '../../interfaces/interfaces';
interface IProps {
  member: IMemberDetails;
}

const DecisionSubmitSidebarCaseDetails: FC<IProps> = ({ member }) => {
  const [getNpiRegistry] = useGetNpiRegistry();
  const [facilityProvider, setFacilityProvider] = useState('');

  useEffect(() => {
    (async () => {
      if (member) {
        const response = await getNpiRegistry(member.facilityProvider);
        if (response && response.status === 200) {
          setFacilityProvider(response.data.name);
        }
      }
    })();
  }, [member.facilityProvider]);

  const classes = useStyles();

  return (
    <div>
      <table className="authorization-table mb-4">
        <tbody>
          <tr>
            <th>Authorization Id</th>
            <td>{member.authNumber}</td>
          </tr>
          <tr>
            <th>Member Name</th>
            <td>
              {member.memberFirstName} {member.memberLastName}
            </td>
          </tr>
          <tr>
            <th>Member ID</th>
            <td>{member.memberId}</td>
          </tr>
          <tr>
            <th>Date of Birth</th>
            <td>{member.memberDateOfBirth}</td>
          </tr>
          <tr>
            <th>Gender</th>
            <td>{member.memberGender}</td>
          </tr>
          <tr>
            <th>Facility Provider</th>
            <td>
              {facilityProvider} ({member.facilityProvider})
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              <span
                className={clsx(
                  {
                    [classes.approved]: member.authStatus === 'APPROVED',
                    [classes.partiallyApproved]:
                      member.authStatus === 'PARTIALLY APPROVED',
                    [classes.deferred]: member.authStatus === 'DEFERRED',
                    [classes.deniedAdministrative]:
                      member.authStatus === 'DENIED--ADMINISTRATIVE',
                    [classes.deniedMedicalNecessity]:
                      member.authStatus === 'DENIED - MEDICAL NECESSITY',
                    [classes.outOfNetwork]:
                      member.authStatus === 'OUT OF NETWORK INPATIENT',
                    [classes.pended]: member.authStatus === 'PENDED',
                    [classes.cancelled]: member.authStatus === 'CANCELLED',
                    [classes.waiting]:
                      member.authStatus === `WAITING ON ADDT'L DOCUMENTS` ||
                      member.authStatus === `UNDER MEDICAL REVIEW`,
                  },
                  'custom-chip green'
                )}
              >
                {member.authStatus}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {member.serviceRequests.length
        ? member.serviceRequests.map((item: any, key: number) => {
            return (
              <Accordion className="mb-4" key={key}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="service-request-header"
                >
                  <div className="flex justify-between w-full">
                    <div className="font-bold">
                      <span className="text-gray-500">{item.cptCode}</span>{' '}
                      {item.description}
                    </div>
                    <div className="flex">
                      <div
                        className={clsx({
                          [classes.done]: item.serviceAuthStatus === 'APPROVED',
                          [classes.done]: item.serviceAuthStatus === 'DONE',
                          [classes.new]: item.serviceAuthStatus === 'NEW',
                          [classes.new]: item.serviceAuthStatus === 'SUBMITTED',
                          [classes.deniedMedicalNecessity]:
                            item.serviceAuthStatus ===
                              'DENIED-ADMINISTRATIVE' ||
                            item.serviceAuthStatus ===
                              'DENIED - MEDICAL NECESSITY',
                        })}
                      >
                        {item.serviceAuthStatus}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    <hr className="mb-4" />
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Service from: </span>
                      <span className="font-bold">
                        {item.caseServiceFromDate
                          ? moment(item.caseServiceFromDate).format('L')
                          : null}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Service to: </span>
                      <span className="font-bold">
                        {item.caseServiceToDate
                          ? moment(item.caseServiceToDate).format('L')
                          : null}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Requested Quantity:</span>
                      <span className="font-bold">
                        {item.serviceRequestedQuantity}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">
                        Authorized Quantity:{' '}
                      </span>
                      <span className="font-bold">
                        {item.serviceAuthorizedQuantity}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Reviewer: </span>
                      <span className="font-bold">
                        {item.emailLastSubmitted}
                      </span>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })
        : null}
    </div>
  );
};

export default DecisionSubmitSidebarCaseDetails;

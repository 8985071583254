import { FC, useEffect, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { setMemberSelected } from '../../store/memberDetails/memberDetails.slice';
import { memberSearchResultInitialValues } from '../../initial-values/initial-values';
import { useGetAllowedNpis } from '../../hooks/queries/useAllowedNPIQuery';
import { Alert } from '@material-ui/lab';
import { getCurrentPlan } from '../../planData';

const RequestType: FC = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [enableContinue, setEnableContinue] = useState(false);
  const history = useHistory();
  const [showNotificationOnlyLink, setShowNotificationOnlyLink] =
    useState(false);
  const profile = useSelector((state: RootState) => state.profile.data);
  const { site } = getCurrentPlan();
  const {
    data: allowedNpiData,
    isLoading,
    isError,
    error,
  } = useGetAllowedNpis();

  useEffect(() => {
    if (allowedNpiData && allowedNpiData.length) {
      const intersection = profile.groupNpis.filter((element) =>
        allowedNpiData.includes(element)
      );
      if (intersection && intersection.length) {
        setShowNotificationOnlyLink(true);
      }
    }
  }, [allowedNpiData, profile.groupNpis]);

  const handleContinue = () => {
    // CLEAR Member Selected
    dispatch(setMemberSelected(memberSearchResultInitialValues));
    switch (value) {
      case 'standard':
        history.push(`/authorization/standard/new`);
        break;
      case 'expedited':
        history.push(`/authorization/expedited/new`);
        break;
      case 'additionalDays':
        history.push(`/authorization/additional-days/new`);
        break;
      case 'notificationOnly':
        history.push(`/authorization/notification-only/new`);
        break;
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  return (
    <div className="w-full px-4 md:px-8 mx-auto">
      <FormControl component="fieldset">
        <h2 className="mb-4">Request Type</h2>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setEnableContinue(true);
          }}
        >
          <FormControlLabel
            value="standard"
            control={<Radio color="primary" />}
            label="Initial Request: Standard"
          />
          <p className="mb-4 lg:pl-8">
            CMS allows up to 72 hours for Part B medications and 14 days for all
            other standard authorizations requests.
          </p>

          <FormControlLabel
            value="expedited"
            control={<Radio color="primary" />}
            label="Initial Request: Expedited "
          />
          <p className="mb-4 lg:pl-8">
            Expedited requests are reserved for instances where waiting for a
            decision under the standard timeframe could place the Member's life,
            health, or ability to gain maximum function in serious jeopardy.
          </p>

          <FormControlLabel
            value="additionalDays"
            control={<Radio color="primary" />}
            label="Request for Additional Days"
          />

          {(site === 'alignseniorcare.com' ||
            site === 'localhost:3000' ||
            site === 'provider-portal-dev-ui.azurewebsites.net' ||
            site === 'provider-portal-staging-ui.azurewebsites.net' ||
            site === 'providers.alignseniorcare.com') &&
          showNotificationOnlyLink ? (
            <FormControlLabel
              value="notificationOnly"
              control={<Radio color="primary" />}
              label="Notification Only"
            />
          ) : null}
        </RadioGroup>
      </FormControl>

      <hr className="mt-8" />
      <div className="buttons-container flex justify-between py-8">
        <Link to="/dashboard">Cancel</Link>
        <button
          type="button"
          onClick={handleContinue}
          className={clsx(
            { 'btn-disabled': !enableContinue, 'btn-green': enableContinue },
            'btn'
          )}
          disabled={!enableContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default RequestType;

import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { IMemberSearchResult } from '../../MemberDetailsComponent/model';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import '@inovua/reactdatagrid-enterprise/index.css';
import useStyles from '../DashboardTable/dashboardStyle';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { IMember } from '../../../interfaces/interfaces';

interface IControls {
  label: string;
  value: string;
}

window.moment = moment;

interface IProps {
  data: IMember[];
}

const DashboardTableMemberMobile: FC<IProps> = ({ data }) => {
  const [currentMemberData, setCurrentMemberData] = useState<
    IMemberSearchResult[] | []
  >([]);
  const history = useHistory();
  const classes = useStyles();
  const [filterBy, setFilterBy] = useState('');
  const [selectedServiceFrom, setSelectedServiceFrom] = useState<Date | null>(
    null
  );
  const [selectedServiceTo, setSelectedServiceTo] = useState<Date | null>(null);
  const [filterValue, setFilterValue] = useState('');

  const filterByControls = [
    {
      label: 'Service From',
      value: 'serviceFromDate',
    },
    {
      label: 'Service To',
      value: 'serviceToDate',
    },
    {
      label: 'Diagnosis',
      value: 'diagnoses',
    },
    {
      label: 'Procedure',
      value: 'serviceRequests',
    },
    {
      label: 'Status',
      value: 'authStatus',
    },
  ];

  useEffect(() => {
    if (filterBy !== 'serviceFromDate') {
      setSelectedServiceFrom(null);
    }
    if (filterBy !== 'serviceTo') {
      setSelectedServiceTo(null);
    }
    if (filterBy) {
      setFilterValue('');
    }
  }, [filterBy]);

  useEffect(() => {
    // TODO: Improve this
    let found = [...data];

    if (filterBy === 'serviceFromDate' && selectedServiceFrom) {
      found = data.filter(
        (item: any) =>
          moment(item.serviceFromDate).format('MM/DD/yyyy').toString() ===
          moment(selectedServiceFrom).format('MM/DD/yyyy').toString()
      );
    }

    if (filterBy === 'serviceToDate' && selectedServiceTo) {
      found = data.filter(
        (item: any) =>
          moment(item.serviceToDate).format('MM/DD/yyyy').toString() ===
          moment(selectedServiceTo).format('MM/DD/yyyy').toString()
      );
    }

    // Status or other input text
    if (filterValue.length) {
      if (filterBy === 'diagnoses') {
        found = data.filter((item: any) => {
          const a = item.diagnoses.filter((d: any) => {
            const isFound = d.icdCode
              .toLowerCase()
              .includes(filterValue.toLowerCase());
            return isFound ? d : null;
          });
          return a.length ? true : false;
        });
      } else if (filterBy === 'serviceRequests') {
        found = data.filter((item: any) => {
          const a = item.serviceRequests.filter((d: any) => {
            const isFound = d.cptCode
              .toLowerCase()
              .includes(filterValue.toLowerCase());
            return isFound ? d : null;
          });
          return a.length ? true : false;
        });
      } else if (filterBy === 'authStatus') {
        found = data.filter((item: any) =>
          item['authStatus'].toLowerCase().includes(filterValue.toLowerCase())
        );
      }
    }
    setCurrentMemberData(found);
  }, [filterValue, filterBy, selectedServiceFrom, selectedServiceTo]);

  const clearFilters = () => {
    setSelectedServiceFrom(null);
    setSelectedServiceTo(null);
    setFilterValue('');
    setFilterBy('');
    setCurrentMemberData(data);
  };

  const handleDetails = (authNumber: string) => {
    history.push(`/dashboard/${authNumber}`);
  };

  const handleChangeFilterBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBy((event.target as HTMLInputElement).value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  const handleSelectedServiceFrom = (date: Date | null) => {
    setSelectedServiceFrom(date);
  };

  const handleSelectedServiceTo = (date: Date | null) => {
    setSelectedServiceTo(date);
  };

  return (
    <section className="customTable w-full mb-16 showMobile">
      {currentMemberData.length ? (
        <>
          <div>
            <Accordion className="mb-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="filters"
                id="er-notifications-filters"
              >
                <div className="flex flex-col w-full">
                  <div className="font-bold">
                    <span className="text-gray-500">Filters</span>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="flex flex-col">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Filter by</FormLabel>
                  <RadioGroup
                    aria-label="filterBy"
                    name="filterBy"
                    value={filterBy}
                    onChange={handleChangeFilterBy}
                  >
                    {filterByControls.map((item: IControls) => {
                      return (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={<Radio color="primary" />}
                          label={item.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {filterBy &&
                filterBy !== 'serviceFromDate' &&
                filterBy !== 'serviceToDate' ? (
                  <form noValidate autoComplete="off" className="w-full">
                    <TextField
                      className="w-full"
                      id="filled-name"
                      label="text here..."
                      value={filterValue}
                      onChange={handleFilterChange}
                      disabled={!filterBy ? true : false}
                    />
                  </form>
                ) : null}
                {filterBy && filterBy === 'serviceFromDate' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDatePicker
                        className="w-full"
                        margin="normal"
                        id="date-picker-dialog"
                        label="Service From"
                        format="MM/dd/yyyy"
                        value={selectedServiceFrom}
                        onChange={handleSelectedServiceFrom}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : null}
                {filterBy && filterBy === 'serviceToDate' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDatePicker
                        className="w-full"
                        margin="normal"
                        id="date-picker-dialog"
                        label="Service To"
                        format="MM/dd/yyyy"
                        value={selectedServiceTo}
                        onChange={handleSelectedServiceTo}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : null}
                <button onClick={clearFilters} className="mt-4 border-2 p-2">
                  Clear Filters
                </button>
              </AccordionDetails>
            </Accordion>
            {currentMemberData.map((row: IMemberSearchResult) => (
              <Accordion className="mb-4 mt-4" key={row.authNumber}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="current-submitted"
                >
                  <div className="flex flex-col w-full">
                    <div>{row.memberId}</div>
                    <div className="font-bold">
                      {row.memberFirstName} {row.memberLastName}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    <hr className="mb-4" />
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">ID: </span>
                      <span className="font-bold">{row.authNumber}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Service From </span>
                      <span className="font-bold">
                        {row.serviceFromDate
                          ? moment(row.serviceFromDate).format('MM/DD/YYYY')
                          : null}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Service To </span>
                      <span className="font-bold">
                        {row.serviceToDate
                          ? moment(row.serviceToDate).format('MM/DD/YYYY')
                          : null}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Diagnosis: </span>
                      <span className="font-bold">
                        {row.diagnoses.map((item: any) => (
                          <Chip
                            key={item.icdCode}
                            className="mr-2"
                            title={item.diagnosisDescription}
                            label={item.icdCode}
                          />
                        ))}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Procedures: </span>
                      <span className="font-bold">
                        {row.serviceRequests.map((item: any) => (
                          <Chip
                            key={item.cptCode}
                            className="mr-2"
                            title={item.description}
                            label={item.cptCode}
                          />
                        ))}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Status: </span>
                      <div className="flex">
                        <div
                          className={clsx({
                            [classes.approved]: row.authStatus === 'APPROVED',
                            [classes.partiallyApproved]:
                              row.authStatus === 'PARTIALLY APPROVED',
                            [classes.deferred]: row.authStatus === 'DEFERRED',
                            [classes.deniedAdministrative]:
                              row.authStatus === 'DENIED--ADMINISTRATIVE',
                            [classes.deniedMedicalNecessity]:
                              row.authStatus === `DENIED - MEDICAL NECESSITY`,
                            [classes.outOfNetwork]:
                              row.authStatus === 'OUT OF NETWORK INPATIENT',
                            [classes.pended]: row.authStatus === 'PENDED',
                            [classes.cancelled]: row.authStatus === 'CANCELLED',
                            [classes.waiting]:
                              row.authStatus ===
                                `WAITING ON ADDT'L DOCUMENTS` ||
                              row.authStatus === `UNDER MEDICAL REVIEW`,
                          })}
                        >
                          {row.authStatus}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-blue-transparent w-full mt-4"
                      onClick={() => {
                        handleDetails(row.authNumber);
                      }}
                    >
                      Select
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </>
      ) : (
        <h3>There is no data to display!</h3>
      )}
    </section>
  );
};

export default DashboardTableMemberMobile;

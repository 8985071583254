import { FC, useEffect, useMemo, useState } from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileInput from '../FileInput';
import { IAttachment } from '../../providers/MainProvider';
import { FileComposed } from '../FileInput/FileInput';
import { usePostAttachements } from '../../services/ServiceHook';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { IDocument } from '../MemberDetailsComponent/model';
import { v4 as uuidv4 } from 'uuid';
import { IMemberDetails } from '../../interfaces/interfaces';

interface IProps {
  member: IMemberDetails;
}

const AttachmentsSidebar: FC<IProps> = ({ member }) => {
  const [postAttachments] = usePostAttachements();
  const [basedFiles, setBasedFiles] = useState<IAttachment[]>([]);
  const [files, setFiles] = useState<FileComposed[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const location = useLocation();
  const [resultMessage, setResultMessage] = useState({
    message: '',
    status: '',
  });

  const isSubmitDecisionPage = location.pathname.includes('submit-decision');

  const filesMemo = useMemo(
    () => ({
      getValues: files,
      setValues: setFiles,
    }),
    [files]
  );

  useEffect(() => {
    if (files.length > 0) {
      for (const file of files) {
        if (file.action === 'ADD') {
          file.action = 'ADDED';
          const reader = new FileReader();

          reader.onabort = () => console.error('file reading was aborted');
          reader.onerror = () => console.error('file reading has failed');

          reader.onload = () => {
            const newFile = reader.result as string;
            const stripedFile = newFile.split(',')[1];
            setBasedFiles((basedFiles) => [
              ...basedFiles,
              {
                fileName: file.name.replace(/\s+/g, ''),
                base64: stripedFile,
              },
            ]);
          };
          reader.readAsDataURL(file.file);
        }
      }
    } else {
      setBasedFiles([]);
    }
  }, [files]);

  const updateBasedFiles = (index: number, fileName: string) => {
    const newX = basedFiles.filter(
      (item, _index) => item.fileName !== fileName
    );
    setBasedFiles(newX);
  };

  const onFileChange = (files: FileComposed[]) => {};

  const handleAddFiles = async () => {
    setIsSaving(true);
    const data = {
      authorizationNumber: member.authNumber,
      requestId: uuidv4(),
      forExistingAuth: true,
      memberId: member.memberId,
      attachments: basedFiles,
    };
    try {
      const attachResponse = await postAttachments(data);
      if (attachResponse?.data.status === 200) {
        if (
          attachResponse?.data.partialUnsubmittedAttachmentNames &&
          attachResponse?.data.partialUnsubmittedAttachmentNames.length
        ) {
          const unsubmittedAttachments =
            attachResponse?.data.partialUnsubmittedAttachmentNames.join(', ');
          setResultMessage({
            message: `Some files were added successfully, except for: ${unsubmittedAttachments}. Please email/fax to intake`,
            status: 'failed',
          });
        } else {
          setResultMessage({
            message: 'Files were added successfully',
            status: 'success',
          });
        }

        setTimeout(() => {
          if (
            attachResponse?.data.partialUnsubmittedAttachmentNames &&
            attachResponse?.data.partialUnsubmittedAttachmentNames.length
          ) {
            setFiles(
              files.filter((item) =>
                attachResponse?.data.partialUnsubmittedAttachmentNames.includes(
                  item.file.name
                )
              )
            );
            setBasedFiles(
              basedFiles.filter((item) =>
                attachResponse?.data.partialUnsubmittedAttachmentNames.includes(
                  item.fileName
                )
              )
            );
          } else {
            setFiles([]);
          }
        }, 500);
      } else {
        setResultMessage({
          message: 'There was an error sending attachments, please try again',
          status: 'failed',
        });
      }
    } catch (error: any) {
      setResultMessage({
        message:
          'There was an error sending attachments, the server response was: ' +
          error.response.data.message,
        status: 'failed',
      });
    }

    setIsSaving(false);
  };

  if (member.documents && !member.documents.length) {
    return (
      <section className="pt-8">
        <h2 className="pb-4">Documents</h2>
        <p>There are not documents attached</p>
        {!isSubmitDecisionPage && (
          <section id="attachClinicalDocumentsSection" className="pt-6">
            <FileInput
              multiple
              accept="image/*, application/*, .svg"
              files={filesMemo}
              icon={<div>Icon</div>}
              updateBasedFiles={updateBasedFiles}
              onFileChange={onFileChange}
            />
          </section>
        )}

        <p
          className={clsx({
            'text-red-500': resultMessage.status === 'failed',
            'text-green-500': resultMessage.status === 'success',
          })}
          style={{ fontSize: '12px' }}
        >
          {resultMessage.message}
        </p>

        {!isSubmitDecisionPage ? (
          isSaving ? (
            <CircularProgress />
          ) : (
            <button
              type="button"
              className={clsx(
                { 'btn-disabled': files.length ? false : true },
                'btn btn-blue w-full mt-4'
              )}
              disabled={!files.length}
              onClick={handleAddFiles}
            >
              Add
            </button>
          )
        ) : (
          ''
        )}
      </section>
    );
  }
  return (
    <section className="pt-8">
      <h2>Documents</h2>

      {/* <button className="btn btn-gray mt-8">Add Clinical Docment</button> */}
      <div className="list-documents">
        {member.documents &&
          member.documents.map((item: IDocument) => (
            <a
              href={item.popurl}
              key={item.dateStored}
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="mt-4 flex items-center p-4"
                style={{ backgroundColor: '#F2F5F9' }}
              >
                <InsertDriveFileIcon className="mr-2" />
                <div className="flex flex-col pl-4">
                  <span
                    style={{
                      color: '#003868',
                      fontWeight: 'normal',
                      fontSize: '12px',
                    }}
                  >
                    {item.documentName}
                  </span>
                  <span
                    style={{
                      color: '#555F5F',
                      fontWeight: 'normal',
                      fontSize: '12px',
                      opacity: '0.5',
                    }}
                  >
                    {item.description}
                  </span>
                </div>
              </div>
            </a>
          ))}
      </div>
      {!isSubmitDecisionPage && (
        <section id="attachClinicalDocumentsSection" className="pt-6">
          <FileInput
            multiple
            accept="image/*, application/*"
            files={filesMemo}
            icon={<div>Icon</div>}
            updateBasedFiles={updateBasedFiles}
            onFileChange={onFileChange}
          />
        </section>
      )}

      <p
        className={clsx({
          'text-red-500': resultMessage.status === 'failed',
          'text-green-500': resultMessage.status === 'success',
        })}
      >
        {resultMessage.message}
      </p>

      {!isSubmitDecisionPage ? (
        isSaving ? (
          <CircularProgress />
        ) : (
          <button
            type="button"
            className={clsx(
              { 'btn-disabled': files.length ? false : true },
              'btn btn-blue w-full mt-4'
            )}
            disabled={!files.length}
            onClick={handleAddFiles}
          >
            Add
          </button>
        )
      ) : null}
    </section>
  );
};

export default AttachmentsSidebar;

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FooterBottom, Header, HeaderTitle } from '../../components';
import { getCurrentPlan } from '../../planData';

const ConfirmationAccount: FC<any> = (props) => {
  let foundPlanData = getCurrentPlan();

  return (
    <div style={{ background: '#F2F5F9' }} className="custom-container">
      <div
        style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Header />
        <HeaderTitle
          title={`Thank you for confirming your account for the ${foundPlanData.name} `}
        />

        <div className="px-8">
          <p>
            Thank you for confirming your email address. Your registration is
            under review. <br />
            Please allow us 24-48 hours for the review and account activation
            process to be completed. <br />
            <br />
            If you are having any issues with your account after that time,
            please don't hesitate to{' '}
            <Link to="/contact" className="text-blue-500">
              contact us
            </Link>
            .
          </p>

          <p style={{ color: '#003967' }} className="text-2xl mt-8">
            Thanks!
          </p>
        </div>
      </div>

      <FooterBottom />
    </div>
  );
};

export default ConfirmationAccount;

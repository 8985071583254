import { FC, useState, useCallback } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import moment from 'moment';
import 'date-fns';
import { useHistory } from 'react-router-dom';
import useStyles from '../Dashboard/DashboardTable/dashboardStyle';
import clsx from 'clsx';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { IProps } from '../../interfaces/query';
import { downloadBlob } from '../../utils/download-blob';

// Filter for CURRENT TAB
const defaultFilterValue = [
  {
    name: 'requestId',
    operator: 'eq',
    type: 'string',
    value: '',
  },
  {
    name: 'memberBenefitPlan',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberId',
    operator: 'eq',
    type: 'string',
    value: '',
  },
  {
    name: 'memberFirstName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberLastName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'memberDateOfBirth',
    operator: 'before',
    type: 'date',
    value: '',
  },
  {
    name: 'primaryDiagnosis',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'facilityName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'requesterName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'dateOfReferral',
    operator: 'before',
    type: 'date',
    value: '',
  },
  {
    name: 'createdAt',
    operator: 'before',
    type: 'date',
    value: '',
  },

  // {
  //   name: 'requesterEmail',
  //   operator: 'contains',
  //   type: 'string',
  //   value: '',
  // },
  // {
  //   name: 'requesterPhoneNumber',
  //   operator: 'contains',
  //   type: 'string',
  //   value: '',
  // },
  // {
  //   name: 'requesterMemberRelationship',
  //   operator: 'contains',
  //   type: 'string',
  //   value: '',
  // },
  {
    name: 'status',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const CcmReferralsSentDesktop: FC<IProps> = ({ data }) => {
  const [gridRef, setGridRef] = useState<any>(null);
  const history = useHistory();
  const classes = useStyles();
  const [isPaginated, setIsPaginated] = useState(true);
  const [isCSVDisabled, setIsCSVDisabled] = useState(false);
  const role = sessionStorage.getItem('role');

  // React Data Grid
  const columns = [
    {
      name: 'requestId',
      header: 'Request Id',
      minWidth: 150,
    },
    {
      name: 'memberBenefitPlan',
      header: 'Benefit Plan',
      minWidth: 260,
    },
    {
      name: 'memberId',
      header: 'Member Id',
      minWidth: 150,
    },
    {
      name: 'memberFirstName',
      header: 'First Name',
      defaultFlex: 1,
    },
    {
      name: 'memberLastName',
      header: 'Last Name',
      defaultFlex: 1,
    },
    {
      name: 'memberDateOfBirth',
      header: 'Date of Birth',
      minWidth: 150,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (value: any) => {
        return value.data.memberDateOfBirth
          ? moment(value.data.memberDateOfBirth).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'primaryDiagnosis',
      header: 'Primary Diagnosis',
      defaultFlex: 1,
    },
    {
      name: 'facilityName',
      header: 'Member Address or Facility Name',
      defaultFlex: 1,
    },
    {
      name: 'requesterName',
      header: 'Requester Name',
      minWidth: 150,
    },
    {
      name: 'dateOfReferral',
      header: 'Date of Referral',
      minWidth: 150,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (value: any) => {
        return value.data.dateOfReferral
          ? moment(value.data.dateOfReferral).format('MM/DD/YYYY')
          : null;
      },
    },

    // {
    //   name: 'requesterEmail',
    //   header: 'Requester Email',
    //   minWidth: 150,
    // },
    // {
    //   name: 'requesterPhoneNumber',
    //   header: 'Requester Phone Number',
    //   defaultFlex: 1,
    // },
    // {
    //   name: 'requesterMemberRelationship',
    //   header: 'Requester Member Realationship',
    //   defaultFlex: 1,
    // },
    {
      name: 'createdAt',
      header: 'Date Submitted',
      minWidth: 150,
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      render: (value: any) => {
        return value.data.createdAt
          ? moment(value.data.createdAt).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'status',
      header: 'Status',
      defaultFlex: 1,
      render: (value: any) => {
        return (
          <div className="flex">
            <div
              style={{ display: 'flex' }}
              className={clsx({
                [classes.approved]:
                  value.data.status.toLowerCase() === 'approved',
                [classes.cancelled]:
                  value.data.status.toLowerCase() === 'cancelled',
                [classes.waiting]: value.data.status === 'NEW',
              })}
            >
              {value.data.status}
            </div>
          </div>
        );
      },
    },
  ];

  // Once a row is selected
  const onSelectionChange = useCallback(
    ({ selected }) => {
      history.push(`/ccm-referrals/${selected}`);
    },
    [history]
  );

  // TODO: Check this
  const getRows = (ref: any) => {
    const dataFormatted = ref.map((item: any) => {
      return {
        ...item,
        dateOfReferral: moment(item.dateOfReferral).format('MM/DD/yyyy'),
        memberDateOfBirth: moment(item.memberDateOfBirth).format('MM/DD/yyyy'),
      };
    });
    return dataFormatted;
  };

  const exportCSV = () => {
    setIsPaginated(false);
    setIsCSVDisabled(true);
    setTimeout(() => {
      const columns = gridRef.current.visibleColumns;
      const header = columns.map((c: any) => c.header);
      const dataFormatted = getRows(gridRef.current.data);
      const rows = dataFormatted.map((data: any) =>
        columns.map((c: any) => data[c.id])
      );
      const contents = convertArrayToCSV(rows, {
        header,
        separator: ',',
      });
      const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
      downloadBlob(blob, 'CCM-Referrals.csv');
      setIsPaginated(true);
      setIsCSVDisabled(false);
    }, 1000);
  };

  const gridStyle = { minHeight: 725 };
  const rowStyle = { cursor: 'pointer' };

  return (
    <div className="hidden xl:block mt-4">
      <div className="flex justify-end">
        <button
          onClick={exportCSV}
          className={`btn mb-4 mt-4 ${
            isCSVDisabled || role === 'Viewer' ? 'btn-disabled' : 'btn-blue'
          }`}
          disabled={isCSVDisabled || role === 'Viewer'}
        >
          Export CSV
        </button>
      </div>

      <ReactDataGrid
        licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
        idProperty="requestId"
        onReady={setGridRef}
        dataSource={data}
        style={gridStyle}
        rowStyle={rowStyle}
        defaultFilterValue={defaultFilterValue}
        columns={columns}
        pagination={isPaginated}
        enableSelection={true}
        defaultLimit={15}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export default CcmReferralsSentDesktop;

import { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from '../MemberDetailsComponent/MemberDetailsStyle';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ISeviceRequest } from '../../interfaces/interfaces';

const MemberDetailsComponentServiceRequestsMobile: FC = () => {
  const memberDetails = useSelector(
    (state: RootState) => state.memberDetails.memberDetailsData
  );
  const classes = useStyles();
  const role = useSelector((state: RootState) => state.profile.data.role);

  return (
    <div>
      {!memberDetails.serviceRequests.length && (
        <Accordion className="mb-4 showMobile">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="flex flex-col w-full">
              <div>There is no service requests.</div>
            </div>
          </AccordionSummary>
        </Accordion>
      )}
      {memberDetails.serviceRequests.length
        ? memberDetails.serviceRequests.map(
            (item: ISeviceRequest, key: number) => {
              return (
                <Accordion className="mb-4 showMobile" key={key}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="service-request-header"
                  >
                    <div className="flex flex-col w-full">
                      <div className="font-bold">
                        <span className="text-gray-500">{item.cptCode}</span>
                        {item.description}
                      </div>
                      <div className="flex mt-2">
                        <div
                          className={clsx({
                            [classes.approved]:
                              item.serviceAuthStatus === 'APPROVED',
                            [classes.done]: item.serviceAuthStatus === 'DONE',
                            [classes.new]: item.serviceAuthStatus === 'NEW',
                            [classes.serviceSubmitted]:
                              item.serviceAuthStatus === 'SUBMITTED',
                            [classes.serviceDocumentsAdded]:
                              item.serviceAuthStatus === 'DOCUMENTS ADDED',
                            [classes.servicePendingDocuments]:
                              item.serviceAuthStatus === 'PENDING DOCUMENTS',
                            [classes.deniedMedicalNecessity]:
                              item.serviceAuthStatus ===
                                'DENIED-ADMINISTRATIVE' ||
                              item.serviceAuthStatus === 'DENIED' ||
                              item.serviceAuthStatus ===
                                'DENIED - MEDICAL NECESSITY',
                          })}
                        >
                          {item.serviceAuthStatus}
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full">
                      <hr className="mb-4" />
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Description: </span>
                        <span className="font-bold">{item.description}</span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">CPT Code: </span>
                        <span className="font-bold">{item.cptCode}</span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Service from: </span>
                        <span className="font-bold">
                          {item.caseServiceFromDate
                            ? moment(item.caseServiceFromDate).format('L')
                            : null}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Service to: </span>
                        <span className="font-bold">
                          {item.caseServiceToDate
                            ? moment(item.caseServiceToDate).format('L')
                            : null}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">
                          Authorized Quantity:
                        </span>
                        <span className="font-bold">
                          {item.serviceAuthorizedQuantity}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">
                          Requested Quantity:
                        </span>
                        <span className="font-bold">
                          {item.serviceRequestedQuantity}
                        </span>
                      </div>
                      {role === 'MD - Reviewer' ||
                      role === 'MD - Reviewer Restricted' ||
                      role === 'Admin' ||
                      role === 'MD - Case Manager' ||
                      role === 'Viewer' ? (
                        <div className="mb-2 flex flex-col">
                          <span className="text-gray-500">MD Reviewer: </span>
                          <span className="font-bold">
                            {item.emailLastSubmitted}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )
        : null}
    </div>
  );
};

export default MemberDetailsComponentServiceRequestsMobile;

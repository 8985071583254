import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  Sidebar,
} from '../../components';
import MainNavigation from '../../components/MainNavigation';
import { CircularProgress } from '@material-ui/core';
import AuthorizationForm from '../../components/AuthorizationForm';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';

const Expedited: FC = () => {
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();
  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  };
  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
  }
  return (
    <div>
      <Header />
      <MainNavigation />
      <HeaderTitle title="Expedited" />
      <main className="w-full px-4 md:px-8 mx-auto flex-col lg:flex-row py-4 mb-20 grid lg:grid-cols-3 2xl:grid-cols-5">
        <Sidebar />
        <div className="lg:mt-0 mt-8 lg:col-span-2 2xl:col-span-4">
          <AuthorizationForm />
        </div>
      </main>
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default Expedited;

import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';

const MemberDetailsComponentAuthorizationHistoryDesktop: FC = () => {
  const role = useSelector((state: RootState) => state.profile.data.role);
  const [notesColumns, setNotesColumns] = useState<any>([
    {
      name: 'subject',
      header: 'Subject',
      defaultFlex: 1,
    },
    {
      name: 'createdDate',
      header: 'Created Date',
      defaultFlex: 1,
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'MM/DD/YYYY hh:mm:ss',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Created Date',
        };
      },
      render: (value: any) => {
        return value.data.createdDate
          ? moment(value.data.createdDate).format('MM/DD/YYYY hh:mm:ss')
          : null;
      },
    },
    {
      name: 'note',
      header: 'Notes',
      defaultFlex: 2,
    },
  ]);
  const memberDetails = useSelector(
    (state: RootState) => state.memberDetails.memberDetailsData
  );
  const gridNotesStyle = { minHeight: 500 };

  const notesFilters = [
    {
      name: 'subject',
      operator: 'startsWith',
      type: 'string',
      value: '',
    },
    {
      name: 'createdDate',
      operator: 'before',
      type: 'date',
      value: '',
    },
  ];

  useEffect(() => {
    if (
      role !== 'MD' &&
      role !== 'MD - Restricted' &&
      role !== 'MD - Reviewer' &&
      role !== 'MD - Reviewer Restricted'
    ) {
      setNotesColumns((x: any[]) =>
        x.filter((item: any) => item.name !== 'note')
      );
    }
  }, [role]);

  // Row Details notes
  const renderRowDetailsNotes = (props: any) => {
    return (
      <div style={{ padding: 20 }}>
        <div className="mb-2">
          <span className="font-bold">Subject: </span>
          <span>{props.data.subject}</span>
        </div>
        <div className="mb-2">
          <span className="font-bold">Created: </span>
          <span>{props.data.createdDate}</span>
        </div>
        <div className="mb-2">
          <span className="font-bold">Last update: </span>
          <span>{props.data.lastUpdate}</span>
        </div>
        {(role === 'MD' ||
          role === 'Admin' ||
          role === 'MD - Case Manager' ||
          role === 'Viewer' ||
          role === 'MD - Restricted' ||
          role === 'MD - Reviewer' ||
          role === 'MD - Reviewer Restricted') && (
          <div className="mb-2">
            <span className="font-bold w-full block">Note:</span>
            <span>{props.data.note}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="notesTable showDesktop" id="notesReactDataGrid">
      <ReactDataGrid
        licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
        idProperty="sequence"
        columns={notesColumns}
        dataSource={memberDetails.notes}
        style={gridNotesStyle}
        defaultFilterValue={notesFilters}
        pagination={true}
        enableClipboard={true}
        rowExpandHeight={300}
        renderRowDetails={renderRowDetailsNotes}
      />
    </div>
  );
};

export default MemberDetailsComponentAuthorizationHistoryDesktop;

import { FC } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { IStandardForm } from '../../interfaces/standard-form';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IProps {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IStandardForm>>;
  touched: FormikTouched<IStandardForm>;
  errors: FormikErrors<IStandardForm>;
  formValues: IStandardForm;
  hasNoPermissions: boolean;
}

export const RequestDetailsGeneralInformation: FC<IProps> = ({
  getFieldProps,
  setFieldValue,
  formValues,
  hasNoPermissions,
}) => {
  return (
    <div>
      <div className="flex flex-col mb-4">
        <label className="label-top mb-2">
          Admitting/ Servicing Facility/ Provider Name
        </label>
        <FormControl variant="outlined" disabled={hasNoPermissions}>
          <InputLabel htmlFor="facilityOrProviderName">
            Admitting/ Servicing Facility/ Provider Name
          </InputLabel>
          <OutlinedInput
            id="facilityOrProviderName"
            label="Admitting/ Servicing Facility/ Provider Name"
            {...getFieldProps('facilityOrProviderName')}
          />
        </FormControl>
      </div>
      <div className="flex flex-col mb-4 width-date-100">
        <label className="label-top mb-2">
          Admission Date/ Date of Service
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="admissionOrServiceDate"
            value={formValues.admissionOrServiceDate}
            onChange={(date: MaterialUiPickersDate) => {
              setFieldValue('admissionOrServiceDate', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disabled={hasNoPermissions}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="flex flex-col mb-4">
        <label className="label-top mb-2">NPI/ PIN Number</label>
        <FormControl variant="outlined" disabled={hasNoPermissions}>
          <InputLabel htmlFor="npiPinNumber">NPI/ PIN Number</InputLabel>
          <OutlinedInput
            id="npiPinNumber"
            label="NPI/PIN Number"
            {...getFieldProps('npiPinNumber')}
          />
        </FormControl>
      </div>
      <div className="flex flex-col mb-4">
        <label className="label-top mb-2">Phone Number</label>
        <FormControl variant="outlined" disabled={hasNoPermissions}>
          <InputLabel htmlFor="providerPhoneNumber">Phone Number</InputLabel>
          <OutlinedInput
            id="providerPhoneNumber"
            label="Phone Number"
            {...getFieldProps('providerPhoneNumber')}
          />
        </FormControl>
      </div>
      <div className="flex flex-col mb-4">
        <label className="label-top mb-2">Fax number</label>
        <FormControl variant="outlined" disabled={hasNoPermissions}>
          <InputLabel htmlFor="providerFaxNumber">Fax number</InputLabel>
          <OutlinedInput
            id="providerFaxNumber"
            label="Fax number"
            {...getFieldProps('providerFaxNumber')}
          />
        </FormControl>
      </div>
    </div>
  );
};

import { FC, useCallback, useEffect, useState } from 'react';
import { InitialValuesStandardForm } from '../../initial-values/standard-form';
import { IStandardForm } from '../../interfaces/standard-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useFormik } from 'formik';
import validationSchema from '../../validation-schema/standard-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import RequestTypeSelected from '../RequestTypeSelected/RequestTypeSelected';
import { DisclaimerStatement } from '../DisclaimerStatement/DisclaimerStatement';
import MemberSearch from '../MemberSearch/MemberSearch';
import MemberSearchResultLabels from '../MemberSearchResultLabels';
import { IMemberSearchResult } from '../../interfaces/member-search-result';
import { MemberSearchResultInitialValues } from '../../initial-values/member-search-result';
import {
  useAuthorizationDraftCreateAttachmentsMutations,
  useAuthorizationDraftCreateMutations,
  useAuthorizationDraftMutations,
} from '../../hooks/mutations/useAuthorizationDraftMutations';
import { IAttachments } from '../../interfaces/attachments';
import { RequestDetailsRefactor } from '../RequestDetails/RequestDetailsRefactor';
import { DischargePlanningRefactor } from '../DischargePlanning/DischargePlanningRefactor';
import { FileInputRefactor } from '../FileInput/FileInputRefactor';
import Modal from '../Modal';
import { getCurrentPlan } from '../../planData';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setMemberSelected } from '../../store/memberDetails/memberDetails.slice';
import { memberSearchResultInitialValues } from '../../initial-values/initial-values';
import { Signature } from '../DisclaimerStatement/Signature';
import Cookies from 'js-cookie';
import { MemberInformation } from '../MemberInformation';

export const AuthorizationForm: FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { user } = useAuth0();
  const { permissions } = getCurrentPlan();
  const dispatch = useDispatch();
  const [memberToLoad, setMemberToLoad] = useState<IMemberSearchResult>(
    MemberSearchResultInitialValues
  );
  const member = useSelector(
    (state: RootState) => state.memberDetails.memberSelected
  );
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const [openModalFileWarning, setOpenModalFileWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | unknown>('');
  const {
    mutate: getDraftDetails,
    isLoading,
    isError,
    error,
  } = useAuthorizationDraftMutations();

  const {
    mutate: createDraft,
    isLoading: isLoadingDraft,
    error: errorDraft,
  } = useAuthorizationDraftCreateMutations();

  const {
    mutate: postDraftAttachments,
    isLoading: isLoadingAttachments,
    error: errorAttachments,
  } = useAuthorizationDraftCreateAttachmentsMutations();

  const isEdit = id !== 'new';
  const role = Cookies.get('aah-role');

  const currentAuthorizationType = location.pathname.split('/')[2];

  const getInitialPlan = (): IStandardForm => {
    let valueToReturn = { ...InitialValuesStandardForm };
    switch (currentAuthorizationType) {
      case 'standard':
        valueToReturn = {
          ...InitialValuesStandardForm,
          plan: 'Standard',
          authType: 1,
          formType: 'Standard',
        };
        break;
      case 'expedited':
        valueToReturn = {
          ...InitialValuesStandardForm,
          plan: 'Expedited',
          authType: 2,
          formType: 'Expedited',
        };
        break;
      case 'additional-days':
        valueToReturn = {
          ...InitialValuesStandardForm,
          plan: 'Additional Days',
          authType: 3,
          formType: 'Additional Days',
        };
        break;
      case 'notification-only':
        valueToReturn = {
          ...InitialValuesStandardForm,
          plan: 'NotificationOnly',
          authType: 5,
          formType: 'NotificationOnly',
        };
        break;
    }
    return valueToReturn;
  };

  const initialValues: IStandardForm = getInitialPlan();

  const handleRegister = () => {
    !formValues.files.length
      ? setOpenModalFileWarning(true)
      : saveDraft('submit');
  };

  const {
    values: formValues,
    errors,
    touched,
    isValid,
    getFieldProps,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      handleRegister();
    },
  });

  const hasNoPermissions =
    role === 'MD - Reviewer' ||
    role === 'MD - Reviewer Restricted' ||
    role === 'Viewer';

  // Disable submit button
  const isDisabled =
    !isValid ||
    !formValues.isEligible ||
    hasNoPermissions ||
    isLoadingDraft ||
    isLoadingAttachments;

  const updateData = useCallback(
    (auth: IStandardForm, attachments: IAttachments) => {
      if (auth) {
        setValues(auth);
        setFieldValue('signatureSource', auth.signatureSource);
        setMemberToLoad({
          memberDateOfBirth: auth.memberDateOfBirth,
          planStartDate: null,
          planEndDate: null,
          memberFirstName: auth.memberFirstName,
          memberMiddleName: auth.memberMiddleName,
          memberLastName: auth.memberLastName,
          memberId: auth.memberId,
          companyId: '',
          isEligible: auth.isEligible,
          facilityName: auth.facilityOrProviderName,
          facilityNpi: auth.npiPinNumber,
          memberBenefitPlan: null,
        });
        setFieldValue('files', attachments.attachments);
      }
    },
    [setFieldValue, setValues]
  );

  // Get Authorization Details
  useEffect(() => {
    if (user && user.sub && id && isEdit) {
      const payload = {
        userId: user.sub.split('|')[1],
        id,
      };

      getDraftDetails(payload, {
        onSuccess: (data) => {
          updateData(data.auth, data.attachments);
        },
        onError: (error) => {
          setIsSnackbarErrorOpen(true);
          setErrorMessage(error);
        },
      });
    }
  }, [user, id, updateData, isEdit, getDraftDetails, errorAttachments]);

  useEffect(() => {
    setFieldValue('memberId', member?.memberId);
    setFieldValue('memberFirstName', member?.memberFirstName);
    setFieldValue('memberLastName', member?.memberLastName);
    setFieldValue('memberMiddleName', member?.memberMiddleName);
    setFieldValue('memberDateOfBirth', member?.memberDateOfBirth);
    setFieldValue('isEligible', member?.isEligible);
    setMemberToLoad(member);
  }, [member, setFieldValue, setValues]);

  const saveDraft = (origin = '') => {
    const requestId = formValues.requestId ? formValues.requestId : uuidv4();
    const payload = {
      id: user.sub.split('|')[1],
      authorizationNumber: formValues.authorizationNumber,
      requestId,
      authType: formValues.authType,
      plans: permissions,
      data: { ...formValues, requestId },
    };

    delete payload.data.authType;
    payload.data.sitePlans = permissions;

    createDraft(payload, {
      onSuccess: () => {
        const filesParser = {
          id: user.sub.split('|')[1],
          authorizationNumber: formValues.authorizationNumber,
          memberId: formValues.memberId,
          requestId,
          forExistingAuth: formValues.authorizationNumber ? true : false,
          attachments: formValues.files,
          sitePlans: permissions,
        };
        postDraftAttachments(filesParser, {
          onSuccess: () => {
            setIsSnackbarOpen(true);
            resetForm();
            origin === 'submit'
              ? history.push(`/authorization/confirmation/${requestId}`)
              : history.push('/dashboard');
          },
          onError: () => {
            setIsSnackbarErrorOpen(true);
            setErrorMessage(errorAttachments);
          },
        });
      },
      onError: (error) => {
        setIsSnackbarErrorOpen(true);
        setErrorMessage(errorDraft);
      },
    });
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error loading your request, please try again later. Error
        message: {error}
      </Alert>
    );
  }

  return (
    <div className="pl-4">
      {hasNoPermissions && (
        <Alert severity="error" className="mb-8 p-4">
          You don't have permission to edit or submit this form.
        </Alert>
      )}
      {currentAuthorizationType === 'standard' && (
        <RequestTypeSelected
          title="Initial Request: Standard Authorization"
          description="CMS allows up to 72 hours for Part B medications and 14 days for all other standard authorizations requests."
        />
      )}

      {currentAuthorizationType === 'expedited' && (
        <RequestTypeSelected
          title="Initial Request: Expedited"
          description="Expedited requests are reserved for instances where waiting for a decision under the standard timeframe could place the Member’s life, health, or ability to gain maximum function in serious jeopardy."
        />
      )}

      {currentAuthorizationType === 'additional-days' && (
        <RequestTypeSelected
          title="Initial Request: Additional Days"
          description=""
        />
      )}

      {currentAuthorizationType === 'notification-only' && (
        <RequestTypeSelected
          title="Initial Request: Notification Only"
          description=""
        />
      )}

      <form onSubmit={handleSubmit}>
        <DisclaimerStatement
          formValues={formValues}
          touched={touched}
          errors={errors}
          hasNoPermissions={hasNoPermissions}
          getFieldProps={getFieldProps}
        />

        {/* Signature */}
        {formValues.plan === 'Expedited' ||
        (formValues.plan === 'NotificationOnly' && isEdit) ? (
          <Signature
            formValues={formValues}
            setFieldValue={setFieldValue}
            hasNoPermissions={hasNoPermissions}
            description="By signing, you acknowledge you are submitting an Expedited
              authorization request indicating a delay in medical care or
              treatment could seriously jeopardize the life or health of the
              member or the member's ability to regain maximum function or would
              subject the member to severe pain that cannot be adequately managed."
          />
        ) : null}

        {/* MEMBER INFORMATION */}
        <h2 className="py-8 border-bottom-blue">
          Member Information <span className="text-red-500">*</span>
        </h2>
        <MemberSearch />
        <MemberSearchResultLabels member={memberToLoad} />
        <MemberInformation
          getFieldProps={getFieldProps}
          hasNoPermissions={hasNoPermissions}
        />

        {/* Request Details */}
        {/* (Part A Services) & (Part B / Outpatient Services) */}
        <RequestDetailsRefactor
          formValues={formValues}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          getFieldProps={getFieldProps}
          hasNoPermissions={hasNoPermissions}
        />

        {/* Discharge Planning  */}
        {/* This section is not used when plan=notificationOnly */}
        {formValues.plan !== 'NotificationOnly' && (
          <DischargePlanningRefactor
            formValues={formValues}
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            hasNoPermissions={hasNoPermissions}
          />
        )}

        {/* Clinical Documents (Attachements) */}
        <section id="attachClinicalDocumentsSection" className="pt-6">
          <h2 className="py-8 border-bottom-blue mb-8">
            Attach Clinical Documents
          </h2>
          <FileInputRefactor
            formValues={formValues}
            setFieldValue={setFieldValue}
          />
        </section>

        {isDisabled && (
          <div className="text-red-500 mb-4">
            Please check all required fields and if member verification is
            successful
          </div>
        )}

        {hasNoPermissions && (
          <Alert severity="error" className="m-8 p-4">
            You don't have permission to edit or submit this form.
          </Alert>
        )}

        <div className="buttons-container  flex justify-end">
          <div className="left">
            <button
              type="button"
              className="block button btn mr-2"
              onClick={() => setOpenModalCancel(true)}
            >
              Cancel
            </button>
          </div>
          {formValues.plan !== 'NotificationOnly' && (
            <button
              type="button"
              disabled={isDisabled}
              className={`btn mr-2 btn-blue ${isDisabled && 'btn-disabled'}`}
              onClick={() => saveDraft()}
            >
              Save as Draft
            </button>
          )}

          <button
            type="submit"
            disabled={isDisabled}
            className={`btn btn-green ${isDisabled && 'btn-disabled'}`}
          >
            Submit
          </button>
        </div>
      </form>

      <Modal
        title="Are you sure you want to cancel?"
        description="All your information in this form will be lost"
        open={openModalCancel}
        className="confirmationModal"
        onClose={() => setOpenModalCancel(false)}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            onClick={() => setOpenModalCancel(false)}
            className="btn"
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={() => {
              resetForm();
              setOpenModalCancel(false);
              history.push('/Authorization');
            }}
          >
            Yes
          </button>
        </div>
      </Modal>

      {/* Saving without attachments */}
      <Modal
        title="IMPORTANT: You are submitting an authorization request without any clinical documentation."
        description="Please note that submitting an authorization request without any clinical documentation may delay the authorization decision and/or may result in a denial due to lack of clinical support."
        open={openModalFileWarning}
        className="confirmationModal"
        onClose={() => {
          setOpenModalFileWarning(false);
        }}
      >
        <div className="buttonsContainer flex justify-between">
          <button
            type="button"
            onClick={() => {
              setOpenModalFileWarning(false);
            }}
            className="btn"
          >
            Attach Document
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={() => {
              saveDraft('submit');
            }}
          >
            Continue without attachment
          </button>
        </div>
      </Modal>

      {/* SUCCESS  */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => {
          setIsSnackbarOpen(false);
          dispatch(setMemberSelected(memberSearchResultInitialValues));
        }}
        className="m-0 p-0 w-full min-w-0"
      >
        <Alert severity="success">
          Record has been submitted successfully!
        </Alert>
      </Snackbar>

      {/* ERROR */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSnackbarErrorOpen}
        autoHideDuration={5000}
        onClose={() => {
          setIsSnackbarErrorOpen(false);
        }}
        className="m-0 p-0 w-full min-w-0"
      >
        <Alert severity="error">
          There was an error submitting the record, please try again later!
          Error message: {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AuthorizationForm;

import { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DashboardTable,
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
  Modal,
} from '../../components';
import { CircularProgress } from '@material-ui/core';
import MainNavigation from '../../components/MainNavigation';
import { useLogoutNotAuthenticated } from '../../services/ServiceHook';
import { Banner } from '../../components/Banner';

const Dashboard: FC<any> = () => {
  const [openModal, setOpenModal] = useState(true);
  const { user, error, isAuthenticated, isLoading, loginWithRedirect } =
    useAuth0();
  const localLogoutNotAuthenticated = useLogoutNotAuthenticated();
  if (isLoading) {
    return (
      <div className="mt-8 w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return <div>Going to login page..</div>;
  } else if (!isAuthenticated && error) {
    localLogoutNotAuthenticated();
    return <div>Going to authentication error page..</div>;
  }

  if (isAuthenticated) {
    sessionStorage.setItem('_email', user.email);
    return (
      <div className="custom-container">
        <div>
          <Header />
          <MainNavigation />
          <HeaderTitle title="Prior Auth Dashboard" />
          <DashboardTable />
        </div>
        <div>
          <Footer />
          <FooterBottom />
        </div>
        <Modal
          title="ALERT"
          // description="This registration will not be recoverable if deleted."
          open={
            openModal &&
            window.sessionStorage.getItem('wasModalOpen') !== 'true'
          }
          className="confirmationModal"
          onClose={() => {
            setOpenModal(false);
            window.sessionStorage.setItem('wasModalOpen', 'true');
          }}
        >
          <Banner />
        </Modal>
      </div>
    );
  }
  return <div>You need to login in order to see information!</div>;
};

export default Dashboard;

import { FC } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';

interface IProps {
  title: string;
  description: string;
}

const RequestTypeSelected: FC<IProps> = ({ title, description }) => {
  const buttonStyle: any = {
    backgroundColor: '#1E508C',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '4px',
    fontWeight: 'normal',
  };
  return (
    <>
      <h2 className="mb-4">Request Type</h2>
      <strong style={{ color: '#555F5F' }} className="pb-2 block">
        {title}
      </strong>
      <p>{description}</p>
      <div className="button-container flex justify-end mt-8">
        <Link to="/authorization" style={buttonStyle} className="flex">
          <img src="/images/edit-white.svg" alt="Edit icon" className="pr-2" />
          CHANGE REQUEST TYPE
        </Link>
      </div>
      <hr className="mt-10" />
    </>
  );
};

export default RequestTypeSelected;

import { FC, useCallback, useEffect, useState } from 'react';
import { IDashboardDraft } from '../../../providers/MainProvider';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGetDashboardDraft } from '../../../services/ServiceHook';

window.moment = moment;

const DashboardTableNotificationMobile: FC = () => {
  const [getDraft] = useGetDashboardDraft();
  const [currentNotification, setCurrentNotification] = useState<
    IDashboardDraft[]
  >([]);

  const getData = useCallback(async () => {
    const draftData = await getDraft();
    const onlyNotificationAuthStatus = draftData.filter(
      (item: any) =>
        item.auth.requestId !== '' && item.auth.authStatus === 'Notification'
    );
    if (onlyNotificationAuthStatus && onlyNotificationAuthStatus.length) {
      const notification = onlyNotificationAuthStatus.map((item: any) => ({
        requestId: item.auth.requestId,
        createdAt: moment(item.auth.createdAt).format('LLL'),
        updatedAt: moment(item.auth.updatedAt).format('LLL'),
        memberFirstName: item.auth.memberFirstName,
        memberLastName: item.auth.memberLastName,
        memberId: item.auth.memberId,
      }));
      setCurrentNotification(
        notification.filter((item: any) => item.requestId !== '')
      );
    }
  }, [getDraft]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      {!currentNotification.length && (
        <div className="showMobile mt-4">
          <Accordion className="mt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="current-draft"
            >
              No records available.
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
        </div>
      )}
      {currentNotification.map((item: any, key: number) => {
        return (
          <div className="mt-4 showMobile" key={key}>
            <Accordion className="mb-4 mt-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="current-submitted"
              >
                <div className="flex flex-col w-full">
                  <div>{item.requestId}</div>
                  <div className="font-bold">
                    {item.memberFirstName} {item.memberLastName}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-full">
                  <hr className="mb-4" />
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Submitted Id: </span>
                    <span className="font-bold">{item.requestId}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Created at: </span>
                    <span className="font-bold">{item.createdAt}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Updated at: </span>
                    <span className="font-bold">{item.updatedAt}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Member Id: </span>
                    <span className="font-bold">{item.memberId}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">First Name: </span>
                    <span className="font-bold">{item.memberFirstName}</span>
                  </div>
                  <div className="mb-2 flex flex-col">
                    <span className="text-gray-500">Last Name: </span>
                    <span className="font-bold">{item.memberLastName}</span>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardTableNotificationMobile;

import { useQuery } from '@tanstack/react-query';
import { USERS_QUERY_KEYS } from '../../constants/users';
import { getUsers } from '../../services/user.service';

const defaultOptions = {
  retry: false,
  refetchOnWindowFocus: false,
};

export const useUsersQuery = (queryProps: any) => 
  useQuery(
    [USERS_QUERY_KEYS.GET_USERS],
    () => getUsers(),
    {
      ...defaultOptions,
      ...queryProps
    }
  )

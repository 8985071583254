import { FC } from 'react';
import CcmReferralsSentMobile from '../CcmReferralsSentMobile';
import { useCcmReferralsQuery } from '../../hooks/queries/useCcmReferralsQuery';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CcmReferralsSentDesktop from '../CcmReferralsSentDesktop';

const CcmReferralsSent: FC = () => {
  const { data, isLoading, isError, error } = useCcmReferralsQuery({});

  if (isLoading) {
    return (
      <div className="hidden xl:flex container mx-auto px-4  flex-col justify-center items-center mt-8 ">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  return (
    <div>
      <CcmReferralsSentDesktop
        data={data}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      <CcmReferralsSentMobile
        data={data}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    </div>
  );
};

export default CcmReferralsSent;

import { FC } from 'react';

export const Banner: FC = () => {
  const currentHost = window.location.host;
  // const [redirectTo, setRedirectTo] = useState('');
  let redirectTo = '';

  if (currentHost.includes('agerightadvantage')) {
    redirectTo = 'https://agerightadvantage.com/providers/2024updates';
  } else if (currentHost.includes('keycareadvantage')) {
    redirectTo = 'https://keycareadvantage.com/providers/2024updates';
  } else if (currentHost.includes('procareadvantage')) {
    redirectTo = 'https://procareadvantageplan.com/providers/2024updates';
  } else if (currentHost.includes('alignseniorcare')) {
    redirectTo = 'https://alignseniorcare.com/providers/2024updates';
  } else if (currentHost.includes('lifeworksadvantage')) {
    redirectTo = 'https://lifeworksadvantage.com/providers/2024updates';
  } else if (currentHost.includes('nhcadvantageplan')) {
    redirectTo = 'https://nhcadvantageplan.com/providers/2024updates';
  } else if (currentHost.includes('perennialadvantage')) {
    redirectTo = 'https://perennialadvantage.com/providers/2024updates';
  } else if (currentHost.includes('pruitthealthpremier')) {
    redirectTo = 'https://pruitthealthpremier.com/providers/2024updates';
  } else {
    redirectTo = 'https://alignseniorcare.com/providers/2024updates';
  }

  return (
    <>
      <div className="p-4">
        {/* <img
          src="https://www.usetiful.com/build/images/app/templates/banner-sample.svg"
          alt="Banner"
          width="60"
          height="60"
        /> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p data-pm-slice="1 1 []">
            The Provider Portal will be permanently shutting down on July 31, 2024.
            After this date, access to the portal and its services will be unavailable. 
            We recommend completing any outstanding tasks prior to this date. <br></br>
            <a
              href={redirectTo}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#1e508c' }}
            >
              {redirectTo}
            </a>
          </p>
          <p data-pm-slice="1 1 []">
            <em>&nbsp;</em>
          </p>
        </div>
      </div>
    </>
  );
};

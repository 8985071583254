import { FC, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from '../DashboardTable/dashboardStyle';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useDispatch } from 'react-redux';
import { setDashboardPaginatedDataByPage } from '../../../store/authorizationCase/authorizationCase.slice';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { downloadBlob } from '../../../utils/download-blob';

window.moment = moment;

const DashboardTableCurrentMobile: FC = () => {
  const plan = useSelector((state: RootState) => state.planData.data);
  const role = useSelector((state: RootState) => state.profile.data.role);
  const [page, setPage] = useState(1);
  const paginatedData = useSelector(
    (state: RootState) => state.authorizationCase.paginatedData
  );
  const filteredData = useSelector(
    (state: RootState) => state.authorizationCase.dashboardFiltered
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const headers = [
    {
      name: 'authNumber',
      label: 'Case Id',
    },
    {
      name: 'companyId',
      label: 'Company Id',
    },
    {
      name: 'memberId',
      label: 'Member Id',
    },
    {
      name: 'memberFirstName',
      label: 'First Name',
    },
    {
      name: 'memberLastName',
      label: 'Last Name',
    },
    {
      name: 'serviceFromDate',
      label: 'Service From',
    },
    {
      name: 'serviceToDate',
      label: 'Service To',
    },
    {
      name: 'diagnoses',
      label: 'Diagnosis',
    },
    {
      name: 'serviceRequests',
      label: 'Procedures',
    },
    {
      name: 'lastMDReviewedStatus',
      label: 'MD Reviewed',
    },
    {
      name: 'authStatus',
      label: 'Auth Status',
    },
  ];

  const handleDetails = (authNumber: string) => {
    history.push(`/dashboard/${authNumber}`);
  };

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (value > Math.round(filteredData.length / 10)) {
      value = Math.round(filteredData.length / 10);
    }
    setPage(value);
    dispatch(
      setDashboardPaginatedDataByPage({ pageSize: 10, pageNumber: value })
    );
  };

  // Export to CSV
  const getRows = (ref: any) => {
    const dataFormatted = ref.map((item: any) => {
      return {
        ...item,
        serviceFromDate: moment(item.serviceFromDate).format('MM/DD/yyyy'),
        serviceToDate: moment(item.serviceToDate).format('MM/DD/yyyy'),
        diagnoses: String(item.diagnoses.map((x: any) => x.icdCode)),
        serviceRequests: String(
          item.serviceRequests.map((x: any) => x.cptCode)
        ),
      };
    });
    return dataFormatted;
  };

  // Should pass new grid ref
  const exportCSV = () => {
    const dataFormatted = getRows(filteredData);
    let headerName: string[] = headers.map((c: any) => c.name);
    let header: string[] = headers.map((c: any) => c.label);
    if (
      plan.site !== 'providerportaladmin.allyalign.com' &&
      plan.site !== 'provider-portal-dev-admin.tutelarhealth.io' &&
      plan.site !== 'provider-portal-staging-admin.tutelarhealth.io' &&
      plan.site !== 'localhost:3000'
    ) {
      if (role === 'MD - Reviewer' || role === 'MD - Reviewer Restricted') {
        headerName = headerName.filter((item: string) => item !== 'companyId');
        header = header.filter((item: string) => item !== 'Company Id');
      } else {
        headerName = headerName.filter(
          (item: string) =>
            item !== 'companyId' && item !== 'lastMDReviewedStatus'
        );
        header = header.filter(
          (item: string) => item !== 'Company Id' && item !== 'MD Reviewed'
        );
      }
    }

    const rows = dataFormatted.map((data: any) =>
      headerName.map((c: any) => data[c])
    );
    const contents = convertArrayToCSV(rows, {
      header,
      separator: ';',
    });
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, 'grid-data.csv');
  };

  return (
    <div className="showMobile">
      <div className="flex justify-end">
        <button
          onClick={exportCSV}
          disabled={role === 'Viewer'}
          className={`btn btn-blue mb-4 ${role === 'Viewer' && 'btn-disabled'}`}
        >
          Export CSV
        </button>
      </div>

      {paginatedData && paginatedData.length ? (
        paginatedData.map((item: any, key: number) => {
          return (
            <div className="my-4" key={key}>
              <Accordion className="mb-4 pb-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex flex-col w-full">
                    <div>{item.authNumber}</div>
                    <div className="font-bold">
                      {item.memberFirstName} {item.memberLastName}
                    </div>
                    <div className="flex">
                      <div
                        className={clsx({
                          [classes.approved]: item.authStatus === 'APPROVED',
                          [classes.partiallyApproved]:
                            item.authStatus === 'PARTIALLY APPROVED',
                          [classes.deferred]: item.authStatus === 'DEFERRED',
                          [classes.deniedAdministrative]:
                            item.authStatus === 'DENIED--ADMINISTRATIVE',
                          [classes.deniedMedicalNecessity]:
                            item.authStatus === 'DENIED - MEDICAL NECESSITY',
                          [classes.outOfNetwork]:
                            item.authStatus === 'OUT OF NETWORK INPATIENT',
                          [classes.pended]: item.authStatus === 'PENDED',
                          [classes.cancelled]: item.authStatus === 'CANCELLED',
                          [classes.waiting]:
                            item.authStatus === `WAITING ON ADDT'L DOCUMENTS` ||
                            item.authStatus === `UNDER MEDICAL REVIEW`,
                          [classes.notification]:
                            item.authStatus === `NOTIFICATION ONLY`,
                        })}
                      >
                        {item.authStatus}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-full">
                    {/* <hr className="mb-4" /> */}
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Member Id: </span>
                      <span className="font-bold">{item.memberId}</span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Service from: </span>
                      <span className="font-bold">
                        {item.serviceFromDate
                          ? moment(item.serviceFromDate).format('L')
                          : null}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Service to: </span>
                      <span className="font-bold">
                        {item.serviceToDate
                          ? moment(item.serviceToDate).format('L')
                          : null}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Diagnosis: </span>
                      <span className="font-bold">
                        {[
                          ...new Map(
                            item.diagnoses.map((item: any) => [
                              item['icdCode'],
                              item,
                            ])
                          ).values(),
                        ].map((item: any) => {
                          if (item.icdCode) {
                            return (
                              <Chip
                                key={item.icdCode}
                                className="mr-2"
                                title={item.diagnosisDescription}
                                label={item.icdCode}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">Procedure: </span>
                      <span className="font-bold">
                        {[
                          ...new Map(
                            item.serviceRequests.map((item: any) => [
                              item['cptCode'],
                              item,
                            ])
                          ).values(),
                        ].map((item: any) => (
                          <Chip
                            key={item.cptCode}
                            className="mr-2"
                            title={item.description}
                            label={item.cptCode}
                          />
                        ))}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">MD Reviewed: </span>
                      <span className="font-bold">
                        {item.lastMDReviewedStatus}
                      </span>
                    </div>
                    <div className="mb-2 flex flex-col">
                      <span className="text-gray-500">NPI: </span>
                      <span className="font-bold">{item.npi}</span>
                    </div>

                    {/* For Admin Site */}
                    {plan.site === 'providerportaladmin.allyalign.com' ||
                    plan.site ===
                      'provider-portal-dev-admin.tutelarhealth.io' ||
                    plan.site ===
                      'provider-portal-staging-admin.tutelarhealth.io' ||
                    plan.site === 'localhost:3000' ? (
                      <>
                        <div className="mb-2 flex flex-col">
                          <span className="text-gray-500">Company Id: </span>
                          <span className="font-bold">{item.companyId}</span>
                        </div>
                      </>
                    ) : null}

                    <button
                      type="button"
                      className="btn btn-blue-transparent w-full mt-2"
                      onClick={() => {
                        handleDetails(item.authNumber);
                      }}
                    >
                      View
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <div className="showMobile">No records available.</div>
      )}
      {paginatedData && paginatedData.length ? (
        <div className="flex flex-col items-center showMobile w-full">
          <Typography className="pb-2">Page: {page}</Typography>
          <Pagination
            count={Math.round(filteredData.length / 10)}
            page={page}
            onChange={handlePagination}
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </div>
      ) : null}
    </div>
  );
};

export default DashboardTableCurrentMobile;

import { FC, useCallback, useEffect, useState } from 'react';
import {
  useGetMemberDetails,
  useGetDashboardDataMember,
} from '../../services/ServiceHook';
import useStyles from './MemberDetailsStyle';
import { AttachmentsSidebar } from '..';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Modal from '../Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import {
  setMemberDetails,
  setMemberDetailsOtherCases,
} from '../../store/memberDetails/memberDetails.slice';
import MemberDetailsComponentServiceRequestsMobile from '../MemberDetailsComponentServiceRequestsMobile/MemberDetailsComponentServiceRequestsMobile';
import MemberDetailsComponentServiceRequestsDesktop from '../MemberDetailsComponentServiceRequestsDesktop/MemberDetailsComponentServiceRequestsDesktop';
import MemberDetailsComponentAuthorizationHistoryDesktop from '../MemberDetailsComponentAuthorizationHistoryDesktop/MemberDetailsComponentAuthorizationHistoryDesktop';
import MemberDetailsComponentAuthorizationHistoryMobile from '../MemberDetailsComponentAuthorizationHistoryMobile/MemberDetailsComponentAuthorizationHistoryMobile';
import MemberDetailsComponentOtherCasesMobile from '../MemberDetailsComponentOtherCasesMobile/MemberDetailsComponentOtherCasesMobile';
import MemberDetailsComponentOtherCasesDesktop from '../MemberDetailsComponentOtherCasesDesktop/MemberDetailsComponentOtherCasesDesktop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
export interface IProviderData {
  title: string;
  description: string | number;
  class: string | number;
  companyId: string;
  contract: string | number;
  contractEffectiveDate: string | Date | null;
  termedDate: string | Date | null;
}

const MemberDetailsComponent: FC = () => {
  const role = useSelector((state: RootState) => state.profile.data.role);
  const token = useSelector((state: RootState) => state.authentication.token);
  const isActiveProfile = useSelector(
    (state: RootState) => state.profile.data.isActive
  );
  const dispatch = useDispatch();
  const memberDetails = useSelector(
    (state: RootState) => state.memberDetails.memberDetailsData
  );
  const history = useHistory();
  const location = useLocation();
  const [getMemberDetails] = useGetMemberDetails();
  const [isLoading, setIsLoading] = useState(true);

  const [primaryCarePhysician, setPrimaryCarePhysician] = useState<
    string | null
  >(null);
  const [requestingProvider, setRequestingProvider] = useState('');
  const [facilityProvider, setFacilityProvider] = useState('');
  const [getDashboardDataMember] = useGetDashboardDataMember();
  // MODAL
  const [openProviderModal, setOpenProviderModal] = useState(false);
  const plan = useSelector((state: RootState) => state.planData.data);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState('');
  const [providerData, setProviderData] = useState<IProviderData>({
    title: '',
    description: '',
    class: '',
    companyId: '',
    contract: '',
    contractEffectiveDate: null,
    termedDate: null,
  });

  window.moment = moment;

  function Alert(props: AlertProps) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        className="w-1/3 p-0 m-0"
        {...props}
      />
    );
  }

  const getData = useCallback(async () => {
    if (token && isActiveProfile) {
      // Get Member Details
      try {
        const memberDetailsResponse = await getMemberDetails();
        if (memberDetailsResponse) {
          dispatch(setMemberDetails(memberDetailsResponse));

          // Set Primary Care Physician
          if (
            memberDetailsResponse.providers &&
            memberDetailsResponse.providers.authPCP &&
            memberDetailsResponse.providers.authPCP.fullName
          ) {
            setPrimaryCarePhysician(
              memberDetailsResponse.providers.authPCP.fullName
            );
          }

          // Set Requesting Provider
          if (
            memberDetailsResponse.providers &&
            memberDetailsResponse.providers.requestingProvider &&
            memberDetailsResponse.providers.requestingProvider.fullName
          ) {
            setRequestingProvider(
              memberDetailsResponse.providers.requestingProvider.fullName
            );
          }

          if (
            memberDetailsResponse.providers &&
            memberDetailsResponse.providers.facilityProvider &&
            memberDetailsResponse.providers.facilityProvider.fullName
          ) {
            setFacilityProvider(
              memberDetailsResponse.providers.facilityProvider.fullName
            );
          }

          // -------------------------------------------- //
          // Get OTHER CASES for the same member
          if (
            plan.site === 'providerportaladmin.allyalign.com' ||
            plan.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
            plan.site === 'provider-portal-staging-admin.tutelarhealth.io' ||
            plan.site === 'localhost:3000'
          ) {
            const value = await getDashboardDataMember(
              memberDetailsResponse.memberId
            );
            // Order date DESC
            const tempOtherCases = value.sort((a: any, b: any) => {
              return (
                +new Date(b.serviceFromDate) - +new Date(a.serviceFromDate)
              );
            });
            if (tempOtherCases.length) {
              const filterOtherCases = tempOtherCases.filter(
                (item: any) =>
                  item.authNumber !== memberDetailsResponse.authNumber
              );
              dispatch(setMemberDetailsOtherCases(filterOtherCases));
            }
          }
          // ------------------------------------------ //
        }
      } catch (error: any) {
        setServerResponse(
          error.response.data.message || error.response.statusText
        );
        setIsSnackbarErrorOpen(true);
      }

      setIsLoading(false);
    }
  }, [token, isActiveProfile]);

  // Get Token
  useEffect(() => {
    getData();
  }, [getData]);

  // Styles
  const label: any = {
    opacity: '0.5',
    color: '#112233',
    fontSize: '13px',
    letterSpacing: '0',
    lineHeight: '15px',
    display: 'block',
  };

  const content: any = {
    color: '#112233',
    fontSize: '13px',
    lineHeight: '15px',
  };

  const classes = useStyles();

  const goToAdditionalDays = () => {
    history.push('/authorization/additional-days');
  };

  const goToSubmitDecision = () => {
    history.push(`${location.pathname}/submit-decision`);
  };

  const updateProvider = (provider = '') => {
    if (memberDetails.providers) {
      switch (provider) {
        case 'primaryCarePhysician':
          setProviderData({
            title:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.fullName
                ? memberDetails.providers.authPCP.fullName
                : '',
            description:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.npi
                ? memberDetails.providers.authPCP.npi
                : '',
            class:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.class
                ? memberDetails.providers.authPCP.class
                : '',
            companyId:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.companyID
                ? memberDetails.providers.authPCP.companyID
                : '',
            contract:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.contract
                ? memberDetails.providers.authPCP.contract
                : '',
            contractEffectiveDate:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.contractEffectiveDate
                ? moment(
                    memberDetails.providers.authPCP.contractEffectiveDate
                  ).format('MM/DD/YYYY')
                : null,
            termedDate:
              memberDetails.providers.authPCP &&
              memberDetails.providers.authPCP.termedDate
                ? moment(memberDetails.providers.authPCP.termedDate).format(
                    'MM/DD/YYYY'
                  )
                : null,
          });
          break;
        case 'requestingProvider':
          setProviderData({
            title:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.fullName
                ? memberDetails.providers.requestingProvider.fullName
                : '',
            description:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.npi
                ? memberDetails.providers.requestingProvider.npi
                : '',
            class:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.class
                ? memberDetails.providers.requestingProvider.class
                : '',
            companyId:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.companyID
                ? memberDetails.providers.requestingProvider.companyID
                : '',
            contract:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.contract
                ? memberDetails.providers.requestingProvider.contract
                : '',
            contractEffectiveDate:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.contractEffectiveDate
                ? moment(
                    memberDetails.providers.requestingProvider
                      .contractEffectiveDate
                  ).format('MM/DD/YYYY')
                : null,
            termedDate:
              memberDetails.providers.requestingProvider &&
              memberDetails.providers.requestingProvider.termedDate
                ? moment(
                    memberDetails.providers.requestingProvider.termedDate
                  ).format('MM/DD/YYYY')
                : null,
          });
          break;
        case 'serviceProvider':
          setProviderData({
            title:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.fullName
                ? memberDetails.providers.facilityProvider.fullName
                : '',
            description:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.npi
                ? memberDetails.providers.facilityProvider.npi
                : '',
            class:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.class
                ? memberDetails.providers.facilityProvider.class
                : '',
            companyId:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.companyID
                ? memberDetails.providers.facilityProvider.companyID
                : '',
            contract:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.contract
                ? memberDetails.providers.facilityProvider.contract
                : '',
            contractEffectiveDate:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.contractEffectiveDate
                ? moment(
                    memberDetails.providers.facilityProvider
                      .contractEffectiveDate
                  ).format('MM/DD/YYYY')
                : null,
            termedDate:
              memberDetails.providers.facilityProvider &&
              memberDetails.providers.facilityProvider.termedDate
                ? moment(
                    memberDetails.providers.facilityProvider.termedDate
                  ).format('MM/DD/YYYY')
                : null,
          });
          break;
      }
      setOpenProviderModal(true);
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isActiveProfile && !isLoading) {
    return (
      <section className="customTable container px-4 mx-auto mb-16">
        <h3>
          Your Provider Portal account is not currently active. Please contact
          support for assisstance.
        </h3>
      </section>
    );
  }

  return (
    <div className="w-full px-4 md:px-8 grid lg:grid-cols-4 lg:gap-4">
      <section className="lg:col-span-3 lg:border-r lg:pr-4">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-10">
          <h2 className="xl:col-span-3">
            {memberDetails.memberFirstName} {memberDetails.memberLastName}
          </h2>
          {role !== 'MD - Reviewer' && role !== 'MD - Reviewer Restricted' && (
            <button
              type="button"
              className={`btn btn-blue ${role === 'Viewer' && 'btn-disabled'}`}
              disabled={role === 'Viewer'}
              onClick={goToAdditionalDays}
            >
              Request Additional Days
            </button>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-between flex-wrap">
          <div className="mb-4 mr-4">
            <span style={label}>Case ID</span>
            <span style={content}>{memberDetails.authNumber}</span>
          </div>
          <div className="mb-4 mr-4">
            <span style={label}>Created</span>
            <span style={content}>{memberDetails.createdDate}</span>
          </div>
          <div className="mb-4 mr-4">
            <span style={label}>Last Updated</span>
            <span style={content}>{memberDetails.lastUpdate}</span>
          </div>
          <div className="mb-4 mr-4">
            <span style={label} className="mb-2">
              Cert Type
            </span>
            <span
              className={clsx(
                {
                  [classes.certTypeStandardProcessing]:
                    memberDetails.certType?.trim() === 'APPEAL - IMMEDIATE',
                  [classes.certTypeStandardStandard]:
                    memberDetails.certType?.trim() === 'APPEAL - STANDARD',
                  [classes.authTypeRetrospectiveReview]:
                    memberDetails.certType?.trim() === 'INITIAL',
                },
                'custom-chip green block'
              )}
            >
              {memberDetails.certType}
            </span>
          </div>
          <div className="mb-4 mr-4">
            <span style={label} className="mb-2">
              Authorization Type
            </span>
            <span
              className={clsx(
                {
                  [classes.authTypeStandardProcessing]:
                    memberDetails.authType?.trim() === 'STANDARD PROCESSING',
                  [classes.authTypeExpeditedReview]:
                    memberDetails.authType?.trim() === 'EXPEDITED REVIEW',
                  [classes.authTypeRetrospectiveReview]:
                    memberDetails.authType?.trim() === 'RETROSPECTIVE REVIEW',
                },
                'custom-chip green block'
              )}
            >
              {memberDetails.authType}
            </span>
          </div>
          <div className="flex lg:justify-end">
            <div>
              <span style={label} className="mb-2">
                Status
              </span>
              <span
                className={clsx(
                  {
                    [classes.approved]: memberDetails.authStatus === 'APPROVED',
                    [classes.partiallyApproved]:
                      memberDetails.authStatus === 'PARTIALLY APPROVED',
                    [classes.deferred]: memberDetails.authStatus === 'DEFERRED',
                    [classes.deniedAdministrative]:
                      memberDetails.authStatus === 'DENIED--ADMINISTRATIVE',
                    [classes.deniedMedicalNecessity]:
                      memberDetails.authStatus ===
                        'DENIED - MEDICAL NECESSITY' ||
                      memberDetails.authStatus === 'DENIED',
                    [classes.outOfNetwork]:
                      memberDetails.authStatus === 'OUT OF NETWORK INPATIENT',
                    [classes.pended]: memberDetails.authStatus === 'PENDED',
                    [classes.cancelled]:
                      memberDetails.authStatus === 'CANCELLED',
                    [classes.waiting]:
                      memberDetails.authStatus ===
                        `WAITING ON ADDT'L DOCUMENTS` ||
                      memberDetails.authStatus === `UNDER MEDICAL REVIEW`,
                  },
                  'custom-chip green block'
                )}
              >
                {memberDetails.authStatus}
              </span>
            </div>
          </div>
        </div>

        <h3 className="mt-8">Member Details</h3>
        <div className="grid md:grid-cols-2 gap-8 mt-4">
          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Member ID</th>
                <td>{memberDetails.memberId}</td>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <td>{memberDetails.memberDateOfBirth}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>{memberDetails.memberGender}</td>
              </tr>
              <tr>
                <th>Eligibility Status</th>
                <td>
                  {memberDetails.isEligible ? 'Is eligible' : 'Is not eligible'}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Primary Care Physician</th>
                <td>
                  {role === 'MD - Reviewer' ||
                  role === 'MD - Reviewer Restricted' ||
                  role === 'Admin' ||
                  role === 'MD - Case Manager' ||
                  role === 'Viewer' ? (
                    <button
                      className="text-blue-600 text-left"
                      onClick={() => {
                        updateProvider('primaryCarePhysician');
                      }}
                    >
                      <span className="text-left">
                        {primaryCarePhysician} ({memberDetails.authPCP})
                      </span>
                    </button>
                  ) : (
                    <div className="text-left">
                      <span className="text-left">
                        {primaryCarePhysician} ({memberDetails.authPCP})
                      </span>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>Plan</th>
                <td>{memberDetails.companyId}</td>
              </tr>
              <tr>
                <th>Plan / Coverage Date</th>
                <td>{memberDetails.coverageDate}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="mt-8">Authorization Details</h3>
        <div className="grid md:grid-cols-2 gap-8 mt-4">
          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Diagnosis</th>
                <td>
                  {memberDetails.diagnoses.map((item: any) => (
                    <div
                      key={item.icdCode}
                      className="border-gray-500 border-b-2 border-dashed  m-b-4 p-2"
                    >
                      {item.icdCode} - {item.diagnosisDescription}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>Service from</th>
                <td>
                  {memberDetails.serviceFromDate
                    ? moment(memberDetails.serviceFromDate).format('L')
                    : null}
                </td>
              </tr>
              <tr>
                <th>Service to</th>
                <td>
                  {memberDetails.serviceToDate
                    ? moment(memberDetails.serviceToDate).format('L')
                    : null}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="authorization-table">
            <tbody>
              <tr>
                <th>Requesting Provider</th>
                <td>
                  {role === 'MD - Reviewer' ||
                  role === 'MD - Reviewer Restricted' ||
                  role === 'Admin' ||
                  role === 'MD - Case Manager' ||
                  role === 'Viewer' ? (
                    <button
                      className="text-blue-600 text-left"
                      onClick={() => {
                        updateProvider('requestingProvider');
                      }}
                    >
                      {requestingProvider} ({memberDetails.requestingProvider})
                    </button>
                  ) : (
                    <div className="text-left">
                      {requestingProvider} ({memberDetails.requestingProvider})
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>Service Provider</th>
                <td>
                  {role === 'MD - Reviewer' ||
                  role === 'MD - Reviewer Restricted' ||
                  role === 'Admin' ||
                  role === 'MD - Case Manager' ||
                  role === 'Viewer' ? (
                    <button
                      className="text-blue-600 text-left"
                      onClick={() => {
                        updateProvider('serviceProvider');
                      }}
                    >
                      {facilityProvider} ({memberDetails.facilityProvider})
                    </button>
                  ) : (
                    <div className="text-left">
                      {facilityProvider} ({memberDetails.facilityProvider})
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>Requested Units</th>
                <td>{memberDetails.requestedUnits}</td>
              </tr>
              <tr>
                <th>Authorized Units</th>
                <td>{memberDetails.authorizatedUnits}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="py-8">
          {role === 'MD - Reviewer' ||
          role === 'MD - Reviewer Restricted' ||
          role === 'Admin' ? (
            <div className="flex lg:justify-end mb-10">
              <button
                id="submitDecisionButton"
                className={
                  'btn w-full lg:w-1/2 xl:w-1/3 mb-4 btn block btn-blue'
                }
                onClick={goToSubmitDecision}
              >
                Submit Decision
              </button>
            </div>
          ) : null}

          {/* Service Requests */}
          <h3 className="pb-4">Service Requests</h3>
          <MemberDetailsComponentServiceRequestsMobile />
          <MemberDetailsComponentServiceRequestsDesktop />
        </div>

        {/* Authorization History */}
        <div className="py-8">
          <h3 className="pb-4">Authorization History</h3>
          <MemberDetailsComponentAuthorizationHistoryMobile />
          <MemberDetailsComponentAuthorizationHistoryDesktop />
        </div>

        {/* Other Authorizations, ONLY for Admin Site || TODO: Missing filters in mobile version */}
        {plan.site === 'providerportaladmin.allyalign.com' ||
        plan.site === 'provider-portal-dev-admin.tutelarhealth.io' ||
        plan.site === 'provider-portal-staging-admin.tutelarhealth.io' ||
        plan.site === 'localhost:3000' ? (
          <div className="py-8">
            <h3 className="pb-4">Other Prior Authorization Cases</h3>
            <MemberDetailsComponentOtherCasesMobile />
            <MemberDetailsComponentOtherCasesDesktop />
          </div>
        ) : null}
      </section>
      <AttachmentsSidebar member={memberDetails} />
      <Modal
        title={providerData.title}
        description={`NPI: ${providerData.description}`}
        open={openProviderModal}
        className="confirmationModal"
        onClose={() => {
          setOpenProviderModal(false);
        }}
      >
        <div>
          <div className="mb-4">
            <span style={{ color: '#485373' }}>Class: </span>
            <span style={{ color: '#171539' }}>{providerData.class}</span>
          </div>
          <div className="mb-4">
            <span style={{ color: '#485373' }}>Company Id: </span>
            <span style={{ color: '#171539' }}>{providerData.companyId}</span>
          </div>
          <div className="mb-4">
            <span style={{ color: '#485373' }}>Contract: </span>
            <span style={{ color: '#171539' }}>{providerData.contract}</span>
          </div>
          <div className="mb-4">
            <span style={{ color: '#485373' }}>Contract Effective Date: </span>
            <span style={{ color: '#171539' }}>
              {providerData.contractEffectiveDate}
            </span>
          </div>
          <div className="mb-4">
            <span style={{ color: '#485373' }}>Termed Date: </span>
            <span style={{ color: '#171539' }}>{providerData.termedDate}</span>
          </div>
        </div>
        <div className="buttonsContainer flex justify-between mt-8">
          <button
            type="button"
            onClick={() => {
              setOpenProviderModal(false);
            }}
            className="btn"
          >
            Close
          </button>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSnackbarErrorOpen}
        autoHideDuration={8000}
        onClose={() => {
          setIsSnackbarErrorOpen(false);
        }}
        className="m-0 p-0 w-full min-w-0"
      >
        <Alert severity="error">
          There was an error. Server response: {serverResponse}{' '}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MemberDetailsComponent;

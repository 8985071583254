import { useEffect } from 'react';
import './App.css';
import AppRouter from './AppRouter';
import MainProvider from './providers/MainProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import { getCurrentPlan } from './planData';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { store } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const queryClient = new QueryClient();

function App() {
  const { site, auth0, name, faviconUrl } = getCurrentPlan();

  useEffect(() => {
    if (!sessionStorage.getItem('path')) {
      if (window.location.href.includes('auth0')) {
        sessionStorage.setItem('path', window.location.origin);
      } else {
        sessionStorage.setItem('path', window.location.href);
      }
    }
  }, []);

  useEffect(() => {
    (function (w, d, s) {
      if (
        window.location.href.includes('confirmation-account') ||
        site === 'providerportaladmin.allyalign.com' ||
        site === 'provider-portal-dev-admin.tutelarhealth.io' ||
        site === 'provider-portal-staging-admin.tutelarhealth.io' ||
        site === 'providers.simpra.com'
      ) {
        return;
      } else {
        var a = d.getElementsByTagName('head')[0];
        var r: any = d.createElement('script');
        r.async = 1;
        r.src = s;
        r.setAttribute('id', 'usetifulScript');
        r.dataset.token = '2f163021195a09cba71d64caa4705432';
        a.appendChild(r);
      }
    })(window, document, 'https://www.usetiful.com/dist/usetiful.js');
  }, [site]);

  return (
    <Auth0Provider
      domain={auth0.domain || ''}
      clientId={auth0.clientId || ''}
      cacheLocation="localstorage"
      redirectUri={window.location.origin}
      onRedirectCallback={() => {
        window.location.href = String(sessionStorage.getItem('path'));
      }}
    >
      {/* TODO: Check all providers and remove them where is possible */}
      <Provider store={store}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{name}</title>
          <link rel="icon" href={faviconUrl} />
        </Helmet>
        {/* REACT QUERY */}
        <QueryClientProvider client={queryClient}>
          {/* TODO: Clean Main provider */}
          <MainProvider>
            <AppRouter></AppRouter>
          </MainProvider>
        </QueryClientProvider>
      </Provider>
    </Auth0Provider>
  );
}

export default App;

import { CSSProperties, FC, ReactNode, useRef } from 'react';
import clsx from 'clsx';
import './ModalStyle.css';
import { FocusScope } from '@react-aria/focus';
import { Transition } from '@headlessui/react';
import { useOverlay, OverlayContainer } from '@react-aria/overlays';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
  className?: string;
  children?: ReactNode;
  open?: boolean;
  title?: string;
  description?: string;
  showHeader?: boolean;
  modalStyle?: CSSProperties;
  onClose: () => void;
}

const Modal: FC<Props> = ({
  children,
  className,
  open = false,
  title = '',
  description = '',
  modalStyle,
  onClose,
  showHeader = true,
  ...props
}) => {
  let ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  let { overlayProps } = useOverlay(
    {
      isOpen: open,
      isDismissable: false,
      onClose: onClose,
      ...props,
    },
    ref
  );

  const descriptionStyle: any = {
    color: '#023B64',
    fontWeight: 'normal',
    padding: '10px 0',
  };

  return (
    <Transition show={open}>
      <OverlayContainer>
        <FocusScope contain restoreFocus autoFocus>
          <div className={'root'}>
            <Transition.Child
              style={modalStyle}
              className={className}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={clsx('modal', 'relative')}
                {...overlayProps}
                ref={ref}
              >
                {showHeader && (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <article className="font-bold">
                        {className === 'confirmationModal error' ? (
                          <h2 style={{ color: 'red' }}>{title}</h2>
                        ) : (
                          <h2 style={{ color: '#003967' }}>{title}</h2>
                        )}

                        <p style={descriptionStyle}>{description}</p>
                      </article>
                    </div>

                    <hr />
                    <button
                      type="button"
                      onClick={() => onClose()}
                      aria-label="Close panel"
                      className="hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none absolute top-2 right-2"
                    >
                      <ClearIcon />
                    </button>
                  </>
                )}
                {children}
              </div>
            </Transition.Child>
          </div>
        </FocusScope>
      </OverlayContainer>
    </Transition>
  );
};

export default Modal;

import { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useStyles from '../MemberDetailsComponent/MemberDetailsStyle';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';

const MemberDetailsComponentOtherCasesMobile: FC = () => {
  const otherCases = useSelector(
    (state: RootState) => state.memberDetails.memberDetailsOtherCases
  );
  const classes = useStyles();

  const handleDetails = (selected: string) => {
    window.open(`/dashboard/${selected}`, '_blank');
  };

  return (
    <div>
      <div className="py-4 showMobile">
        {!otherCases.length && (
          <Accordion className="mb-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="flex flex-col w-full">
                <div>There is no other cases to show.</div>
              </div>
            </AccordionSummary>
          </Accordion>
        )}
        {otherCases && otherCases.length
          ? otherCases.map((item: any, key: number) => {
              return (
                <Accordion className="mb-4 showMobile" key={key}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="service-request-header"
                  >
                    <div className="flex justify-between w-full flex-col lg:flex-row">
                      <div className="font-bold">
                        <span className="text-gray-500">{item.authNumber}</span>
                      </div>
                      <div className="flex">
                        <div
                          className={clsx({
                            [classes.approved]: item.authStatus === 'APPROVED',
                            [classes.partiallyApproved]:
                              item.authStatus === 'PARTIALLY APPROVED',
                            [classes.done]: item.authStatus === 'DONE',
                            [classes.new]: item.authStatus === 'NEW',
                            [classes.serviceSubmitted]:
                              item.authStatus === 'SUBMITTED',
                            [classes.deniedMedicalNecessity]:
                              item.authStatus === 'DENIED-ADMINISTRATIVE' ||
                              item.authStatus === 'DENIED' ||
                              item.authStatus === 'DENIED - MEDICAL NECESSITY',
                            [classes.cancelled]:
                              item.authStatus === 'CANCELLED',
                            [classes.waiting]:
                              item.authStatus ===
                                `WAITING ON ADDT'L DOCUMENTS` ||
                              item.authStatus === `UNDER MEDICAL REVIEW`,
                          })}
                        >
                          {item.authStatus}
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full">
                      <hr className="mb-4" />
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Service from:</span>
                        <span className="font-bold">
                          {item.serviceFromDate
                            ? moment(item.serviceFromDate).format('L')
                            : null}
                        </span>
                      </div>
                      <div className="mb-2 flex flex-col">
                        <span className="text-gray-500">Service to: </span>
                        <span className="font-bold">
                          {item.serviceToDate
                            ? moment(item.serviceToDate).format('L')
                            : null}
                        </span>
                      </div>
                      <div className="mb-4 pb-4 flex flex-col">
                        <span className="text-gray-500">Diagnosis: </span>
                        <span className="font-bold">
                          {[
                            ...new Map(
                              item.diagnoses.map((item: any) => [
                                item['icdCode'],
                                item,
                              ])
                            ).values(),
                          ].map((item: any) => {
                            if (item.icdCode) {
                              return (
                                <Chip
                                  key={item.icdCode}
                                  className="mr-2 mb-2"
                                  title={item.diagnosisDescription}
                                  label={item.icdCode}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </span>
                        <div className="mb-2 flex flex-col">
                          <span className="text-gray-500">Procedures:</span>
                          <span className="font-bold">
                            {item.serviceRequests && item.serviceRequests.length
                              ? [
                                  ...new Map(
                                    item.serviceRequests.map((item: any) => [
                                      item['cptCode'],
                                      item,
                                    ])
                                  ).values(),
                                ].map((item: any) => {
                                  if (item.cptCode) {
                                    return (
                                      <Chip
                                        key={item.cptCode}
                                        className="mr-2 mb-2"
                                        title={item.description}
                                        label={item.cptCode}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                              : null}
                          </span>
                        </div>
                        <button
                          type="button"
                          className="btn btn-blue-transparent w-full mt-2"
                          onClick={() => {
                            handleDetails(item.authNumber);
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default MemberDetailsComponentOtherCasesMobile;

import { FC, useCallback, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';

import { ICustomCheck } from '../PortalUsersComponent/PortalUsersComponent';
import { RegistrationsMobile } from './RegistrationsMobile';
import { IUser } from '../../../interfaces/user';
import { userInitialState } from '../../../initial-values/user';
import { columns } from './RegistrationsColumns';
import { defaultFilterValue } from './RegistrationsFilterTable';
import Cookies from 'js-cookie';
import { downloadBlob } from '../../../utils/download-blob';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { useUsersQuery } from '../../../hooks/queries/useUsersQuery';
import { Alert } from '@material-ui/lab';
import { CSVLink } from 'react-csv';

const Registrations: FC = () => {
  const [isPaginated, setIsPaginated] = useState(true);
  const [isCSVDisabled, setIsCSVDisabled] = useState(false);
  const [currentData, setCurrentData] = useState<IUser[]>([userInitialState]);
  const history = useHistory();
  const [plans, setPlans] = useState<ICustomCheck[]>([
    {
      id: null,
      name: null,
      isActive: null,
    },
  ]);
  const [portalUsersFiltered, setPortalUsersFiltered] = useState<IUser[]>([]);
  const [gridRef, setGridRef] = useState<any>(null);
  const { data, isLoading, isError, error } = useUsersQuery({});
  const role = Cookies.get('aah-role');

  const filterCustomTypes: any = {
    ...ReactDataGrid.defaultProps.filterTypes,
    plansByState: {
      name: 'plansByState',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
  };

  const csvDownload = (usersFiltered: any[]) => {
    let headerRow: any[] = [
      'Case Id',
      'First Name',
      'Last Name',
      'Email',
      'Provider Name',
      'NPI',
      'State',
      'Plans',
      'Created At',
    ];
    let rows: any[][] = [];
    rows = usersFiltered.map((elem) => [
      elem.id,
      elem.profile.firstName,
      elem.profile.lastName,
      elem.userEmail,
      elem.profile.providerName,
      elem.profile.npi,
      elem.profile.state,
      elem.plansByState.join(' '),
      new Date(elem.createdAt).toLocaleString(),
    ]);
    rows.unshift(headerRow);
    return rows;
  };

  // Once a row is selected
  const onSelectionChange = useCallback(
    ({ selected }) => {
      history.push(`/admin/registrations/${selected}`);
    },
    [history]
  );

  const gridStyle = { minHeight: 650 };
  const rowStyle = { cursor: 'pointer' };

  const handleDetails = (authNumber: number) => {
    history.push(`/admin/registrations/${authNumber}`);
  };

  // TODO: Improve this
  useEffect(() => {
    if (data && data.length) {
      const hasBeenReviewed = data.filter(
        (item: IUser) => !item.hasBeenReviewed
      );

      for (let item of hasBeenReviewed) {
        item['firstName'] = item.profile.firstName;
        item['lastName'] = item.profile.lastName;
        item['providerName'] = item.profile.providerName;
        item['practiceName'] = item.profile.practiceName;
        item['npi'] = item.profile.npi;
        item['state'] = item.profile.state;
      }
      setCurrentData(hasBeenReviewed);
      setPortalUsersFiltered(hasBeenReviewed);

      // For Maps Filtering
      const plansMapped = hasBeenReviewed.map((item: any) => item.plansByState);
      const allPlans: any = [];
      plansMapped.forEach((item: any) => {
        allPlans.push(...item);
      });
      const distinctPlans = allPlans.filter(
        (value: any, index: number, self: any) => {
          return self.indexOf(value) === index;
        }
      );
      distinctPlans.unshift('All');
      const tempPlans: any = [];
      distinctPlans.forEach((item: string, index: number) => {
        tempPlans.push({
          id: index,
          name: item,
          isActive: false,
        });
      });
      tempPlans[0].isActive = true;
      setPlans(tempPlans);
    }
  }, [data]);

  const handlePlans = (value: any) => {
    value.isActive = !value.isActive;
    let plansActive = plans.filter((item) => item.isActive);
    let dataFiltered = [];

    if (value.name === 'All' || plansActive.length === 0) {
      plans.forEach((item: ICustomCheck) => {
        item.isActive = false;
      });
      plans[0].isActive = true;
    } else {
      if (plansActive[0].name === 'All') {
        plansActive.splice(0, 1);
        plans[0].isActive = false;
      }
    }
    dataFiltered = filter(currentData);
    setPortalUsersFiltered(dataFiltered);
  };

  const filterByLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataFiltered = currentData.filter((item) =>
      item.profile.lastName
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setPortalUsersFiltered(dataFiltered);
  };

  const filterByCaseId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataFiltered = currentData.filter((item) =>
      item.id.toString().includes(event.target.value.toString())
    );
    setPortalUsersFiltered(dataFiltered);
  };

  const filter = (currentData: IUser[]) => {
    let plansActive = plans.filter((item) => item.isActive);
    let dataFiltered: IUser[] = [];
    if (plansActive[0].name === 'All') {
      dataFiltered = currentData;
    } else {
      dataFiltered = currentData.filter((item: IUser) =>
        plansActive.every((val) => item.plansByState.indexOf(val.name!) !== -1)
      );
    }
    return dataFiltered;
  };

  const getRows = (ref: any) => {
    const dataFormatted = ref.map((item: any) => ({
      ...item,
      plansByState: item.plansByState.join(' '),
      createdAt: new Date(item.createdAt).toLocaleString(),
    }));
    return dataFormatted;
  };

  const exportCSV = () => {
    setIsPaginated(false);
    setIsCSVDisabled(true);
    setTimeout(() => {
      const columns = gridRef.current.visibleColumns;
      const header = columns.map((c: any) => c.header);
      const dataFormatted = getRows(gridRef.current.data);
      const rows = dataFormatted.map((data: any) =>
        columns.map((c: any) => data[c.id])
      );
      const contents = convertArrayToCSV(rows, {
        header,
        separator: ',',
      });
      const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
      downloadBlob(blob, 'Registrations.csv');
      setIsPaginated(true);
      setIsCSVDisabled((prev) => !prev);
    }, 1000);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className="m-8 p-4">
        There was an error on Server: {error}
      </Alert>
    );
  }

  return (
    <section className="customTable w-full px-4 md:px-8 mx-auto mb-16">
      <div className="showMobile">
        <div className="btn-div">
          {role === 'MD - Case Manager' || role === 'Admin' ? (
            <CSVLink
              data={csvDownload(data)}
              filename={'Registrations.csv'}
              className="btn btn-blue mb-4"
            >
              Export CSV
            </CSVLink>
          ) : (
            <button type="button" className="mb-4 btn btn-disabled" disabled>
              Export CSV
            </button>
          )}
        </div>
        <RegistrationsMobile
          plans={plans}
          handlePlans={handlePlans}
          filterByLastName={filterByLastName}
          filterByCaseId={filterByCaseId}
          portalUsersFiltered={portalUsersFiltered}
          handleDetails={handleDetails}
        />
      </div>
      <div className="showDesktop">
        <div className="flex justify-end">
          <button
            onClick={exportCSV}
            className={`btn mb-4 mt-4 ${
              isCSVDisabled || role === 'Viewer' ? 'btn-disabled' : 'btn-blue'
            }`}
            disabled={isCSVDisabled || role === 'Viewer'}
          >
            Export CSV
          </button>
        </div>
        <ReactDataGrid
          licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
          idProperty="id"
          columns={columns}
          dataSource={portalUsersFiltered}
          style={gridStyle}
          rowStyle={rowStyle}
          onReady={setGridRef}
          filterTypes={filterCustomTypes}
          defaultFilterValue={defaultFilterValue}
          pagination={isPaginated}
          enableSelection={true}
          onSelectionChange={onSelectionChange}
        />
      </div>
    </section>
  );
};

export default Registrations;

import { FC } from 'react';
import {
  ContactComponent,
  Footer,
  FooterBottom,
  Header,
  HeaderTitle,
} from '../../components';
import MainNavigation from '../../components/MainNavigation';

const Contact: FC<any> = (props) => {
  return (
    <div>
      <Header />
      <MainNavigation />
      <HeaderTitle title="Providers Contact Information" />
      <ContactComponent />
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default Contact;

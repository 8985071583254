import { FC } from 'react';
import moment from 'moment';

import { IMemberSearchResult } from '../../interfaces/interfaces';

interface IData {
  member: IMemberSearchResult;
}

const memberSearchResultLabels: FC<IData> = ({ member }) => {
  return (
    <div className="grid lg:grid-cols-2 xl:gap-6 mt-8">
      <div className="flex flex-col mb-4 xl:mb-0">
        <label className="label-top mb-2">Member Last Name</label>
        {member.memberLastName}
      </div>

      <div className="flex flex-col mb-4 xl:mb-0">
        <label className="label-top mb-2">Member First Name</label>
        {member.memberFirstName}
      </div>
      <div className="mb-4 xl:mb-0 width-date-100">
        <label className="label-top mb-2 block">Date of Birth</label>
        {member.memberDateOfBirth
          ? moment(member.memberDateOfBirth).format('MM/DD/yyyy')
          : null}
      </div>

      <div className="flex flex-col mb-4 xl:mb-0">
        <label className="label-top mb-2">Member ID</label>
        {member.memberId}
      </div>
      <div className="flex flex-col mb-4 xl:mb-0">
        <label className="label-top mb-2">Facility Name</label>
        {member.facilityName}
      </div>
      <div className="flex flex-col mb-4 xl:mb-0">
        <label className="label-top mb-2">Is Eligible:</label>
        {member.isEligible !== null && String(member.isEligible).toUpperCase()
          ? 'Yes'
          : 'No'}
      </div>
    </div>
  );
};

export default memberSearchResultLabels;

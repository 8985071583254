import { FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import useStyles from '../DashboardTable/dashboardStyle';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { IDashboardData } from '../../../store/authorizationCase/authorizationCase.slice';
import { downloadBlob } from '../../../utils/download-blob';

window.moment = moment;

interface IData {
  data: IDashboardData[] | [];
}

const DashboardTableCurrentDesktop: FC<IData> = ({ data }) => {
  const [gridRef, setGridRef] = useState<any>(null);
  const classes = useStyles();
  const history = useHistory();
  const [isPaginated, setIsPaginated] = useState(true);
  const [isCSVDisabled, setIsCSVDisabled] = useState(false);

  const plan = useSelector((state: RootState) => state.planData.data);
  const role = useSelector((state: RootState) => state.profile.data.role);

  const statusList = [
    {
      id: 'APPROVED',
      label: 'APPROVED',
    },
    {
      id: 'PARTIALLY_APPROVED',
      label: 'PARTIALLY APPROVED',
    },
    {
      id: 'DEFERRED',
      label: 'DEFERRED',
    },
    {
      id: 'DENIED--ADMINISTRATIVE',
      label: 'DENIED--ADMINISTRATIVE',
    },
    {
      id: 'DENIED - MEDICAL NECESSITY',
      label: 'DENIED - MEDICAL NECESSITY',
    },
    {
      id: 'OUT OF NETWORK INPATIENT',
      label: 'OUT OF NETWORK INPATIENT',
    },
    {
      id: 'PENDED',
      label: 'PENDED',
    },
    {
      id: 'CANCELLED',
      label: 'CANCELLED',
    },
    {
      id: `WAITING ON ADDT'L DOCUMENTS`,
      label: `WAITING ON ADDT'L DOCUMENTS`,
    },
    {
      id: `UNDER MEDICAL REVIEW`,
      label: `UNDER MEDICAL REVIEW`,
    },
    {
      id: `NOTIFICATION ONLY`,
      label: `NOTIFICATION ONLY`,
    },
  ];

  const lastMdReviewedStatus = [
    {
      id: 'SUBMITTED',
      label: 'SUBMITTED',
    },
    {
      id: 'PENDING PEER REVIEW',
      label: 'PENDING PEER REVIEW',
    },
    {
      id: 'DOCUMENTS ADDED',
      label: 'DOCUMENTS ADDED',
    },
    {
      id: 'PENDING DOCUMENTS',
      label: 'PENDING DOCUMENTS',
    },
    {
      id: 'No',
      label: 'No',
    },
  ];

  const [columns, setColumns] = useState([
    {
      name: 'authNumber',
      defaultFlex: 1,
      header: 'Case Id',
      minWidth: 180,
    },
    {
      name: 'companyId',
      defaultFlex: 1,
      header: 'Company Id',
    },
    {
      name: 'memberId',
      defaultFlex: 1,
      header: 'Member Id',
    },
    {
      name: 'memberFirstName',
      defaultFlex: 1,
      header: 'First Name',
    },
    {
      name: 'memberLastName',
      defaultFlex: 1,
      header: 'Last Name',
    },
    {
      name: 'serviceFromDate',
      defaultFlex: 1,
      header: 'Service From',
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Service From',
        };
      },
      render: (value: any) => {
        return value.data.serviceFromDate
          ? moment(value.data.serviceFromDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'serviceToDate',
      defaultFlex: 1,
      header: 'Service To',
      filterEditor: DateFilter,
      filterEditorProps: (value: any, index: number) => {
        return {
          dateFormat: 'MM/DD/YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Service To',
        };
      },
      render: (value: any) => {
        return value.data.serviceToDate
          ? moment(value.data.serviceToDate).format('MM/DD/YYYY')
          : null;
      },
    },
    {
      name: 'diagnoses',
      header: 'Diagnosis',
      defaultFlex: 1,
      render: (value: any) => {
        return value.value.map((item: any) => (
          <Chip
            key={item.icdCode}
            className="mr-2"
            title={item.diagnosisDescription}
            label={item.icdCode}
          />
        ));
      },
    },
    {
      name: 'serviceRequests',
      header: 'Procedures',
      defaultFlex: 1,
      render: (value: any) => {
        return value.value.map((item: any) => (
          <Chip
            key={item.cptCode}
            className="mr-2"
            title={item.description}
            label={item.cptCode}
          />
        ));
      },
    },
    {
      name: 'lastMDReviewedStatus',
      header: 'MD Reviewed',
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: lastMdReviewedStatus,
      },
      render: (value: any) => {
        return (
          <div
            style={{ display: 'flex' }}
            className={clsx({
              [classes.servicePended]: value.value === 'PENDING PEER REVIEW',
            })}
          >
            {value.data.lastMDReviewedStatus}
          </div>
        );
      },
    },
    {
      name: 'npi',
      defaultFlex: 1,
      header: 'Requesting Provider NPI',
    },
    {
      name: 'authStatus',
      header: 'Auth Status',
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: statusList,
      },
      render: (value: any) => {
        return (
          <div className="flex">
            <div
              style={{ display: 'flex' }}
              className={clsx({
                [classes.approved]: value.data.authStatus === 'APPROVED',
                [classes.partiallyApproved]:
                  value.data.authStatus === 'PARTIALLY APPROVED',
                [classes.deferred]: value.data.authStatus === 'DEFERRED',
                [classes.deniedAdministrative]:
                  value.data.authStatus === 'DENIED--ADMINISTRATIVE',
                [classes.deniedMedicalNecessity]:
                  value.data.authStatus === 'DENIED - MEDICAL NECESSITY',
                [classes.outOfNetwork]:
                  value.data.authStatus === 'OUT OF NETWORK INPATIENT',
                [classes.pended]: value.data.authStatus === 'PENDED',
                [classes.cancelled]: value.data.authStatus === 'CANCELLED',
                [classes.waiting]:
                  value.data.authStatus === `WAITING ON ADDT'L DOCUMENTS` ||
                  value.data.authStatus === `UNDER MEDICAL REVIEW`,
                [classes.notification]:
                  value.data.authStatus === `NOTIFICATION ONLY`,
              })}
            >
              {value.data.authStatus}
            </div>
          </div>
        );
      },
    },
  ]);

  const filterCustomTypes: any = {
    ...ReactDataGrid.defaultProps.filterTypes,
    diagnoses: {
      name: 'diagnoses',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.icdCode.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
    procedures: {
      name: 'procedures',
      operators: [
        {
          name: 'Has',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '') {
              return true;
            }
            const found = value.filter((item: any) =>
              item.cptCode.toLowerCase().includes(filterValue.toLowerCase())
            );
            return found.length ? true : false;
          },
        },
      ],
    },
    isReviewed: {
      name: 'isReviewed',
      operators: [
        {
          name: 'isReviewed',
          fn: ({
            value,
            filterValue,
            data,
          }: {
            value: any;
            filterValue: any;
            data: any;
          }) => {
            if (filterValue === '' || filterValue === false) {
              return true;
            }
            if (filterValue === null) {
              return !data.isReviewed;
            }
            if (filterValue) {
              return data.isReviewed;
            }
          },
        },
      ],
    },
  };

  // Filter for CURRENT TAB
  const currentFilterValue = [
    {
      name: 'authNumber',
      operator: 'eq',
      type: 'string',
      value: '',
    },
    {
      name: 'memberId',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'memberFirstName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'memberLastName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'serviceFromDate',
      operator: 'eq',
      type: 'date',
      value: '',
    },
    {
      name: 'serviceToDate',
      operator: 'eq',
      type: 'date',
      value: '',
    },
    {
      name: 'diagnoses',
      operator: 'Has',
      type: 'diagnoses',
      value: '',
    },
    {
      name: 'serviceRequests',
      operator: 'Has',
      type: 'procedures',
      value: '',
    },
    {
      name: 'companyId',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'lastMDReviewedStatus',
      operator: 'eq',
      type: 'select',
      value: null,
    },
    {
      name: 'npi',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'authStatus',
      operator: 'eq',
      type: 'select',
      value:
        sessionStorage.getItem('role') === 'MD - Reviewer' ||
        sessionStorage.getItem('role') === 'MD - Reviewer Restricted'
          ? 'UNDER MEDICAL REVIEW'
          : null,
    },
  ];

  const [columnOrder, setColumnOrder] = useState([
    'authNumber',
    'companyId',
    'memberId',
    'memberFirstName',
    'memberLastName',
    'serviceFromDate',
    'serviceToDate',
    'diagnoses',
    'serviceRequests',
    'lastMDReviewedStatus',
    'npi',
    'authStatus',
  ]);

  // For Sorting
  const defaultSortInfo: TypeSortInfo = {
    name: 'serviceFromDate',
    dir: -1,
    type: 'date',
  };

  useEffect(() => {
    if (sessionStorage.getItem('columnOrder')) {
      setColumnOrder(JSON.parse(sessionStorage.getItem('columnOrder') || ''));
    }
  }, []);

  useEffect(() => {
    if (
      plan.site !== 'providerportaladmin.allyalign.com' &&
      plan.site !== 'provider-portal-dev-admin.tutelarhealth.io' &&
      plan.site !== 'provider-portal-staging-admin.tutelarhealth.io' &&
      plan.site !== 'localhost:3000'
    ) {
      if (role === 'MD - Reviewer' || role === 'MD - Reviewer Restricted') {
        // Update Columns
        const foundCompanyId = columns.filter(
          (item) => item.name !== 'companyId'
        );

        setColumns(foundCompanyId);
        const columnOrderFiltered = columnOrder.filter(
          (item: any) => item !== 'companyId'
        );
        if (columnOrderFiltered.length) setColumnOrder(columnOrderFiltered);
      } else {
        // if user is in different site to Admin and is Not MD Reviewer
        // then remove companyId and lastMDReviewedStatus
        const foundCompanyIdAndLastMDReviewed = columns.filter(
          (item) =>
            item.name !== 'companyId' && item.name !== 'lastMDReviewedStatus'
        );
        setColumns(foundCompanyIdAndLastMDReviewed);
        const columnOrderFiltered = columnOrder.filter(
          (item: any) => item !== 'companyId' && item !== 'lastMDReviewedStatus'
        );
        setColumnOrder(columnOrderFiltered);
      }
    }
  }, [plan.site, role]);

  // On Changes

  const onFilterValueChange = useCallback((filterValue) => {
    sessionStorage.setItem('filters', JSON.stringify(filterValue));
  }, []);

  const onSortInfoChange = useCallback((sortInfo) => {
    sessionStorage.setItem('sorting', JSON.stringify(sortInfo));
  }, []);

  const onColumnOrderChange = useCallback((colOrder) => {
    setColumnOrder(colOrder);
    sessionStorage.setItem('columnOrder', JSON.stringify(colOrder));
  }, []);

  // Once a row is selected
  const onSelectionChange = useCallback(({ selected }) => {
    history.push(`/dashboard/${selected}`);
  }, []);

  const getRows = (ref: any) => {
    const dataFormatted = ref.map((item: any) => {
      return {
        ...item,
        serviceFromDate: moment(item.serviceFromDate).format('MM/DD/yyyy'),
        serviceToDate: moment(item.serviceToDate).format('MM/DD/yyyy'),
        diagnoses: String(item.diagnoses.map((x: any) => x.icdCode)),
        serviceRequests: String(
          item.serviceRequests.map((x: any) => x.cptCode)
        ),
      };
    });
    return dataFormatted;
  };

  // TODO: Convert this to a global function
  const exportCSV = () => {
    setIsPaginated(false);
    setIsCSVDisabled(true);
    setTimeout(() => {
      const columns = gridRef.current.visibleColumns;
      const header = columns.map((c: any) => c.header);
      const dataFormatted = getRows(gridRef.current.data);
      const rows = dataFormatted.map((data: any) =>
        columns.map((c: any) => data[c.id])
      );
      const contents = convertArrayToCSV(rows, {
        header,
        separator: ',',
      });
      const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
      downloadBlob(blob, 'cases.csv');
      setIsPaginated(true);
      setIsCSVDisabled(false);
    }, 1000);
  };

  const gridStyle = { minHeight: 650 };
  const rowStyle = { cursor: 'pointer' };

  return (
    <div className="showDesktop">
      <div className="flex justify-end">
        <button
          onClick={exportCSV}
          className={`btn mb-4 mt-4 ${
            isCSVDisabled || role === 'Viewer' ? 'btn-disabled' : 'btn-blue'
          }`}
          disabled={isCSVDisabled || role === 'Viewer'}
        >
          Export CSV
        </button>
      </div>

      <ReactDataGrid
        licenseKey="AppName=multi_app,Company=LogicCadence,ExpiryDate=2023-05-26,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=LogicCadenceLicenseRef,Z=-1641077796-162513427-491365839-2088127149-1641077796215708553"
        idProperty="authNumber"
        onReady={setGridRef}
        onDataSourceCacheChange={setGridRef}
        columns={columns}
        dataSource={data}
        style={gridStyle}
        rowStyle={rowStyle}
        filterTypes={filterCustomTypes}
        defaultFilterValue={
          sessionStorage.getItem('filters')
            ? JSON.parse(sessionStorage.getItem('filters') || '')
            : currentFilterValue
        }
        onFilterValueChange={onFilterValueChange}
        defaultSortInfo={
          sessionStorage.getItem('sorting')
            ? JSON.parse(sessionStorage.getItem('sorting') || '')
            : defaultSortInfo
        }
        onSortInfoChange={onSortInfoChange}
        reorderColumns={true}
        columnOrder={
          sessionStorage.getItem('columnOrder')
            ? JSON.parse(sessionStorage.getItem('columnOrder') || '')
            : columnOrder
        }
        onColumnOrderChange={onColumnOrderChange}
        pagination={isPaginated}
        enableSelection={true}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export default DashboardTableCurrentDesktop;

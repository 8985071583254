import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Cookies from 'js-cookie';
import { Banner } from '../Banner';

const titleStyle: any = {
  color: '#1e508c',
  letterSpacing: '0',
};

const Header: FC = () => {
  const { logout, user, isAuthenticated } = useAuth0();

  const role = useSelector((state: RootState) => state.profile.data.role);
  const plan = useSelector((state: RootState) => state.planData.data);

  const localLogout = () => {
    sessionStorage.clear();
    Cookies.remove('aah-token');
    Cookies.remove('aah-email');
    Cookies.remove('aah-role');
    Cookies.remove('aah-profileId');
    if (plan.site === 'localhost:3000') {
      logout({ returnTo: `http://localhost:3000/` });
    } else if (plan.site === 'providerportaladmin.allyalign.com') {
      logout({ returnTo: `https://providerportaladmin.allyalign.com` });
    } else if (plan.site === 'provider-portal-dev-admin.tutelarhealth.io') {
      logout({
        returnTo: `https://provider-portal-dev-admin.tutelarhealth.io`,
      });
    } else if (plan.site === 'provider-portal-staging-admin.tutelarhealth.io') {
      logout({
        returnTo: `https://provider-portal-staging-admin.tutelarhealth.io`,
      });
    } else if (plan.site === 'provider-portal-dev-ui.azurewebsites.net') {
      logout({ returnTo: `https://provider-portal-dev-ui.azurewebsites.net` });
    } else if (plan.site === 'provider-portal-staging-ui.azurewebsites.net') {
      logout({
        returnTo: `https://provider-portal-staging-ui.azurewebsites.net/`,
      });
    } else if (plan.site === 'keycareadvantageportal.allyalign.com') {
      logout({
        returnTo: `https://keycareadvantage.com/providers`,
      });
    } else if (plan.site === 'procareadvantageportal.allyalign.com') {
      logout({
        returnTo: `https://procareadvantageplan.com/providers`,
      });
    } else {
      const siteDot: any = plan.site.split('.');
      const finalUrl: any = `${siteDot[1]}.com`;
      logout({ returnTo: `https://${finalUrl}/providers` });
    }
  };

  return (
    <>
      <header>
        <p
          style={{
            marginBottom: '5px',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '15px 15px 0 15px',
          }}
        >
          ALERT
        </p>
        <Banner />
        <div className="flex flex-col md:flex-row justify-between items-center px-4 py-3.5 md:px-8">
          <div className="logo w-36 sm:w-56">
            <Link to="/">
              <img src={`${plan.logoUrl}`} alt="" />
            </Link>
          </div>
          {isAuthenticated ? (
            <div className="flex w-full justify-between md:justify-end">
              <img className="mr-4" src="/images/ic-user-grey.svg" alt="" />
              <div className="flex flex-col items-end align-middle">
                <span style={{ color: '#99a2ac' }}>Hi {user.name}</span>
                <span style={{ color: '#99a2ac' }}>{role}</span>
                <button
                  type="button"
                  className="flex justify-end"
                  style={titleStyle}
                  onClick={() => localLogout()}
                >
                  Logout
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </header>
    </>
  );
};

export default Header;

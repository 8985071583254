import { CSSProperties, FC, useEffect, useState } from 'react';
import planData, { IPlan, getCurrentPlan } from '../../planData';

const footerBottomStyle: any = {
  color: '#99a2ac',
  fontSize: '14px',
};

const FooterBottom: FC = () => {
  const [localPlanData, setLocalPlanData] = useState<IPlan>(planData[0]);

  useEffect(() => {
    const foundPlanData = getCurrentPlan();
    if (foundPlanData) {
      setLocalPlanData(foundPlanData);
    }
  }, []);

  return (
    <section className="py-4" style={footerBottomStyle}>
      <div className="w-full px-4 md:px-8 mx-auto">
        <div className="flex flex-col lg:flex-row justify-between">
          <span className="mb-2 lg:mb-0">
            {new Date().getFullYear()} © {localPlanData.name}. All rights
            reserved.
          </span>
          <span>{localPlanData.cmsIdNumber}</span>
        </div>
      </div>
    </section>
  );
};

export default FooterBottom;

import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import 'date-fns';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import PqiSuspectedInformation from './PqiSuspectedInformation';
import PqiGeneralInformation from './PqiGeneralInformation';
import PqiOcurrenceInformation from './PqiOcurrenceInformation';
import { useCreatePqiReferralMutation } from '../../../hooks/mutations/usePqiReferralsMutations';
import { useAuth0 } from '@auth0/auth0-react';
import { IPqiReferralsSubmit } from '../../../interfaces/pqi-referrals';
import { validationSchema } from './validationSchema';
import { pdf } from '@react-pdf/renderer';
import { GeneratePdf } from './GeneratePdf';
import Cookies from 'js-cookie';
import { Signature } from '../../DisclaimerStatement/Signature';
// import { GeneratePdfComponent } from './GeneratePdfComponent';
window.moment = moment;

interface IData {
  updateValue: (value: number) => void;
}

const PqiReferralsSubmitForm: FC<IData> = ({ updateValue }) => {
  const { user } = useAuth0();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarErrorOpen, setIsSnackbarErrorOpen] = useState(false);
  const profile = useSelector((state: RootState) => state.profile.data);
  const role = Cookies.get('aah-role');

  const initialValues: IPqiReferralsSubmit = {
    id: '',
    requestId: '',
    memberId: '',
    memberName: '',
    memberDateOfBirth: null,
    dateOfReferral: new Date(),
    sex: '',
    timeOfReferral: null,
    healthPlan: '',
    providerName: '',
    providerNumber: '',
    facilityName: '',
    facilityLocation: '',
    nameOfPersonSubmittingReferral: user.name,
    departmentAndTitle: '',
    contactInformation: '',
    suspectedCategory: [],
    dateOfOcurrence: null,
    timeOfOccurrence: null,
    wasPatientHospitalized: null,
    nameOfHospital: '',
    locationOfHospital: '',
    hospitalizationDate: null,
    hospitalizationTime: null,
    wasTheIncidentReportedToAStateAgency: null,
    agencyName: '',
    wasAPhysicianCalled: null,
    physicianRecomendation: '',
    briefDescriptionOfTheOccurrence: '',
    signatureName: '',
    signatureSource: '',
    signatureDate: null,
  };

  // Create Referral
  const { isLoading: isLoadingCreateReferral, mutate: createReferral } =
    useCreatePqiReferralMutation();

  // Submit Referral
  const handleRegister = async (payload: IPqiReferralsSubmit) => {
    // Generate PDF
    const blob = pdf(GeneratePdf(payload)).toBlob();
    blob.then((value) => {
      var reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onloadend = function () {
        var newFile = reader.result as string;
        const stripedFile = newFile.split(',')[1];
        payload.pdf = stripedFile;

        // Submit
        createReferral(payload, {
          onSuccess: () => {
            setIsSnackbarOpen(true);
            resetForm();
            setTimeout(() => {
              updateValue(1);
            }, 2500);
          },
          onError: (error) => {
            setIsSnackbarErrorOpen(true);
          },
        });
      };
    });
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiInputBase-root': {
          minHeight: '100px',
        },
      },
      customSnack: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    })
  );

  function Alert(props: AlertProps) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        className="w-1/3 p-0 m-0"
        {...props}
      />
    );
  }

  const classes = useStyles();

  const {
    handleSubmit,
    setFieldValue,
    values: formValues,
    getFieldProps,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let payload = { ...values };
      payload.id = profile.id;
      payload.requestId = uuidv4();
      handleRegister(payload);
    },
  });

  useEffect(() => {
    if (String(formValues.wasPatientHospitalized) === 'false') {
      setFieldValue('nameOfHospital', '');
      setFieldValue('locationOfHospital', '');
      setFieldValue('hospitalizationDate', null);
      setFieldValue('hospitalizationTime', null);
    }
    if (String(formValues.wasTheIncidentReportedToAStateAgency) === 'false') {
      setFieldValue('agencyName', '');
    }
    if (String(formValues.wasAPhysicianCalled) === 'false') {
      setFieldValue('physicianRecomendation', '');
    }
    if (formValues.nameOfPersonSubmittingReferral) {
      setFieldValue('signatureName', formValues.nameOfPersonSubmittingReferral);
    }
  }, [
    formValues.nameOfPersonSubmittingReferral,
    formValues.wasAPhysicianCalled,
    formValues.wasPatientHospitalized,
    formValues.wasTheIncidentReportedToAStateAgency,
    setFieldValue,
  ]);

  const isFormValid =
    isEmpty(errors) &&
    isValid &&
    dirty &&
    formValues.suspectedCategory.length &&
    (formValues.providerName || formValues.facilityName);

  return (
    <div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <PqiGeneralInformation
          role={role}
          formValues={formValues}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          getFieldProps={getFieldProps}
        />
        <PqiSuspectedInformation role={role} setFieldValue={setFieldValue} />
        <PqiOcurrenceInformation
          role={role}
          formValues={formValues}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          getFieldProps={getFieldProps}
          handleChange={handleChange}
        />

        <Signature
          formValues={formValues}
          setFieldValue={setFieldValue}
          hasNoPermissions={role === 'Viewer'}
          description="By signing, you are authorizing the Quality Department to render a formal review of your potential quality issue."
          isSignatureNameDisabled={true}
        />

        {isLoadingCreateReferral ? (
          <div className="px-4 flex flex-col items-center">
            <CircularProgress />
          </div>
        ) : (
          <button
            type="submit"
            className={clsx(
              {
                'btn-disabled': !isFormValid || role === 'Viewer',
                'btn btn-blue': isFormValid,
              },
              'btn mt-8'
            )}
            disabled={!isFormValid || role === 'Viewer'}
          >
            SEND REFERRAL
          </button>
        )}
      </form>
      <div className={classes.customSnack}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={() => {
            setIsSnackbarOpen(false);
          }}
          className="m-0 p-0 w-full min-w-0"
        >
          <Alert severity="success">
            Record has been submitted successfully!
          </Alert>
        </Snackbar>

        {/* ERROR */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSnackbarErrorOpen}
          autoHideDuration={3000}
          onClose={() => {
            setIsSnackbarErrorOpen(false);
          }}
          className="m-0 p-0 w-full min-w-0"
        >
          <Alert severity="error">
            There was an error submitting the record, please try again later!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default PqiReferralsSubmitForm;

import { FC, useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  Chip,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useGetUser,
  useGetRoles,
  useGetPlans,
  useUpdateUser,
  useActivateUser,
  useDeleteUser,
} from '../../../services/ServiceHook';
import { useHistory } from 'react-router-dom';
import Modal from '../../Modal';
import { IUser, IUserUpdate } from '../../../interfaces/user';
import {
  userInitialState,
  userInitialStateUpdate,
} from '../../../initial-values/user';

interface IResponseMessage {
  isGoodResponse: boolean | null;
  message: string;
}

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  groupNpis: Yup.array(),
  plansByState: Yup.array(),
  isActive: Yup.boolean().nullable(),
  role: Yup.string(),
  userEmail: Yup.string().email('Invalid email format'),
  hasPortalAccess: Yup.boolean().nullable(),
  profile: Yup.mixed(),
  hasBeenReviewed: Yup.boolean().nullable(),
  createdAt: Yup.string(),
  updatedAt: Yup.string(),
});

const inputStyle: any = {
  padding: '10px',
  border: '1px solid #D0D4D8',
  marginBottom: '15px',
};

const RegistrationsDetails: FC = () => {
  const history = useHistory();
  const [getUser] = useGetUser();
  const [deleteUser] = useDeleteUser();
  const [getRoles] = useGetRoles();
  const [getPlans] = useGetPlans();
  const [postUpdateUser] = useUpdateUser();
  const [postActivateUser] = useActivateUser();
  const { isAuthenticated } = useAuth0();
  const [localUser, setLocalUser] = useState<IUser>(userInitialState);
  const [localRole, setLocalRole] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [localNpis, setLocalNpis] = useState<any>([]);
  const [localIsActive, setLocalIsActive] = useState(false);
  const [isActiveMessage, setIsActiveMessage] = useState('');
  const [localHasPortalAccess, setLocalHasPortalAccess] = useState(false);
  const [localHasBeenReviewed, setLocalHasBeenReviewed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const [localRoles, setLocalRoles] = useState([]);
  const [localPlansByState, setLocalPlansByState] = useState([]);
  const [plansSelected, setPlansSelected] = useState<string[]>([]);
  const [previousPlans, setPreviousPlans] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [responseMessage, setResponseMessage] = useState<IResponseMessage>({
    isGoodResponse: null,
    message: '',
  });
  const [openModal, setOpenModal] = useState(false);
  let params = useParams<{ id: string }>();

  const initialValues = userInitialStateUpdate;
  interface IPlan {
    id: number;
    name: string;
    isSelected: boolean;
  }

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        setIsLoading(true);
        const data = {
          id: params.id,
          userEmail: null,
        };

        // Get Roles
        const roles = await getRoles();
        if (roles && roles.status === 200) {
          setLocalRoles(roles.data);
        }

        // Get Plans
        const plans = await getPlans();
        let plansMapped = [];

        // Format Plans
        if (plans && plans.status === 200) {
          plansMapped = plans.data.map((item: string, index: number) => ({
            id: index,
            name: item,
            isSelected: false,
          }));
          setLocalPlansByState(plansMapped);

          // Get User Data
          const userResponse = await getUser(data);
          if (userResponse && userResponse.status === 200) {
            delete userResponse.data.createdAt;
            delete userResponse.data.updatedAt;

            setLocalUser(userResponse.data);
            setValues(userResponse.data);
            setLocalRole(userResponse.data.role);

            // Is Active
            setLocalIsActive(userResponse.data.isActive);
            setFieldValue('isActive', userResponse.data.isActive);
            if (userResponse.data.isActive) {
              setIsActiveMessage('Deactivate');
            } else {
              setIsActiveMessage('Activate');
            }

            // Has Portal Access
            setLocalHasPortalAccess(userResponse.data.hasPortalAccess);
            setFieldValue('hasPortalAccess', userResponse.data.hasPortalAccess);

            // Has been reviewed
            setLocalHasBeenReviewed(userResponse.data.hasBeenReviewed);
            setFieldValue('hasBeenReviewed', userResponse.data.hasBeenReviewed);

            setFieldValue('groupNpis', '');
            // setLocalNpis(userResponse.data.groupNpis)

            // Set selected Plans
            if (userResponse.data.plansByState.length) {
              setPreviousPlans(userResponse.data.plansByState);
              setFieldValue('previousPlans', userResponse.data.plansByState);
              const tempArray: any = plansMapped;
              for (const plan of userResponse.data.plansByState) {
                const index: any = plansMapped.findIndex(
                  (item: IPlan) => item.name === plan
                );
                if (index >= 0) {
                  tempArray[index].isSelected = true;
                }
              }
              setLocalPlansByState(tempArray);
            }
          }
        }
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const plansSelected = localPlansByState
      .filter((item: IPlan) => item.isSelected)
      .map((found: IPlan) => found.name);
    setFieldValue('plansByState', plansSelected);
    setPlansSelected(plansSelected);
  }, [localPlansByState]);

  const onSubmit = async (
    data: IUserUpdate,
    formikHelpers: FormikHelpers<IUserUpdate>
  ) => {};

  const handleActivate = async () => {
    let data = formValues;
    const npisTrimmed = localNpis.map((item: string) => item.trim());
    data.groupNpis = npisTrimmed;
    data.firstName = formValues.profile.firstName;
    data.lastName = formValues.profile.lastName;
    setIsSaving(true);
    const response = await postUpdateUser(data);

    const activatePayload = {
      id: formValues.id,
      isActive: true,
    };
    const activateUser = await postActivateUser(activatePayload);

    if (activateUser?.status === 200) {
      // console.log('User activated')
    }
    setIsSaving(false);
    if (response && (response.status === 200 || response.status === 202)) {
      setResponseMessage({
        isGoodResponse: true,
        message: 'User updated successfully',
      });
      clearForm();
      setTimeout(() => {
        setResponseMessage({
          isGoodResponse: null,
          message: '',
        });
        history.push(`/admin/registrations/`);
      }, 3000);
    } else {
      setFieldValue('groupNpis', '');
      setResponseMessage({
        isGoodResponse: false,
        message: 'There was an error sending the form',
      });
    }
  };

  const clearForm = () => {
    resetForm();
  };

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
    values: formValues,
    getFieldProps,
    resetForm,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  function onInputChange(event: any) {
    const { value, name } = event.target;
    setFieldValue(name, value);
    setFieldTouched(name, true);
  }

  function handleAddNpis(value: any) {
    const x = value.split(',');
    const concat = [...localUser.groupNpis, ...x];
    const unique = concat.filter((localValue, index, self) => {
      return self.indexOf(localValue) === index;
    });
    setLocalUser({
      ...localUser,
      groupNpis: unique,
    });
    setLocalNpis(unique);
    setFieldValue('groupNpis', '');
  }

  function onSelectChange(event: React.ChangeEvent<any>) {
    setFieldValue(event.target.name, event.target.value);
    setLocalRole(event.target.value);
  }

  const handlePlanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLocalPlansByState((localPlansByState: any) => {
      return localPlansByState.map((x: any) => {
        if (x.name === event.target.name) {
          return { ...x, isSelected: event.target.checked };
        }
        return x;
      });
    });
  };

  const handleChipDelete = (e: any) => {
    const temp = localUser.groupNpis;
    const x = temp.findIndex((item) => item === e);
    temp.splice(x, 1);
    setLocalUser({
      ...localUser,
      groupNpis: temp,
    });
    // localUser.groupNpis
  };

  const handleDeleteUser = async (id: number) => {
    setIsDeleting(true);
    const response = await deleteUser(String(id));
    setIsDeleting(false);
    setOpenModal(false);

    if (response?.status === 200) {
      history.push('/admin/registrations');
    } else {
      console.log('there was en error deleting the record');
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="px-8 mb-8">
      <h2>
        {localUser.profile.firstName} {localUser.profile.lastName}
      </h2>
      <h3 className="">{localUser.userEmail}</h3>
      <h3>Id: {localUser.id}</h3>
      <form className="my-4 flex flex-col" onSubmit={handleSubmit}>
        <h3 className="my-4">Profile Information</h3>
        <div className="grid lg:grid-cols-2 gap-8">
          <table style={{ color: '#023B64' }}>
            <tr>
              <td>First Name</td>
              <td>{localUser.profile.firstName}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{localUser.profile.lastName}</td>
            </tr>
            <tr>
              <td>Job Role</td>
              <td>{localUser.profile.jobRole}</td>
            </tr>
            <tr>
              <td>Business Address</td>
              <td>{localUser.profile.businessAddress}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{localUser.profile.city}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{localUser.profile.state}</td>
            </tr>
            <tr>
              <td>Zip</td>
              <td>{localUser.profile.zip}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{localUser.profile.phone}</td>
            </tr>
            <tr>
              <td>
                <h3 className="mt-4">Provider Information</h3>
              </td>
            </tr>
            <tr>
              <td>Provider Name</td>
              <td>{localUser.profile.providerName}</td>
            </tr>
            <tr>
              <td>Practice Name</td>
              <td>{localUser.profile.practiceName}</td>
            </tr>
            <tr>
              <td>Provider Status</td>
              <td>{localUser.profile.providerStatus}</td>
            </tr>
          </table>
          <div>
            <div className="mt-8">
              <FormControl component="fieldset" className="w-full">
                <FormLabel component="legend">Plans by State</FormLabel>
                <FormGroup>
                  <div className="grid grid-cols-1 md:grid-cols-4  gap-4">
                    {localPlansByState.map((item: any, index) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            color="primary"
                            className="p-0"
                            checked={item.isSelected}
                            onChange={handlePlanChange}
                            name={item.name}
                          />
                        }
                        label={item.name}
                      />
                      // <FormControlLabel key={item} checked={checkboxStatus[item]} value={item} control={<Radio color="primary" />} label={item} />
                    ))}
                  </div>
                </FormGroup>
              </FormControl>
            </div>
            <div className="w-full mt-8">
              <FormLabel component="legend" className="my-4">
                Role
              </FormLabel>
              <FormControl variant="outlined" className="w-full">
                <Select
                  labelId="role"
                  id="role-select"
                  name="role"
                  value={localRole}
                  onChange={onSelectChange}
                >
                  {localRoles.map((item: string) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col mt-8">
              <FormLabel component="legend" className="my-2">
                Group NPIs
              </FormLabel>
              <p>Enter one or multiple, separating by commas</p>
              <textarea
                style={inputStyle}
                id="npis"
                {...getFieldProps('groupNpis')}
              />
              <button
                type="button"
                className="btn btn-green mb-4"
                onClick={() => handleAddNpis(formValues.groupNpis)}
              >
                Add
              </button>
              <div className="mb-4">
                {localUser.groupNpis.map((item: string, index: number) => (
                  <Chip
                    key={index}
                    label={item}
                    onDelete={() => {
                      handleChipDelete(item);
                    }}
                    style={{ marginRight: '10px', marginBottom: '15px' }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {!isSaving ? (
          <div className="flex justify-end">
            <button
              type="submit"
              className="btn inline-block btn-blue mr-2 md:w-auto mb-2 md:mb-0"
              onClick={handleActivate}
            >
              Activate
            </button>
            <button
              type="reset"
              className="btn inline-block btn-gray mr-2 md:w-auto mb-2 md:mb-0"
              onClick={() => {
                history.push(`/admin/registrations/`);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn inline-block btn-red md:w-auto mb-2 md:mb-0"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Delete
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <CircularProgress />
            <h3>Sending form...</h3>
          </div>
        )}
      </form>
      <div
        className={clsx(
          {
            'text-green-500': responseMessage.isGoodResponse,
            'text-red-500': !responseMessage.isGoodResponse,
          },
          'mt-4'
        )}
      >
        {responseMessage.message}
      </div>
      <Modal
        title="Are you sure you want to Delete this registration?"
        description="This registration will not be recoverable if deleted."
        open={openModal}
        className="confirmationModal"
        onClose={() => {
          setOpenModal(false);
        }}
      >
        {isDeleting ? (
          <div className="flex flex-col">
            Deleting...
            <CircularProgress />
          </div>
        ) : (
          <div className="buttonsContainer flex justify-between">
            <button
              type="button"
              onClick={() => {
                setOpenModal(false);
              }}
              className="btn"
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-green"
              onClick={() => {
                handleDeleteUser(localUser.id);
              }}
            >
              Yes
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RegistrationsDetails;
